import React from 'react';
import { Box, styled } from '@mui/material';

const TitleElement = styled(Box)(({ theme }) => ({
	fontSize: '24px',
	fontWeight: '600',
	color: theme.palette.primary.main,
	marginBottom: '4px',
}));

const ContainerTitle = ({ title }) => {
	return <TitleElement>{title}</TitleElement>;
};

export default ContainerTitle;
