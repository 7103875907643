import React, { useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Box, Divider } from '@mui/material';
import WarehouseP1 from './Components/WarehouseP1';
import OfficeP1 from './Components/OfficeP1';
import WarehouseP2 from './Components/WarehouseP2';
import OfficeP2 from './Components/OfficeP2';
import backgroundImage from '../../Assets/MatchingGame/matchBackground.png';

const MatchingGame = () => {
  const [value, setValue] = useState(0);

  const renderContent = () => {
    switch (value) {
      case 0:
        return <WarehouseP1 />;
      case 2:
        return <WarehouseP2 />;
      case 4:
        return <OfficeP1 />;
      case 6:
        return <OfficeP2 />;
      default:
        return <div>Start Content</div>;
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        height: '100vh',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        overflow: 'hidden',
      }}
    >
      <BottomNavigation
        sx={{ position: 'fixed', top: 0, width: '100%' }}
        value={value}
        onChange={(event, newValue) => {
          console.log('New value:', newValue);
          setValue(newValue);
        }}
        showLabels
      >
        <BottomNavigationAction
          label='Warehouse P1'
          sx={{ '& .MuiBottomNavigationAction-label': { fontSize: '1.2rem' } }}
        />
        <Divider orientation='vertical' flexItem />
        <BottomNavigationAction
          label='Warehouse P2'
          sx={{ '& .MuiBottomNavigationAction-label': { fontSize: '1.2rem' } }}
        />
        <Divider orientation='vertical' flexItem />
        <BottomNavigationAction
          label='Office P1'
          sx={{ '& .MuiBottomNavigationAction-label': { fontSize: '1.2rem' } }}
        />
        <Divider orientation='vertical' flexItem />
        <BottomNavigationAction
          label='Office P2'
          sx={{ '& .MuiBottomNavigationAction-label': { fontSize: '1.2rem' } }}
        />
      </BottomNavigation>
      <Box sx={{ mt: '50px' }}>{renderContent()}</Box>
    </Box>
  );
};

export default MatchingGame;
