import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, Switch, styled, useTheme } from '@mui/material';
import CategoryFilter from './CategoryFilter';
import CategoryAttributeList from './CategoryAttributeList';

const SwitchLabel = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '.8em',
  color: theme.palette.primary.main,
}));

const CategoryAttributeSwitchFilter = () => {
  const theme = useTheme();

  const [checked, setChecked] = useState(false);

  return (
    <Box>
      <Grid container justifyContent={'center'} alignItems={'center'}>
        <Grid item>
          <SwitchLabel sx={{ color: checked ? '#a7a7a7' : theme.palette.primary.main }}>categories</SwitchLabel>
        </Grid>
        <Grid item>
          <Switch checked={checked} onChange={() => setChecked(!checked)} inputProps={{ 'aria-label': 'controlled' }} />
        </Grid>
        <Grid item>
          <SwitchLabel sx={{ color: checked ? theme.palette.primary.main : '#a7a7a7' }}>attributes</SwitchLabel>
        </Grid>
      </Grid>

      {checked ? (
        <Box>attributes</Box>
      ) : (
        <>
          <CategoryFilter />
          <CategoryAttributeList />
        </>
      )}
    </Box>
  );
};
export default CategoryAttributeSwitchFilter;
