import { IconButton, TextField } from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';

const SearchBox = ({ label, handleInput, handleUserEnterSubmit, handleUserClickSubmit }) => {
  return (
    <>
      <TextField
        variant='outlined'
        label={label}
        onChange={handleInput}
        onKeyDown={handleUserEnterSubmit}
        InputProps={{
          endAdornment: (
            <IconButton onClick={handleUserClickSubmit}>
              <SearchIcon />
            </IconButton>
          ),
        }}
      />
    </>
  );
};

export default SearchBox;
