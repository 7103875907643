import { MenuBook } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';

const DigitalFlyerButton = ({ digitalFlyerURL }) => {
	const handleClick = () => {
		if (digitalFlyerURL) {
			window.open(digitalFlyerURL, '_blank'); // Open the URL in a new tab
		}
	};

	console.log('digitalFlyerURL: ' + digitalFlyerURL);
	return (
		<IconButton onClick={handleClick} disabled={!digitalFlyerURL} color={digitalFlyerURL ? 'error' : 'disabled'}>
			<MenuBook />
		</IconButton>
	);
};

export default DigitalFlyerButton;
