import React, { useContext, useEffect, useState } from 'react';
import { EntityContextState } from './../../../../../../Context/Entity/EntityContext';
import axios from 'axios';
import { Box, IconButton, Tooltip, useTheme } from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import PhoneEntryForm from './../PhoneMaintenance/PhoneEntryForm';
import ContainerAlpha from '../../../ContainerAlpha';
import ContainerTitle from '../../../ContainerTitle';

const PhoneManager = ({ contact_id }) => {
	const theme = useTheme();
	const [data, setData] = useState([]);
	const {
		triggerRefreshContactPhoneList,

		updateTriggerRefreshContactPhoneList,
		updateTriggerRefreshContactData,
	} = useContext(EntityContextState);

	// FUNCTION TO ADD PHONE NUMBER TO CONTACT
	const addPhoneNumberToContact = async (entity_contact_id) => {
		try {
			const response = await axios.post('/enity/addPhoneNumberToContact', {
				entity_contact_id: entity_contact_id,
				phone_type_id: 1,
				phone_number: '(888) 888-9999',
				description: '',
			});
			updateTriggerRefreshContactPhoneList();
			updateTriggerRefreshContactData();
		} catch (error) {
			console.error('Error:', error);
		}
	};

	useEffect(() => {
		let tempArray = [];
		const fetchData = async () => {
			try {
				const response = await axios.get(`/entity/getContactsPhoneNumbers/${contact_id}`);
				response.data.forEach((e) => {
					tempArray.push({
						phone_number_id: e.entity_contact_phone_number_id,
						phone_number: e.phone_number,
						phone_type_id: e.phone_type_id,
						description: e.description,
					});
				});
			} catch (error) {
				console.log('fetchContacts error: ', error);
			}
			setData([...tempArray]);
		};

		fetchData();
	}, [contact_id, triggerRefreshContactPhoneList]);

	return (
		<ContainerAlpha>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
				<ContainerTitle title='Phone Manager:' />
				<Box>
					<Tooltip title='Add Phone Number' placement='top'>
						<IconButton
							variant='contained'
							color='success'
							size='small'
							onClick={() => addPhoneNumberToContact(contact_id)}
						>
							<AddCircle />
						</IconButton>
					</Tooltip>
				</Box>
			</Box>
			<Box>
				{data.map((e, i) => (
					<PhoneEntryForm
						key={e.phone_number_id}
						contactId={contact_id}
						item={e}
						//handleCloseAndOpenPopover={handleCloseAndOpenPopover}
					/>
				))}
			</Box>
		</ContainerAlpha>

		// <Box>
		// 	<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
		// 		<Box sx={{ color: theme.palette.primary.main, fontWeight: 'bold', fontSize: '22px' }}>
		// 			Phone Number Maintenance :
		// 		</Box>
		// 		<Box>
		// 			<Tooltip title='Add Phone Number' placement='top'>
		// 				<IconButton
		// 					variant='contained'
		// 					color='success'
		// 					size='small'
		// 					sx={{}}
		// 					onClick={() => addPhoneNumberToContact(contact_id)}
		// 				>
		// 					<AddCircle />
		// 				</IconButton>
		// 			</Tooltip>
		// 		</Box>
		// 	</Box>
		// 	<Box>
		// 		{data.map((e, i) => (
		// 			<PhoneEntryForm
		// 				key={e.phone_number_id}
		// 				contactId={contact_id}
		// 				item={e}
		// 				//handleCloseAndOpenPopover={handleCloseAndOpenPopover}
		// 			/>
		// 		))}
		// 	</Box>
		// </Box>
	);
};

export default PhoneManager;
