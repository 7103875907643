import React, { useEffect, useState } from 'react';
import { Alert, Box, Snackbar, useTheme } from '@mui/material';
import SnackBarAlert from '../../../../_GlobalComponents/SnackBarAlert';

const ContactEmails = ({ emails }) => {
	const theme = useTheme();
	const [emailsState, setEmailsState] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [open, setOpen] = useState(false);

	const Content = () => {
		return (
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'centers' }}>
				{emailsState.map((email, idx) => (
					<React.Fragment key={email.entity_contact_email_id}>
						<Box
							sx={{
								padding: '4px 8px',
								cursor: 'pointer',
								transition: '400ms ease-in-out',
								borderRadius: '4px',
								fontWeight: 'bold',
								color: '#777',
								'&:hover': { backgroundColor: theme.palette.primary.main, color: 'white' },
							}}
							onClick={() => {
								navigator.clipboard.writeText(email.email_address);
								setOpen(true);
							}}
						>
							{email.email_address}
						</Box>
						{emailsState.length - 1 === idx
							? ''
							: '' //<Box style={{ display: 'inline-block', padding: '4px 8px' }}>|</Box>
						}
					</React.Fragment>
				))}
			</div>
		);
	};

	const ResetOpen = () => {
		setOpen(false);
	};

	useEffect(() => {
		const emailList = Array.from(emails).map((item) => JSON.parse(item));
		setEmailsState([...emailList]);
		setLoaded(true);
	}, [emails]);

	return (
		<>
			{loaded ? (
				<>
					<Content />
					<SnackBarAlert doOpen={open} ResetOpen={ResetOpen} message='Email Address Copied To Clipboard!' />
				</>
			) : (
				'---'
			)}
		</>
	);
};

export default ContactEmails;
