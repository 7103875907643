import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function SnackBarAlert({ message, severity, doOpen, ResetOpen }) {
	const [open, setOpen] = useState(false);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	useEffect(() => {
		const func = () => {
			setOpen(true);
			ResetOpen();
		};
		doOpen && func();
	}, [doOpen, ResetOpen]);

	return (
		<Snackbar
			sx={{ top: '100px !important', zIndex: 25000 }}
			open={open}
			autoHideDuration={2500}
			onClose={() => handleClose()}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
		>
			<Alert
				onClose={() => handleClose()}
				severity='success'
				variant='filled'
				sx={{ width: '100%', zIndex: '20000' }}
			>
				{message !== '' ? message : 'Action Taken!'}
			</Alert>
		</Snackbar>
	);
}
