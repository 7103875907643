import React, { useState } from 'react';
import { AddCircleOutline, Edit } from '@mui/icons-material';
import { Box, Button, Modal, useTheme } from '@mui/material';
import Form from './Actions/components/Form';

const initialData = {
	ss_publication_id: 0,
	ss_publication_code: '',
	ss_publication_type_id: '',
	description: '',
	detailed_description: '',
	submit_contract_date: '',
	submit_art_date: '',
	production_start_date: '',
	start_date: '',
	expiration_date: '',
};

const AddNewPublicationButton = () => {
	// INIT's
	const theme = useTheme();

	// LOCAL STATE
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<>
			<Button
				variant='contained'
				color='success'
				sx={{ fontWeight: 'bold', borderRadius: '8px' }}
				startIcon={<AddCircleOutline />}
				onClick={() => handleOpen()}
			>
				Create Publication
			</Button>
			<Modal
				open={open}
				onClose={() => handleClose()}
				aria-labelledby='Contracts'
				aria-describedby="Contact's Contracts"
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: `${theme.palette.primary.light}33`,
				}}
			>
				<Box sx={{ width: '90%', maxWidth: '650px' }}>
					<Form publicationData={initialData} />
				</Box>
			</Modal>
		</>
	);
};

export default AddNewPublicationButton;
