import React, { useEffect, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import {
	MaterialReactTable,
	useMaterialReactTable,
	MRT_ToggleFullScreenButton,
	MRT_ToggleDensePaddingButton,
	MRT_ShowHideColumnsButton,
	MRT_GlobalFilterTextField,
	MRT_ToggleFiltersButton,
} from 'material-react-table';
import { EntityContextState } from '../../../../../Context/Entity/EntityContext';
import { Box, useTheme } from '@mui/material';
import DeleteContactActionButton from './DeleteContactActionButton';
import RowEditAction from './RowEditAction';
import ContactName from './ContactName';
import ContactAddress from './ContactAddress';
import ContactEmails from './ContactEmails';
import ContactPhoneNumbers from './ContactPhoneNumbers';
import ContactMaterialTableDropDownMenu from './ContactMaterialTableDropDownMenu';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import CreateContactButton from './CreateContactButton';

// PRIMARY COMPONENT EXPORT
const ContactsMaterialTable = () => {
	const { id } = useParams();

	const theme = useTheme();
	const { triggerRefreshContactData, toggleUpdateContactDrawerOpener } = useContext(EntityContextState);

	const [selectedRowIds, setSelectedRowIds] = useState({});
	const [contacts, setContacts] = useState([]);
	const [mounted, setMounted] = useState(false);

	// COLUMN DEFINITIONS
	const columns = useMemo(
		() => [
			{
				accessorFn: (row) => row.entity_contact_id,
				id: 'entity_id',
				header: 'ID',
				Cell: ({ cell }) => (
					<Box sx={{ display: 'flex', justifyContent: 'space-around', gap: 4, alignItems: 'center' }}>
						<div>
							<RowEditAction id={cell.getValue()} />
						</div>
					</Box>
				),
				size: 150,
				grow: false,
			},

			{
				accessorFn: (row) => `${row.first_name} ${row.last_name} ${row.user_id}`,
				id: 'contact_name',
				header: 'Contact',
				Cell: ({ cell }) => {
					return (
						<ContactName
							userId={cell.row.original.user_id}
							firstName={cell.row.original.first_name}
							lastName={cell.row.original.last_name}
							imageId={'444'}
						/>
					);
				},
				size: 250,
				grow: false,
			},
			{
				accessorFn: (row) => `${row.address_1} ${row.city} ${row.state} ${row.postal_code}`,
				id: 'address',
				header: 'Address',
				Cell: ({ cell }) => {
					return (
						<ContactAddress
							street={cell.row.original.address_1}
							city={cell.row.original.city}
							state={cell.row.original.state}
							zip={cell.row.original.postal_code}
						/>
					);
				},
				size: 250,
				grow: true,
			},
			{
				accessorFn: (row) => `${row.emails}`,
				id: 'emails',
				header: 'Emails',
				Cell: ({ cell, i }) => {
					const emailListRaw = new Set(cell.row.original.emails_2.map((item) => JSON.stringify(item)));
					return <ContactEmails emails={emailListRaw} />;
				},
				size: 350,
				grow: false,
			},

			{
				accessorFn: (row) => `${row.phone_numbers}`,
				id: 'phone_numbers',
				header: 'Phone Numbers',
				Cell: ({ cell }) => {
					const phoneListRaw = new Set(cell.row.original.phone_numbers_2.map((item) => JSON.stringify(item)));
					const phoneList = Array.from(phoneListRaw).map((item) => JSON.parse(item));
					return <ContactPhoneNumbers items={phoneList} />;
				},
				size: 350,
				grow: false,
			},

			{
				accessorFn: (row) => row.deleted,
				id: 'active',
				header: 'Active',
				Cell: ({ cell, row }) => {
					return (
						<DeleteContactActionButton
							className={`value${cell.getValue()}`}
							isChecked={parseInt(cell.getValue()) === 1 ? false : true}
							contactId={cell.row.original.entity_contact_id}
						/>
					);
				},
				size: 150,
				grow: false,
			},
			{
				accessorFn: (row) => row.has_associations,
				id: 'associated',
				header: 'Associated',
				Cell: ({ cell, row }) => {
					return (
						<Box sx={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', pl: 2 }}>
							{cell.getValue() > 0 ? (
								<CheckBox color='success' />
							) : (
								<CheckBoxOutlineBlank color='disabled' />
							)}
						</Box>
					);
				},
				size: 150,
				grow: false,
			},
		],
		[]
	);

	// FETCH CONTACT DATA
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`/entity/getAllContacts`);
				setContacts([...response.data]);
			} catch (error) {
				console.log('fetchContacts error: ', error);
			}
		};

		fetchData();
	}, [triggerRefreshContactData]);

	useEffect(() => {
		contacts.length > 0 && setMounted(true);
	}, [contacts]);

	useEffect(() => {
		const myFunc = () => {
			setSelectedRowIds(id);
			toggleUpdateContactDrawerOpener(true);
		};
		id > 0 && myFunc();
	}, [id, toggleUpdateContactDrawerOpener]);

	const table = useMaterialReactTable({
		columns,
		data: contacts,
		enableStickyHeader: true,
		enableStickyFooter: true,
		enablePagination: true,
		initialState: {
			density: 'compact',
			pagination: { pageIndex: 0, pageSize: 100 },
			showGlobalFilter: true,
			columnOrder: ['entity_id', 'contact_name', 'address', 'emails', 'phone_numbers', 'active', 'associated'],
		},
		enableColumnResizing: true,
		enableRowSelection: false,
		state: { rowSelection: selectedRowIds },
		onRowSelectionChange: setSelectedRowIds,
		layoutMode: 'grid',
		muiTablePaperProps: { sx: { backgroundColor: 'white', padding: '18px', borderRadius: '18px' } },
		muiTableContainerProps: { sx: { maxHeight: '700px' } },
		muiTableBodyRowProps: { sx: { fontWeight: 'bold', backgroundColor: 'white' } },

		muiTableHeadRowProps: {
			sx: {
				backgroundColor: 'white',
				pt: 2,
				fontWeight: 'bold',
				height: 'auto',
				borderTopLeftRadius: '12px',
				borderTopRightRadius: '12px',
				mb: 0,
			},
		},
		muiTableHeadCellProps: {
			sx: {
				color: theme.palette.primary.main,
				fontWeight: 'bold',
				borderBottomColor: theme.palette.secondary.main,
				pb: 2,
			},
		},
		muiSearchTextFieldProps: {
			placeholder: 'Search Contacts',
			sx: { minWidth: '500px' },
			variant: 'outlined',
		},

		renderTopToolbar: () => (
			<Box sx={{ alignItems: 'center', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '15px' }}>
				<Box sx={{ gridColumn: 'span 2' }}>
					<MRT_GlobalFilterTextField table={table} />
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<CreateContactButton />
					<MRT_ToggleDensePaddingButton table={table} />
					<MRT_ToggleFiltersButton table={table} />
					<MRT_ShowHideColumnsButton table={table} />
					<MRT_ToggleFullScreenButton table={table} />
					{/* <ContactMaterialTableDropDownMenu /> */}
				</Box>
			</Box>
		),
	});

	return (
		<>
			{mounted && (
				<Box className='ManageContactsWrapper' sx={{ px: 6, pt: 6, boxSizing: 'border-box', height: '100%' }}>
					<MaterialReactTable table={table} />
				</Box>
			)}
		</>
	);
};

export default ContactsMaterialTable;
