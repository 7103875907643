import {
  Grid,
  TextField,
  Typography,
  FormControl,
  Button,
  CircularProgress, 
  Backdrop,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper 
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import * as XLSX from 'xlsx';
import React, { useState, useEffect } from 'react';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const UpdateCustProbCForm = () => {
  // onchange states
  const [excelFile, setExcelFile] = useState(null);
  const [customerNumber, setCustomerNumber] = useState(''); 
  const [textSpan, setTextSpan] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [initialPull, setInitialPull] = useState(false);

  // submit states
  const [showHeader, setShowHeader] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [customerArray, setCustomerArray] = useState([]);
  // Loading state
  const [isLoading, setIsLoading] = useState(false); 

  useEffect(() => {    
    if(excelFile !== null) {
      const workbook = XLSX.read(excelFile, { bookType: "xlsx", type: 'array' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      
      const newArray = jsonData.filter((element) => element[0] > 0).map(function(element){
        const container = {};
        container['customer'] = element[0];          
        return container.customer;
      });
      // XXXXXXXXXXXXXXXXXXXXXXXX
      setCustomerArray(newArray);
      // XXXXXXXXXXXXXXXXXXXXXXXX
      setInitialPull(true);      
      // XXXXXXXXXXXXXXXXXX
    } else {
      console.log('nothing to call');
    }
  }, [excelFile]);

  useEffect(() => {
    console.log('hit other useEffect');  
    if (initialPull === true) {
      let whatParam;

      customerNumber >1 ? whatParam = customerNumber: whatParam = customerArray;      
      // XXXXXXXXXXXXXXXX 
      setIsLoading(true);
      // XXXXXXXXXXXXXXXX
      axios
      .post('/hubRelay', {
        j: '/webservice/hub/helpdesk_utilities/accounts/update_cuprob_c.php?INITIAL_PULL='+whatParam
      })
      .then((response) => {
        // handle the response        
        response.data.RESULT === 'OK' ? console.log(response.data.INITIAL_CUST_LIST) : console.log('ERROR');

        if(response.data.RESULT === 'OK') {
          // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
          setTableData(response.data.INITIAL_CUST_LIST);
          // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
          setShowHeader(true);
        } else {
          setTableData([]);
          setShowHeader(false);
        }
        
        setIsLoading(false);
        // XXXXXXXXXXXXXXXXXX
        setInitialPull(false);
        // XXXXXXXXXXXXXXXXXXX
      })
      .catch((error) => {
        // handle errors
        console.log(error);
      });
    } else {
      console.log('initial pull == false');
    }
    
  }, [initialPull]);

  const handleFile = (e) => {
    e.preventDefault();    
    const file = e.target.files[0];
    file ? console.log('file type', file.type) : console.log('please select your file...');    
    // set the file name for the textfield
    // XXXXXXXXXXXXXXXXXXXX
    setFileName(file.name);
    // XXXXXXXXXXXXXXXXXXXX    
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload=(e)=>{
      // XXXXXXXXXXXXXXXXXXXXXXXXXXX
      setExcelFile(e.target.result);
      // XXXXXXXXXXXXXXXXXXXXXXXXXXX     
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // XXXXXXXXXXXXXXXX
    setIsLoading(true);
    // XXXXXXXXXXXXXXXX
    if(excelFile !== null || customerNumber !== null) {            
      axios
      .post('/hubRelay', {
        j: '/webservice/hub/helpdesk_utilities/accounts/update_cuprob_c.php?EXCEL_DATA='+customerArray+'&CUST_INPUT='+customerNumber
      })
      .then((response) => {
        // handle the response
        console.log(response.data);
        // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
        setTableData(response.data.UPDATED_CUST_LIST);
        // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
        setShowHeader(true);
        setIsLoading(false);
        // XXXXXXXXXXXXXXXXX
      })
      .catch((error) => {
        // handle errors
        console.log(error);
      });

    } else {
      // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
      setTextSpan('Please upload a file or input a customer number...');
      // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
      setShowHeader(false);
      setIsLoading(false);
      // XXXXXXXXXXXXXXXXX
    }
  };

  const handleClear = (e) => {
    e.preventDefault();
    console.log('checking states');
    // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    setTableData((tableData) => [...tableData, []]);
    // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    // setInitialPull((initialPull) => [...initialPull, false]);
    // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    setCustomerNumber('');
    setShowHeader(false);
    // setExcelFile(null);
  };

  const handleCustomerNumber = (e) => {
    e.preventDefault();
    setCustomerNumber(e.target.value);
    e.target.value > 1 ? setInitialPull(true): setInitialPull(false);
  };

  return (
    <Grid container spacing={5} sx={{ p: 4 }}>      
      <Grid item xs={12}>
        <Typography sx={{textAlign: 'left', mt: 1}}>Customer Numbers must be in column A </Typography>
      </Grid>      
      <Grid item xs={10} sm={8} md={6} style={{paddingTop: '20px'}}>
        <form>            
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              color='secondary'
              startIcon={<CloudUploadIcon />}
            >
              Upload file
              <VisuallyHiddenInput type="file" onChange={handleFile}/>
            </Button>            
        </form>
      </Grid>
      <Grid item xs={12} sm={12} md={6} style={{paddingTop: '20px'}}>
        {fileName && <Typography variant='h6' sx={{textAlign: 'left', mt: 1}}>{fileName}</Typography>}
      </Grid>      
      <Grid item xs={12} style={{paddingTop: '20px'}}>or enter a single customer number</Grid>
      <Grid item xs={8} sm={8} md={6} sx={{textAlign: 'left', mt: 1}} style={{paddingTop: '20px'}}>
        <FormControl fullWidth={true}>
          <TextField
            value={customerNumber}
            onChange={handleCustomerNumber}
            label="CUSTOMER #"
          />
        </FormControl>
      </Grid>
      <Grid item xs={4} sm={4} md={6}></Grid>
      <Grid item xs={4} md={2} style={{paddingTop: '20px'}}>
        <Button variant='contained' color='primary' onClick={handleSubmit}>
          Submit
        </Button>          
      </Grid>
      <Grid item xs={4} md={2} style={{paddingTop: '20px'}}>          
        <Button variant='contained' color='error' onClick={handleClear}>
          Clear
        </Button>
      </Grid>
      <Grid item xs={4} md={8} style={{paddingTop: '20px'}}>
        {textSpan && <Typography variant='h6' sx={{textAlign: 'left', mt: 1}}>{textSpan}</Typography>}
      </Grid>
      <Grid item xs={12}>
        {(showHeader ==true) &&
        <Paper sx={{ width: '100%', overflowX: 'initial' }}>
          <TableContainer sx={{ maxHeight: 300 }}>          
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>                  
                  <TableRow>                        
                    <TableCell component="th" scope="row">CUSNO</TableCell>
                    <TableCell component="th" scope="row">CUSNM</TableCell>
                    <TableCell component="th" scope="row">CUPROB</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>                                        
                {tableData.map((rowData, index)=>(
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th, & td': { border: 0 }}}>
                    {Object.keys(rowData).map((index)=>(
                      <TableCell key={index} component="td" scope="row">{rowData[index]}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          
          </TableContainer>
        </Paper>
        }
      </Grid>       
      {isLoading && (
        <Backdrop
          open={isLoading}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}
        >
          <CircularProgress sx={{color: '#0FFF00'}} />
        </Backdrop>
      )}
    </Grid>
  );
};

export default UpdateCustProbCForm;
