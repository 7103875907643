import { Grid, Typography } from '@mui/material';
import React from 'react';
import PromotionCard from './PromotionCard';
import moment from 'moment-timezone';

const CurrentPromotions = ({ promotions }) => {
  moment.tz.setDefault('America/Chicago');
  const currentDate = moment();

  const validPromotions = promotions
    .filter((promotion) => {
      const startTime = moment(promotion.start_time);
      const endTime = moment(promotion.end_time);
      return currentDate.isSameOrAfter(startTime) && currentDate.isSameOrBefore(endTime);
    })
    .sort((a, b) => {
      const startTimeA = moment(a.start_time);
      const startTimeB = moment(b.start_time);
      return startTimeA - startTimeB;
    });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Typography variant='h4' gutterBottom>
          Current
        </Typography>
      </Grid>
      {validPromotions.map((promotion, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <PromotionCard promotion={promotion} />
        </Grid>
      ))}
    </Grid>
  );
};

export default CurrentPromotions;
