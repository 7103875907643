import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pdmCloudSearchResultsValue } from './../../../../Redux/PDMSlice';
import { as400ExtendedDescriptionProductIdsValues } from './../../../../Redux/ReductionDataSlice';

import axios from 'axios';

// Material UI
import { Box, styled, TextField } from '@mui/material';

// Material UI Styled Components
const Wrapper = styled(Box)(({ theme }) => ({
  padding: 16,
  textAlign: 'center',
}));

// Primary Component
const SearchTerm1 = () => {
  const dispatch = useDispatch();
  const [uniqAttr, setUniqAttr] = useState([]);
  //const [items, setItems] = useState([]);
  //const as400DescResultProductIds = useSelector((state) => state.productSlice.as400DescResultProductIds);

  // get product id from term
  const getItems = async (textTerm) => {
    const itemList = [];
    const numberData = isNaN(textTerm) ? -1 : textTerm;
    textTerm.length > 1
      ? await axios
          .get(`/products/pdmAWSCloudSearch/${textTerm}`)
          .then(function (response) {
            console.log('full data: ', response.data);
            dispatch(pdmCloudSearchResultsValue(response.data));

            // console.log(
            //   'fields: ',
            //   response.data.hits.hit.map((item) => Object.keys(item.fields).map(f => console.log('fieldKey: ' + f)))
            // );
            response.data.hits.hit.map((item) => Object.entries(item.fields).map((e) => console.log(e[0] + ' : ' + e[1])));

            //.map((v) => v.join(',')
            // response.data.hits.hit.map((item) => itemList.push(item.id));

            //response.data.hits.hit.map((item) => item.fields);

            // console.log(
            //   'fields',
            //   response.data.hits.hit.map((item) => item.fields.catgroup.map((a) => console.log('hello' + a)))
            // );

            // const newArray = array.map(element => {
            //   var result = element * 2;
            //   return result;
            //  });

            // response.data.hits.hit.map((item) => item.fields.map((attr) => uniqAttr.indexOf(attr) === -1 && uniqAttr.push(attr)));

            //uniqAttr.indexOf(item.fields.brand) === -1 && uniqAttr.push(item.fields.brand)
            uniqAttr.sort();
            console.log('attrs: ', uniqAttr);
            dispatch(as400ExtendedDescriptionProductIdsValues(itemList));
          })
          .catch((error) => {
            console.log('error: ' + error);
          })
      : // ? await axios
        //     .get(`/products/getProductIdFromSearchTerm/${textTerm.toLowerCase()}/${numberData}`)
        //     .then(function (response) {
        //       // setItems([]);
        //       // console.log('searchterm: ' + textTerm.toLowerCase());
        //       response.data.map((item) => itemList.push(item.product_id)); //itemList((prev) => [...prev, item.product_id]));
        //       dispatch(as400DescResultProductIdsValue(itemList));
        //     })
        // .catch((error) => {
        //   console.log('error: ' + error);
        // })
        console.log('serchterm = 0 or blank');
  };

  return (
    <Wrapper>
      <TextField sx={{ width: 'calc(100% - 16px)' }} label='SS# / UPC# / AS400' onKeyUp={(e) => e.key === 'Enter' && getItems(e.target.value)} />
    </Wrapper>
  );
};
export default SearchTerm1;
