import React, { useEffect, useMemo, useState } from 'react';
import { Box, Chip } from '@mui/material';
import ContractsByBridAction from './ContractsByBridAction';
//import DashBoardActions from './DashBoardActions';
//import { getSubBrandsByMfrId } from '../ManageContracts/Feeds/ContractsFeed';
import { getPublicationByPubId } from '../../../../Publications/Feed/PrimaryFeed';

const useColumns = () => {
	return useMemo(
		() => [
			{
				accessorKey: 'ss_publication_contract_id',
				id: 'ss_publication_contract_id',
				header: 'Contract_id',
				Cell: ({ cell }) => <ContractsByBridAction item={cell.row.original} />,
				size: 166,
				grow: false,
			},
			{
				accessorKey: 'sold_price',
				id: 'sold_price',
				header: 'Sold Price',
				size: 166,
				grow: false,
			},
			{
				accessorKey: 'time_created',
				id: 'time_created',
				header: 'Created',
				size: 166,
				grow: false,
			},
			{
				accessorKey: 'product',
				id: 'product',
				header: 'Product',
				size: 166,
				grow: false,
			},
			{
				accessorKey: 'status',
				id: 'status',
				header: 'Status',
				size: 166,
				grow: false,
			},
			{
				accessorKey: 'last_status_change',
				id: 'last_status_change',
				header: 'Status Changed',
				Cell: ({ cell }) => (cell.getValue() ? cell.getValue() : '---'),
				size: 166,
				grow: false,
			},

			{
				accessorKey: 'ss_publication_id',
				id: 'ss_publication_id',
				header: 'Publication',
				Cell: ({ cell }) => {
					const [pubData, setPubData] = useState([]);
					useEffect(() => {
						const fetchData = async () => {
							try {
								const returnedData = await getPublicationByPubId(parseInt(cell.getValue()));
								setPubData(returnedData);
							} catch (error) {
								console.log('error: ', error);
							}
						};
						fetchData();
					}, [cell]);

					return <>{pubData[0]?.description}</>;
				},
				size: 166,
				grow: false,
			},

			// Add more column definitions as needed
		],
		[]
	);
};

export default useColumns;
