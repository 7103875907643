import React, { useEffect, useState } from 'react';
import { Edit } from '@mui/icons-material';
import { Box, Button, Drawer, Tooltip } from '@mui/material';

import ContactsForEntity from '../ManageContracts/components/ContactsForEntity/ContactsForEntity';
import ContractFormMobile from '../ManageContracts/components/ContractFormMobile';
import ContractForm from '../ManageContracts/components/ContractForm';
import { getContractDetailsByContractId } from '../ManageContracts/Feeds/ContractsFeed';

const DashBoardActions = ({ entityId, contractId }) => {
	// LOGOL STATE
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [initialData, setInitialData] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const returnData = await getContractDetailsByContractId(contractId);
				setInitialData(returnData);
			} catch (error) {
				console.log('error: ', error);
			}
		};
		fetchData();
	}, [contractId]);

	const handleEditClick = () => {
		setDrawerOpen(true);
	};

	return (
		<>
			<Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
				<Tooltip title={`Edit Contract ${contractId}`}>
					<Button
						variant='contained'
						sx={{ borderRadius: '14px' }}
						size='small'
						color='warning'
						startIcon={<Edit />}
						onClick={() => handleEditClick()}
					>
						<span style={{ fontWeight: 'bold' }}>Edit</span>
					</Button>
				</Tooltip>

				<ContactsForEntity entityId={entityId} />
			</Box>
			<Drawer
				anchor={'right'}
				open={drawerOpen}
				onClose={() => {
					setDrawerOpen(false);
				}}
			>
				{/* <ContractFormMobile contractId={contractId} userid={54155} /> */}
				<ContractForm contractId={contractId} userid={54155} initialData={initialData} />
			</Drawer>
		</>
	);
};

export default DashBoardActions;
