import React, { useState } from 'react';
import axios from 'axios';
import Dayjs from 'dayjs';

export const PublicationContextState = React.createContext({
	triggerRefreshPublicationData: 0,
	updateTriggerRefreshPublicationData: () => {},
	publicationDrawerOpener: false,
	updatePublicationDrawerOpener: () => {},
	updateSelectedPublicationId: () => {},
	triggerRefreshContractData: 0,
	updateTriggerRefreshContractData: () => {},
});

// PRIMARY COMPONENT
const PublicationContext = ({ children }) => {
	// LOCAL STATE
	const [triggerRefreshPublicationDataState, setTriggerRefreshPublicationDataState] = useState(0);
	const [publicationDrawerOpenerState, setPublicationDrawerOpenerState] = useState(false);
	const [selectedPublicationIdState, setSelectedPublicationIdState] = useState(0);
	const [triggerRefreshContractDataState, setTriggerRefreshContractDataState] = useState(0);

	// FUNCTIONS
	const updateTriggerRefreshPublicationData = () => {
		setTriggerRefreshPublicationDataState(triggerRefreshPublicationDataState + 1);
	};
	const updatePublicationDrawerOpener = (status) => {
		setPublicationDrawerOpenerState(status);
		//status === false && updateSelectedPublicationId(0);
	};
	const updateSelectedPublicationId = (id) => setSelectedPublicationIdState(id);

	const updateTriggerRefreshContractData = () =>
		setTriggerRefreshContractDataState(triggerRefreshContractDataState + 1);

	const contextValue = {
		triggerRefreshPublicationData: triggerRefreshPublicationDataState,
		updateTriggerRefreshPublicationData,
		publicationDrawerOpener: publicationDrawerOpenerState,
		updatePublicationDrawerOpener,
		selectedPublicationId: selectedPublicationIdState,
		updateSelectedPublicationId,
		triggerRefreshContractData: triggerRefreshContractDataState,
		updateTriggerRefreshContractData,
	};
	return <PublicationContextState.Provider value={contextValue}>{children}</PublicationContextState.Provider>;
};
export default PublicationContext;
