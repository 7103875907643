import {
  Grid,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Select,
  MenuItem,
  Radio,
  Button,
  Divider,
  Box,
  CircularProgress, 
  Backdrop  
} from '@mui/material';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';

const LookupMfgAssignedBuyerForm = () => {

  // State For Form Entry
  const [mfgNumber, setMfgNumber] = useState('');
  const [buyer, setBuyer] = useState('');
  const [getBuyersResult, setGetBuyersResult] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [searchResultMessage, setSearchResultMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Loading state


  useEffect(() => {
    setIsLoading(true);
    axios
    .post('/hubRelay', { j: "/webservice/hub/helpdesk_utilities/general/basic_buyer_info.php" })
    .then((response) => {
      console.log(response.data);      
      setGetBuyersResult(response.data.BUYERS);
      setIsLoading(false);
    })
    .catch((error) => {
      console.log(error);
    });
  }, []);
  
  // Handle Submit For Item Lookup
  const handleSubmit = () => {
    console.log('mfgNumber in submit' + mfgNumber);
    console.log('buyer in submit' + buyer);

    setIsLoading(true);

    let params;

    if(buyer && mfgNumber) {
      params = '&MFG_NUMBER='+mfgNumber;
    } else if(!buyer && mfgNumber) {      
      params = '&MFG_NUMBER='+mfgNumber;
    } else if(buyer && !mfgNumber){
      params = '&BUYER='+buyer;
    }

    // buyer ? params = '&BUYER='+buyer : '';
    // mfgNumber ? params = '&MFG_NUMBER='+mfgNumber : '';

    console.log('params :' +params);

    axios
    .post('/hubRelay', {
      j: '/webservice/hub/helpdesk_utilities/general/basic_mfg_info.php?'+params
    })
    .then((response) => {
      console.log(response.data);
      setSearchResult(response.data.DATA);
      
      setShowTable(true);
      setIsLoading(false);
    })
    .catch((error) => {
      console.log(error);
      handleRefresh();
    });
    
    
  };

  // const handleBuyerChange = (e) => {
  //   // e.preventDefault();
    
  //   console.log('buyer in handle :' + buyer);

  // };

  
  const handleRefresh = () => {
    setMfgNumber('');
    setBuyer('');
    setSearchResult('');
    setSearchResultMessage('');
    setShowTable(false);    
  };


  return (
    <Grid container spacing={5} alignItems='center' sx={{ p: 4 }}>

      <Box sx={{ overflowY: 'auto', mx: '1em', mb: '1em', minHeight: '90vh', maxHeight: '90vh', width: '30%', pl: '.5em' }}>        
        <Grid item xs={12} style={{paddingLeft: '2em',paddingTop: '1px'}}>
          <Typography variant='h6' sx={{textAlign: 'left'}} color="error">MANUFACTURER</Typography>
        </Grid>
        <Grid item xs={12} style={{paddingLeft: '2em',paddingTop: '10px'}}>
          <FormControl fullWidth={true}>
            <TextField
              value={mfgNumber}            
              onChange={(e) => setMfgNumber(e.target.value)}
              label="Mfg #"            
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} style={{paddingLeft: '2em',paddingTop: '20px'}}>
          <Typography variant='h6' sx={{textAlign: 'left'}} color="primary">BUYER</Typography>
        </Grid>

        <Grid item xs={12} style={{paddingLeft: '2em',paddingTop: '10px'}}>
          <FormControl fullWidth={true}>            
            <Select labelId="select_label" value={buyer} onChange={(e) => setBuyer(e.target.value)}>
              <MenuItem value={""}>CLEAR</MenuItem>              
              {Object.entries(getBuyersResult).map(([key, detail]) => (                                
                <MenuItem key={key} value={detail.ALT_DELCD}>
                {detail.BUYER}
                </MenuItem>                
              ))}
            </Select>            
          </FormControl>
        </Grid>
        
        <Grid item xs={12} style={{paddingLeft: '2em', paddingTop: '20px'}}>
          <Button variant='contained' color='primary' onClick={handleSubmit}>
            Submit
          </Button>        
          <Button variant='contained' color='error' onClick={handleRefresh} style={{marginLeft: '2em'}}>
            Clear
          </Button>
        </Grid>
        
      </Box>

      <Box sx={{ overflowY: 'auto', maxHeight: '90vh', minHeight: '90vh', mx: '1em', mb: '1em', width: '65%', pl: '.5em' }}>        
        <Box sx={{ overflowY: 'auto', minHeight: '4vh', maxHeight: '4vh', mx: '1em', mt: '1em', mb: '.5em', pl: '.5em' }}>        
          {showTable && <Grid container spacing={2} sx={{ color: '#00563f'}}>            
            <Grid item xs={1}>
              <Typography variant='h6' sx={{ paddingBottom: '2px', fontSize: '1vw'}}>#</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='h6' sx={{ paddingBottom: '2px', fontSize: '1vw'}}>NAME</Typography>
            </Grid>          
            <Grid item xs={5}>
              <Typography variant='h6' sx={{fontSize: '1vw'}}>ADDRESS</Typography>
            </Grid>            
            <Grid item xs={2}>
              <Typography variant='h6' sx={{fontSize: '1vw'}}>BUYER</Typography>
            </Grid>                              
          </Grid>}        
        </Box>
        <Box sx={{ overflowY: 'auto', maxHeight: '60vh', minHeight: '60vh', mx: '1em', mb: '1em', pl: '.5em' }}>        
          {showTable && <Grid container spacing={2}>            
            {Object.entries(searchResult).map(([key, detail]) => (
              <React.Fragment key={key}>
                <Grid item xs={1} sx={{ border: '1px solid whitesmoke', fontSize: '.65vw' }}>
                  <Typography sx={{fontSize: '.85vw'}}>{detail.MFG_NUMBER}</Typography>
                </Grid>
                <Grid item xs={4} sx={{ border: '1px solid whitesmoke', fontSize: '.65vw' }}>
                  <Typography sx={{fontSize: '.85vw'}}>{detail.MANUFACTURER_NAME}</Typography>
                </Grid>
                <Grid item xs={5} sx={{ border: '1px solid whitesmoke', fontSize: '.65vw' }}>
                  <Typography sx={{fontSize: '.85vw'}}>{detail.ADDRESS_1+' '+detail.CITY+', '+detail.STATE+' '+detail.ZIP_CODE}</Typography>
                </Grid>                
                <Grid item xs={2} sx={{ border: '1px solid whitesmoke', fontSize: '.65vw' }}>
                  <Typography sx={{fontSize: '.85vw'}}>{detail.BUYER}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>}        
        </Box>
      </Box>

      
      {isLoading && (
        <Backdrop
          open={isLoading}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}
        >
          <CircularProgress sx={{color: '#0FFF00'}} />
        </Backdrop>
      )}
    </Grid>
  );
};

export default LookupMfgAssignedBuyerForm;
