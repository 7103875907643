import { createSlice } from '@reduxjs/toolkit';

export const entitySlice = createSlice({
	name: 'entity',
	initialState: {
		selectedContactId: 0,
		refreshAssociatedData: 0,
		selectedEntityType: 0,
	},
	reducers: {
		selectedContactIdValue: (state, action) => {
			state.selectedContactId = action.payload;
		},
		refreshAssociatedDataValue: (state, action) => {
			state.refreshAssociatedData = action.payload;
		},
		setSelectedEntityType: (state, action) => {
			state.selectedEntityType = action.payload;
		},
	},
});

export const { selectedContactIdValue, refreshAssociatedDataValue, setSelectedEntityType } = entitySlice.actions;
export default entitySlice.reducer;
