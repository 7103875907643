import React, { useContext, useEffect, useState } from 'react';
import { EntityContextState } from '../../../../../Context/Entity/EntityContext';
import { Box, Button, Drawer, MenuItem, TextField, useTheme } from '@mui/material';

import EmailMaintenance from './EmailMaintenance/EmailMaintenance';
import EmailPopoverContainer from './EmailMaintenance/EmailPopoverContainer';
import EmailManager from './EmailMaintenance/EmailManager';
import PhoneManager from './PhoneMaintenance/PhoneManager';
import ContactInfoForm from './ContactInfoForm';
import ContactAssociation from './ContactAssociation/ContactAssociation';

const ContactDrawerUpdater = ({ contact_info }) => {
	// INIT
	const theme = useTheme();

	// LOCAL STORAGE

	// CONTEXT STORAGE AND FUNCTIONS
	const { selectedContactIds, toggleUpdateContactDrawerOpener, updateContactDrawerOpener } =
		useContext(EntityContextState);

	return (
		<Drawer open={updateContactDrawerOpener} anchor='right' onClose={() => toggleUpdateContactDrawerOpener(false)}>
			<Box
				sx={{
					maxWidth: '1600px',
					minWidth: '1600px',
					height: '100%',
					backgroundColor: '#fcfcfc',
					overflowX: 'hidden',
					overflowY: 'auto',
					padding: '32px',
				}}
			>
				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: 'repeat(12, 1fr)',
						gridTemplateRows: 'auto auto',
						gap: '24px',
					}}
				>
					{/* CONTACT FORM AREA */}
					<Box sx={{ gridColumn: 'span 12', minHeight: '400px' }}>
						<ContactInfoForm />
					</Box>

					{/* CONTACT EMAIL AREA */}
					{selectedContactIds !== 0 && (
						<Box sx={{ gridColumn: 'span 4' }}>
							<EmailManager contact_id={selectedContactIds} />
						</Box>
					)}

					{/* CONTACT PHONE NUMBER AREA */}
					{selectedContactIds !== 0 && (
						<Box sx={{ gridColumn: 'span 8' }}>
							<PhoneManager contact_id={selectedContactIds} />
						</Box>
					)}
					{selectedContactIds !== 0 && (
						<Box sx={{ gridColumn: 'span 12' }}>
							<ContactAssociation side='contact' objId={selectedContactIds} />
						</Box>
					)}
				</Box>
			</Box>
		</Drawer>
	);
};

export default ContactDrawerUpdater;
