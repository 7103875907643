import { Box } from '@mui/material';
import React from 'react';
import ContactsForEntity from './ContactsForEntity/ContactsForEntity';

const ContractsByBridAction = ({ item }) => {
	return (
		<Box>
			<ContactsForEntity entityId={item.entity_id} />
		</Box>
	);
};

export default ContractsByBridAction;
