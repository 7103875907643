import React, { useContext } from 'react';
import { Button } from '@mui/material';
import { EntityContextState } from '../../../../../Context/Entity/EntityContext';
import { ControlPoint } from '@mui/icons-material';

const CreateEntityButton = () => {
	const { toggleUpdateEntityDrawerOpener, addSelectedEntityId } = useContext(EntityContextState);

	const handleClick = () => {
		addSelectedEntityId(0);
		toggleUpdateEntityDrawerOpener(true);
	};

	return (
		<Button
			sx={{ fontWeight: 'bold' }}
			variant='contained'
			color='success'
			onClick={() => handleClick()}
			endIcon={<ControlPoint />}
			size='small'
		>
			Add Entity
		</Button>
	);
};

export default CreateEntityButton;
