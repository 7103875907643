import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import { getSubBrandsByMfrId } from '../Feeds/ContractsFeed';
import { TextField } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

export default function BrandChipsSelect({ handleChange, mfrid = 0, brandIds, contractId }) {
	// LOCAL STATE
	const [loaded, setLoaded] = useState(false);
	const [selectedBrands, setSelectedBrands] = useState(brandIds ? brandIds.map((id) => parseInt(id, 10)) : []);
	const [data, setData] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoaded(false);
				const dataBrand = await getSubBrandsByMfrId(mfrid);
				setData(dataBrand);
				setSelectedBrands(brandIds ? brandIds.map((id) => parseInt(id, 10)) : []);
				setLoaded(true);
			} catch (error) {
				console.error('Error fetching sub-brands:', error);
				setLoaded(true); // To avoid an indefinite loading state
			}
		};

		if (mfrid > 0) {
			fetchData();
		} else {
			setLoaded(true);
		}
	}, [mfrid, brandIds]);

	const _handleChange = (event) => {
		const { value } = event.target;
		setSelectedBrands(value);
		handleChange(value);
	};

	console.log('mfrid: ' + mfrid);
	console.log('brandid; ', brandIds);

	return (
		<div>
			{loaded ? (
				<TextField
					select
					label='Choose Brands'
					value={selectedBrands}
					onChange={_handleChange}
					fullWidth
					variant='outlined'
					SelectProps={{
						multiple: true,
						renderValue: (selected) => (
							<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
								{selected.map((value) => {
									const brand = data.find((item) => item.entity_id === value);
									return (
										<Chip
											key={value}
											label={brand ? brand.business_name : 'Unknown'}
											sx={{ mx: 2 }}
										/>
									);
								})}
							</Box>
						),
						MenuProps: MenuProps,
					}}
					sx={{ mb: '24px', width: '100%' }}
				>
					{data.length > 0 ? (
						data.map((name) => (
							<MenuItem key={name.entity_id} value={name.entity_id}>
								{name.business_name}
							</MenuItem>
						))
					) : (
						<MenuItem value='0'>-- NO Data --</MenuItem>
					)}
				</TextField>
			) : (
				'...Loading!'
			)}
		</div>
	);
}
