import React, { useContext } from 'react';
import { EditProductDataContextState } from '../../../../../../Context/pdm/EditProductDataContext';
import { Box } from '@mui/material';
import Tab from './components/Tab';

const Tabs = () => {
	const { tabs } = useContext(EditProductDataContextState);
	return (
		<>
			<Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', pr: '50px' }}>
				{tabs.map((e) => (
					<Tab key={e.id} details={e} />
				))}
			</Box>
		</>
	);
};

export default Tabs;
