import React, { useContext, useMemo } from 'react';
import { EntityContactContextState } from '../../../../../Context/Entity/EntityContacts/EntityContactContext';
import { Box } from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank, CorporateFare, Diversity3 } from '@mui/icons-material';
import ContactName from './ContactName';
import ContactAddress from './ContactAddress';
import DeleteContactActionButton from './DeleteContactActionButton';
import ContactEmails from './ContactEmails';
import ContactPhoneNumbers from './ContactPhoneNumbers';
import RowEditAction from './RowEditAction';

// COLUMN DEFINITIONS
const useColumns = () => {
	// CONTEXT STATE
	const { allAssociationLink, allStates } = useContext(EntityContactContextState);
	return useMemo(
		() => [
			{
				accessorFn: (row) => row.entity_contact_id,
				id: 'entity_id',
				header: 'ID',
				Cell: ({ cell }) => (
					<Box sx={{ display: 'flex', justifyContent: 'space-around', gap: 4, alignItems: 'center' }}>
						<div>
							<RowEditAction contactId={cell.getValue()} />
						</div>
					</Box>
				),
				size: 180,
				grow: false,
			},
			{
				accessorFn: (row) => `${row.first_name} ${row.last_name} ${row.user_id}`,
				id: 'contact_name',
				header: 'Contact',
				Cell: ({ cell }) => {
					return (
						<ContactName
							userId={cell.row.original.user_id}
							firstName={cell.row.original.first_name}
							lastName={cell.row.original.last_name}
							imageId={'444'}
						/>
					);
				},
				size: 300,
				grow: false,
			},
			{
				accessorFn: (row) => `${row.address_1} ${row.city} ${row.state} ${row.postal_code}`,
				id: 'address',
				header: 'Address',
				Cell: ({ cell }) => {
					//	const state = allStates.find((s) => s.state_id === cell.row.original.state_id);
					return (
						<ContactAddress
							street={cell.row.original.address_1}
							city={cell.row.original.city}
							state={cell.row.original.state_id}
							zip={cell.row.original.postal_code}
						/>
					);
				},
				size: 250,
				grow: true,
			},
			{
				accessorFn: (row) => `${row.emails}`,
				id: 'emails',
				header: 'Emails',
				Cell: ({ cell, i }) => {
					return <ContactEmails contactId={cell.row.original.entity_contact_id} />;
				},
				size: 320,
				grow: false,
			},
			{
				accessorFn: (row) => `${row.entity_contact_id}`,
				id: 'phone_numbers',
				header: 'Phone Numbers',
				Cell: ({ cell }) => {
					return <ContactPhoneNumbers contactId={cell.row.original.entity_contact_id} />;
				},
				size: 350,
				grow: false,
			},
			{
				accessorFn: (row) => row.deleted,
				id: 'active',
				header: 'Active',
				Cell: ({ cell, row }) => {
					return (
						<DeleteContactActionButton
							className={`value${cell.getValue()}`}
							isChecked={parseInt(cell.getValue()) === 1 ? false : true}
							contactId={cell.row.original.entity_contact_id}
						/>
					);
				},
				size: 150,
				grow: false,
			},
			// {
			// 	accessorFn: (row) => row.has_associations,
			// 	id: 'associated',
			// 	header: 'Associated',
			// 	Cell: ({ cell, row }) => {
			// 		const hasAsso = allAssociationLink.find(
			// 			(e) => e.entity_contact_id === cell.row.original.entity_contact_id
			// 		);

			// 		return (
			// 			<Box sx={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', pl: 2 }}>
			// 				{hasAsso ? <Diversity3 color='warning' /> : <CorporateFare color='disabled' />}
			// 			</Box>
			// 		);
			// 	},
			// 	size: 150,
			// 	grow: false,
			// },
		],
		[allAssociationLink]
	);
};

export default useColumns;
