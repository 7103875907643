import React, { useContext } from 'react';

import { EditProductDataContextState } from '../../../../../../../Context/pdm/EditProductDataContext';

import { Button } from '@mui/material';

const SearchButton = () => {
	const { selectedMfrId, selectedCatId, selectedSeriesId, selectedModelId, addTab } =
		useContext(EditProductDataContextState);
	return (
		<>
			{selectedCatId > 0 ? (
				<Button
					variant='contained'
					onClick={() =>
						addTab(
							Math.floor(Math.random() * (999999 - 100000 + 1) + 100000),
							`${selectedMfrId},${selectedCatId},${selectedSeriesId},${selectedModelId}`,
							'list',
							'0'
						)
					}
				>
					Search
				</Button>
			) : null}
		</>
	);
};

export default SearchButton;
