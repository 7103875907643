// Material UI
import { Box, styled } from '@mui/material';

// Material Styled Components
const Wrapper = styled(Box)(({ theme }) => ({
	color: theme.palette.primary.main,
	fontWeight: 'bold',
	fontSize: '1.4em',
	marginTop: 15,
	marginBottom: 15,
}));

// Primary Component
const SectionTitle = ({ title }) => {
	return <Wrapper>{title}</Wrapper>;
};
export default SectionTitle;
