import React from 'react';
import { useTheme } from '@mui/material';
const imagePath =
	'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';
const ContactName = ({ userId, firstName, lastName, imageId }) => {
	const theme = useTheme();

	const Content = () => {
		return (
			<div style={{ display: 'grid', gridTemplateColumns: '60px 1fr', alignItems: 'center', gap: '16px' }}>
				<div
					style={{
						position: 'relative',
						aspectRatio: '1/1',
						boxShadow: '0 4px 6px rgba(0,0,0,.3)',
						borderRadius: '8px',
						border: 'solid 2px rgb(220,220,220)',
					}}
				>
					<img
						style={{
							objectFit: 'cover',
							display: 'inline-block',
							position: 'absolute',
							width: '100%',
							height: '100%',
							borderRadius: '8px',
						}}
						src={imagePath}
						alt={`${firstName} ${lastName}`}
					/>
				</div>
				<div>
					<div
						style={{
							position: 'relative',
							fontSize: '16px',
							color: theme.palette.primary.main,
							fontWeight: 'bold',
						}}
					>
						{` ${!firstName ? '---' : firstName} ${!lastName ? '---' : lastName}`}
						<div
							style={{
								display: userId < 1 ? 'none' : 'block',
								position: 'absolute',
								top: '18px',
								fontSize: '12px',
								color: theme.palette.grey[600],
							}}
						>
							<span>User#</span>
							<span>{userId}</span>
						</div>
					</div>
				</div>
			</div>
		);
	};

	return <>{lastName === '' ? '---' : <Content />}</>;
};

export default ContactName;
