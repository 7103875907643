import axios from 'axios';
import Dayjs from 'dayjs';

export const insertOrUpdateContracts = async (data) => {
	// Format dates using Dayjs
	const formatDate = (date) => Dayjs(date).format('YYYY-MM-DD');
	console.log('passed obj: ', data, ' id: ' + data.ss_publication_contract_id);

	const record = {
		ss_publication_contract_id: data.ss_publication_contract_id,
		ss_publication_id: data.ss_publication_id,
		ss_publication_product_id: data.ss_publication_product_id,
		ss_publication_contract_status_id: data.ss_publication_contract_status_id,
		entity_id: data.entity_id,
		user_id: data.user_id,
		promotion_set_id: data.promotion_set_id,
		product_manufacturers: data.product_manufacturers,
		//product_manufacturers: data.product_manufacturers.join(','),
		last_status_change: formatDate(data.last_status_change),
		sold_price: data.sold_price,
		time_created: formatDate(data.time_created),
		notes: data.notes,
		deleted: data.deleted,
	};

	try {
		const endpoint =
			data.ss_publication_contract_id === 0 ? '/contracts/insertContract' : '/contracts/updateContract';
		const response = await axios.post(endpoint, { record });

		if (data.ss_publication_contract_id === 0) {
			console.log('Inserted object ID:', response.data.insertId);
			return response.data.insertId;
		} else {
			console.log('Updated object:', response.data);
			return response.data;
		}
	} catch (err) {
		console.error(err.response ? err.response.data : 'An error occurred');
		throw err;
	}
};

export const getAllBrandsFromParentEntityIdThatHasProducts = async (entityId) => {
	try {
		const response = await axios.get(`/entities/getSubBrandsByEntityId/${entityId}`);
		return response.data;
	} catch (error) {
		console.error('Error fetching data:', error);
	}
};

export const getContractsMix = async (entityContactId, publicationId, entityId) => {
	try {
		const response = await axios.get(`/contracts/getContractsMix/${entityContactId}/${publicationId}/${entityId}`);
		return response.data;
	} catch (error) {
		console.error('Error fetching data:', error);
	}
};
