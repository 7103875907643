import { createSlice } from '@reduxjs/toolkit';

export const AnalyticsSlice = createSlice({
  name: 'lastInsertId',
  initialState: {
    value: [],
  },
  reducers: {
    setLastInsertId: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setLastInsertId } = AnalyticsSlice.actions;
export default AnalyticsSlice.reducer;
