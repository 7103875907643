import { Delete } from '@mui/icons-material';
import { Button, Tooltip, Typography, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';

const DeleteButton = ({ cardData, handleDelete }) => {
	// INIT's
	const theme = useTheme();

	// LOCAL STATE
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleAgree = () => {
		handleDelete();
		setOpen(false);
	};

	return (
		<>
			<Tooltip title='Delete Contract'>
				<Button
					sx={{ display: 'flex', flexDirection: 'column', color: 'white' }}
					onClick={() => handleClickOpen()}
				>
					<Delete sx={{ filter: `drop-shadow(4px 4px 3px ${theme.palette.primary.main})` }} />
					<Typography
						sx={{
							fontSize: '1em',
							fontWeight: 'bold',
							filter: `drop-shadow(4px 4px 3px ${theme.palette.primary.main})`,
						}}
					>
						Delete
					</Typography>
				</Button>
			</Tooltip>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>{'Deleting Advertising Contract!'}</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Are you sure you want to DELETE this contract?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>No</Button>
					<Button onClick={() => handleAgree()} autoFocus>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default DeleteButton;
