import React from 'react';
import { Box, Divider, Paper, Typography } from '@mui/material';
import {
  MaterialReactTable,
  MRT_ToggleDensePaddingButton as MrtToggleDensePaddingButton,
  MRT_ToggleFiltersButton as MrtToggleFiltersButton,
  MRT_ToggleFullScreenButton as MrtToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton as MrtToggleGlobalFilterButton,
} from 'material-react-table';

const InnerTable = ({ data = [] }) => {
  const columns = [
    {
      header: 'Name',
      accessorFn: (row) => `${row.last_name}, ${row.first_name}`,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Job Title',
      accessorKey: 'job_title',
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Company Name',
      accessorKey: 'company_name',
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Email',
      accessorKey: 'email_address',
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Cell Phone #',
      accessorKey: 'phone_number',
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'City',
      accessorKey: 'city',
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'State',
      accessorKey: 'state',
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Mfr Party',
      accessorKey: 'poi',
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
  ];

  return (
    <>
      {data.length > 0 ? (
        <MaterialReactTable
          columns={columns}
          data={data}
          enableHiding={false}
          initialState={{
            showColumnFilters: false,
            density: 'compact',
            pagination: { pageIndex: 0, pageSize: 10 },
          }}
          positionToolbarAlertBanner='bottom'
          renderToolbarInternalActions={({ table }) => {
            return (
              <Box sx={{ whiteSpace: 'pre' }}>
                <MrtToggleGlobalFilterButton table={table} />
                <MrtToggleFiltersButton table={table} />
                <MrtToggleDensePaddingButton table={table} />
                <MrtToggleFullScreenButton table={table} />
              </Box>
            );
          }}
          renderTopToolbarCustomActions={({ table }) => {
            return (
              <Box display='flex' alignItems='center' justifyContent='space-between' width='80%'>
                <Box display='flex' flexDirection='column' width='100%' justifyContent='center'>
                  <Typography
                    variant='h6'
                    sx={{
                      textAlign: 'center',
                      fontWeight: 600,
                      textTransform: 'uppercase',
                      letterSpacing: '0.05em',
                      color: 'primary.main',
                      fontFamily: 'Cabin, sans-serif',
                      lineHeight: 1.2,
                    }}
                  >
                    Badges
                  </Typography>
                  <Divider
                    sx={{
                      width: '40%',
                      height: '2px',
                      backgroundColor: '#aaa',
                      margin: '0 auto',
                      marginTop: '0.25rem',
                    }}
                  />
                </Box>
              </Box>
            );
          }}
        />
      ) : (
        <Paper sx={{ height: '100px', pb: 1 }}>
          <Box
            sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Typography
              variant='h6'
              sx={{
                fontStyle: 'italic',
              }}
            >
              No badges to display
            </Typography>
          </Box>
        </Paper>
      )}
    </>
  );
};

export default InnerTable;
