import PageTitle from '../../_GlobalComponents/PageTitle.jsx';

// Material UI
import { Box, styled } from '@mui/material';
import SearchContacts from './Components/SearchContacts.jsx';
import AssociatedEntities from './Components/AssociatedEntities.jsx';
import CreateAssociation from './Components/CreateAssociation.jsx';

// Material Styled Components
const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
}));

const Columns = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 20,
  maxWidth: theme.deminsions.maxWidth,
  margin: 'auto',
}));

// Primary Component
const ContactToEntity = () => {
  return (
    <Wrapper>
      <PageTitle title='Reference Contact to Entity' />
      <Columns>
        <Box sx={{ minWidth: '500px', maxWidth: '500px' }}>
          <SearchContacts />
        </Box>
        <Box sx={{ flex: 1 }}>
          <CreateAssociation />
        </Box>
        <Box sx={{ minWidth: '400px', maxWidth: '400px' }}>
          <AssociatedEntities />
        </Box>
      </Columns>
    </Wrapper>
  );
};
export default ContactToEntity;
