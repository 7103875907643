import React, { useContext, useEffect, useState } from 'react';
import { EntityContextState } from '../../../../../../../Context/Entity/EntityContext';
import axios from 'axios';
import { Box, Button } from '@mui/material';
import DeleteAssociation from './DeleteAssociation';

const AssociationList = ({ type, identifier }) => {
	const [list, setList] = useState([]);

	// CONTEXT DATA
	const { triggerRefreshContactAssociationList } = useContext(EntityContextState);

	useEffect(() => {
		let tempArray = [];

		const funcContacts = async () => {
			try {
				const response = await axios.get(`/entity/getContactAssociationsById/${identifier}`);
				response.data.map((e) =>
					tempArray.push({
						row_id: e.entity_contact_link_id,
						id: e.entity_id,
						label: e.business_name,
						contact_role: e.contact_role,
					})
				);
			} catch (error) {
				console.log('fetchContacts error: ', error);
			}
			setList([...tempArray]);
		};

		const funcEntities = async () => {
			try {
				const response = await axios.get(`/entity/getEntityAssociationsById/${identifier}`);
				response.data.map((e) =>
					tempArray.push({
						row_id: e.entity_contact_link_id,
						id: e.contact_id,
						label: `${e.first_name} ${e.last_name}`,
						contact_role: e.contact_role,
					})
				);
			} catch (error) {
				console.log('fetchContacts error: ', error);
			}
			setList([...tempArray]);
		};

		type === 'contact' ? funcContacts() : funcEntities();
	}, [type, identifier, triggerRefreshContactAssociationList]);

	return (
		<>
			{list.map((c) => (
				<Box key={c.id} sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 2 }}>
					<Box>
						<Button variant='contained' color='secondary' size='small' sx={{ fontWeight: 'bold' }}>
							{c.id}
						</Button>
					</Box>
					<Box sx={{ width: '180px' }}>{c.label}</Box>
					<Box sx={{ width: '150px' }}>{c.contact_role}</Box>
					<Box>
						<DeleteAssociation identifier={c.row_id} />
					</Box>
				</Box>
			))}
		</>
	);
};

export default AssociationList;
