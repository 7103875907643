import React, { useState } from 'react';
import { Modal, TextField, Button, Typography, Grid, Paper, Divider } from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';

const AddLead = ({ open, handleClose }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [attentionLine, setAttentionLine] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const userId = useSelector((state) => state.userInformation.value.userID);

  const handleSubmit = () => {
    setIsLoading(true);
    try {
      axios.post(
        '/crm/createLead',
        {},
        {
          params: {
            userId: userId,
            firstName: firstName,
            lastName: lastName,
            companyName: companyName,
            phoneNumber: phoneNumber,
            emailAddress: email,
            attentionLine: attentionLine,
            address1: address1,
            address2: address2,
            city: city,
            state: state,
            zipCode: zipCode,
          },
        }
      );
      setFirstName('');
      setLastName('');
      setCompanyName('');
      setPhoneNumber('');
      setEmail('');
      setAttentionLine('');
      setAddress1('');
      setAddress2('');
      setCity('');
      setState('');
      setZipCode('');
      console.log('Lead submitted successfully'); // Debug log
    } catch (error) {
      console.error('Error creating lead:', error);
    } finally {
      setIsLoading(false);
      console.log('Closing modal...'); // Debug log
      handleClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          borderRadius: '4px',
          minWidth: '300px',
          maxWidth: '500px', // Adjusted for better proportion
        }}
      >
        <Typography variant='h6' textAlign='center' sx={{ mb: 2 }}>
          Add New Lead
        </Typography>
        <Divider sx={{ mb: 2 }} />

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              multiline
              rows={1}
              variant='outlined'
              label='First Name'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              sx={{ mb: 1 }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              multiline
              rows={1}
              variant='outlined'
              label='Last Name'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              sx={{ mb: 1 }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              multiline
              rows={1}
              variant='outlined'
              label='Company Name'
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              sx={{ mb: 1 }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              multiline
              rows={1}
              variant='outlined'
              label='Phone Number'
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              sx={{ mb: 1 }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              multiline
              rows={1}
              variant='outlined'
              label='E-mail'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ mb: 1 }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              multiline
              rows={1}
              variant='outlined'
              label='Attention'
              value={attentionLine}
              onChange={(e) => setAttentionLine(e.target.value)}
              sx={{ mb: 1 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={1}
              variant='outlined'
              label='Address 1'
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
              sx={{ mb: 1 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={1}
              variant='outlined'
              label='Address 2'
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
              sx={{ mb: 1 }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              multiline
              rows={1}
              variant='outlined'
              label='City'
              value={city}
              onChange={(e) => setCity(e.target.value)}
              sx={{ mb: 1 }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              multiline
              rows={1}
              variant='outlined'
              label='State'
              value={state}
              onChange={(e) => setState(e.target.value)}
              sx={{ mb: 1 }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              multiline
              rows={1}
              variant='outlined'
              label='Zip'
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              sx={{ mb: 1 }}
            />
          </Grid>
        </Grid>
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={handleSubmit}
          disabled={isLoading} // Disable button when isLoading is true
          sx={{
            padding: '10px 20px',
            boxShadow: '0px 3px 6px rgba(0,0,0,0.2)',
            position: 'relative', // For proper positioning of CircularProgress
          }}
        >
          {isLoading ? (
            <CircularProgress
              size={24}
              sx={{
                color: 'white',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          ) : (
            'Submit'
          )}
        </Button>
      </Paper>
    </Modal>
  );
};

export default AddLead;
