import React from 'react';
import { Box, IconButton, useTheme } from '@mui/material';
import { DeleteForever } from '@mui/icons-material';

const Attribute = ({ info }) => {
	const theme = useTheme();
	console.log('info: ', info);
	return (
		<Box
			sx={{
				backgroundColor: 'white',
				pb: '8px',
				m: '0px 20px 12px 0',
				display: 'flex',
				borderBottom: `1px solid ${theme.palette.gray.medium}`,
				alignItems: 'center',
			}}
		>
			<Box
				sx={{
					color: theme.palette.primary.main,
					fontWeight: 'bold',
					flex: 4,
					backgroundColor: '',
					pr: 1,
					textAlign: 'center',
				}}
			>
				{info.label}:
			</Box>

			<Box sx={{ flex: 6 }}>
				{info.value.map((e, i) => (
					<Box
						key={i}
						sx={{
							textAlign: 'left',
							fontWeight: 'bold',
							display: 'flex',
							justifyContent: 'space-between',
							'&:hover': { backgroundColor: theme.palette.primary.lightScreen },
							pl: 1,
							borderRadius: '8px',
							alignItems: 'center',
						}}
					>
						<span>{e.label}</span>
						<Box>
							<IconButton aria-label='delete' color='error' onClick={() => alert('hello')}>
								<DeleteForever />
							</IconButton>
						</Box>
					</Box>
				))}
			</Box>
		</Box>
	);
};

export default Attribute;
