import React, { useContext } from 'react';
import { Box, useTheme } from '@mui/material';
import { EditProductDataContextState } from '../../../../../../../Context/pdm/EditProductDataContext';

const ProductHeaderInfo = ({ productId }) => {
	const theme = useTheme();
	const { activeTab } = useContext(EditProductDataContextState);

	return (
		<>
			<Box sx={{ display: 'flex', gap: '12px', fontWeight: 'bold', mb: '8px' }}>
				<span style={{ color: theme.palette.gray.medium }}>
					<span style={{ display: 'inline-block', marginRight: '6px', color: theme.palette.gray.extraDark }}>
						ID#
					</span>
					{productId}
				</span>

				<span style={{}}>|</span>

				<span style={{ color: theme.palette.gray.medium }}>
					<span style={{ display: 'inline-block', marginRight: '6px', color: theme.palette.gray.extraDark }}>
						SS#
					</span>
					237542 - {activeTab}
				</span>

				<span style={{}}>|</span>

				<span style={{ color: theme.palette.gray.medium }}>
					<span style={{ display: 'inline-block', marginRight: '6px', color: theme.palette.gray.extraDark }}>
						MFR#
					</span>
					AOL5600K
				</span>

				<span style={{}}>|</span>

				<span style={{ color: theme.palette.gray.medium }}>
					<span style={{ display: 'inline-block', marginRight: '6px', color: theme.palette.gray.extraDark }}>
						UPC#
					</span>
					800854103211
				</span>
			</Box>
			<Box style={{ color: theme.palette.gray.medium, fontWeight: 'bold' }}>
				<span style={{ display: 'inline-block', marginRight: '6px', color: theme.palette.gray.extraDark }}>
					AS400:
				</span>
				165384 AMMOINC 9115FMJ-TR50 TRGT 9M 115 FMJ 50/20
			</Box>
		</>
	);
};

export default ProductHeaderInfo;
