import React, { useState, useEffect } from 'react';
import { Box, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';

const PromotionFilter = ({ promotions, onSelectionChange }) => {
  const [uniqueCategories, setUniqueCategories] = useState({});
  const [selected, setSelected] = useState([]);

  const theme = useTheme();

  useEffect(() => {
    const newUniqueCategories = promotions.reduce((acc, { calendar_category_description }) => {
      acc[calendar_category_description] = true;
      return acc;
    }, {});

    setUniqueCategories(newUniqueCategories);

    // Only set selected categories on initial load
    if (!selected.length) {
      const newSelectedCategories = Object.keys(newUniqueCategories);
      setSelected(newSelectedCategories);
      onSelectionChange(newSelectedCategories);
    }
  }, [promotions, onSelectionChange]);

  const handleSelection = (event, newSelection) => {
    setSelected(newSelection);
    onSelectionChange(newSelection);
  };

  const getStyle = (category) => ({
    borderRadius: 2,
    // Apply a uniform light blue color if the category is selected, keep grey for deselected
    backgroundColor: selected.includes(category) ? '#317FB9' : 'grey.700',
    color: 'primary.contrastText',
    '&:hover': {
      // Maintain the shade of grey for hovering over a deselected button
      backgroundColor: selected.includes(category) ? '#317FB9' : 'grey.500',
    },
    // Ensure the selected state has the correct light blue color, overriding any previous styles
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: '#317FB9',
      color: 'primary.contrastText',
    },
  });

  return (
    <Box>
      <Typography
        sx={{
          marginTop: '2px', // Add some spacing above the notification
          color: 'grey.600', // Use the secondary color from the theme
          fontWeight: 'bold', // Make it bold to catch attention
        }}
      >
        Select Categories to Hide
      </Typography>
      <ToggleButtonGroup
        value={selected}
        onChange={handleSelection}
        aria-label='category filter'
        exclusive={false}
      >
        {Object.entries(uniqueCategories).map(([description]) => (
          <ToggleButton key={description} value={description} sx={getStyle(description)}>
            {description}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
};

export default PromotionFilter;
