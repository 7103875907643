import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EditProductDataContextState } from '../../../../../../../../../Context/pdm/EditProductDataContext';
import { AddCircleOutline, MoreVert } from '@mui/icons-material';
// import axios from 'axios';
import { Box, Drawer, IconButton, useTheme } from '@mui/material';
import AttributeValues from './AttributeValues/AttributeValues';
import ProductDetails from '../../../../ProductDetails/ProductDetails';
import ProductDetailsContext from '../../../../../../../../../Context/pdm/ProductDetailsContext';

const AttributeAction = ({ product_id }) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);

	const { addTab } = useContext(EditProductDataContextState);
	console.log('aaction: ' + product_id);
	return (
		<>
			<IconButton
				aria-label='delete'
				color='primary'
				onClick={() =>
					addTab(
						Math.floor(Math.random() * (999999 - 100000 + 1) + 100000),
						`Product: ${product_id}`,
						'product',
						product_id
					)
				}
			>
				<MoreVert />
			</IconButton>
			<Drawer
				id='drawer'
				anchor='right'
				open={open}
				onClose={() => setOpen(false)}
				sx={{
					zIndex: '12000',
					'& .MuiDrawer-paper': {
						overflow: 'hidden',
						height: '100vh',
						boxSizing: 'border-box',
						backgroundColor: 'rgba(200,200,200,.7)',
					},
				}}
			>
				<ProductDetailsContext activeProductId={product_id}>
					<ProductDetails productId={product_id} />
				</ProductDetailsContext>
			</Drawer>
		</>
	);
};

export default AttributeAction;
