import axios from 'axios';
import Dayjs from 'dayjs';

export const insertOrUpdateContact = async (data) => {
	//	const formatDate = (date) => Dayjs(date).format('YYYY-MM-DD');
	console.log('passed obj: ', data);

	const record = {
		address_1: data[0].address_1,
		address_2: data[0].address_2,
		attention_line: data[0].attention_line,
		birthdate: data[0].birthdate,
		city: data[0].city,
		country_id: data[0].country_id,
		date_created: data[0].date_created,
		deleted: data[0].deleted,
		entity_contact_id: data[0].entity_contact_id,
		first_name: data[0].first_name,
		image_id: data[0].image_id,
		job_title: data[0].job_title,
		last_name: data[0].last_name,
		latitude: data[0].latitude,
		longitude: data[0].longitude,
		notes: data[0].notes,
		postal_code: data[0].postal_code,
		state_id: data[0].state_id,
		timezone_id: data[0].timezone_id,
		user_id: data[0].user_id,
	};

	console.log('record for contact: ', record, ' | ', data[0].entity_contact_id);

	try {
		console.log('try happend');
		const endpoint =
			data[0].entity_contact_id < 1
				? '/entity-contacts/createEntityContact'
				: '/entity-contacts/updateEntityContact';
		const response = await axios.post(endpoint, { record });
		console.log('update response: ', response);
		return response;
	} catch (error) {
		console.log('error insertOrUpdateContact: ', error);
	}
};

export const insertOrUpdateAssociation = async (data) => {
	console.log('passed obj Association: ', data);

	const record = {
		entity_contact_link_id: data[0].entity_contact_link_id,
		entity_contact_id: data[0].entity_contact_id,
		entity_contact_role_id: data[0].entity_contact_role_id,
		entity_contact_email_id: data[0].entity_contact_email_id,
		entity_contact_phone_number_id: data[0].entity_contact_phone_number_id,
		entity_id: data[0].entity_id,
	};

	try {
		const endpoint =
			data[0].entity_contact_link_id < 1 || data[0].entity_contact_link_id === undefined
				? '/entity-contacts/insertAssociation'
				: '/entity-contacts/updateAssociation';
		const response = await axios.post(endpoint, { record });
		console.log('update response: ', response);
		return response;
	} catch (error) {
		console.log('error insertOrUpdatePhoneNumbers: ', error);
	}
};

export const deleteAssociation = async (associationId) => {
	console.log('delete asso : ' + associationId);
	try {
		const response = await axios.post('/entity-contacts/deleteAssociation', { associationId: associationId });
		console.log('delete response: ', response);
		return response;
	} catch (error) {
		console.log('error deletePhoneNumber: ', error);
	}
};

export const insertOrUpdatePhoneNumbers = async (data) => {
	//const formatDate = (date) => Dayjs(date).format('YYYY-MM-DD');
	console.log('passed obj: ', data);

	const record = {
		entity_contact_phone_number_id: data[0].entity_contact_phone_number_id,
		entity_contact_id: data[0].entity_contact_id,
		phone_type_id: data[0].phone_type_id,
		phone_number: data[0].phone_number,
		extension: data[0].extension,
		description: data[0].description,
		primary_phone: data[0].primary_phone,
	};

	try {
		console.log('try happend');
		const endpoint =
			data[0].entity_contact_phone_number_id < 1
				? '/entity-contacts/insertPhoneNumber'
				: '/entity-contacts/updatePhoneNumber';
		const response = await axios.post(endpoint, { record });
		console.log('update response: ', response);
		return response;
	} catch (error) {
		console.log('error insertOrUpdatePhoneNumbers: ', error);
	}
};

export const updatePrimaryPhoneToZero = async (contactId) => {
	try {
		const response = await axios.post('/entity-contacts/updatePrimaryPhoneToZero', { contactId: contactId });
		return response;
	} catch (error) {
		console.log('error updatePrimaryPhoneToZero: ', error);
	}
};

export const updatePrimaryPhoneToOneByPhoneNumberId = async (phoneId) => {
	try {
		const response = await axios.post('/entity-contacts/updatePrimaryPhoneToOneByPhoneNumberId', {
			phoneId: phoneId,
		});
		return response;
	} catch (error) {
		console.log('error updatePrimaryPhoneToOneByPhoneNumberId: ', error);
	}
};

export const updatePrimaryEmailToZero = async (contactId) => {
	try {
		const response = await axios.post('/entity-contacts/updatePrimaryEmailToZero', { contactId: contactId });
		return response;
	} catch (error) {
		console.log('error updatePrimaryEmailToZero: ', error);
	}
};

export const updatePrimaryEmailToOneByEmailId = async (emailId) => {
	try {
		const response = await axios.post('/entity-contacts/updatePrimaryEmailToOneByEmailId', {
			emailId: emailId,
		});
		return response;
	} catch (error) {
		console.log('error updatePrimaryEmailToOneByEmailId: ', error);
	}
};

export const getCountEmailUsed = async (emailId) => {
	try {
		const response = await axios.get(`/entity-contacts/getCountEmailUsed/${emailId}`);
		console.log('how many uses: ', response);
		return response;
	} catch (error) {
		console.log('error getCountEmailUsed: ', error);
	}
};

export const deleteEmail = async (emailId) => {
	console.log('middle email delete id: ' + emailId);
	try {
		const response = await axios.post('/entity-contacts/deleteEmail', { emailId: emailId });
		console.log('delete response: ', response);
		return response;
	} catch (error) {
		console.log('error deleteEmail: ', error);
	}
};

export const insertOrUpdateEmail = async (data) => {
	console.log('passed obj: ', data);

	const record = {
		entity_contact_email_id: data[0].entity_contact_email_id,
		entity_contact_id: data[0].entity_contact_id,
		email_address: data[0].email_address,
		description: data[0].description,
		primary_email: data[0].primary_email,
	};

	try {
		console.log('try happend');
		const endpoint =
			data[0].entity_contact_email_id < 1 ? '/entity-contacts/insertEmail' : '/entity-contacts/updateEmail';
		const response = await axios.post(endpoint, { record });
		console.log('update response: ', response);
		return response;
	} catch (error) {
		console.log('error insertOrUpdateEmail: ', error);
	}
};

export const getCountPhoneNumberUsed = async (phoneId) => {
	try {
		const response = await axios.get(`/entity-contacts/getCountPhoneNumberUsed/${phoneId}`);
		console.log('how many uses: ', response);
		return response;
	} catch (error) {
		console.log('error getCountPhoneNumberUsed: ', error);
	}
};

export const deletePhoneNumber = async (phoneId) => {
	console.log('middle phone delete id: ' + phoneId);
	try {
		const response = await axios.post('/entity-contacts/deletePhoneNumber', { phoneId: phoneId });
		console.log('delete response: ', response);
		return response;
	} catch (error) {
		console.log('error deletePhoneNumber: ', error);
	}
};
