// MUI
import { Checkbox, FormControlLabel } from '@mui/material';

// Primary Component
const NoDescriptionCheckBox = () => {
  const onlyNoDescription = (e) => {
    const isChecked = e.target.checked;
    const items = document.querySelectorAll('.descriptionResultItem');
    const processItem = (e) => {
      const id = e.id;
      e.querySelector('.desc_input').value.length > 12 ? (document.getElementById(id).style.display = 'none') : console.log('has no desc');
    };
    isChecked ? items.forEach((item) => processItem(item)) : document.querySelectorAll('.descriptionResultItem').forEach((item) => (item.style.display = 'grid'));
  };
  return <FormControlLabel control={<Checkbox onChange={(e) => onlyNoDescription(e)} />} label='Show Only Items Missing Description' />;
};
export default NoDescriptionCheckBox;
