import React from 'react';

const Logo = () => {
	return (
		<>
			<img
				src='/images/ssDuckSquarewhite.png'
				alt='Sports South Logo'
				style={{
					display: 'block',
					margin: 0,
					width: '100%',
					height: 'calc(100% - 16px)',
					objectFit: 'contain',
					objectPosition: 'center',
					position: 'absolute',
					top: '50%',
					transform: 'translateY(-50%)',
					left: 0,
				}}
			/>
		</>
	);
};

export default Logo;
