import { useMemo, useState, useRef, useEffect } from 'react';
import { Box, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, Modal, Typography, Autocomplete } from '@mui/material';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton
} from 'material-react-table';
import axios from 'axios';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';



export default function PDMMaintTable(props) {
    const [data, setData] = useState([{}])
    const terms = props.searchTerms
    const [dialogOpen, setDialogOpen] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [facets, setFacets] = useState([{label: 'No Results', id:0}])
    const [facetOptions, setFacetOptions] = useState([{label: 'No Options', id:0}])
    const [facetSelected, setFacetSelected] = useState(false)
    const [facetOptionSelected, setFacetOptionSelected] = useState(false)
    const [refresh, setRefresh] = useState(false)

    let facetData = useRef({})
    let facetOptionData = useRef({})

    useEffect(() => {
      //console.log('triggeredPDMMT')
      setData([{}])
      updateResults()
    }, [terms])

    useEffect(() => {},[refresh])

    useEffect(() => {
      console.log(data)
      const getAllActiveFacets = () => {
          axios.get("/pdm/getAllActiveFacets"
          ).then((res) => {
              setFacets(res.data)
          }).catch((err) => {
              alert(err)
          })
      }
      getAllActiveFacets()
  }, [])

  const updateResults = async () => {
    await axios.get('/productData/getItemsForFacetOption/' + terms.facet.id + '/' + terms.facet_option.id)
      .then((res) => {
        setData(res.data)
        setRefresh(!refresh)
      })
      .catch((err) => {
        console.log(err)
      }
      )
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'description', 
        header: 'Description',
        size: 200,
      },
      {
        accessorKey: 'detailed_description',
        header: 'Detailed Description',
        size: 300,
      },
      {
        accessorKey: 'product_id', 
        header: 'Product ID',
        size: 25,
      },
      {
        accessorKey: 'product_code',
        header: 'Product Code',
        size: 25,
      },
      {
        accessorKey: 'product_facet_value_id',
        header: 'Product Facet Value ID',
        size: 100
      }
    ],
    [],
  );

  const getOptions = async (id) => {
    await axios.get("/pdm/getAllValueForAttribute/" + id)
    .then((res) => {
        console.log(id)
        console.log(res.data)
        setFacetOptions(res.data)
    })
    .catch((err) => {
        console.log(err)
    })
  }

  const table = useMaterialReactTable({
    columns,
    data, 
    initialState: {
      columnVisibility: {
        product_facet_value_id: false
      }
    },
    enableColumnFilters: true,
    enableColumnFilterModes: true,
    enableRowSelection: true,
    renderTopToolbar: ({ table }) => {
          


        const handleDelete = () => {
          setDialogOpen(true)
        };
  
        const handleAdd = () => {
          openModal()
          // table.getSelectedRowModel().flatRows.map((row) => {
          //   alert('activating ' + row.getValue('name'));
          // });
        };

        const closeDialog = () => {
          setDialogOpen(false)
        }

        const handleContinue = () => {
          setDialogOpen(false)
          table.getSelectedRowModel().flatRows.map((row) => {
            deleteAttribute(row)
            updateResults()
          })
        }

        const addFacetOption = () => {
          // console.log(facetData.current.id)
          // console.log(facetOptionData.current.id)
          table.getSelectedRowModel().flatRows.map(async (row) => {
            // console.log(row.getValue('product_id'))
          
            await axios.post('/productData/insertProductFacetRecord', {
              "product_id": row.getValue('product_id'),
              "product_facet_id": facetData.current.id,
              "product_facet_option_id":facetOptionData.current.id
            }).then((res) => {
              if(res.status == 200){
                alert('Successfully added Facet Option')
              }
              //console.log(res)
              closeModal()
            }).catch((err) => {
              alert("ERROR: Facet Option Not Added")
            })
          })
        }

        const deleteAttribute = async (row) => {
            //console.log("deleting " + row.getValue('product_facet_value_id'))
            await axios.post('/productData/deleteProductFacetRecord', {
             "product_facet_value_id": row.getValue('product_facet_value_id')
            })
            .then((res) => {
                console.log(res)
                alert('Successfully deleted')
            })
            .catch((err) => {
              alert(err)
            })
        }

        const updateResults = async () => {
          await axios.get('/productData/getItemsForFacetOption/' + terms.facet.id + '/' + terms.facet_option.id)
            .then((res) => {
              setData(res.data)
            })
            .catch((err) => {
              console.log(err)
            }
            )
        }

        const closeModal = () => {
          setIsModalOpen(false)
        }

        const openModal = () => {
          setIsModalOpen(true)
        }
  
        return (
          <Box
            sx={{
              display: 'flex',
              padding: '8px',
              paddingTop: '35px',
              justifyContent: 'space-between',
            }}
          >
           <Modal
            open={isModalOpen}
            onClose={closeModal}
           >
            <Box
                        sx={{
                            top: '25%', 
                            left:'25%',
                            position: 'absolute',
                            border: '2px',
                            boxShadow: 24,
                            padding: 5,
                            alignItems:'center',
                            alignSelf:'center'
                        }}
                        textAlign={'center'}
                        minHeight={500}
                        minWidth={500}
                        height={'33%'}
                        width={'33%'}
                        bgcolor={"whitesmoke"}
                    >
                        <Grid2
                            container
                            spacing={{xs: 1, md: 2}} 
                            alignItems={'center'} 
                            alignSelf={'center'}
                            columns={{xs: 1, sm: 1, md:1}}
                            paddingLeft={1}
                            paddingBottom={2}
                            maxWidth={1400}
                        >
                            <Grid2 xs={1} sm={2} md={5}>
                            <Typography align="center" paddingBottom={1}>
                                Select Facet
                            </Typography>
                            <Autocomplete
                                options={facets}
                                isOptionEqualToValue={(option, newValue) => option.label === newValue.label}
                                onChange={(event, val, reason) => {
                                    if(reason === 'selectOption'){
                                        getOptions(val.id)
                                        facetData.current = val
                                        setFacetSelected(true)
                                    }
                                    console.log(val)
                                    console.log(reason)
                                    if(reason === 'clear'){
                                        setFacetSelected(false)
                                        setFacetOptionSelected(false)
                                        setFacetOptions([{id: 0, label:'No Options'}])
                                        facetData.current = {}
                                        facetOptionData = {}
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} label="Attributes"/>}
                            />
                            </Grid2>
                            <Grid2 xs={1} sm={2} md={5}>
                            <Typography align="center" padding={1}>
                                Select Facet option
                            </Typography>
                            <Autocomplete
                                disabled={!facetSelected}
                                options={facetOptions}
                                isOptionEqualToValue={(option, newValue) => option.label === newValue.label}
                                onChange={(event, val, reason) => {
                                    if(reason === 'selectOption'){
                                        setFacetOptionSelected(true)
                                        facetOptionData.current = val
                                    }
                                    if(reason === 'clear') {
                                        setFacetOptionSelected(false)
                                        facetOptionData = {}
                                    }
                                    console.log(val)
                                }}
                                renderInput={(params) => <TextField {...params} label="Options"/>}
                            />
                            </Grid2>
                        </Grid2>
                        <Button 
                            variant={'contained'} 
                            onClick={addFacetOption} 
                            disabled={!facetOptionSelected || !facetSelected}
                        >
                            Add Facet Option
                        </Button>
                    </Box>
           </Modal>
            <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
              <MRT_GlobalFilterTextField table={table} />
              <MRT_ToggleFiltersButton table={table} />
            </Box>
            <Box>
              <Box sx={{ display: 'flex', gap: '8px'}}>
                <Button
                  color="error"
                  disabled={!(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected())}
                  onClick={handleDelete}
                  variant="contained"
                >
                  Delete Attribute
                </Button>
                <Button
                  color="success"
                  disabled={!(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected())}
                  onClick={handleAdd}
                  variant="contained"
                >
                  Add Attribute
                </Button>
                
              </Box>
            </Box>
            <Dialog
              open={dialogOpen}
              onClose={closeDialog}
            >
              <DialogTitle>
                {
                  "Are you sure you want to delete the Attribute?"
                }
              </DialogTitle>
              <DialogContent>
                It is recommended that a replacement Attribute before you delete this.
              </DialogContent>
              <DialogActions>
                <Button onClick={closeDialog}>
                  Cancel
                </Button>
                <Button onClick={handleContinue}>
                  Continue
                </Button>
              </DialogActions>

            </Dialog>
          </Box>
        );
      },
  });

  return (
    <Box>
      <Typography
        textAlign={'center'}
        fontWeight={'bold'}
      >
        Results for {terms.facet.label} - {terms.facet_option.label}
      </Typography>
      <MaterialReactTable table={table} />
    </Box>
  );
}