import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//import { getContractsByBrandId } from '../Feeds/ContractsFeed';
import ContractsByBrandTable from './ContractsByBrandTable';

const ContractsByBrandId = () => {
	const { entityId, subBrandId } = useParams();

	console.log('passed ent id and sub id ' + entityId + ' | ' + subBrandId);
	return (
		<>
			<ContractsByBrandTable entityId={entityId} subBrandId={subBrandId} />
		</>
	);
};

export default ContractsByBrandId;
