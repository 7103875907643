import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { EditProductDataContextState } from '../../../../../../../Context/pdm/EditProductDataContext';

import { Autocomplete, TextField } from '@mui/material';

const initArray = [{ id: 0, label: 'Choose Series Please' }];

const InputSeries = () => {
	const { selectedMfrId, selectedCatId, updateSelectedSeriesId } = useContext(EditProductDataContextState);

	const [data, setData] = useState([]);
	const [value, setValue] = useState(...initArray);
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		setMounted(false);
		const fetchSeriesListFromMfrId = async () => {
			//alert('this ran');
			try {
				const response = await axios.get(`/pdm/getUniqueSeriesByMfrIdCatId/${selectedMfrId}/${selectedCatId}`);
				setValue(initArray[0]);
				setData([...initArray, ...response.data]); // Assuming the response has a data object
				response.data.length > 0 && setMounted(true);
			} catch (error) {
				console.log('fetchCategoryListFromMfrId error: ', error);
			}
		};

		selectedCatId > 0 && fetchSeriesListFromMfrId();
	}, [selectedMfrId, selectedCatId]);

	return (
		<>
			{mounted ? (
				<Autocomplete
					value={value}
					onChange={(event, newValue) => {
						setValue(newValue);
						updateSelectedSeriesId(newValue.id);
					}}
					options={data}
					disableClearable={true}
					getOptionLabel={(option) => option.label}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					renderInput={(params) => <TextField {...params} label={`Filter By Series (Optional)`} />}
					sx={{ width: 300 }}
				/>
			) : null}
		</>
	);
};

export default InputSeries;
