import { Route, Routes } from 'react-router-dom';
import ValueCorrections from '../Components/ProductDataManager/Components/ValueCorrections/ValueCorrections';
import PDMTest from '../Components/ProductDataManager/Components/NewPDM/PDMAttrDrawer';
import NewPDM from '../Components/ProductDataManager/NewPDM';

const ProductDataManagement = () => {
  return (
    <Routes>
      <Route path='/value-corrections' element={<ValueCorrections />} />
      <Route path='/current-attributes' element={<PDMTest />} />
      <Route path='/new-product-data-management' element={<NewPDM />}/>
      <Route path='/*' element={<h4>This Route Does Not Container Content</h4>}/>
      
    </Routes>
  );
};
export default ProductDataManagement;
