import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { as400ExtendedDescriptionFilteredDataValues, as400ExtendedDescriptionProductIdsValues } from '../../../../Redux/ReductionDataSlice';

// Material UI
import { Box, styled } from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';

// Material UI Styled Components
const Wrapper = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: 12,
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
}));

// ********************* PRIMARY COMPONENT *************************************
const ReductiveSearchValues = ({ groupName }) => {
  const dispatch = useDispatch();

  // Redux Selectors
  const as400ExtendedDescriptionFullData = useSelector((state) => state.reductionDataSlice.as400ExtendedDescriptionFullData);
  const as400ExtendedDescriptionFilteredProducts = useSelector((state) => state.reductionDataSlice.as400ExtendedDescriptionFilteredProducts);

  // Local State
  const [dataSet, setDataSet] = useState([]);
  const [uniqueFacetHeaders, setUniqueFacetHeaders] = useState([]);
  const [uniqueFacetValues, setUniqueFacetValues] = useState([]);
  const [holdingList, setHoldingList] = useState([]);
  const [addingList, setAddingList] = useState([]);
  const [finalList, setFinalList] = useState([]);
  const [optionIds, setOptionIds] = useState([101, 241, 62759]);

  // FUNCTIONS
  const handleOnClick = (fId, oId) => {
    console.log('fid: ' + fId + ' | oid: ' + oId);
  };

  // ********************************
  useEffect(() => {
    setDataSet(as400ExtendedDescriptionFullData);
  }, [as400ExtendedDescriptionFullData]);

  useEffect(() => {
    const facetIdName = [];
    const facetValues = [];

    console.log('optionIds length: ' + optionIds.length);

    if (optionIds.length > 0) {
      const hold_0 = [];
      const hold_1 = [];
      const hold_2 = [];

      //
      optionIds.map((o, idx) => dataSet.filter((f) => f.option_ids[0].includes(o)).map((ds, i) => eval('hold_' + idx).push(ds.product_id)));

      console.log(hold_0, ' | ', hold_1, ' | ', hold_2);

      // .map((row) =>
      //   row.facets.map((fac) =>
      //     fac.map(
      //       (attr) => facetIdName.map((id) => id.product_facet_id).indexOf(attr[0].product_facet_id) === -1 && facetIdName.push({ product_facet_id: attr[0].product_facet_id, facet: attr[0].facet })
      //     )
      //   )
      // );

      dataSet
        .filter((f) => f.option_ids[0].includes(optionIds[0]))
        .filter((f) => f.option_ids[0].includes(optionIds[1]))
        .filter((f) => f.option_ids[0].includes(optionIds[2]))
        .map((row) =>
          row.facets.map((fac) =>
            fac.map(
              (attr) =>
                facetValues.map((id) => id.product_facet_option_id).indexOf(attr[0].product_facet_option_id) === -1 &&
                facetValues.push({ product_facet_id: attr[0].product_facet_id, product_facet_option_id: attr[0].product_facet_option_id, facet_value: attr[0].facet_value })
            )
          )
        );
    } else {
      //how to loop full data set
      dataSet.map((row) =>
        row.facets.map((fac) =>
          fac.map(
            (attr) => facetIdName.map((id) => id.product_facet_id).indexOf(attr[0].product_facet_id) === -1 && facetIdName.push({ product_facet_id: attr[0].product_facet_id, facet: attr[0].facet })
          )
        )
      );

      dataSet.map((row) =>
        row.facets.map((fac) =>
          fac.map(
            (attr) =>
              facetValues.map((id) => id.product_facet_option_id).indexOf(attr[0].product_facet_option_id) === -1 &&
              facetValues.push({ product_facet_id: attr[0].product_facet_id, product_facet_option_id: attr[0].product_facet_option_id, facet_value: attr[0].facet_value })
          )
        )
      );
    }
    //buildingDataSet.map((row) => row.facets.map((fac) => fac.map((attr) => console.log('attrr: ', row.product_id, ' | ', attr[0].facet))));

    //facet_id: row.product_facet_id, facet_name: row.facet

    //console.log('attrr: ', row.product_id, ' | ', attr[0].facet)
    setUniqueFacetHeaders(facetIdName);
    setUniqueFacetValues(facetValues);

    //dataSet.map((row) => row.facets.map((fac) => fac.map((attr) => attr[0].product_facet_option_id === 309 && console.log('id matches: ' + row.product_id))));
  }, [dataSet, optionIds]);

  // useEffect(() => {
  //   switch (groupName) {
  //     case 'as400ExtendedDescription':
  //       const facetIdName = [];
  //       const facetValues = [];

  //       const buildOutFacetIdNameAndFacetValues = (row) => {
  //         console.log('row: ', row);
  //         setDataSet((prev) => [...prev, row]);
  //         facetIdName.map((h) => h.facet_id).indexOf(row.product_facet_id) === -1 && facetIdName.push({ facet_id: row.product_facet_id, facet_name: row.facet });

  //         facetValues.map((v) => v.product_facet_option_id).indexOf(row.product_facet_option_id) === -1 &&
  //           facetValues.push({ product_facet_id: row.product_facet_id, product_facet_option_id: row.product_facet_option_id, facet_value: row.facet_value });
  //       };

  //       setDataSet([]);
  //       as400ExtendedDescriptionFilteredProducts.map((item) =>
  //         as400ExtendedDescriptionFullData.filter((row) => row.product_id === item.product_id && row.product_facet_option_id === item.option_id).map((fRow) => buildOutFacetIdNameAndFacetValues(fRow))
  //       );

  //       setUniqueFacetHeaders(facetIdName);
  //       setUniqueFacetValues(facetValues);
  //       break;
  //     default:
  //       console.log('not filtered data');
  //   }
  // }, [groupName, as400ExtendedDescriptionFilteredProducts, as400ExtendedDescriptionFullData]);

  // CONSOLES
  console.log('dataSet: ', dataSet);
  return (
    <Wrapper>
      nav2
      {uniqueFacetHeaders
        ? uniqueFacetHeaders.map((data, idx) => (
            <div key={idx}>
              <div style={{ backgroundColor: 'red', color: 'white' }}>
                {data.product_facet_id} | {data.facet}
              </div>
              <div>
                {uniqueFacetValues
                  .filter((v) => v.product_facet_id === data.product_facet_id)
                  .map((o, idx) => (
                    <div key={idx} onClick={() => handleOnClick(o.product_facet_id, o.product_facet_option_id)}>
                      {o.product_facet_id} | {o.product_facet_option_id} | {o.facet_value}
                    </div>
                  ))}
              </div>
            </div>
          ))
        : console.log('no headers')}
    </Wrapper>
  );
};
export default ReductiveSearchValues;
