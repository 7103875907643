import {
  Grid,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Radio,
  Button,
  Divider,
  Box,
  CircularProgress, 
  Backdrop
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';

const LookupForm = () => {
  // State For Form Entry
  const [accountNumber, setAccountNumber] = useState('99994');
  const [password, setPassword] = useState('12345');
  const [itemNumber, setItemNumber] = useState('');
  const [radioValue, setRadioValue] = useState('TSWWEB');
  const [searchResult, setSearchResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Loading state

  // Handle Submit For Item Lookup
  const handleSubmit = () => {
    setIsLoading(true);
    axios
      .post(
        `https://www.theshootingwarehouse.com/generalactions.php`,
        {},
        {
          params: {
            action: 'feed_lookup_item',
            a: accountNumber,
            p: password,
            item: itemNumber,
            s: radioValue,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setSearchResult(response.data);
        setIsLoading(false);
      });
  };

  const handleOnChange = (e) => {
    setItemNumber(e.target.value);
  };

  return (
    <Grid container spacing={1} alignItems='center' sx={{ p: 2 }}>
      <Grid item xs={2}>
        <Typography variant='h6'>Account #</Typography>
      </Grid>
      <Grid item xs={10}>
        <TextField value={accountNumber}>{accountNumber}</TextField>
      </Grid>
      <Grid item xs={2}>
        <Typography variant='h6'>Password</Typography>
      </Grid>
      <Grid item xs={10}>
        <TextField value={password}>{password}</TextField>
      </Grid>
      <Grid item xs={2}>
        <Typography variant='h6'>Item #</Typography>
      </Grid>
      <Grid item xs={10}>
        <TextField onChange={handleOnChange} />
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel id='demo-radio-buttons-group-label'>
            Include Class III Items (Suppressors)
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby='demo-radio-buttons-group-label'
            defaultValue='TSWWEB'
            name='radio-buttons-group'
            onChange={() => {
              setRadioValue(radioValue === 'TSWWEB' ? '12345' : 'TSWWEB');
            }}
          >
            <FormControlLabel value='TSWWEB' control={<Radio />} label='Yes' />
            <FormControlLabel value='12345' control={<Radio />} label='No' />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={1}>
        <Button variant='contained' color='primary' onClick={handleSubmit}>
          Submit
        </Button>        
      </Grid>      
      <Box sx={{ overflowY: 'auto', maxHeight: '55vh', mx: '.5em', my: '1em' }}>
        {searchResult.map((result, index) => (
          <Grid container spacing={1} key={index}>
            <Grid item xs={2}>
              <Typography variant='h5'>Field</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='h5'>Value</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h5'>Description</Typography>
            </Grid>
            {Object.entries(result).map(([key, detail]) => (
              <React.Fragment key={key}>
                <Grid item xs={2} sx={{ border: '1px solid' }}>
                  <Typography variant='h6'>{key}</Typography>
                </Grid>
                <Grid item xs={4} sx={{ border: '1px solid' }}>
                  <Typography>{detail.value}</Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    border: '1px solid black',
                    color:
                      key === 'CSEQ' ||
                      key === 'IMFSEQ' ||
                      key === 'CPRC' ||
                      key === 'PRC1' ||
                      key === 'QTYOH'
                        ? 'red'
                        : 'inherit',
                  }}
                >
                  <Typography>{detail.description}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        ))}
      </Box>
      {isLoading && (
        <Backdrop
          open={isLoading}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
    </Grid>
  );
};

export default LookupForm;
