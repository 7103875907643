import React, { useState } from 'react';

// CONTEXT STATE
export const MarketingContextState = React.createContext({
	//USER
	userId: 0,
	updateUserId: () => {},
});

// PRIMARY COMPONENT
const MarketingContext = ({ children }) => {
	// LOCAL STATE
	const [userIdState, setUserIdState] = useState(54155);

	// FUNCTIONS
	const updateUserId = (value) => setUserIdState(value);

	// CONTEXT OBJECT TO PASS THROUGH PROVIDER
	const contextValue = {
		userId: userIdState,
		updateUserId,
	};
	return <MarketingContextState.Provider value={contextValue}>{children}</MarketingContextState.Provider>;
};

export default MarketingContext;
