import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Box, styled, useTheme } from '@mui/material';

// Material Styled Components
const Wrapper = styled(Box)(({ theme }) => ({
	color: theme.palette.primary.main,
	fontWeight: 'bold',
	display: 'grid',
	gridTemplateColumns: '70px 1fr',
	alignItems: 'center',
	gap: '16px',
	borderRadius: '4px',
	padding: '8px',
	border: `solid 1px ${theme.palette.primary.main}`,
	boxShadow: '2px 2px 6px #00000020',
	marginBottom: '24px',
	'.imageWrapper': {
		borderRadius: '4px',
		aspectRatio: ' 3 /4',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
	},
}));

const PublicationCard = ({ item }) => {
	const theme = useTheme();

	const [loaded, setLoaded] = useState(false);
	const [data, setData] = useState({});

	useEffect(() => {
		setData({ item });
		setLoaded(true);
	}, [item]);

	console.log('passed item: ', item, ' | ', data);
	return (
		<Wrapper>
			{loaded && (
				<>
					<Box className='imageWrapper' style={{ backgroundImage: `url('/images/magazine-cover.jpg')` }} />
					<Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
						<Box sx={{ fontSize: 12, fontWeight: 'bold', textTransform: 'uppercase' }}>
							{data.item[0].description}
						</Box>
						<Box
							sx={{
								fontSize: 10,
								flex: 1,
								overflowY: 'auto',
								color: theme.palette.gray.medium,
							}}
						>
							{data.item[0].detailed_description}
						</Box>
						<Box sx={{ fontSize: 12, fontWeight: 'bold', textAlign: 'right' }}>{`${dayjs(
							data.item[0].start_date
						).format('MM-DD-YYYY')} - ${dayjs(data.item[0].expiration_date).format('MM-DD-YYYY')}`}</Box>
					</Box>
				</>
			)}
		</Wrapper>
	);
};

export default PublicationCard;
