import React, { useEffect, useState } from 'react';

import ContactsMaterialTable from './components/ContactsMaterialTable';
import { Box } from '@mui/material';

// PRIMARY COMPONENT EXPORT
const ManageContacts = () => {
	// LOCAL STATE
	const [loaded, setLoaded] = useState(false);

	// WAIT FOR ALL TO LOAD
	useEffect(() => {
		setLoaded(true);
	}, []);

	return (
		<>
			{loaded && (
				<Box sx={{ width: '100%', maxWidth: '1920px', mx: 'auto' }}>
					<ContactsMaterialTable />
				</Box>
			)}
		</>
	);
};

export default ManageContacts;
