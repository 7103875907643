import { Card, Grid, CardActionArea } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import EdiMfg from './EdiMfg/EdiMfg';
import ConvertCustomerToChain from './ConvertCustomerToChain/ConvertCustomerToChain';



const Edi = () => {

  const [isEdiMfgOpen, setIsEdiMfgOpen ] = useState(false);
  const [isConvertCustomerToChainOpen, setIsConvertCustomerToChainOpen ] = useState(false);
  const userPermission = useSelector((state) => state.userPermission.value);

  return (
    <Grid container spacing={2}>
      {userPermission[950] && 
      <Grid item xs={3}>
        <CardActionArea>
          <Card onClick={() => setIsConvertCustomerToChainOpen(true)} sx={{ pt:3,pb:3,pl:1,pr:1, textAlign:'center' }}>CONVERT CUSTOMER TO CHAIN</Card>
        </CardActionArea>
      </Grid>}
      {userPermission[949] && 
      <Grid item xs={3}>
        <CardActionArea>
          <Card onClick={() => setIsEdiMfgOpen(true)} sx={{ pt:3,pb:3,pl:1,pr:1, textAlign:'center' }}>EDI MFG</Card>
        </CardActionArea>         
      </Grid>}
      {/* <Grid item xs={3}>
        <Card>EDI 3</Card>
      </Grid>
      <Grid item xs={3}>
        <Card>EDI 4</Card>
      </Grid> */}
      <EdiMfg
        open={isEdiMfgOpen}
        onClose={() => setIsEdiMfgOpen(false)}
      />
      <ConvertCustomerToChain
        open={isConvertCustomerToChainOpen}
        onClose={() => setIsConvertCustomerToChainOpen(false)}
      />
    </Grid>
  );
};

export default Edi;

