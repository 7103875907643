import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import EntityContext from '../Context/Entity/EntityContext';
import Entities from '../Components/Entities/Entities';
import ManageContacts from '../Components/Entities/components/ManageContacts/ManageContacts';
import ManageEntities from '../Components/Entities/components/ManageEntities/ManageEntities';

const EntityRoutes = () => {
	const userPermission = useSelector((state) => state.userPermission.value);

	return (
		<EntityContext>
			{/* <Routes>
				{userPermission[965] && <Route path='/manage-contacts' element={<ManageContacts />} />}
				{userPermission[966] && <Route path='/manage-entities' element={<ManageEntities />} />}
				<Route path='/manage-contacts/contact/:id' element={<ManageContacts />} />
				<Route path='/products/product-details/:id' element={<ProductDetails />} />
				<Route path='/*' element={<Entities />} />
			</Routes> */}
		</EntityContext>
	);
};

export default EntityRoutes;
