import axios from 'axios';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import DirectoryEmailLayout from './DirectoryEmailLayout';

const DirectoryEmail = (details, description) => {
  // Add description to details so it can be passed to the email layout
  const emailDetails = {
    ...details,
    issueDescription: description, // Add the description to the details object
  };

  // Render the email layout with the details and issue description
  const htmlEncodedString = ReactDOMServer.renderToStaticMarkup(
    <DirectoryEmailLayout details={emailDetails} />
  );

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlEncodedString, 'text/html');
  const htmlContent = doc.body.textContent;

  const formData = new FormData();
  const subject = details.contact_name
    ? `Directory Report Issue: ${details.contact_name}`
    : `Directory Report Issue: ${details.business_name || 'Unknown Business'}`;

  formData.append('subject', subject);
  formData.append('htmlContent', htmlContent);
  formData.append('notificationCode', 'DIRECTORY_REPORT');

  return new Promise((resolve, reject) => {
    axios
      .post('/email/hubDirectoryReport', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default DirectoryEmail;
