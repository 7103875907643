import { createSlice } from '@reduxjs/toolkit';

export const userPermissionSlice = createSlice({
  name: 'userPermission',
  initialState: {
    value: [],
  },
  reducers: {
    setUserPermission: (state, action) => {
      state.value = action.payload;
    },
    resetUserPermission: (state) => {
      state.value = [];
    },
  },
});

export const { setUserPermission, resetUserPermission } = userPermissionSlice.actions;
export default userPermissionSlice.reducer;
