import { AddCircleOutline } from '@mui/icons-material';
import { Box, Button, Modal } from '@mui/material';
import React, { useState } from 'react';
import EntityContactForm from '../../EditContact/components/EntityContactForm';

const CreateNewContact = () => {
	const [modalOpen, setModalOpen] = useState(false);

	return (
		<>
			<Button
				variant='contained'
				color='success'
				startIcon={<AddCircleOutline />}
				size='small'
				sx={{ fontWeight: 'bold' }}
				onClick={() => setModalOpen(true)}
			>
				Add Contact
			</Button>
			{/* MODAL */}
			<Modal
				open={modalOpen}
				onClose={() => setModalOpen(false)}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={{}}>
					<EntityContactForm contactId={0} />
				</Box>
			</Modal>
		</>
	);
};

export default CreateNewContact;
