import { Card, Grid, CardActionArea } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import UpdateCustProbC from './CustProbC/UpdateCustProbC';


const Accounts = () => {
  const [isUpdateCustProbCOpen, setIsUpdateCustProbCOpen ] = useState(false);
  const userPermission = useSelector((state) => state.userPermission.value);
  
  return (
    <Grid container spacing={2}>
      {userPermission[955] && <Grid item xs={3}>
        <CardActionArea>
          <Card onClick={() => setIsUpdateCustProbCOpen(true)} sx={{ pt:3,pb:3,pl:1,pr:1, textAlign:'center' }}>Update Customer Problem C</Card>
        </CardActionArea>
      </Grid>}      
      <UpdateCustProbC
        open={isUpdateCustProbCOpen}
        onClose={() => setIsUpdateCustProbCOpen(false)}
      />      
    </Grid>
  );
};

export default Accounts;