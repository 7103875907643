import { Box } from '@mui/material';

const CategoryFilterItem = ({ func, catId, catName }) => {
  const setSelectedCategoryID = (catid) => {
    func(catid);
  };
  return (
    <Box sx={{ padding: 1, cursor: 'pointer' }} onClick={() => setSelectedCategoryID(catId)}>
      {catId} | {catName}
    </Box>
  );
};
export default CategoryFilterItem;
