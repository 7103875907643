import React from 'react';
import { Box, useTheme } from '@mui/material';

const DetailsContainer = ({
	children,
	title = 'My Container',
	barColor = 'teal',
	col = '1 / 2',
	row = '1 / 2',
	productId,
}) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				position: 'relative',
				gridColumn: col,
				gridRow: row,
				backgroundColor: 'white',
				overflow: 'hidden',
				display: 'flex',
				flexDirection: 'column',
				paddingBottom: '20px',
				boxShadow: '1px 1px 8px rgba(0,0,0,.6)',
			}}
		>
			<Box sx={{ backgroundColor: theme.palette.gray.light, lineHeight: '1px', fontSize: '1px' }}>
				<span
					style={{
						display: 'inline-block',
						fontSize: '1px',
						height: '5px',
						width: '75px',
						backgroundColor: barColor,
					}}
				>
					&nbsp;
				</span>
			</Box>
			<Box
				sx={{
					color: theme.palette.gray.dark,
					fontWeight: 'bold',
					fontSize: '18px',
					py: '12px',
					mx: '15px',
					borderBottom: `2px solid ${theme.palette.gray.medium}`,
					textTransform: 'uppercase',
				}}
			>
				{title}
			</Box>
			<Box sx={{ p: '15px', mt: '15px', flex: 1, overflowY: 'auto' }}>{children}</Box>
		</Box>
	);
};

export default DetailsContainer;
