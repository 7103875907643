import React, { useEffect, useState } from 'react';
import { getAllManufacturersByUserId } from '../Feeds/ContractsFeed';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const UsersManufacturersSelect = ({ userid, handleChange, entityId }) => {
	const [data, setData] = useState([]);
	const [pickedEntityId, setPickedEntityId] = useState(0);

	const _handleChange = (event) => {
		handleChange('entity_id', event.target.value);
	};

	useEffect(() => {
		const toDo = async () => {
			const dataMfr = await getAllManufacturersByUserId(userid);
			setData([{ entity_id: 0, business_name: 'Choose Manufacturer' }, ...dataMfr]);
			setPickedEntityId(entityId);
		};
		toDo();
	}, [userid, entityId]);

	console.log('entityId: ' + entityId);
	return (
		<>
			<FormControl fullWidth sx={{ mb: '24px' }}>
				<InputLabel id='my-manufacturers'>My Manufacturers</InputLabel>
				<Select
					labelId='my-manufacturers'
					value={pickedEntityId}
					label='My Manufacturers'
					onChange={handleChange}
				>
					{data.map((mfr) => (
						<MenuItem key={mfr.entity_id} value={mfr.entity_id}>
							{mfr.business_name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</>
	);
};

export default UsersManufacturersSelect;
