import { createSlice } from '@reduxjs/toolkit';

export const productSlice = createSlice({
  name: 'productInformation',
  initialState: {
    reductiveNavFullList: [],
    reductiveNavFilteredList: [],
    reductiveNavDataSet: ['1', '2', '3'],

    getAllActiveItems: [],
    getActiveProductToCategory: [],
    getActiveCategories: [],
    getActiveManufacturers: [],
    flagTypeProductId: 0,
    noteModalOpen: false,
    userInformation: {
      userID: 10007,
      contactID: 10008,
    },
  },

  reducers: {
    reductiveNavFullListValues: (state, action) => {
      state.reductiveNavFullList = action.payload;
    },
    reductiveNavFilteredListValues: (state, action) => {
      state.reductiveNavFilteredList = action.payload;
    },
    reductiveNavDataSetValues: (state, action) => {
      state.reductiveNavDataSet = action.payload;
    },
    getAllActiveItemsValues: (state, action) => {
      state.getAllActiveItems = action.payload;
    },
    getActiveProductToCategoryValues: (state, action) => {
      state.getActiveProductToCategory = action.payload;
    },
    getActiveCategoriesValues: (state, action) => {
      state.getActiveCategories = action.payload;
    },
    getActiveManufacturersValues: (state, action) => {
      state.getActiveManufacturers = action.payload;
    },
    flagTypeProductIdValue: (state, action) => {
      state.flagTypeProductId = action.payload;
    },
    noteModalOpenValue: (state, action) => {
      state.noteModalOpen = action.payload;
    },
    userInformationValue: (state, action) => {
      state.userInformation = action.payload;
    },
  },
});

export const {
  reductiveNavFullListValues,
  reductiveNavFilteredListValues,
  reductiveNavDataSetValues,
  getAllActiveItemsValues,
  getActiveProductToCategoryValues,
  getActiveCategoriesValues,
  getActiveManufacturersValues,
  flagTypeProductIdValue,
  noteModalOpenValue,
  userInformationValue,
} = productSlice.actions;
export default productSlice.reducer;
