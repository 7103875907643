import React, { useContext, useEffect, useState } from 'react';
import { EntityContactContextState } from '../../../../Context/Marketing/EntityContactContext';
import { Email, Factory, MoreVert, Person, PhoneIphone } from '@mui/icons-material';
import { Box, Button, IconButton, Menu, MenuItem, Modal, styled, Tooltip, useTheme } from '@mui/material';
import EntityContactForm from '../EditContact/components/EntityContactForm';

import Phone from './components/Phone';
import EmailComponent from './components/Email';
import Associations from './components/Associations';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
	borderBottom: `2px solid rgb(220,220,220)`,
	display: 'flex',
	justifyContent: 'flex-start',
	padding: '16px 8px',
	gap: '8px',
	'.icon-label': {
		fontWeight: 'bold',
		fontSize: '1em',
	},
	'&:last-of-type': {
		borderBottom: `0px solid rgb(220,220,220)`,
	},
}));

const EditContactMenu = ({ contactId }) => {
	// INITs
	const theme = useTheme();

	// CONTEXT STATE
	const {
		allPhoneNumbers,
		setAllPhoneNumbers,
		allEmails,
		setAllEmails,
		setAllEntityContacts,
		//allAssociationLink,
		setAllAssociationLink,
	} = useContext(EntityContactContextState);

	// LOCAL STATE
	const [anchorEl, setAnchorEl] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedComponent, setSelectedComponent] = useState('profile');
	const [allPhoneNumbersData, setAllPhoneNumbersData] = useState([]);
	const [allEmailsData, setAllEmailsData] = useState([]);
	//const [allAssociationsData, setAllAssociationsData] = useState([]);

	// OTHER CONST
	const open = Boolean(anchorEl);

	// useEffect(() => {
	// 	setAllAssociationLink();
	// }, [setAllAssociationLink]);

	// FUNCTIONS
	const handleClick = (event) => {
		setAnchorEl(event.target);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleMenuItemClick = (e, selection) => {
		setSelectedComponent(selection);
		setAnchorEl(null);
		setModalOpen(true);
	};

	const handleMenuItemClose = () => {
		console.log('this modal closing just got called');
		setAllPhoneNumbers();
		setAllEmails();
		setAllEntityContacts();
		setAllAssociationLink();
		setModalOpen(false);
	};

	const renderComponent = () => {
		switch (selectedComponent) {
			case 'profile':
				return <EntityContactForm contactId={contactId} />;

			case 'phone':
				return <Phone allPhoneNumbersData={allPhoneNumbersData} contactId={contactId} />;

			case 'email':
				return <EmailComponent allEmailsData={allEmailsData} contactId={contactId} />;

			case 'associations': {
				return <Associations contactId={contactId} />;
			}
			//	return <Associations allAssociationsData={allAssociationsData} contactId={contactId} />;

			default:
				return <Box sx={{ p: 2 }}>Please Choose Option</Box>;
		}
	};

	useEffect(() => {
		const fetchPhones = allPhoneNumbers.filter((e) => e.entity_contact_id === contactId);
		setAllPhoneNumbersData([...fetchPhones]);
	}, [allPhoneNumbers, contactId]);

	useEffect(() => {
		const fetchEmails = allEmails.filter((e) => e.entity_contact_id === contactId);
		setAllEmailsData([...fetchEmails]);
	}, [allEmails, contactId]);

	// useEffect(() => {
	// 	const fetchAssociations = allAssociationLink.filter((e) => e.entity_contact_id === contactId);
	// 	setAllAssociationsData([...fetchAssociations]);
	// }, [allAssociationLink, contactId]);

	return (
		<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
			<IconButton
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				sx={{ color: theme.palette.primary.main }}
			>
				<MoreVert />
			</IconButton>
			<Menu
				sx={{
					'.MuiMenu-paper': {
						padding: '16px 24px',
						borderRadius: '24px',
					},
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				<StyledMenuItem onClick={(e) => handleMenuItemClick(e, 'profile')}>
					<Person sx={{ color: theme.palette.secondary.main }} />
					<Box className='icon-label'>Profile</Box>
				</StyledMenuItem>
				<StyledMenuItem onClick={(e) => handleMenuItemClick(e, 'phone')}>
					<PhoneIphone sx={{ color: theme.palette.secondary.main }} />
					<Box className='icon-label'>Phone Numbers</Box>
				</StyledMenuItem>
				<StyledMenuItem onClick={(e) => handleMenuItemClick(e, 'email')}>
					<Email sx={{ color: theme.palette.secondary.main }} />
					<Box className='icon-label'>Email Addresses</Box>
				</StyledMenuItem>
				{/* <StyledMenuItem onClick={(e) => handleMenuItemClick(e, 'associations')}>
					<Factory sx={{ color: theme.palette.secondary.main }} />
					<Box className='icon-label'>Associations</Box>
				</StyledMenuItem> */}
			</Menu>

			{/* MODAL */}
			<Modal
				className='bbb'
				open={modalOpen}
				onClose={handleMenuItemClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				slotProps={{
					backdrop: {
						sx: {
							backdropFilter: 'blur(10px)',
							backgroundColor: 'rgba(255,255,255,.5)', // Change the backdrop color here
						},
					},
				}}
			>
				<div> {renderComponent()}</div>
			</Modal>
		</Box>
	);
};

export default EditContactMenu;
