import React, { useCallback, useEffect, useState } from 'react';
import {
  MaterialReactTable,
  MRT_ToggleFullScreenButton as MrtFullScreenToggleButton,
  MRT_ToggleDensePaddingButton as MrtToggleDensePaddingButton,
  MRT_ToggleFiltersButton as MrtToggleFiltersButton,
  MRT_ToggleGlobalFilterButton as MrtToggleGlobalFilterButton,
} from 'material-react-table';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ItemModal from './Components/ItemModal';
import { setIsLoading } from '../../Redux/LoadingSlice';
import dayjs from 'dayjs';

const PublishItems = ({ pageTitle }) => {
  const userInformation = useSelector((state) => state.userInformation.value);
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();

  const formattedDate = (date) => {
    return date ? dayjs(date).format('M/DD/YYYY HH:mm') : '';
  };

  const formattedDateNoTime = (date) => {
    return date ? dayjs(date).format('MM/DD/YYYY') : '';
  };

  const getItems = useCallback(() => {
    dispatch(setIsLoading(true));
    axios
      .get('/publishItems')
      .then((response) => {
        setData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  }, [dispatch]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  useEffect(() => {
    return () => {
      dispatch(setIsLoading(false));
    };
  }, [dispatch]);

  const handleModal = (rowData) => {
    setSelectedItem(rowData);
    setIsOpen(!isOpen);
  };

  const columns = [
    {
      header: 'Publish Date',
      accessorKey: 'publish_time',
      size: 125,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          <Typography variant='body1'>{formattedDate(renderedCellValue)}</Typography>
        </Box>
      ),
    },
    {
      header: 'Item #',
      accessorKey: 'product_code',
      size: 125,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'UPC #',
      accessorKey: 'upc_code',
      size: 125,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    // {
    //   header: 'MFR #',
    //   accessorKey: 'manufacturer_sku',
    //   size: 125,
    //   Cell: ({ renderedCellValue }) => (
    //     <Box
    //       sx={{
    //         display: 'flex',
    //         alignItems: 'center',
    //         justifyContent: 'start',
    //       }}
    //     >
    //       <Typography variant='body1'>{renderedCellValue}</Typography>
    //     </Box>
    //   ),
    // },
    {
      header: 'Brand',
      accessorKey: 'brand',
      size: 125,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Category',
      accessorKey: 'category',
      size: 125,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            maxWidth: '200px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <Typography
            variant='body1'
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '100%',
            }}
            title={renderedCellValue}
          >
            {renderedCellValue}
          </Typography>
        </Box>
      ),
    },
    {
      header: 'Description',
      accessorKey: 'description',
      size: 125,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            maxWidth: '200px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <Typography
            variant='body1'
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '100%',
            }}
            title={renderedCellValue}
          >
            {renderedCellValue}
          </Typography>
        </Box>
      ),
    },

    {
      header: 'Creation Date',
      accessorKey: 'date_imported',
      size: 125,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          <Typography variant='body1'>{formattedDateNoTime(renderedCellValue)}</Typography>
        </Box>
      ),
    },
    {
      header: 'Last Edited',
      accessorKey: 'last_edited',
      size: 125,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          <Typography variant='body1'>{formattedDate(renderedCellValue)}</Typography>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ p: 3, maxWidth: '100vw' }}>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableHiding={false}
        enableRowActions
        initialState={{
          showColumnFilters: false,
          density: 'compact',
          sorting: [{ id: 'publish_time', desc: true }],
          pagination: { pageSize: 15 },
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: '',
          },
        }}
        positionToolbarAlertBanner='bottom'
        renderToolbarInternalActions={({ table }) => {
          return (
            <Box>
              <MrtToggleGlobalFilterButton table={table} />
              <MrtToggleFiltersButton table={table} />
              <MrtToggleDensePaddingButton table={table} />
              <Button
                className='MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-78trlr-MuiButtonBase-root-MuiIconButton-root'
                tabIndex={0}
                type='button'
                aria-label='Refresh'
                data-mui-internal-clone-element='true'
                onClick={() => {
                  getItems();
                }}
              >
                <RefreshIcon className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root' />
                <span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
              </Button>
            </Box>
          );
        }}
        renderRowActions={({ row }) => {
          return (
            <Box>
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  handleModal(row.original);
                }}
              >
                View
              </Button>
            </Box>
          );
        }}
      />
      <ItemModal isOpen={isOpen} closeModal={handleModal} itemData={selectedItem} />
    </Box>
  );
};

export default PublishItems;
