import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, Save } from '@mui/icons-material';

const OptionsContainer = styled(Box)(({ theme }) => ({
  maxHeight: '600px',
  overflowY: 'auto',
  height: 0,
  transition: '5000ms ease-in-out',
  '&.dropped': {
    height: 'auto',
  },
}));

const ArrowWrapper = styled(Grid)(({ theme }) => ({
  '.arrow-up': { display: 'none' },
  '.arrow-down': { display: 'inline-block' },
  '&.dropped': {
    '.arrow-up': { display: 'inline-block' },
    '.arrow-down': { display: 'none' },
  },
}));

const CategoryAttributeList = () => {
  const theme = useTheme();

  const getPDMCategoryAttributes = useSelector((state) => state.pdmSlice.getPDMCategoryAttributes);
  const [categoryAttributes, setCategoryAttributes] = useState([]);
  const [attrHeaders, setAttrHeaders] = useState([]);

  useEffect(() => {
    const ids = [];
    getPDMCategoryAttributes.length > 0 &&
      getPDMCategoryAttributes.map(
        (attr, idx) =>
          ids
            .filter((header) => header === attr.product_facet_id)
            .indexOf(attr.product_facet_id) === -1 && ids.push(attr.product_facet_id)
      );
    setAttrHeaders([...ids]);
  }, [getPDMCategoryAttributes]);

  const toggleOptionsContainer = (e) => {
    const parent = e.target.closest('.facetWrapper');
    parent.querySelector('.optionsContainer').classList.toggle('dropped');
    e.currentTarget.querySelector('.arrowWrapper').classList.toggle('dropped');
  };

  const handleOptionClick = (optionid, facetid, catid) => {
    console.log('optionid: ' + optionid + ' facetid: ' + facetid, ' catid: ' + catid);
  };

  return (
    <Box className='CatagoryAttributeList' sx={{}}>
      {attrHeaders.map((attr, idx) => (
        <Box key={idx} className='facetWrapper' sx={{ mt: 1 }} data-attr={attr}>
          {getPDMCategoryAttributes
            .filter((item) => item.product_facet_id === attr)
            .map(
              (facet, idxx) =>
                idxx === 0 && (
                  <Grid
                    container
                    key={idxx}
                    justifyContent={'space-between'}
                    sx={{ cursor: 'pointer' }}
                    data-option-id={facet.product_facet_option_id}
                    onClick={(e) => toggleOptionsContainer(e)}
                  >
                    <Grid item sx={{}}>
                      <Typography
                        sx={{
                          fontSize: '15px',
                          fontWeight: 'bold',
                          color: theme.palette.primary.main,
                        }}
                      >
                        {facet.facet}
                      </Typography>
                    </Grid>
                    <ArrowWrapper item className='arrowWrapper'>
                      <KeyboardArrowDown
                        className='arrow-down'
                        sx={{ color: theme.palette.secondary.main }}
                      />
                      <KeyboardArrowUp
                        className='arrow-up'
                        sx={{ color: theme.palette.secondary.main }}
                      />
                    </ArrowWrapper>
                  </Grid>
                )
            )}
          <OptionsContainer className='optionsContainer'>
            <Box>
              {getPDMCategoryAttributes
                .filter((item, idx) => item.product_facet_id === attr)
                .map((facet) => (
                  <Grid
                    container
                    sx={{
                      p: 1,
                      pl: 2,
                      cursor: 'pointer',
                      transition: '200ms ease-in-out',
                      borderRadius: 1,
                      '&:hover': { backgroundColor: theme.palette.primary.lightScreen },
                    }}
                    alignItems={'center'}
                    onClick={() =>
                      handleOptionClick(
                        facet.product_facet_option_id,
                        facet.product_facet_id,
                        facet.product_category_id
                      )
                    }
                  >
                    <Grid item sx={{ pr: '2px', fontSize: '.85em' }}>
                      {facet.value}
                    </Grid>
                    <Grid item sx={{ fontSize: '.7em', color: theme.palette.secondary.main }}>
                      ({facet.amount_used})
                    </Grid>
                  </Grid>
                ))}
            </Box>
          </OptionsContainer>
        </Box>
      ))}
    </Box>
  );
};
export default CategoryAttributeList;
