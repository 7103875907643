import React, { useEffect, useState } from 'react';

// IMPORTED COMPONENTS
import Contracts from './Contracts/Contracts';

const Advertising = () => {
	// LOCAL STATE
	const [userId, setUserId] = useState(54155);

	return (
		<div>
			Advertising
			<Contracts userId={userId} />
		</div>
	);
};

export default Advertising;
