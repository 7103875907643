import { Switch } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { EntityContextState } from '../../../../../Context/Entity/EntityContext';

const DeleteContactActionButton = ({ isChecked, contactId }) => {
	const [checked, setChecked] = useState(false);
	const { updateContactsActiveStatus } = useContext(EntityContextState);

	const handleChange = (e) => {
		setChecked(!checked);
		updateContactsActiveStatus(!checked, contactId);
	};

	useEffect(() => {
		setChecked(isChecked);
	}, [isChecked]);

	return (
		<Switch
			checked={checked}
			onChange={(e) => handleChange(e)}
			color='success'
			inputProps={{ 'aria-label': 'controlled' }}
		/>
	);
};

export default DeleteContactActionButton;
