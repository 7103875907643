import React, { useContext } from 'react';
import { Box, useTheme } from '@mui/material';
import Attributes from './components/Attributes';

import DetailsContainer from './components/DetailsContainer';
import ProductHeaderInfo from './components/ProductHeaderInfo';
import { ProductDetailsContextState } from '../../../../../../Context/pdm/ProductDetailsContext';

const ProductDetails = ({ productId }) => {
	const theme = useTheme();
	const { productId: productIdContext, productsAttributesAndValues } = useContext(ProductDetailsContextState);

	console.log('productId sent to PD: ' + productId + ' | ', productsAttributesAndValues);
	return (
		<Box
			sx={{
				padding: '25px',
				minWidth: '75vw',
				height: '100%',
				display: 'grid',
				gridTemplateColumns: 'repeat(12, 1fr)',
				gridTemplateRows: 'repeat(12, 1fr)',
				gap: '25px',
				overflow: 'hidden',
			}}
		>
			<DetailsContainer
				productId={productId}
				title='Product Header Info:'
				barColor='purple'
				col='1 / span 4'
				row='1 / span 2'
			>
				<ProductHeaderInfo productId={productIdContext} />
			</DetailsContainer>

			<DetailsContainer
				productId={productId}
				title='My Container:'
				barColor='orange'
				col='5/ span 2'
				row='3 / span 8'
			>
				some contenta
			</DetailsContainer>

			<DetailsContainer
				productId={productId}
				title='My Container:'
				barColor='orange'
				col='5/ span 2'
				row='9 / -1'
			>
				some contentb
			</DetailsContainer>

			<DetailsContainer productId={productId} title='My Container:' barColor='orange' col='7/ 10' row='9 / -1'>
				some contentc
			</DetailsContainer>

			<DetailsContainer
				productId={productId}
				title='Attributes:'
				barColor={theme.palette.primary.main}
				col='10/ span 3'
				row='1/ -1'
			>
				<Attributes data={productsAttributesAndValues} />
			</DetailsContainer>
		</Box>
	);
};

export default ProductDetails;
