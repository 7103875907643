import { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { getPDMCategoryAttributesValue } from './../../../../Redux/PDMSlice';

import axios from 'axios';

import { Box, Grid, IconButton, TextField, useTheme } from '@mui/material';
import { AddShoppingCart } from '@mui/icons-material';

import CategoryFilterItem from './CategoryFilterItem';

const CategoryFilter = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [categoryName, setCategoryName] = useState('');
  const [selectedCategoryId, setSelectedCategoryID] = useState(0);
  const [categoryAttributes, setCategoryAttributes] = useState([]);
  const categories = [
    {
      catid: 26,
      name: 'pistols',
    },
    {
      catid: 62,
      name: 'cameras',
    },
    {
      catid: 87,
      name: 'cleaning kits',
    },
  ];

  const funcSetSelectedCategoryID = (catid) => {
    setSelectedCategoryID(catid);
  };

  useEffect(() => {
    const fetchData = () => {
      selectedCategoryId > 0 &&
        axios
          .get(`/pdm/valuesCorrections/${selectedCategoryId}`)
          .then(function (response) {
            const data = response.data;
            setCategoryAttributes(data);
            setCategoryName('');
            dispatch(getPDMCategoryAttributesValue(data));
          })
          .catch((error) => {
            console.log('error: ' + error);
          });
    };

    fetchData();
  }, [selectedCategoryId, dispatch]);

  return (
    <Grid container justifyContent={'center'}>
      <Grid item className='apple' xs={12} sx={{ position: 'relative' }}>
        <TextField
          fullWidth
          label='Namee'
          value={categoryName}
          onChange={(e) => {
            setCategoryName(e.target.value);
          }}
        />
        {categoryName.length > 1 && (
          <Box
            sx={{ position: 'absolute', top: '100%', left: 0, width: '100%', maxHeight: '300px', overflow: 'auto', p: 2, border: `solid 1px ${theme.palette.primary.main}`, backgroundColor: 'white' }}
          >
            <Box>
              {categoryName === 'all'
                ? categories.map((cat, idx) => <CategoryFilterItem key={idx} func={funcSetSelectedCategoryID} catId={cat.catid} catName={cat.name} />)
                : categories
                    .filter((catt) => catt.catid === parseInt(categoryName) || (catt.name.toLowerCase().includes(categoryName.toLowerCase()) && categoryName !== 'a'))
                    .map((catt, idxc) => <CategoryFilterItem key={idxc} func={funcSetSelectedCategoryID} catId={catt.catid} catName={catt.name} />)}
            </Box>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
export default CategoryFilter;
