import React, { useContext, useEffect, useState } from 'react';
import { EntityContextState } from '../../../../../../Context/Entity/EntityContext';
import { Save } from '@mui/icons-material';
import { Box, IconButton, InputBase } from '@mui/material';
import axios from 'axios';
import DeleteEmailFromContact from './DeleteEmailFromContact';
import SnackBarAlert from '../../../../../_GlobalComponents/SnackBarAlert';

const EmailEntryForm = ({ contactId, item }) => {
	const [emailState, setEmailState] = useState('');
	const [saveButtonState, setSaveButtonState] = useState(false);
	const [hideRow, setHideRow] = useState([]);
	const [snackbarOpen, setSnackbarOpen] = useState(false);

	const { updateTriggerRefreshContactData, updateTriggerRefreshContactEmailList } = useContext(EntityContextState);

	const handleSaveButtonClick = () => {
		setSaveButtonState(false);

		const updateFunc = () => {
			try {
				const response = axios.post('/entity/updateContactsEmail', {
					email_address: emailState,
					entity_contact_email_id: item.id,
				});
				updateTriggerRefreshContactData();
				setSnackbarOpen(true);
			} catch (error) {
				console.error('Error:', error);
			}
		};
		//
		const addEmailAddressToContact = async () => {
			try {
				const response = await axios.post('/entity/addEmailAddressToEntityContact', {
					entity_contact_id: contactId,
					email_address: emailState,
				});
				console.log('add email response: ', response);
				updateTriggerRefreshContactEmailList();
				updateTriggerRefreshContactData();
			} catch (error) {
				console.error('Error:', error);
			}
		};

		item.id === 999999 ? addEmailAddressToContact() : updateFunc();
	};

	const removeDeletedEmail = (e) => {
		setHideRow((prev) => [...prev, item.id]);
		updateTriggerRefreshContactEmailList();
	};

	const handleChange = (value) => {
		setEmailState(value);
		setSaveButtonState(true);
	};

	const ResetOpen = () => {
		setSnackbarOpen(false);
	};

	// Use Effects
	useEffect(() => {
		setEmailState(item.email);
	}, [item.email]);

	return (
		<Box
			sx={{
				display: hideRow.includes(item.id) ? 'none' : 'flex',
				gap: 2,
				'&:last-of-type': { borderBottom: 'none', mb: 0 },
			}}
		>
			<Box
				sx={{
					border: 'solid 2px rgb(220,220,220)',
					p: 1,
					borderRadius: '8px',
					flex: 1,
					display: 'flex',
				}}
			>
				<InputBase fullWidth sx={{}} value={emailState} onChange={(ele) => handleChange(ele.target.value)} />
			</Box>

			<Box
				sx={{
					border: 'solid 2px rgb(220,220,220)',
					p: 1,
					borderRadius: '8px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				{saveButtonState ? (
					<>
						<IconButton onClick={() => handleSaveButtonClick()}>
							<Save color='success' />
						</IconButton>
						<Box>|</Box>
					</>
				) : (
					<>
						<IconButton>
							<Save sx={{ color: '#e7e7e7' }} />
						</IconButton>

						<Box>|</Box>
					</>
				)}

				<DeleteEmailFromContact entity_contact_email_id={item.id} removeDeletedEmail={removeDeletedEmail} />
			</Box>
			<SnackBarAlert doOpen={snackbarOpen} ResetOpen={ResetOpen} message='Email Address Saved!' />
		</Box>
	);
};

export default EmailEntryForm;
