import React from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { setUserInformation } from '../../Redux/UserInformationSlice';
import { useSelector } from 'react-redux';
import { Container, CssBaseline, Grid, Paper, TextField, Typography, Button } from '@mui/material';
import { Alert } from '@mui/material';
import Logo from '../../Assets/BugLogo.png';
import { Box } from '@mui/system';
import jwt_decode from 'jwt-decode';
import { setUserPermission } from '../../Redux/UserPermissionSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';

const StyledGrid = styled(Grid)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.main,
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  borderRadius: '15px',
  textAlign: 'center',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& label.Mui-focused': {
    color: theme.palette.primary.main,
  },
  '& .Mui-focused fieldset': {
    borderColor: theme.palette.primary.main,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const userInformation = useSelector((state) => state.userInformation.value);
  const userPermission = useSelector((state) => state.userPermission.value);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLoginClick = (e) => {
    e.preventDefault();
    axios
      .post('/users/tswLogin', {
        username: username,
        password: password,
      })
      .then((response) => {
        const decoded = jwt_decode(response.data.token);
        dispatch(
          setUserInformation({
            userID: decoded.user_id,
            contactID: decoded.contact_id,
            token: response.data.token,
            firstName: decoded.first_name,
            lastName: decoded.last_name,
            avatarLink: decoded.avatar_link,
            employeeNumber: decoded.employee_number,
          })
        );
        dispatch(setUserPermission(decoded.elements));
        if (location.state && location.state.from) {
          navigate(location.state.from);
        } else {
          navigate('/');
        }
      })
      .catch((error) => {
        console.log(error);
        setError('Incorrect username or password. Please try again.');
      });
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <StyledGrid container component='main'>
      <CssBaseline />
      <Container component={StyledPaper} maxWidth='xs' elevation={5}>
        <Box>
          <img src={Logo} alt='Logo' style={{ width: '100%' }} />
        </Box>
        {error && <Alert severity='error'>{error}</Alert>}
        <form onSubmit={handleLoginClick}>
          <StyledTextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='username'
            label='Username'
            name='username'
            autoComplete='username'
            autoFocus
            value={username}
            onChange={handleUsernameChange}
          />
          <StyledTextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
            value={password}
            onChange={handlePasswordChange}
          />
          <StyledButton type='submit' fullWidth variant='contained' color='primary'>
            Sign In
          </StyledButton>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='subtitle2'>
                Use the same Hub login that you always have.
              </Typography>
              <Typography variant='subtitle2'>
                Contact Help Desk if you have trouble logging in.
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Container>
    </StyledGrid>
  );
};

export default Login;
