import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

// Material UI
import { Box, Button, Chip, styled } from '@mui/material';

// Material Styled Components
const Wrapper = styled(Box)(({ theme }) => ({}));
const AttrWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '.editItemsAttributeValue': { textDecoration: 'none', color: theme.palette.secondary.main },
}));

// Primary Component
const SelectedItemsAttributes = () => {
  //const [attributeMasterList, setAttributeMasterList] = useState([]);
  const [defaultAttributes, setDefaultAttributes] = useState([]);
  const [currentAttributes, setCurrentAttributes] = useState([]);

  const as400DescSelectedCategoryId = useSelector((state) => state.pdmSlice.as400DescSelectedCategoryId);
  const as400DescSelectedProductId = useSelector((state) => state.pdmSlice.as400DescSelectedProductId);

  //   const splitCurrentAttributeValues = (values) => {
  //     let output = '';
  //     const splitValues = values.split(',');
  //     const splitItems = (item) => {
  //       const itemSplit = item.split('~~');
  //       output += `<a href='#' class='editItemsAttributeValue' data-option-id="${itemSplit[0]}">${itemSplit[1]}</a>`;
  //     };
  //     splitValues.map((val) => splitItems(val));
  //     const sendMarkup = <Box dangerouslySetInnerHTML={{ __html: output }}></Box>;
  //     return sendMarkup;
  //   };

  useEffect(() => {
    axios
      .get(`/pdm/getCategorysDefaultAttributes/${as400DescSelectedCategoryId}`)
      .then(function (response) {
        setDefaultAttributes([]);
        setDefaultAttributes(response.data);
      })
      .catch((error) => {
        console.log('error: ' + error);
      });
  }, [as400DescSelectedCategoryId]);

  useEffect(() => {
    axios
      .get(`/pdm/getItemAttributeValues/${as400DescSelectedProductId}`)
      .then(function (response) {
        setCurrentAttributes([]);
        setCurrentAttributes(response.data);
      })
      .catch((error) => {
        console.log('error: ' + error);
      });
  }, [as400DescSelectedProductId]);

  return (
    <Wrapper>
      {currentAttributes
        .filter((model) => model.product_facet_id === 11980 || model.product_facet_id === 11981)
        .map((cm, idx) => (
          <AttrWrapper key={idx}>
            <Box sx={{ width: '200px', textAlign: 'left' }}>
              <Button sx={{ justifyContent: 'flex-start', textAlign: 'left' }} onClick={() => console.log(`${cm.product_facet_id} | ${cm.facet_option_value.split('~~')[0]}`)}>
                {cm.facets}
              </Button>
            </Box>
            <span style={{ display: 'inline-block', margin: '0 10px' }}>:</span>
            <Box>
              {cm.facet_option_value.split(',').map((value) => (
                <Chip
                  sx={{ mx: 1, whiteSpace: 'pre-wrap', height: 'auto' }}
                  label={value.split('~~')[2]}
                  onClick={() => console.log('chip clicked' + value.split('~~')[1] + ' | ' + value.split('~~')[0])}
                  onDelete={() => console.log('chip deleted. remove record ' + value.split('~~')[0])}
                  color='secondary'
                />
              ))}
            </Box>
          </AttrWrapper>
        ))}

      {currentAttributes
        .filter((model) => model.product_facet_id !== 11980 && model.product_facet_id !== 11981)
        .map((cm) => (
          <AttrWrapper>
            <Box sx={{ width: '200px', textAlign: 'left' }}>
              <Button sx={{ justifyContent: 'flex-start', textAlign: 'left' }} onClick={() => console.log(`${cm.product_facet_id} | ${cm.facet_option_value.split('~~')[0]}`)}>
                {cm.facets}
              </Button>
            </Box>
            <span style={{ display: 'inline-block', margin: '0 10px' }}>:</span>
            <Box>
              {cm.facet_option_value.split(',').map((value) => (
                <Chip
                  sx={{ mx: 1, whiteSpace: 'pre-wrap', height: 'auto' }}
                  label={value.split('~~')[2]}
                  onClick={() => console.log('chip clicked' + value.split('~~')[1] + ' | ' + value.split('~~')[0])}
                  onDelete={() => console.log('chip deleted. remove record ' + value.split('~~')[0])}
                  color='secondary'
                />
              ))}
            </Box>
          </AttrWrapper>
        ))}
      {/* {defaultAttributes.map((attr) =>
        currentAttributes
          .filter((c) => c.product_facet_id === attr.product_facet_id)
          .map((cm) => (
            <AttrWrapper>
              <Box sx={{ width: '200px', textAlign: 'left' }}>
                <Button sx={{ justifyContent: 'flex-start', textAlign: 'left' }} onClick={() => console.log(`${attr.product_facet_id} | ${cm.facet_option_value.split('~~')[0]}`)}>
                  {attr.facets}
                </Button>
              </Box>
              <span style={{ display: 'inline-block', margin: '0 10px' }}>:</span>
              <Box>
                {cm.facet_option_value.split(',').map((value) => (
                  <Chip
                    sx={{ mx: 1, whiteSpace: 'pre-wrap', height: 'auto' }}
                    label={value.split('~~')[2]}
                    onClick={() => console.log('chip clicked' + value.split('~~')[1] + ' | ' + value.split('~~')[0])}
                    onDelete={() => console.log('chip deleted. remove record ' + value.split('~~')[0])}
                    color='secondary'
                  />
                ))}
              </Box>
            </AttrWrapper>
          ))
      )} */}
    </Wrapper>
  );
};
export default SelectedItemsAttributes;
