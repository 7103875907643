import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Paper, Typography, Link, TextField, Box } from '@mui/material'; // Added Box from MUI
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

const Reps = () => {
  const RepData = useSelector((state) => state.repInfoSlice.value);
  const [searchQuery, setSearchQuery] = useState('');

  const formatPhoneNumber = (phoneNumberString) => {
    // Check for null or undefined phone number
    if (!phoneNumberString) return '';

    // Remove non-numeric characters
    const cleaned = phoneNumberString.replace(/\D/g, '');

    // Handle different phone number lengths
    switch (cleaned.length) {
      case 10: // Standard 10-digit US phone number
        return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      case 11: // US phone number with country code
        return cleaned.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');
      default:
        return phoneNumberString; // Return original if format is unknown
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const handleClearSearch = () => {
    setSearchQuery('');
  };

  const filteredReps = searchQuery
    ? RepData.filter(
        (rep) =>
          rep.first_name.toLowerCase().includes(searchQuery) ||
          rep.last_name.toLowerCase().includes(searchQuery)
      )
    : RepData;

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label='Search Reps'
          variant='outlined'
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{ mb: 2 }}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleClearSearch} edge='end'>
                <ClearIcon />
              </IconButton>
            ),
          }}
        />
      </Grid>
      {Array.isArray(filteredReps) &&
        filteredReps.map((rep) => (
          <Grid item xs={12} key={rep.sales_number}>
            <Paper
              elevation={3}
              sx={{
                p: 2,
                border: 1,
                borderColor: '#ccc',
                borderRadius: 2,
                boxShadow: 2,
                backgroundColor: '#f5f5f5',
                ':hover': { transform: 'scale(1.02)' },
              }}
            >
              <Typography variant='h6' align='center' sx={{ fontWeight: 'bold', mb: 1 }}>
                {`${rep.first_name} ${rep.last_name}`}
              </Typography>
              <Typography variant='body1' sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                <PhoneIcon sx={{ mr: 1 }} />
                {rep.phone_number === '' || rep.phone_number === null ? (
                  'No Phone Number'
                ) : (
                  <Link href={`tel:${rep.phone_number}`} color='primary' underline='always'>
                    {formatPhoneNumber(rep.phone_number)}
                  </Link>
                )}
              </Typography>

              <Typography variant='body1' sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                <EmailIcon sx={{ mr: 1 }} />
                <Link href={`mailto:${rep.email_address}`} color='primary' underline='always'>
                  {rep.email_address}
                </Link>
              </Typography>
              <Typography variant='body1' sx={{ display: 'flex', alignItems: 'center' }}>
                {rep.at_shot_show ? (
                  <CheckCircleIcon color='success' sx={{ marginRight: 1 }} />
                ) : (
                  <CancelIcon color='error' sx={{ marginRight: 1 }} />
                )}
                <Box sx={{ verticalAlign: 'middle' }}>
                  {rep.at_shot_show ? 'At SHOT Show' : 'Not At SHOT Show'}
                </Box>
              </Typography>
            </Paper>
          </Grid>
        ))}
    </Grid>
  );
};

export default Reps;
