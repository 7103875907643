import React, { useEffect, useState } from 'react';
import axios from 'axios';

// CONTEXT STATE
export const EntitiesV2ContextState = React.createContext({
	selectedTypeId: 999999,
	setSelectedTypeId: () => {},

	term: '',
	setTerm: () => {},

	activeItemsFilter: '',
	setActiveItemsFilter: () => {},

	entityAssoTypes: [],
});

const EntitiesV2Context = ({ children }) => {
	// LOCAL STATE
	const [selectedTypeIdState, setSelectedTypeIdState] = useState(999999);
	const [termState, setTermState] = useState('');
	const [activeItemsFilterState, setActiveItemsFilterState] = useState('all');
	const [entityAssoTypesState, setEntityAssoTypesState] = useState([]);

	// FUNCTIONS
	const fetchEntityAssoTypes = async () => {
		try {
			const response = await axios.get(`/entity-contacts/getAllAssociationRolesTypes`);
			setEntityAssoTypesState([...response.data]);
		} catch (error) {
			console.error('Error fetching data:', error);
			return [];
		}
	};

	const setSelectedTypeId = (typeId) => {
		setSelectedTypeIdState(typeId);
	};

	const setTerm = (term) => {
		setTermState(term);
	};

	const setActiveItemsFilter = (value) => {
		setActiveItemsFilterState(value);
	};

	// USE EFFECTS
	useEffect(() => {
		fetchEntityAssoTypes();
	}, []);

	const contextValue = {
		selectedTypeId: selectedTypeIdState,
		setSelectedTypeId,
		term: termState,
		setTerm,
		activeItemsFilter: activeItemsFilterState,
		setActiveItemsFilter,
		entityAssoTypes: entityAssoTypesState,
	};
	return <EntitiesV2ContextState.Provider value={contextValue}>{children}</EntitiesV2ContextState.Provider>;
};
export default EntitiesV2Context;
