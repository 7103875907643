import React, { useContext, useEffect, useState } from 'react';
import { EditProductDataContextState } from '../../../../../../Context/pdm/EditProductDataContext';
import Panel from './components/Panel';

const Panels = () => {
	const { tabs } = useContext(EditProductDataContextState);
	return (
		<>
			{tabs.map((e, i) => (
				<Panel details={e} />
			))}
		</>
	);
};

export default Panels;
