import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { flagTypeProductIdValue } from '../../../../Redux/ProductSlice';
import { PDMModalFlagsNoteFormWrapperValue } from '../../../../Redux/PDMSlice';

import axios from 'axios';

// Material UI
import { Box, Button, FormControl, InputLabel, MenuItem, Select, styled, TextField } from '@mui/material';

import { dateFormatterYYYYMMDD } from './../../../_GlobalFunctions/pdm';
import { fetchUserData } from './../../../_GlobalFunctions/pdm';
import { PDMSnackBarMessageValue, PDMSnackBarOpenValue, PDMSnackBarSevertyValue } from '../../../../Redux/PDMSlice';

// Material UI Styled Components
const Wrapper = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: 12,
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
}));

// Primary Component
const AddFlag = ({ productId }) => {
  const dispatch = useDispatch();
  const flags = useRef([]);
  const [selectedFlag, setSelectedFlag] = useState(0);
  const [data, setData] = useState([]);
  const [noteValue, setNoteValue] = useState('');
  const userInformationContactID = useSelector((state) => state.productSlice.userInformation.contactID);
  const PDMModalFlagsNoteFormWrapper = useSelector((state) => state.pdmSlice.PDMModalFlagsNoteFormWrapper);

  useEffect(() => {
    const getFlagTypes = async () => {
      await axios
        .get(`/products/getProductFlagTypes`)
        .then(function (response) {
          flags.current = response.data;
        })
        .catch((error) => {
          console.log('error: ' + error);
        });
    };
    getFlagTypes();
  }, [flags]);

  useEffect(() => {
    setData(fetchUserData());
    const getData = async () => {
      return await fetchUserData('https://jsonplaceholder.typicode.com/photos')
        .then(function (response) {
          setData(response);
        })
        .catch((error) => {
          console.log('error: ' + error);
        });
    };
    getData();
  }, []);

  const saveNote = () => {
    axios
      .post(`/products/addProductFlagNote`, { contactId: userInformationContactID, productId: productId, typeId: selectedFlag, note: noteValue })
      .then(function (response) {
        console.log('inserted flag note: ' + response.data);
        dispatch(flagTypeProductIdValue(10));
        setTimeout(() => {
          dispatch(flagTypeProductIdValue(productId));
        }, '1000');
        dispatch(PDMModalFlagsNoteFormWrapperValue(!PDMModalFlagsNoteFormWrapper));
        dispatch(PDMSnackBarOpenValue(true));
        dispatch(PDMSnackBarMessageValue('Flag Note Saved!'));
        dispatch(PDMSnackBarSevertyValue('success'));
      })
      .catch((error) => {
        console.log('error: ' + error);
      });
    setSelectedFlag(0);
    setNoteValue('');
  };

  return (
    <Wrapper>
      <FormControl>
        <InputLabel id='flag-label'>Choose Flag Type</InputLabel>
        <Select sx={{ textAlign: 'left' }} labelId='flag-label' label='Choose Flag Type' value={selectedFlag} onChange={(e) => setSelectedFlag(e.target.value)}>
          <MenuItem value='0'></MenuItem>
          {flags.current.map((flag, idx) => (
            <MenuItem key={idx} value={flag.ss_pdm_product_flag_type_id}>
              {flag.description}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField sx={{ width: '100%' }} label='Note' multiline rows={4} value={noteValue} onChange={(e) => setNoteValue(e.target.value)} />
      <Box sx={{ textAlign: 'right' }}>
        <Button variant='contained' color='success' onClick={() => saveNote()}>
          Add Note
        </Button>
      </Box>
      {/* {data.length > 0 && data.map((item, idx) => <h5 key={idx}>{item.id}</h5>)} */}
    </Wrapper>
  );
};
export default AddFlag;
