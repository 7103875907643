import React from 'react';
import { Box, useTheme } from '@mui/material';

const Footer = () => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				textAlign: 'center',
				fontWeight: 'bold',
				textTransform: 'uppercase',
				backgroundColor: theme.palette.primary.alt,
				color: 'white',
				padding: '12px 0',
			}}
		>
			Sports South's PDM &copy; 2024
		</Box>
	);
};

export default Footer;
