import React from 'react';
import { Box, styled, useTheme } from '@mui/material';
import MarketingLayoutMenu from './components/MarketingLayoutMenu';

// Material Styled Components
const MenuBar = styled(Box)(({ theme }) => ({
	color: theme.palette.primary.main,
	fontWeight: 'bold',
	padding: '24px 16px',
	marginBottom: '16px',
	position: 'sticky',
	top: '0',
	boxShadow: '0 1px 2px rgba(0,0,0,.2)',
	backgroundColor: 'white',
	zIndex: 3,
}));

const Layout = ({ children }) => {
	// INIT's
	const theme = useTheme();

	return (
		<Box
			className='marketingLayoutFixedParent'
			sx={{
				top: 0,
				left: 0,
				position: 'fixed',
				width: '100vw',
				height: '100vh',
				overflow: 'hidden',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Box sx={{ minHeight: '67px' }}>a</Box>
			<MenuBar className='markting-layout'>
				<MarketingLayoutMenu />
			</MenuBar>
			<Box sx={{ flex: 1, overflow: 'hidden' }}>{children}</Box>
		</Box>
	);
};

export default Layout;
