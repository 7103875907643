import React, { useContext, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

import { EditProductDataContextState } from '../../../../../../../Context/pdm/EditProductDataContext';

//const data = [];

const InputMfr = () => {
	const {
		updateSelectedMfrId,
		selectedMfrId,

		usersManufacturers: data,
	} = useContext(EditProductDataContextState);

	const [value, setValue] = useState(data[0]);

	console.log('value: ', selectedMfrId);
	console.log('usersManufacturers: ', data);

	return (
		<Autocomplete
			value={value ? value : data[0]}
			onChange={(event, newValue) => {
				setValue(newValue);
				updateSelectedMfrId(newValue.id);
			}}
			options={data.length > 0 ? data : []}
			getOptionLabel={(option) => option.label}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			disableClearable={true}
			renderInput={(params) => <TextField {...params} label='Choose a manufacturer' />}
			sx={{ width: 300 }}
		/>
	);
};

export default InputMfr;
