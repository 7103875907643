import React from 'react';
import { useDrag, useDrop, DndProvider, useDragLayer } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { Grid, Box, IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import o2_1 from '../../../Assets/MatchingGame/Ribbons/o2_1.png';
import o2_2 from '../../../Assets/MatchingGame/Ribbons/o2_2.png';
import o2_3 from '../../../Assets/MatchingGame/Ribbons/o2_3.png';
import o2_4 from '../../../Assets/MatchingGame/Ribbons/o2_4.png';
import o22_1 from '../../../Assets/MatchingGame/office_tab_2/o2_1.png';
import o22_2 from '../../../Assets/MatchingGame/office_tab_2/o2_2.png';
import o22_3 from '../../../Assets/MatchingGame/office_tab_2/o2_3.png';
import o22_4 from '../../../Assets/MatchingGame/office_tab_2/o2_4.png';

const ItemType = {
  IMAGE: 'image',
};

// Custom Drag Layer
const CustomDragLayer = () => {
  const { isDragging, item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging || !currentOffset) {
    return null;
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;

  return (
    <div
      style={{
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 100,
        left: 0,
        top: 0,
        transform,
        WebkitTransform: transform,
      }}
    >
      <img src={item.src} alt={`Drag preview`} style={{ width: '20%', opacity: 0.5 }} />
    </div>
  );
};

const DraggableImage = ({ id, src, isGrayedOut }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemType.IMAGE,
    item: { id, src },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <img
      ref={drag}
      src={src}
      alt={`Draggable ${id}`}
      style={{
        opacity: isDragging ? 0.5 : isGrayedOut ? 0.3 : 1,
        width: '60%',
        height: 'auto',
        cursor: 'pointer',
      }}
    />
  );
};

const DroppableBox = ({ id, onDrop, children }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemType.IMAGE,
    drop: (item) => onDrop(item.id, id),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return (
    <Box
      ref={drop}
      sx={{
        position: 'relative',
        width: '100%',
        height: 'auto',
        marginBottom: 2,
        backgroundColor: isOver ? 'lightgreen' : 'null',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </Box>
  );
};

const OfficeP2 = () => {
  const [topImages, setTopImages] = React.useState([
    { id: 1, src: o2_1, isGrayedOut: false },
    { id: 2, src: o2_2, isGrayedOut: false },
    { id: 3, src: o2_3, isGrayedOut: false },
    { id: 4, src: o2_4, isGrayedOut: false },
  ]);
  const [bottomImages, setBottomImages] = React.useState([
    { id: 5, src: o22_1, pinnedImage: null },
    { id: 6, src: o22_2, pinnedImage: null },
    { id: 7, src: o22_3, pinnedImage: null },
    { id: 8, src: o22_4, pinnedImage: null },
  ]);

  const handleDrop = (topId, bottomId) => {
    setBottomImages((prev) =>
      prev.map((image) => (image.id === bottomId ? { ...image, pinnedImage: topId } : image))
    );
    setTopImages((prev) =>
      prev.map((image) => (image.id === topId ? { ...image, isGrayedOut: true } : image))
    );
  };

  const handleReset = () => {
    setTopImages((prev) => prev.map((image) => ({ ...image, isGrayedOut: false })));
    setBottomImages((prev) => prev.map((image) => ({ ...image, pinnedImage: null })));
  };

  return (
    <DndProvider backend={TouchBackend}>
      <CustomDragLayer />
      <Grid
        container
        spacing={2}
        sx={{
          padding: 2,
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <IconButton
            onClick={handleReset}
            sx={{
              backgroundColor: 'white',
              borderRadius: '50%',
              padding: '5px',
              '&:hover': {
                backgroundColor: 'white',
              },
              '&:focus': {
                backgroundColor: 'white',
              },
              '&:active': {
                backgroundColor: 'white',
              },
            }}
          >
            <RefreshIcon sx={{ fontSize: '40px' }} />
          </IconButton>
        </Grid>
        {topImages.map((image) => (
          <Grid item xs={3} key={image.id}>
            <DraggableImage id={image.id} src={image.src} isGrayedOut={image.isGrayedOut} />
          </Grid>
        ))}
        {bottomImages.map((image, index) => (
          <Grid item xs={6} key={image.id}>
            <DroppableBox id={image.id} onDrop={handleDrop}>
              <img
                src={image.src}
                alt={`Droppable ${image.id}`}
                style={{ width: '300px', height: '100%' }}
              />
              {image.pinnedImage && (
                <img
                  src={topImages.find((img) => img.id === image.pinnedImage).src}
                  alt={`Pinned ${image.pinnedImage}`}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '30%',
                    height: 'auto',
                  }}
                />
              )}
            </DroppableBox>
          </Grid>
        ))}
      </Grid>
    </DndProvider>
  );
};

export default OfficeP2;
