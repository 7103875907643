import React from 'react';
import { Box, Button } from '@mui/material';
import InputMfr from './InputMfr';
import InputCat from './InputCat';
import InputSeries from './InputSeries';
import InputModels from './InputModels';
import SearchButton from './SearchButton';

const MenuItems = () => {
	return (
		<Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, alignItems: 'center' }}>
			<Box>
				<InputMfr />
			</Box>
			<Box>
				<InputCat />
			</Box>
			<Box>
				<InputSeries />
			</Box>
			<Box>
				<InputModels />
			</Box>
			<Box>
				<SearchButton />
			</Box>
		</Box>
	);
};

export default MenuItems;
