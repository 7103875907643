import { Box, Button, Drawer, Tab, Tabs, Stack, Grid, Typography, styled, ButtonBase, CircularProgress } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import Modal from "@mui/material"
import { Close, Flag } from "@mui/icons-material"
import PDMAttributeDrawer from "./Components/NewPDM/PDMAttrDrawer"
import PDMSearch from "./Components/NewPDM/PDMSearch"
import PDMMaint
 from "./Components/NewPDM/PDMMaint"
import axios from "axios"
import PDMMaintTable from "./Components/NewPDM/PDMMaintTable"
import PDMSearchTable from "./Components/NewPDM/PDMSearchTable"

// TODO 
//      Finally merge the Attribute Changes to the real stuff instead of test

export default function PDMDrawer() {
    const [openDrawer, setOpenDrawer] = useState(false)
    const [openSearch, setOpenSearch] = useState(false)
    const [openMaint, setOpenMaint] = useState(false)
    const [loading, setLoading] = useState(false)

    const [val, setVal] = useState(0)
    const [tabs, setTabs] = useState([])

    const CloseButton = styled(ButtonBase)(({ theme }) => ({
        borderRadius: theme.spacing(1),
        backgroundColor: 'ghostwhite',
        border: theme.spacing(1),
      }));

    const StyledTab = styled(Tab)(({theme}) => ({
        opacity: 1,
        fontSize: 12,
        paddingLeft:theme.spacing(1),
        paddingRight: theme.spacing(1),
        backgroundColor: '#b2c9d1',
        borderTopLeftRadius: theme.spacing(1),
        borderTopRightRadius: theme.spacing(1),
        borderTop: 'solid',
        borderLeft: 'solid',
        borderRight: 'solid',
        borderWidth: '1px',
        marginLeft:'1px',
    }))

    const TabContent = () => {
        let title = ''
        let key = ''
        let searchTerms = {}
        let data = []
        tabs.map((tabVal) => {
            if(tabVal.key == val){
                title = tabVal.title
                key = tabVal.key
                searchTerms = tabVal.search_terms
               }
        })
        console.log(title)
        switch (title) {
            case "FOR": 
                return <PDMMaintTable key={key} searchTerms={searchTerms}/>
            case "department":
            case "category":
            case "brand":        
            case "manufacturer":
            case "scan":
            case "sports_south":    
            case "upc":    
                return <PDMSearchTable key={key} searchTerms={searchTerms}/>   
            default: 
            return(
                <div>
                    <div>{title}</div>
                    <div>{key}</div>
                    <div>Not Implemented</div>
                </div>
            )
            
        }
        
    }

    const SearchCallback = useCallback((res) => {
        addTabSearch(res)
        toggleSearch()
    })

    const MaintCallback = useCallback((res) => {
        // console.log(res)
        toggleMaint()
        addMaintTab(res)
    })

    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer)
    }

    const toggleSearch = () => {
        setOpenSearch(!openSearch)
    }

    const toggleMaint = () => {
        setOpenMaint(!openMaint)
    }

    const addTabSearch = async (res) => {
        //console.log(res)
        let i = 0
        let newArray = []
        let returnData = []
        tabs.map((value) => {
            newArray.push(value)
            i++
        })

        newArray.push({
            label: res.value.label, 
            value: res.value, 
            title:res.type, 
            key: res.value.label+'-'+i,
            search_terms: res
        })
        setVal(res.value.label+'-'+i)
        setTabs(newArray)

    }

    const addMaintTab = async (res) => {
        let i = 0;
        let newArray = []
        let returnData = []
        let newLabel = ''

        tabs.map((value) => {
            newArray.push(value)
            i++
        })


        if(res.type == 'FOR'){
            newLabel = 'Option Replace' +'-'+ res.facet.label + '_' + res.facet_option.label
        }else{
            newLabel = res.type
        }    
            newArray.push({
                label: newLabel,
                value: i,
                title: res.type,
                key: res.type+'-'+i,
                search_terms: res
            })
            setVal(res.type+'-'+i)
        
        console.log(newArray)
        setTabs(newArray)
    }

    const handleChange = (event, value) => {
        //console.log(value)
        setVal(value)
    }

    const handleCloseTab = (event, value) => {
        //console.log(value)
        let toDelete = value.key
        //console.log(value.label)
        const unsortedTabs = tabs.filter((val) => toDelete !== val.key)
        //console.log(unsortedTabs)

        let i = 0
        let newTabs = []
        unsortedTabs.map((tab) => {
            //console.log(tab)
            newTabs.push({
                label: tab.label,
                value: tab.value,
                title: tab.title,
                key: tab.title + '-' +i,
                data: tab.data,
                search_terms: tab.search_terms
            })
            i++
        })

        setTabs(newTabs)
    }

    return(
        <div>
            
            <Stack
                position={'fixed'}
                bgcolor={'white'}
                zIndex={100}
                borderBottom={1}
                width={'100%'}
            >
                {loading ? <div style={{paddingBottom: 1, paddingTop:10, alignSelf:'center'}}> <CircularProgress/> </div>: 
                <Box
                    paddingBottom={1}
                    textAlign={'right'}
                    paddingRight={1}
                    alignSelf={'center'}
                >
                <Button 
                    onClick={toggleSearch} 
                    variant='outlined'
                    sx={{margin:1}}
                >
                    Search 
                </Button>
                <Button 
                    onClick={toggleMaint} 
                    variant='outlined'
                    sx={{margin:1}}
                >
                    Maintenance
                </Button>
                </Box>
                }
                <Box
                    overflow={'scroll'}
                >
                <Tabs
                    value={val}
                    onChange={(event, value) => handleChange(event, value)}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {tabs.map((res) => (
                        <StyledTab 
                            
                            value={res.key} 
                            label={res.label} 
                            icon={<CloseButton
                                size="small"
                                sx={{backgroundColor:'ghostwhite',
                                }}
                                id={res.label}
                                key={res.key}
                                onClick={(event) => handleCloseTab(event, res)}
                            ><Close fontSize="small" color="warning"/></CloseButton>}
                            iconPosition="end"
                        />
                    ))}
                </Tabs>
                </Box>
                
            </Stack>
     
            <div style={{paddingTop: '140px'}}>
            {
                
                (tabs.length !== 0 ? TabContent(): 
                <Typography  textAlign={'center'} fontSize={'20px'} fontWeight={'bold'}>
                    Please Search for a product or Value
                    </Typography>)
            }
            <Drawer
                sx={{width:'300'}}
                anchor={'right'}
                open={openDrawer}
                onClose={toggleDrawer}
            >
                <PDMAttributeDrawer productCode={'8028'}/>
            </Drawer>
            <Drawer
                anchor={'top'}
                open={openSearch}
                onClose={toggleSearch}
            >
                <PDMSearch 
                    searchCallback={SearchCallback} 
                />
            </Drawer>
            
          <Drawer
            open={openMaint}
            onClose={toggleMaint}
            anchor="top"
          >
            <PDMMaint 
                maintCallback={MaintCallback}
            />
          </Drawer>   
            <Button variant={'contained'}  onClick={toggleDrawer}>**TEST** Attribute Drawer</Button>

          </div>
        </div>
    )
}