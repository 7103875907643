import { Modal, Paper } from '@mui/material';
import React from 'react';
import UpdateCustProbCForm from './Components/UpdateCustProbCForm';

const UpdateCustProbC = ({ open, onClose }) => {
  return (
    <Modal onClose={onClose} open={open} sx={{ overflowY: 'none', minHeight: '50em', maxHeight: '50em', display: 'flex',  justifyContent: 'center'}} >
      <Paper sx={{ mx: '1em', mt: '5em', width: '50%' }}>
        <UpdateCustProbCForm />
      </Paper>
    </Modal>
  );
};

export default UpdateCustProbC;
