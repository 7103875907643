import React from 'react';
import FileSelect from './Components/FileSelect';
import { Grid } from '@mui/material';

const ImageManagement = ({ pageTitle }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <FileSelect />
      </Grid>
    </Grid>
  );
};

export default ImageManagement;
