import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  as400DescProgressModalValue,
  as400DescSelectedProductIdValue,
  as400DescSelectedCategoryIdValue,
  as400DescItemListValue,
  as400DescItemFilteredListValue,
  as400DescItemFilterValue,
  as400DescItemCountValue,
  as400DescPerPageCountValue,
  as400DescCurrentPageNumberValue,
} from '../../../../Redux/PDMSlice';

import { flagTypeProductIdValue } from '../../../../Redux/ProductSlice';

import axios from 'axios';

// Material UI
import { Alert, Badge, Box, Checkbox, Chip, FormControlLabel, Grid, IconButton, Snackbar, styled, useTheme } from '@mui/material';
import { Save, TextSnippet } from '@mui/icons-material';
//import RowFilterInput from './RowFilterInput';

// Material UI Styled Components
const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: theme.deminsions.maxWidth,
  margin: 'auto',
  marginTop: '16px',
  marginBottom: '16px',
  '.desc_input': {
    width: '100%',
    border: 'none',
    padding: 10,
    fontSize: '1.1em',
    backgroundColor: theme.palette.primary.lightScreen,
    borderRadius: 8,
    color: theme.palette.text.primary,
    '&:active': { outline: `${theme.palette.primary.main} auto 0px` },
  },
}));

const ResultsGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 100px',
  gridTemplateRows: 'auto auto auto auto auto',
  border: `solid 2px ${theme.palette.primary.main}`,
  borderRadius: 12,
  color: '#666',
  '.thumbnail_wrapper': { gridRow: '1 / 3', gridColumn: '2/3', position: 'relative', marginRight: 15, img: { width: '100%', height: '100%', objectFit: 'contain', objectPosition: 'center center' } },
  '&.active': { border: `solid 2px ${theme.palette.secondary.main} ` },
}));

// Primary Component
const SearchResults = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const as400ExtendedDescriptionFilteredData = useSelector((state) => state.reductionDataSlice.as400ExtendedDescriptionFilteredData);

  const as400DescriptionSearchTerm = useSelector((state) => state.pdmSlice.as400DescriptionSearchTerm);

  const [fullSearchResults, setFullSearchResults] = useState([]);
  const [filterSearchResults, setFilterSearchResults] = useState([]);
  const [saveSnackbar, setSaveSnackbar] = useState(false);

  // func to set redux with items catid and productid
  const setReduxCatIdProductIdFromSelectedItem = (e, product_category_id, product_id) => {
    document.querySelectorAll('.descriptionResultItem').forEach((i) => i.classList.remove('active'));
    e.target.closest('.descriptionResultItem').classList.add('active');
    dispatch(as400DescSelectedProductIdValue(product_id));
    dispatch(as400DescSelectedCategoryIdValue(product_category_id));
  };

  const onSaveClick = async (id, code, e) => {
    dispatch(as400DescProgressModalValue(true));
    const desc = e.currentTarget;
    const descId = e.currentTarget.getAttribute('data-description-id');
    const inputId = `description_text_${code}`;
    const selectedInput = document.getElementById(inputId);

    descId < 1
      ? await axios
          .post(`/pdm/insertAS400Description`, {
            value: selectedInput.value,
            product_id: id,
          })
          .then(function (response) {
            desc.setAttribute('data-description-id', response.data.insertId);
            dispatch(as400DescProgressModalValue(false));
            setSaveSnackbar(true);
          })
          .catch((error) => {
            console.log('error: ' + error);
          })
      : await axios
          .post(`/pdm/updateAS400Description`, {
            value: selectedInput.value,
            rowid: descId,
          })
          .then(function (response) {
            dispatch(as400DescProgressModalValue(false));
            setSaveSnackbar(true);
          })
          .catch((error) => {
            console.log(error);
          });
  };

  useEffect(() => {
    const filterDescriptionSearchResults = (term) => {
      const allRows = document.querySelectorAll('.descriptionResultItem');

      const processOut = (row) => {
        const rowId = row.id;
        const thisRow = document.getElementById(rowId);
        const product_code = row.getElementsByClassName('resultsProductCode')[0].getAttribute('data-value');
        const product_idesc = row.getElementsByClassName('product_idesc')[0].getAttribute('data-value');
        const product_categories = row.getElementsByClassName('items_categories')[0].innerHTML;
        product_idesc.toLowerCase().includes(term.toLowerCase()) || product_code === term || product_categories.toLowerCase().includes(term.toLowerCase())
          ? (thisRow.style.display = 'grid')
          : (thisRow.style.display = 'none');
      };
      allRows.forEach((row) => processOut(row));
      dispatch(as400DescProgressModalValue(false));
    };
    filterDescriptionSearchResults(as400DescriptionSearchTerm);
  }, [as400DescriptionSearchTerm, dispatch]);

  useEffect(() => {
    let filteredIds = [];
    as400ExtendedDescriptionFilteredData.map((e) => e.product_id.split(',').map((id) => filteredIds.map((ee) => ee).indexOf(parseInt(id)) === -1 && filteredIds.push(parseInt(id))));
    filteredIds.sort();
    console.log('filteredIds: ', filteredIds, ' | ', filteredIds.length);

    filteredIds.length > 0
      ? axios
          .post(`/products/getProductDataFromIdsV1`, { ids: filteredIds })
          .then(function (response) {
            setFullSearchResults(response.data);
            dispatch(as400DescItemListValue(response.data));
            dispatch(as400DescItemFilteredListValue(response.data));
            setFilterSearchResults([]);
            setTimeout(function () {
              setFilterSearchResults(response.data);
            }, 1000);

            dispatch(as400DescProgressModalValue(false));
          })
          .catch((error) => {
            console.log('error: ' + error);
          })
      : console.log('no ids');
  }, [as400ExtendedDescriptionFilteredData, dispatch]);

  return (
    <Wrapper id='SearchResults'>
      {/* SEARCH RESULT ROWS */}
      {/* <Box sx={{ flex: 1, overflow: 'auto' }} className='checker'> */}
      {filterSearchResults.map((item, idx) => (
        <ResultsGrid id={`descriptionResultItem_${item.product_code}`} className='descriptionResultItem' key={idx} sx={{ marginBottom: 2, padding: 1 }}>
          <Box className='thumbnail_wrapper'>
            <img
              style={{ display: 'block', width: '100%', height: '100%', objectFit: 'contain', objectPosition: 'center center', position: 'absolute', top: 0, left: 0 }}
              alt='product'
              src={`https://media.server.theshootingwarehouse.com/thumbnail/${item.pic_ref}.jpg`}
            />
          </Box>
          <Box>
            <span style={{ color: theme.palette.primary.main, fontWeight: 'bold', cursor: 'pointer' }} className='resultsProductCode' data-value={item.product_code}>
              SS# <span onClick={(e) => setReduxCatIdProductIdFromSelectedItem(e, `${item.product_category_id}`, `${item.product_id}`)}>{item.product_code}</span>
            </span>{' '}
            | <span>{item.upc_code}</span> |
            <span>
              {item.manufacturer} Model {item.model} {item.series}
            </span>
          </Box>
          <Box className='items_categories' sx={{ textTransform: 'uppercase', fontWeight: 'bold', color: '#222', padding: '6px 2px', fontSize: '12px' }}>
            {item.categories !== null &&
              item.categories.split('----').map((cats, idx) => (
                <span style={{ marginRight: '15px' }} key={idx}>
                  {cats.split('~~')[1]}
                </span>
              ))}
          </Box>

          <Box sx={{ gridColumn: '1/-1' }}>
            <input className='desc_input' type='text' id={`description_text_${item.product_code}`} defaultValue={item.as400_extended_text !== null ? item.as400_extended_text : ''} />
          </Box>
          <Box sx={{ gridColumn: '1/-1', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box className='product_idesc' data-value={item.as400_desc}>
              {item.as400_desc}
            </Box>
            <Box>
              <IconButton
                id={`note_button_${item.product_code}`}
                color='secondary'
                aria-label='Note Description'
                component='label'
                data-description-id={item.as400_extended_id > 0 ? item.as400_extended_id : 0}
                onClick={(e) => dispatch(flagTypeProductIdValue(item.product_id))}
              >
                <Badge badgeContent={item.note_count} color='secondary'>
                  <TextSnippet />
                </Badge>
              </IconButton>
              |
              <IconButton
                id={`save_button_${item.product_code}`}
                color='success'
                aria-label='Save Description'
                component='label'
                data-description-id={item.as400_extended_id > 0 ? item.as400_extended_id : 0}
                onClick={(e) => onSaveClick(item.product_id, item.product_code, e)}
              >
                <Save />
              </IconButton>
            </Box>
          </Box>

          <Box sx={{ padding: '2px' }}>
            {item.tags !== null &&
              item.tags
                .split('----')
                .map((tags, idx) => <Chip key={idx} label={tags.split('~~')[1]} sx={{ backgroundColor: `${tags.split('~~')[2]}`, color: 'white', fontSize: '10px', height: '21px' }} />)}
          </Box>
        </ResultsGrid>
      ))}
      {/* </Box> */}
      <Snackbar open={saveSnackbar} autoHideDuration={6000} onClose={() => setSaveSnackbar(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSaveSnackbar(false)} severity='success' sx={{ width: '100%' }}>
          Description Saved!
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};

export default SearchResults;
