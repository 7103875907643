import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { flagTypeProductIdValue, noteModalOpenValue } from '../../../../Redux/ProductSlice';
import { PDMModalFlagsNoteFormWrapperValue } from '../../../../Redux/PDMSlice';

// IMPORT FUNCTION
import { dateFormatterYYYYMMDD } from '../../../_GlobalFunctions/pdm';

import axios from 'axios';

// Material UI
import { Box, Collapse, IconButton, Modal, styled, TextField, useTheme } from '@mui/material';
import { AddCircle, RemoveCircle, SaveAs } from '@mui/icons-material';

import AddFlag from './AddFlag';

// Material styled components

// PRIMARY COMPONENT
const ModalFlags = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  // REDUX STATE
  const flagTypeProductId = useSelector((state) => state.productSlice.flagTypeProductId);
  const noteModalOpen = useSelector((state) => state.productSlice.noteModalOpen);
  const PDMModalFlagsNoteFormWrapper = useSelector((state) => state.pdmSlice.PDMModalFlagsNoteFormWrapper);

  // LOCAL STATE
  const [flagTypeResults, setFlagTypeResults] = useState([]);
  const [allFlagNotes, setAllFlagNotes] = useState([]);
  const [addNoteOpen, setAddNoteOpen] = useState(false);

  useEffect(() => {
    const getItemsNotes = async (id) => {
      await axios
        .get(`/products/getProductFlagsByProductId/${id}`)
        .then(function (response) {
          setAllFlagNotes(response.data);
          getItemsNoteTypes(flagTypeProductId);
        })
        .catch((error) => {
          console.log('error: ' + error);
        });
    };

    const getItemsNoteTypes = async (id) => {
      console.log('getItemsNoteTypes ran');
      console.log('id: ' + id);
      await axios
        .get(`/products/getProductFlagsTypesByProductId/${id}`)
        .then(function (response) {
          dispatch(noteModalOpenValue(true));
          setFlagTypeResults(response.data);
        })
        .catch((error) => {
          console.log('error: ' + error);
        });
    };

    flagTypeProductId !== 0 && getItemsNotes(flagTypeProductId);
  }, [flagTypeProductId, dispatch]);

  const onSaveClick = (id) => {
    const note = document.getElementById(`items_note_${id}`).value;
    const d = new Date(Date.now());
    const dateValue =
      d.getFullYear() +
      '-' +
      (d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1) +
      '-' +
      (d.getDate() < 10 ? '0' + d.getDate() : d.getDate()) +
      ' ' +
      d.getHours() +
      ':' +
      d.getMinutes() +
      ':' +
      d.getSeconds();
    console.log('id: ' + id + ' note: ' + note + ' time: ' + dateValue);
    axios
      .post(`/products/updateProductsFlagNote`, { id: id, note: note, time: dateValue })
      .then(function (response) {
        console.log('update return: ' + response.data);
      })
      .catch((error) => {
        console.log('error: ' + error);
      });
  };

  const onCloseModal = () => {
    dispatch(flagTypeProductIdValue(0));
    dispatch(noteModalOpenValue(false));
  };

  const setNewFlagFormWrapper = () => {
    const isNoteOpen = !addNoteOpen;
    const newFlagBox = document.getElementById('_new_flag_box');
    const maxHeightValue = newFlagBox.scrollHeight + 'px';
    isNoteOpen ? (newFlagBox.style.maxHeight = maxHeightValue) : (newFlagBox.style.maxHeight = 0);
    setAddNoteOpen(!addNoteOpen);
  };
  console.log('collapse? ' + PDMModalFlagsNoteFormWrapper);
  return (
    <Modal open={noteModalOpen} onClose={() => onCloseModal()} aria-labelledby='note viewer' aria-describedby='note viewer'>
      <Box sx={{ width: '450px', maxHeight: '50vh', position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%,-50%)', overflow: 'auto' }}>
        <Box sx={{ backgroundColor: theme.palette.primary.main, borderTopLeftRadius: '12px', borderTopRightRadius: '12px', padding: 1, display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>Product Notes: </span>
          <IconButton color='primary' aria-label='add flag note' component='label' onClick={() => dispatch(PDMModalFlagsNoteFormWrapperValue(!PDMModalFlagsNoteFormWrapper))}>
            {PDMModalFlagsNoteFormWrapper ? (
              <RemoveCircle
                sx={{
                  color: 'white',
                  fontSize: '30px',
                  cursor: 'pointer',
                  transition: '200ms ease-in-out',
                  '&:hover': { color: theme.palette.secondary.main, scale: '1.2' },
                }}
              />
            ) : (
              <AddCircle
                sx={{
                  color: 'white',
                  fontSize: '30px',
                  cursor: 'pointer',
                  transition: '200ms ease-in-out',
                  '&:hover': { color: theme.palette.secondary.main, scale: '1.2' },
                }}
              />
            )}
          </IconButton>
          {/* 
          {PDMModalFlagsNoteFormWrapper ? (
            <RemoveCircle
              sx={{
                color: 'white',
                fontSize: '30px',
                cursor: 'pointer',
                transition: '200ms ease-in-out',
                '&:hover': { color: theme.palette.secondary.main, scale: '1.2' },
              }}
              onClick={() => PDMModalFlagsNoteFormWrapperValue(false)}
            />
          ) : (
            <AddCircle
              sx={{
                color: 'white',
                fontSize: '30px',
                cursor: 'pointer',
                transition: '200ms ease-in-out',
                '&:hover': { color: theme.palette.secondary.main, scale: '1.2' },
              }}
              onClick={() => dispatch(PDMModalFlagsNoteFormWrapperValue(true))}
            />
          )} */}
        </Box>
        <Collapse in={PDMModalFlagsNoteFormWrapper} timeout='auto' unmountOnExit>
          <Box sx={{ backgroundColor: 'white' }}>
            <AddFlag productId={flagTypeProductId} />
          </Box>
        </Collapse>
        {/* <NewFlagBox id='_new_flag_box' sx={{ backgroundColor: 'white' }}>
          <AddFlag productId={flagTypeProductId} />
        </NewFlagBox> */}
        {flagTypeResults
          .filter((types) => types.qty > 0)
          .map((type, idx) => (
            <Box key={idx}>
              <Box key={idx} sx={{ backgroundColor: `${type.display_color}`, padding: 1, fontWeight: 'bold', color: 'white' }}>
                {type.description}
              </Box>
              <Box sx={{ backgroundColor: 'white', color: theme.palette.text.primary, padding: 1 }}>
                {allFlagNotes
                  .filter((notes) => notes.ss_pdm_product_flag_type_id === type.ss_pdm_product_flag_type_id)
                  .map((note, n_idx) => (
                    <Box key={n_idx}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>{note.contact_name}</span> <span>{dateFormatterYYYYMMDD(note.time_created)}</span>
                      </Box>
                      <Box className='flag_note_wrapper' sx={{ display: 'flex', gap: '15px', padding: 2 }}>
                        <Box sx={{ flex: 1 }}>
                          <TextField sx={{ width: '100%' }} id={`items_note_${note.ss_pdm_product_flag_id}`} label='Note' multiline rows={4} defaultValue={note.notes} />
                        </Box>
                        <Box sx={{ width: '60px' }}>
                          <IconButton id={`save_flag_${flagTypeProductId}`} color='success' aria-label='Save Flag' component='label' onClick={(e) => onSaveClick(`${note.ss_pdm_product_flag_id}`)}>
                            <SaveAs />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  ))}
              </Box>
            </Box>
          ))}
      </Box>
    </Modal>
  );
};
export default ModalFlags;
