import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { EditProductDataContextState } from '../../../../../../../Context/pdm/EditProductDataContext';

import { Autocomplete, TextField } from '@mui/material';

const initArray = [{ id: 0, label: 'Choose Category Please' }];

const InputCat = () => {
	const { selectedMfrId, updateSelectedCatId } = useContext(EditProductDataContextState);

	const [data, setData] = useState([]);
	const [value, setValue] = useState(...initArray);
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		setMounted(false);
		const fetchCategoryListFromMfrId = async (id) => {
			//alert('this ran');
			try {
				const response = await axios.get(`/pdm/getUniqueCategoriesByMfrId/${selectedMfrId}`);
				setValue(initArray[0]);
				setData([...initArray, ...response.data]); // Assuming the response has a data object
				setMounted(true);
			} catch (error) {
				console.log('fetchCategoryListFromMfrId error: ', error);
			}
		};

		selectedMfrId > 0 && fetchCategoryListFromMfrId(selectedMfrId);
	}, [selectedMfrId]);

	return (
		<>
			<Autocomplete
				value={value}
				onChange={(event, newValue) => {
					setValue(newValue);
					updateSelectedCatId(newValue.id);
				}}
				options={data}
				disableClearable={true}
				getOptionLabel={(option) => option.label}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				renderInput={(params) => <TextField {...params} label={`Choose a Category`} />}
				sx={{ width: 300 }}
			/>

			{/* {mounted ? (
				<Autocomplete
					value={value}
					onChange={(event, newValue) => {
						setValue(newValue);
						updateSelectedCatId(newValue.id);
					}}
					options={data}
					disableClearable={true}
					getOptionLabel={(option) => option.label}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					renderInput={(params) => <TextField {...params} label={`Choose a Cat ${selectedMfrId}`} />}
					sx={{ width: 300 }}
				/>
			) : (
				' un mounted'
			)} */}
		</>
	);
};

export default InputCat;
