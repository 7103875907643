import axios from 'axios';

export const dateFormatterYYYYMMDD = (d) => {
	const myDate = new Date(d);
	const myYear = myDate.getFullYear();
	const myMonth = myDate.getMonth() < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1;
	const myDay = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate();
	return myYear + '-' + myMonth + '-' + myDay;
};

export const fetchUserData = async (url) => {
	return await axios.get(url).then((response) => {
		return response.data;
	});
};

export const random7DigitNumber = () => {
	return Math.floor(1000000 + Math.random() * 9000000);
};
