import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { as400DescProgressModalValue } from '../../../../Redux/PDMSlice';
import {
  as400ExtendedDescriptionFullDataValues,
  as400ExtendedDescriptionFilteredDataValues,
  as400ExtendedDescriptionInitialIdsValues,
  as400ExtendedDescriptionProductIdsValues,
  as400ExtendedDescriptionFilteredProductsValues,
} from '../../../../Redux/ReductionDataSlice';

import axios from 'axios';

import { FormControl, InputLabel, Select, styled, MenuItem, Button } from '@mui/material';

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  padding: '1rem',
  width: '100%',
});

const FormItem = styled(FormControl)({
  margin: '0.5rem',
  minWidth: '10rem',
  width: '100%',
});

// Primary Component
const BrandCategorySearch = ({ groupName, func }) => {
  const dispatch = useDispatch();
  const [manufacturer, setManufacturer] = useState(0);
  const [category, setCategory] = useState(0);
  const [allMfrs, setAllMfrs] = useState([]);
  const [allCategories, setAllCategories] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const buildFacetData = async (ids) => {
      ids &&
        (await axios
          .get(`/products/getReductiveSearchSourceData/${ids}`)
          .then(function (response) {
            console.log('data2: ', response.data);
            const uids = [];
            response.data.map((row) => uids.map((id) => id).indexOf(row.product_id) === -1 && uids.push(row.product_id));

            const buildingDataSet = [];

            uids.map((id) =>
              buildingDataSet.push({
                product_id: id,
                facets: [
                  response.data
                    .filter((line) => line.product_id === id)
                    .map((row) => [{ product_facet_id: row.product_facet_id, facet: row.facet, product_facet_option_id: row.product_facet_option_id, facet_value: row.facet_value }]),
                ],
                option_ids: [response.data.filter((line) => line.product_id === id).map((row) => row.product_facet_option_id)],
              })
            );

            dispatch(as400ExtendedDescriptionFullDataValues(buildingDataSet));
            console.log('buildingDataSet: ', buildingDataSet);

            // how to loop full data set
            //buildingDataSet.map((row) => row.facets.map((fac) => fac.map((attr) => console.log('attrr: ', row.product_id, ' | ', attr[0].facet))));

            // dispatch(as400ExtendedDescriptionProductIdsValues(ids));
            //dispatch(as400ExtendedDescriptionInitialIdsValues(ids));

            // const initFacetData = [];
            // response.data.map((item) => initFacetData.push(item));
            // dispatch(as400ExtendedDescriptionFullDataValues(initFacetData));
            // dispatch(as400ExtendedDescriptionFilteredDataValues(initFacetData));

            // const dataA = [];
            // response.data.map((row, idx) => dataA.push({ option_id: row.product_facet_option_id, product_id: row.product_id }));
            // dispatch(as400ExtendedDescriptionFilteredProductsValues(dataA));
          })

          .catch((error) => {
            console.log('error: ' + error);
          }));
    };

    axios
      .get(`/products/getProductIdFromMfrCat/${manufacturer}/${category}`)
      .then(function (response) {
        const items = [];
        response.data.map((item) => items.push(item.product_id));
        buildFacetData(items);
      })
      .catch((error) => {
        console.log('error: ' + error);
      });

    // dispatch(as400DescProgressModalValue(true));
  };

  useEffect(() => {
    // THE FOLLOWING RUNS ON LOAD
    axios
      .get(`/pdm/getActiveManufacturers`)
      .then(function (response) {
        setAllMfrs(response.data);
      })
      .catch((error) => {
        console.log('error: ' + error);
      });
  }, []);

  useEffect(() => {
    // THE FOLLOWING RUNS ON LOAD
    manufacturer !== '' &&
      axios
        .get(`/pdm/getCategoriesByMfrId/${manufacturer}`)
        .then(function (response) {
          setAllCategories(response.data);
        })
        .catch((error) => {
          console.log('error: ' + error);
        });
  }, [manufacturer]);

  return (
    <Form onSubmit={handleSubmit}>
      <FormItem>
        <InputLabel id='manufacturer-label'>Choose Manufacturer</InputLabel>
        <Select labelId='manufacturer-label' label='Choose Manufacturer' value={manufacturer} onChange={(e) => setManufacturer(e.target.value)}>
          <MenuItem value='0'>All</MenuItem>
          {allMfrs.map((mfr) => (
            <MenuItem key={mfr.product_manufacturer_id} value={mfr.product_manufacturer_id}>
              {mfr.description}
            </MenuItem>
          ))}
        </Select>
      </FormItem>
      <FormItem>
        <InputLabel id='category-label'>Choose Category</InputLabel>
        <Select labelId='category-label' id='category-select' label='Choose Category' value={category} onChange={(e) => setCategory(e.target.value)}>
          <MenuItem value='0'>All</MenuItem>
          {allCategories.map((cat) => (
            <MenuItem key={cat.product_category_id} value={cat.product_category_id}>
              {cat.description}
            </MenuItem>
          ))}
        </Select>
      </FormItem>
      <FormItem>
        <Button type='submit' variant='contained' color='primary'>
          Search
        </Button>
      </FormItem>
    </Form>
  );
};

export default BrandCategorySearch;
