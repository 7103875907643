import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { as400DescriptionSearchTermValue, as400DescProgressModalValue } from '../../../../Redux/PDMSlice';

// Material UI
import { Backdrop, Box, CircularProgress, styled, TextField, useTheme } from '@mui/material';

// Material UI Styled Components
const Wrapper = styled(Box)(({ theme }) => ({}));

// Primary Component
const RowFilterInput = () => {
  const dispatch = useDispatch();

  // Local State
  const [searchTerm, setSearchTerm] = useState('');
  const [timeoutId, setTimeoutId] = useState(null);

  const filterRows = (value) => {
    const func = () => {
      dispatch(as400DescProgressModalValue(true));
      clearTimeout(timeoutId);
      setTimeoutId(
        setTimeout(() => {
          dispatch(as400DescriptionSearchTermValue(searchTerm));
        }, 200)
      );
    };
    func();
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeoutId, dispatch]);

  return (
    <Wrapper>
      <TextField
        label='Filter Items'
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
        onKeyUp={(e) => e.key === 'Enter' && filterRows(e.target.value)}
      />
    </Wrapper>
  );
};

export default RowFilterInput;
