import { Divider, Grid } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import CurrentPromotions from './Components/CurrentPromotions';
import UpcomingPromotions from './Components/UpcomingPromotions';
import PromotionFilter from './Components/PromotionFilter';
import PromotionDropdown from './Components/PromotionDropdown';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const MarketingPromotions = ({ pageTitle }) => {
  const [promotions, setPromotions] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedManufacturers, setSelectedManufacturers] = useState([]);
  const [uniqueTags, setUniqueTags] = useState([]);
  const [uniqueManufacturers, setUniqueManufacturers] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    axios.get('/marketing/events').then((response) => {
      setPromotions(response.data);

      // Extracting unique categories
      const allCategories = [
        ...new Set(response.data.map((promo) => promo.calendar_category_description)),
      ];
      setSelectedCategories(allCategories);

      // Extracting unique tags with safety check
      const uniqueTags = [
        ...new Set(
          response.data.flatMap(
            (promo) => (promo.tags || []).map((tag) => tag.tag) // Use an empty array if promo.tags is null/undefined
          )
        ),
      ].map((tag) => ({ label: tag }));

      // Extracting unique manufacturers with safety check
      const uniqueManufacturers = [
        ...new Set(
          response.data.flatMap(
            (promo) => (promo.manufacturers || []).map((manufacturer) => manufacturer.manufacturer) // Use an empty array if promo.manufacturers is null/undefined
          )
        ),
      ].map((manufacturer) => ({ label: manufacturer }));

      setUniqueTags(uniqueTags);
      setUniqueManufacturers(uniqueManufacturers);
    });
  }, []);

  const handleSelectedCategories = (categories) => {
    setSelectedCategories(categories);
  };

  return (
    <Grid container spacing={2} sx={{ p: 2, maxWidth: '100vw' }}>
      {!isMobile && (
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <PromotionFilter promotions={promotions} onSelectionChange={handleSelectedCategories} />
        </Grid>
      )}
      <Grid item xs={12} container justifyContent='center' spacing={2}>
        <Grid item xs={12} sm={2}>
          <PromotionDropdown
            options={uniqueTags}
            label='Filter by Tags'
            onChange={setSelectedTags}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <PromotionDropdown
            options={uniqueManufacturers}
            label='Filter by Manufacturers'
            onChange={setSelectedManufacturers}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <CurrentPromotions
          promotions={promotions.filter((promo) => {
            const matchesCategory = selectedCategories.includes(
              promo.calendar_category_description
            );

            const matchesTags =
              selectedTags.length === 0 ||
              (promo.tags || []).some((tag) =>
                selectedTags.map((selectedTag) => selectedTag.label).includes(tag.tag)
              );

            const matchesManufacturers =
              selectedManufacturers.length === 0 ||
              (promo.manufacturers || []).some((manufacturer) =>
                selectedManufacturers
                  .map((selectedManufacturer) => selectedManufacturer.label)
                  .includes(manufacturer.manufacturer)
              );

            return matchesCategory && matchesTags && matchesManufacturers;
          })}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ m: 2 }} />
        <UpcomingPromotions
          promotions={promotions.filter((promo) => {
            const matchesCategory = selectedCategories.includes(
              promo.calendar_category_description
            );

            const matchesTags =
              selectedTags.length === 0 ||
              (promo.tags || []).some((tag) =>
                selectedTags.map((selectedTag) => selectedTag.label).includes(tag.tag)
              );

            const matchesManufacturers =
              selectedManufacturers.length === 0 ||
              (promo.manufacturers || []).some((manufacturer) =>
                selectedManufacturers
                  .map((selectedManufacturer) => selectedManufacturer.label)
                  .includes(manufacturer.manufacturer)
              );

            return matchesCategory && matchesTags && matchesManufacturers;
          })}
        />
      </Grid>
    </Grid>
  );
};

export default MarketingPromotions;
