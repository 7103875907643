import axios from 'axios';
import Dayjs from 'dayjs';
// GET ALL PUBLICATIONS
export const getAllPublications = async () => {
	try {
		const response = await axios.get('/advertising/getAllPublications');
		//console.log('respone all pubs: ', response.data);
		return [...response.data]; // Assuming the response data is an array of objects
	} catch (error) {
		console.error('Error fetching data:', error);
		return [];
	}
};

// GET PUBLICATIONS BY PUBID
export const getPublicationByPubId = async (pubid) => {
	try {
		const response = await axios.get(`/advertising/getPublicationByPubId/${pubid}`);
		//console.log('respone all pubs: ', response.data);
		return [...response.data]; // Assuming the response data is an array of objects
	} catch (error) {
		console.error('Error fetching data:', error);
		return [];
	}
};

// GET PUBLICATIONS BY PUBID
export const insertOrUpdatePublication = async (data, id) => {
	console.log('passed id:', id);

	// Format dates using Dayjs
	const formatDate = (date) => Dayjs(date).format('YYYY-MM-DD');

	const record = {
		ss_publication_id: id,
		ss_publication_code: data.ss_publication_code,
		ss_publication_type_id: parseInt(data.ss_publication_type_id, 10),
		description: data.description,
		detailed_description: data.detailed_description,
		submit_contract_date: formatDate(data.submit_contract_date),
		submit_art_date: formatDate(data.submit_art_date),
		production_start_date: formatDate(data.production_start_date),
		start_date: formatDate(data.start_date),
		expiration_date: formatDate(data.expiration_date),
	};

	console.log('record:', record);

	try {
		const endpoint = id === 0 ? '/advertising/insertPublication' : '/advertising/updatePublication';
		const response = await axios.post(endpoint, { record });

		if (id === 0) {
			return response.data.insertId;
		} else {
			return response.data;
		}
	} catch (err) {
		console.error(err.response ? err.response.data : 'An error occurred');
		throw err; // rethrow the error to handle it further up the call stack if needed
	}
};

// GET PUBLICATIONS BY PUBID
export const getPublicationTypes = async () => {
	try {
		const response = await axios.get(`/advertising/getAllPublicationTypes`);
		//console.log('respone all pubs: ', response.data);
		return [...response.data]; // Assuming the response data is an array of objects
	} catch (error) {
		console.error('Error fetching data:', error);
		return [];
	}
};

// GET PUBLICATIONS BY PUBID
export const updateInactiveInternalUseOnly = async (data) => {
	console.log('passed data abc: ', data);
	const record = {
		ss_publication_id: data.ss_publication_id,
		field_name: data.field_name,
		value: data.value,
	};

	try {
		const endpoint = '/advertising/updateInactiveInternalUseOnly';
		const response = await axios.post(endpoint, { record });
		return response.data;
	} catch (err) {
		console.error(err.response ? err.response.data : 'An error occurred');
		throw err; // rethrow the error to handle it further up the call stack if needed
	}
};

// GET PRODUCTS FOR PUBLICATION TYPE ID
export const getPublicationProductsByType = async (ss_publication_type_id) => {
	try {
		const response = await axios.get(`/advertising/getPublicationProducts`);
		//console.log('respone all pubs: ', response.data);
		return [...response.data]; // Assuming the response data is an array of objects
	} catch (error) {
		console.error('Error fetching data:', error);
		return [];
	}
};
