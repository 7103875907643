import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Box, Divider } from '@mui/material';

const MobileList = ({ componentList = [] }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id='demo-customized-button'
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='contained'
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
      >
        Actions
      </Button>

      <Menu
        id='demo-customized-menu'
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {componentList.map((component, index) => (
          <MenuItem
            key={index}
            onClick={handleClose}
            disableRipple
            sx={{ gap: 1, display: 'flex' }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Divider sx={{ width: '100%', borderBottomWidth: '2px' }} />
            </Box>
            {component}
            <Box sx={{ flexGrow: 1 }}>
              <Divider sx={{ width: '100%', borderBottomWidth: '2px' }} />
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MobileList;
