import React, { useEffect, useState } from 'react';
import {
  Paper,
  Typography,
  Box,
  CircularProgress,
  Grid,
  Modal,
  Button,
  TextField,
  Divider,
} from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import NoteHistory from './NoteHistory';

const Notes = (accNumber) => {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingNote, setEditingNote] = useState('');
  const [noteHistory, setNoteHistory] = useState([]);
  const [noteImages, setNoteImages] = useState([]);

  const userId = useSelector((state) => state.userInformation.value.userID);

  const handleNoteClick = (note) => {
    setEditingNote(note);
    getNoteHistory(note.ss_crm_note_id);
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setNoteImages([]);
  }, [notes]);

  const handleUpdateNote = () => {
    console.log(editingNote);
    axios
      .post(
        '/crm/updateNote',
        {},
        {
          params: {
            noteId: editingNote.ss_crm_note_id,
            userId: userId,
            description: 'description',
            note: editingNote.notes,
          },
        }
      )
      .then((response) => {
        // Replace the old note with the updated one in the 'notes' state
        const updatedNotes = notes.map((note) => {
          if (note.ss_crm_note_id === editingNote.ss_crm_note_id) {
            return editingNote;
          }
          return note;
        });
        setNotes(updatedNotes);
      })
      .catch((error) => {
        console.error('Error updating note:', error);
      })
      .finally(() => {
        setIsModalOpen(false);
      });
  };

  const getNoteHistory = (noteId) => {
    axios
      .get('/crm/noteHistory', { params: { noteId: noteId } })
      .then((res) => {
        if (res.data) {
          setNoteHistory(res.data);
        } else {
          setNoteHistory([]);
        }
      })
      .catch((error) => {
        console.error('Error fetching note history:', error);
        setNoteHistory([]);
      });
  };

  useEffect(() => {
    setLoading(true);
    setError(null);
    const getNotes = async () => {
      try {
        const notesResponse = await axios.get('/crm/notes/byAccount', {
          params: { accountNumber: accNumber.accNumber },
        });
        const filteredNotes = notesResponse.data.filter((note) => {
          return note.private !== 1 || note.created_by_user_id === userId;
        });

        setNotes(filteredNotes);

        const images = [];
        for (const note of filteredNotes) {
          try {
            const imgResponse = await axios.get('/crm/noteImages', {
              params: { primaryIdentifier: note.ss_crm_note_id },
            });
            images[note.ss_crm_note_id] = imgResponse.data.map((img) => img.signedUrl);
          } catch (err) {
            console.error('Error getting note images:', err);
            images[note.ss_crm_note_id] = [];
          }
        }
        setNoteImages(images);
        console.log(images);
      } catch (err) {
        setError('No Notes Found For This Account');
      } finally {
        setLoading(false);
      }
    };
    getNotes();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography variant='h6' sx={{ textAlign: 'center' }}>
        {error}
      </Typography>
    );
  }

  return (
    <Box>
      <Box>
        <Grid container spacing={2} justifyContent='center' sx={{ p: 2 }}>
          {notes.map((note, index) => (
            <Grid item xs={12} key={index} onClick={() => handleNoteClick(note)}>
              <Paper
                elevation={3}
                sx={{
                  paddingTop: '16px',
                  paddingLeft: '16px',
                  paddingRight: '16px',
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
                  backgroundColor: '#f5f5f5',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'scale(1.02)',
                  },
                }}
              >
                <Typography
                  variant='h6'
                  align='center'
                  sx={{ fontWeight: 'bold', marginBottom: '8px' }}
                >
                  {note.description}
                </Typography>
                <Typography variant='body1' sx={{ padding: '8px', marginBottom: '8px' }}>
                  {note.notes}
                </Typography>
                {/* Display images for this note */}
                <Box>
                  {noteImages[note.ss_crm_note_id]?.map((imgUrl, imgIndex) => (
                    <img
                      key={imgIndex}
                      src={imgUrl}
                      alt={`Note ${note.ss_crm_note_id}`}
                      style={{ width: '100%', marginBottom: 8 }}
                    />
                  ))}
                </Box>
                <Box sx={{ alignSelf: 'flex-end', textAlign: 'right' }}>
                  <Divider />
                  <Typography variant='caption' sx={{ pr: '10px' }}>
                    Created by: {note.created_by} on{' '}
                    {new Date(note.time_created).toLocaleString('en-US', {
                      year: '2-digit',
                      month: '2-digit',
                      day: '2-digit',
                      hour12: true,
                      hour: 'numeric',
                      minute: 'numeric',
                    })}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby='edit-note-modal'
        aria-describedby='modal-modal-description'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: 3,
            borderRadius: '4px',
            minWidth: '300px',
            maxWidth: '500px', // Adjusted for better proportion
          }}
        >
          <Box sx={{ mb: 2 }}>
            <TextField
              label='Edit Note'
              multiline
              rows={4}
              defaultValue={editingNote.notes}
              onChange={(e) => setEditingNote({ ...editingNote, notes: e.target.value })}
              sx={{ width: '100%', mb: 2 }}
            />
            <Button
              onClick={handleUpdateNote}
              variant='contained'
              color='primary'
              fullWidth
              sx={{
                padding: '10px 20px',
                boxShadow: '0px 3px 6px rgba(0,0,0,0.2)',
                ':hover': {
                  boxShadow: '0px 5px 10px rgba(0,0,0,0.3)',
                  backgroundColor: 'secondary.main',
                },
              }}
            >
              Save
            </Button>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box>
            <Typography variant='h5' sx={{ mb: 1, textAlign: 'center' }}>
              Note History
            </Typography>
            <Box sx={{ maxHeight: 300, overflow: 'auto' }}>
              {' '}
              <NoteHistory noteHistory={noteHistory} />
            </Box>
          </Box>
        </Paper>
      </Modal>
    </Box>
  );
};

export default Notes;
