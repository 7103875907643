import { useState } from 'react';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import SortableItem from './SortableItem';
import { Box } from '@mui/material';
const DragAndDrop = () => {
  const [languages, setLanguages] = useState([
    { id: 1, lang: 'JavaScript' },
    { id: 2, lang: 'Python' },
    { id: 3, lang: 'TypeScript' },
  ]);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    console.log('active: ' + active.id);
    console.log('over: ' + over.id);

    if (active.id !== over.id) {
      setLanguages((items) => {
        const activeIndex = items.findIndex((item) => item.id === active.id);
        const overIndex = items.findIndex((item) => item.id === over.id);
        console.log('info: ' + activeIndex);
        console.log('event: ', event);
        return arrayMove(items, activeIndex, overIndex);
      });
    }
  };

  return (
    <>
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <Box sx={{ margin: 'auto', marginTop: 3, maxWidth: '500px', border: 'solid 1px teal', p: 3 }}>
          <h3>Best Languages</h3>
          <SortableContext items={languages} strategy={verticalListSortingStrategy}>
            {languages.map((lang, idx) => (
              <SortableItem key={lang.lang} id={lang.id} text={lang.lang} />
            ))}
          </SortableContext>
        </Box>
      </DndContext>
    </>
  );
};
export default DragAndDrop;
