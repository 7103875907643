import React from 'react';
import { Box, useTheme } from '@mui/material';
import Logo from './component/Logo';

const NavBar = () => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				backgroundColor: theme.palette.primary.alt,
				color: 'white',
				height: '75px',
				display: 'flex',
				width: '100%',
				position: 'fixed',
				top: 0,
				boxShadow: 6,
				zIndex: 10000,
			}}
		>
			<Box sx={{ height: '100%', position: 'relative', width: '100px' }}>
				<Logo />
			</Box>

			<Box sx={{ flex: 1 }}></Box>
		</Box>
	);
};

export default NavBar;
