import React, { useEffect, useState } from 'react';
import { getContractDetailsByContractId } from '../../../../../../Feeds/ContractsFeed';
import { getPublicationTypes } from '../../../../../../Feeds/PublicationsFeed';
import { Box, MenuItem, TextField, useTheme } from '@mui/material';

const ContractFormCard = ({ initialData }) => {
	const theme = useTheme();

	const [originalData, setOriginalData] = useState({});
	const [allPublicatonTypes, setAllPublicationTypes] = useState([{ ss_publication_type_id: 0, descripton: '-' }]);

	// FORM STATE
	const [pubTypeValue, setPubTypeValue] = useState(0);

	useEffect(() => {
		const propData = async () => {
			const dataReturned = await initialData;
			// setOriginalData(dataReturned);
			setPubTypeValue(dataReturned.ss_publication_type_id);
			const getTypeData = await getPublicationTypes();
			setAllPublicationTypes([{ ss_publication_type_id: 0, description: '---' }, ...getTypeData]);
			// console.log('cfc ran: ' + dataReturned.ss_publication_type_id);
			console.log('a1010', dataReturned);
		};
		propData();
	}, [initialData]);

	const handlePubTypeChange = (event) => {
		setPubTypeValue(event.target.value);
	};

	console.log('getPublicationTypes: ', allPublicatonTypes);
	return (
		<Box>
			{/* TITLE */}
			<Box sx={{ color: theme.palette.primary.main, fontWeight: 'bold', fontSize: '1.4em' }}>
				Modify Contract #: {originalData.ss_publication_contract_id}
			</Box>
			{/* PUBLICATION TYPES SELECT */}
			<TextField fullWidth select label='Select' value={pubTypeValue} onChange={(e) => handlePubTypeChange(e)}>
				{allPublicatonTypes.map((type) => (
					<MenuItem key={type.ss_publication_type_id} value={type.ss_publication_type_id}>
						{type.description}
					</MenuItem>
				))}
			</TextField>
		</Box>
	);
};

export default ContractFormCard;
