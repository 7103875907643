import {
  Grid,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Radio,
  Button,
  Divider,
  Box,
  CircularProgress, 
  Backdrop  
} from '@mui/material';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';
import axios from 'axios';
import React, { useState } from 'react';

const LookupEdiMfgForm = () => {

  // State For Form Entry
  const [mfgNumber, setMfgNumber] = useState('');
  const [radioValue, setRadioValue] = useState('');
  const [searchResult, setSearchResult] = useState('');
  const [searchResultMessage, setSearchResultMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [myUrl, setMyUrl] = useState('');

  // Handle Submit For Item Lookup
  const handleSubmit = () => {
    console.log(mfgNumber);
    console.log(myUrl);
    console.log(radioValue);
    
    
    if ((mfgNumber && radioValue)) {
      console.log('MFG and RV');
      setIsLoading(true);
      axios
      .post('/hubRelay', {
        j: '/webservice/hub/helpdesk_utilities/edi/manage_edi_mfg.php?MFG_NUMBER='+mfgNumber+'&WHAT_CALL='+radioValue
      })
      .then((response) => {
        console.log(response.data);
        setSearchResult(response.data.RESULT);
        setSearchResultMessage(response.data.MESSAGE);
        setIsLoading(false);        
      })
      .catch((error) => {
        console.log(error);
      });

    } else if (mfgNumber && !radioValue) {
      console.log('MFG and != RV');
      setIsLoading(true);
      axios
      .post('/hubRelay', {
        j: '/webservice/hub/helpdesk_utilities/general/basic_mfg_info.php?MFG_NUMBER='+mfgNumber
      })
      .then((response) => {
        console.log(response.data);
        setSearchResult(response.data.RESULT);
        setSearchResultMessage(response.data.MESSAGE);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });

    } else {
      console.log('Missing Parameters...');
    }
    
  };
  
  const handleRefresh = () => {
    setMfgNumber('');
    setSearchResult('');
    setSearchResultMessage('');
    setRadioValue('');  
  };


  return (
    <Grid container spacing={5} alignItems='center' sx={{ p: 4 }}>
      <Grid item xs={12}>
        <Typography variant='h6' sx={{textAlign: 'left', mt: 2}}>Enter a Manufacturer number...</Typography>
      </Grid>      
      <Grid item xs={4}>
        <FormControl fullWidth={true}>
          <TextField
            value={mfgNumber}            
            onChange={(e) => setMfgNumber(e.target.value)} 
            label="Mfg #"            
          />
        </FormControl>
      </Grid>      
      <Grid item xs={2} sx={{textAlign: 'left', mt: 2}}>
        {searchResult === "OK" && <CheckSharpIcon style={{color: "#198754"}} /> }
        {searchResult === "ERROR" && <ErrorOutlineSharpIcon style={{color: "#dc3545"}} />}        
      </Grid>
      <Grid item xs={6}></Grid>

      <Grid item xs={12}>
        {searchResult && <FormControl>          
          <RadioGroup
            row
            defaultValue=''
            name='WHAT_CALL'
            onChange={(e) => setRadioValue(e.target.value)}
          >
            <FormControlLabel value='insert' control={<Radio />} label='Insert' />
            <FormControlLabel value='delete' control={<Radio />} label='Delete' />
          </RadioGroup>
        </FormControl>}
      </Grid>
      
      <Grid item xs={2}>
        <Button variant='contained' color='primary' onClick={handleSubmit}>
          Submit
        </Button>        
      </Grid>
      <Grid item xs={2}>
        {mfgNumber && 
        <Button variant='contained' color='error' onClick={handleRefresh}>
          Clear
        </Button>}
      </Grid>
      <Grid item xs={8}></Grid> 

      <Grid item xs={12} sx={{textAlign: 'center'}}>
        <Typography 
          variant='span' 
          fontSize='small' 
          style={{color: searchResult === "OK" ? "#198754" : "#dc3545"}}
        >{searchResultMessage}
        </Typography>
      </Grid>     
      
      {isLoading && (
        <Backdrop
          open={isLoading}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}
        >
          <CircularProgress sx={{color: '#0FFF00'}} />
        </Backdrop>
      )}
    </Grid>
  );
};

export default LookupEdiMfgForm;
