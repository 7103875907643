import React, { useEffect, useState } from 'react';
import {
  Modal,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Typography,
  Grid,
  Paper,
  Divider,
} from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import FileUploadButton from './UploadFile';
import CircularProgress from '@mui/material/CircularProgress';

const AddNote = ({ open, handleClose, accountNumber, accountName }) => {
  const [note, setNote] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);
  const [description, setDescription] = useState('');
  const [isTestMode, setIsTestMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`/crm/emailTestMode`)
      .then((response) => {
        console.log(response.data);
        if (response.data[0].system_value === '1') {
          setIsTestMode(true);
          console.log('Test Mode Enabled');
        } else {
          setIsTestMode(false);
          console.log('Test Mode Disabled');
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const userId = useSelector((state) => state.userInformation.value.userID);
  const lastInsertId = useSelector((state) => state.analyticsSlice.value);

  const repData = useSelector((state) => state.repInfoSlice.value);

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (file) => {
    setSelectedFile(file);
  };

  const analyticsNoteAdded = () => {
    axios.put('/crm/analytics/noteAdded', {}, { params: { analyticsId: lastInsertId } });
  };

  const handleSubmit = async () => {
    const privateValue = isPrivate ? 1 : 0;
    setIsLoading(true);

    try {
      // Create Note
      const response = await axios.post(
        '/crm/createNote',
        {},
        {
          params: {
            noteTypeId: 1,
            createdByUserId: userId,
            description: description,
            note: note,
            privateFlag: privateValue,
            accountNumber: accountNumber,
          },
        }
      );
      const lastInsertId = response.data.lastInsertId;
      const lastInsertIdInt = parseInt(lastInsertId, 10);
      if (isNaN(lastInsertIdInt)) {
        throw new Error('Invalid lastInsertId. Expected an integer.');
      }

      // Upload File
      if (selectedFile) {
        const formData = new FormData();
        formData.append('file', selectedFile);
        const uploadUrl = `/crm/uploadFile/${lastInsertId}`;
        const uploadResponse = await axios.post(uploadUrl, formData);
      }

      // Fetch Sales Rep's Name and Email
      const accountInfoResponse = await axios.post(
        `/crm/accountInfo`,
        {},
        {
          params: { accountNumber: accountNumber },
        }
      );
      const salesRepName = accountInfoResponse.data.sales_rep.trim();
      const [firstName, lastName] = salesRepName.split(' ').map((name) => name.toLowerCase());

      // Find the sales rep's email address in the repData array
      const rep = repData.find(
        (rep) =>
          rep.first_name.toLowerCase().trim() === firstName &&
          rep.last_name.toLowerCase().trim() === lastName
      );

      const emailAddress = isTestMode ? 'will.irvine@sportssouth.biz' : rep.email_address;

      // Send Email
      axios.post(
        '/crm/createEmail',
        {},
        {
          params: {
            email: JSON.stringify({
              email_id: 7,
              email_credential_code: 'TSW',
              email_addresses: emailAddress,
              bcc_addresses: '',
              substitutions: {
                first_name: firstName,
                account_name: accountName,
                note_link: 'https://hub.sportssouth.app/account-notes',
              },
              send_immediately: 0,
            }),
          },
        }
      );

      // Analytics and Cleanup
      analyticsNoteAdded();
      setNote('');
      setDescription('');
      setIsPrivate(false);
      setIsLoading(false);
      handleClose();
    } catch (error) {
      console.error('Error in operation:', error.response ? error.response.data : error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          borderRadius: '4px',
          minWidth: '300px',
          maxWidth: '500px',
        }}
      >
        <Grid item xs={12} textAlign='center'>
          <Typography variant='h6'>Add Note For:</Typography>
        </Grid>
        <Grid item xs={12} textAlign='center' sx={{ pb: 2 }}>
          <Typography variant='h6'>{accountName}</Typography>
          <Divider />
        </Grid>
        <Paper elevation={2} sx={{ padding: 2, mb: 3, backgroundColor: 'background.paper' }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isPrivate}
                    onChange={(e) => setIsPrivate(e.target.checked)}
                    color='primary'
                  />
                }
                label='Private'
              />
            </Grid>
            <Grid item xs={6}>
              <FileUploadButton onFileSelect={handleFileSelect} />
            </Grid>
          </Grid>
        </Paper>
        <TextField
          fullWidth
          variant='outlined'
          label='Enter Title...'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          sx={{ mb: 3 }}
        />
        <TextField
          fullWidth
          multiline
          rows={4}
          variant='outlined'
          label='Enter Note...'
          value={note}
          onChange={(e) => setNote(e.target.value)}
          sx={{ mb: 3 }}
        />

        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={handleSubmit}
          disabled={isLoading} // Disable button when isLoading is true
          sx={{
            padding: '10px 20px',
            boxShadow: '0px 3px 6px rgba(0,0,0,0.2)',
            position: 'relative', // For proper positioning of CircularProgress
          }}
        >
          {isLoading ? (
            <CircularProgress
              size={24}
              sx={{
                color: 'white',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          ) : (
            'Submit'
          )}
        </Button>
      </Paper>
    </Modal>
  );
};

export default AddNote;
