import React, { useState } from 'react';
import { getContactDetailsByEntityId } from '../../../ManageContracts/Feeds/ContractsFeed';
import { People } from '@mui/icons-material';
import { Backdrop, Box, IconButton, Modal, Tooltip } from '@mui/material';
import ContactCard from './components/ContactCard/ContactCard';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '100%',
	maxWidth: '900px',
};

const ContactsForEntity = ({ entityId }) => {
	const [open, setOpen] = useState(false);
	const [data, setData] = useState([]);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const handlePeopleClick = () => {
		const fetchContactInfo = async () => {
			const returnData = await getContactDetailsByEntityId(entityId);
			setData(returnData);
			//console.log('contact info: ', returnData);
			handleOpen();
		};
		fetchContactInfo();
	};

	return (
		<>
			<Tooltip title={`View Manufacturers Contacts`}>
				<IconButton onClick={() => handlePeopleClick()}>
					<People color='primary' sx={{ fontSize: '32px' }} />
				</IconButton>
			</Tooltip>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						sx: {
							bgcolor: 'rgba(255, 255, 255, 0.8)', // Set your desired color here
						},
					},
				}}
			>
				<Box sx={style}>
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' },
							gap: '24px',
							justifyContent: 'center',
						}}
					>
						{data.map((m) => (
							<ContactCard key={m.entity_contact_link_id} contact={m} />
						))}
					</Box>
				</Box>
			</Modal>
		</>
	);
};

export default ContactsForEntity;
