import { Card, Grid, CardActionArea } from '@mui/material';
import React, { useState } from 'react';
// import WebServicesItemLookup from './WebServicesItemLookup/WebServicesItemLookup';

const Pos = () => {
  // const [isWebServicesModalOpen, setIsWebServicesModalOpen] = useState(false);
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>        
        {/* <CardActionArea>
          <Card onClick={() => setIsWebServicesModalOpen(true)} sx={{ p:3, textAlign:'center' }}>WEB SERVICES ITEM LOOKUP</Card>
        </CardActionArea> */}
      </Grid>
      {/* <Grid item xs={1}>
        <Card sx={{ pl:1, textAlign:'center' }}>Product 2</Card>
      </Grid>
      <Grid item xs={1}>
        <Card sx={{ pl:1, textAlign:'center' }}>Product 3</Card>
      </Grid>
      <Grid item xs={1}>
        <Card sx={{ pl:1, textAlign:'center' }}>Product 4</Card>
      </Grid>       */}
      {/* <WebServicesItemLookup
        open={isWebServicesModalOpen}
        onClose={() => setIsWebServicesModalOpen(false)}
      /> */}
    </Grid>
  );
};

export default Pos;
