import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import userInformationSlice from './UserInformationSlice';
import mainDrawerSlice from './MainDrawerSlice';
import pdmSlice from './PDMSlice';
import entitySlice from './EntitySlice';
import productSlice from './ProductSlice';
import reductionDataSlice from './ReductionDataSlice';
import userPermissionSlice from './UserPermissionSlice';
import CRMSlice from './CRMSlice';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import AnalyticsSlice from './AnalyticsSlice';
import repInfoSlice from './RepSlice';
import LoadingSlice from './LoadingSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const reducer = combineReducers({
  // Add Reducers Here
  userInformation: userInformationSlice,
  userPermission: userPermissionSlice,
  mainDrawer: mainDrawerSlice,
  pdmSlice: pdmSlice,
  entitySlice: entitySlice,
  productSlice: productSlice,
  CRMSlice: CRMSlice,
  reductionDataSlice: reductionDataSlice,
  analyticsSlice: AnalyticsSlice,
  repInfoSlice: repInfoSlice,
  loadingSlice: LoadingSlice,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export default configureStore({
  reducer: persistedReducer,
});
