import React, { useContext, useEffect, useState } from 'react';
import { EntityContactContextState } from '../../../../../Context/Marketing/EntityContactContext';
import { Box, Button, CircularProgress } from '@mui/material';
import EmailEditCard from '../../EmailEditCard';

const Email = ({ allEmailsData, contactId }) => {
	// CONTEXT STATE
	const { allEmails } = useContext(EntityContactContextState);

	// LOCAL STATE
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);

	useEffect(() => {
		const proccessFun = async () => {
			try {
				const allEmailsData = await allEmails.filter((e) => e.entity_contact_id === contactId);
				setData([...allEmailsData]);
			} catch (error) {
				console.log('error: ', error);
			}
			setLoading(false);
		};

		proccessFun();
	}, [allEmails, contactId]);

	if (loading) {
		return (
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				<CircularProgress />
			</Box>
		);
	}

	const newEmailForm = () => {
		setData((prev) => [...prev, { entity_contact_email_id: 0 }]);
	};

	console.log('data emails ', data);
	return (
		<Box
			className='ccc'
			sx={{
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%,-50%)',
				width: `${data.length * 365}px`,
				maxWidth: '90%',
				minWidth: '300px',
			}}
		>
			{/* <Box sx={{ backgroundColor: 'teal', width: `${data.length * 365}px` }}> */}
			<Box sx={{ textAlign: 'center', mb: '24px' }}>
				<Button variant='contained' color='success' onClick={() => newEmailForm()}>
					Add Email Address?
				</Button>
			</Box>
			<Box
				sx={{
					display: 'flex',
					// gridTemplateColumns: 'repeat(auto-fill, minmax(345px, 1fr))',
					gap: 2,
					margin: 'auto',
					justifyContent: 'center',
					flexWrap: 'wrap',
				}}
			>
				{data.map((email) => (
					<React.Fragment key={email.entity_contact_email_id}>
						<EmailEditCard emailId={email.entity_contact_email_id} contactId={contactId} />
					</React.Fragment>
				))}
			</Box>
		</Box>
		// </Box>
	);
};

export default Email;
