import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import MainButtonStack from './components/MainButtonStack';
import ContractForm from '../Contracts/components/ManageContracts/components/ContractForm';
import ContractFormMobile from '../Contracts/components/ManageContracts/components/ContractFormMobile';

const Manufacturers = () => {
	const navigate = useNavigate();

	return (
		<>
			<MainButtonStack />

			{/* <ContractForm userid={54155} /> */}
			{/* <ContractFormMobile userid={54155} /> */}
		</>
	);
};

export default Manufacturers;
