import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import EntitiesMaterialTable from './components/EntitiesMaterialTable';
const ManageEntities = () => {
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		setMounted(true);
	}, []);

	return (
		<>
			{mounted && (
				<Box sx={{ width: '100%', maxWidth: '1920px', mx: 'auto' }}>
					<EntitiesMaterialTable />
				</Box>
			)}
		</>
	);
};

export default ManageEntities;
