import { createSlice } from '@reduxjs/toolkit';

export const CRMSlice = createSlice({
  name: 'selectedDealer',
  initialState: {
    value: [],
  },
  reducers: {
    setSelectedDealer: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setSelectedDealer } = CRMSlice.actions;
export default CRMSlice.reducer;
