import React from 'react';
import EntityForm from './components/EntityForm';

import { Box, Container } from '@mui/material';
import ChooseEntityTypeButtons from './components/ChooseEntityTypeButtons';

// PRIMARY COMPONENT
const EntityUpdater = () => {
  return (
    <Container maxWidth={'xl'} sx={{ display: 'grid', gridTemplateColumns: '350px 1fr', gap: 2 }}>
      <Box>
        <h3>Search Existing Entities</h3>
        <ChooseEntityTypeButtons />
      </Box>
      <Box>
        <EntityForm />
      </Box>
    </Container>
  );
};

export default EntityUpdater;
