import React, { useState } from 'react';
import { Email, PhoneInTalk } from '@mui/icons-material';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import SnackBarAlert from '../../../../../../../_GlobalComponents/SnackBarAlert';

const photoLink =
	'https://images.unsplash.com/photo-1653379671088-c377dd7f7830?q=80&w=2042&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';

const style = {
	background: `linear-gradient(to bottom,  rgba(255,255,255,.3) 0%,rgba(255,255,255,.5) 47%,rgba(250,250,250,1) 100%)`,
};
const ContactCard = ({ contact }) => {
	const [openSnack, setOpenSnack] = useState(false);
	const ResetOpen = () => {
		setOpenSnack(false);
	};

	return (
		<>
			<Box
				sx={() => ({
					...style,
					display: 'flex',
					flexDirection: 'column',
					gap: '16px',
					p: 3,
					borderRadius: '16px',
					border: 'solid 2px #c7c7c7',
					boxShadow: '2px 2px 6px rgba(0,0,0,.2)',
					backdropFilter: 'blur(2px)',
				})}
			>
				<Box sx={{ textAlign: 'center' }}>
					<Box
						sx={{
							aspectRatio: '3/4',
							width: '110px',
							backgroundImage: `url(${photoLink})`,
							backgroundSize: 'cover',
							backgroundPosition: 'center center',
							mx: 'auto',
							borderRadius: '16px',
							border: 'solid 2px #e7e7e7',
							boxShadow: '2px 2px 6px rgba(0,0,0,.2)',
						}}
					/>
				</Box>
				<Box sx={{ textAlign: 'center' }}>
					<Typography sx={{ fontWeight: 'bold', fontSize: '1.4em' }}>
						{contact.first_name} {contact.last_name}
					</Typography>
					<Typography sx={{ fontWeight: 'bold', fontSize: '1.1em' }}>{contact.role}</Typography>
					<Typography>{contact.address_1}</Typography>
					<Typography>
						{contact.city}, {contact.state} {contact.postal_code}
					</Typography>
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<Box sx={{ flex: 1, textAlign: 'center' }}>
						<Tooltip title={`Email: ${contact.email_address}`}>
							<IconButton
								sx={{ flex: 1 }}
								onClick={() => {
									navigator.clipboard.writeText(contact.email_address);
									setOpenSnack(true);
								}}
							>
								<Email color='primary' />
							</IconButton>
						</Tooltip>
					</Box>

					<Box>|</Box>
					<Box sx={{ flex: 1, textAlign: 'center' }}>
						<Tooltip title={`${contact.phone_type} Number: ${contact.phone_number}`}>
							<IconButton
								sx={{ flex: 1 }}
								onClick={() => {
									navigator.clipboard.writeText(contact.phone_number);
									setOpenSnack(true);
								}}
							>
								<PhoneInTalk color='primary' />
							</IconButton>
						</Tooltip>
					</Box>
				</Box>
			</Box>
			<SnackBarAlert doOpen={openSnack} ResetOpen={ResetOpen} message='Info Has Been Copied To Clipboard!' />
		</>
	);
};

export default ContactCard;
