import { Card, Grid, CardActionArea } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import LabelPrinting from './LabelPrinting/LabelPrinting';


const Printing = () => {

  const [isLabelPrintingOpen, setIsLabelPrintingOpen ] = useState(false);
  const userPermission = useSelector((state) => state.userPermission.value);

  return (
    <Grid container spacing={2}>
      {userPermission[952] && 
      <Grid item xs={3}>
        <CardActionArea>
          <Card onClick={() => setIsLabelPrintingOpen(true)} sx={{ pt:3,pb:3,pl:1,pr:1, textAlign:'center' }}>LABEL PRINTING</Card>
        </CardActionArea>
      </Grid>}
      {/* <Grid item xs={3}>
        <Card>EDI 3</Card>
      </Grid>
      <Grid item xs={3}>
        <Card>EDI 4</Card>
      </Grid> */}
      <LabelPrinting
        open={isLabelPrintingOpen}
        onClose={() => setIsLabelPrintingOpen(false)}
      />      
    </Grid>
  );
};

export default Printing;