import axios from 'axios';

export const contactTypeData = async () => {
	try {
		const response = await axios.get('/entity/getAllContactTypes');

		return [{ role_id: null, label: 'Unknown Roll' }, ...response.data]; // Assuming the response data is an array of objects
	} catch (error) {
		console.error('Error fetching data:', error);
		return [];
	}
};
