import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	MenuItem,
	TextField,
	Typography,
	useTheme,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { EntityContactContextState } from '../../../Context/Marketing/EntityContactContext';
import {
	insertOrUpdatePhoneNumbers,
	updatePrimaryPhoneToZero,
	updatePrimaryPhoneToOneByPhoneNumberId,
	getCountPhoneNumberUsed,
	deletePhoneNumber,
} from './EntityContactMiddleWare';
import { DeleteForever, Save } from '@mui/icons-material';
import SnackBarV2 from '../../_GlobalComponents/SnackBarV2';

const PhoneEditCard = ({ phoneId, contactId }) => {
	// INIT's
	const theme = useTheme();

	// CONTEXT STATE
	const { allPhoneNumbers, allPhoneTypes, setAllPhoneNumbers } = useContext(EntityContactContextState);

	// LOCAL STATE
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [data, setData] = useState([]);
	const [phoneTypes, setPhoneTypes] = useState([]);
	const [snackBarObj, setSnackBarObj] = useState({
		open: false,
		message: 'default message',
		severity: 'success',
	});
	const [displayValue, setDisplayValue] = useState('grid');

	const ResetOpen = () => {
		setSnackBarObj((prev) => ({ ...prev, open: false }));
	};

	useEffect(() => {
		const phoneTypesData = allPhoneTypes;
		setPhoneTypes([...phoneTypesData]);
		console.log('init data: ', data);
	}, [allPhoneTypes, data]);

	useEffect(() => {
		if (phoneId === 0) {
			setData([
				{
					entity_contact_phone_number_id: 0,
					entity_contact_id: contactId,
					phone_type_id: undefined,
					phone_number: undefined,
					extension: undefined,
					description: '',
					primary_phone: 0,
				},
			]);
			setLoading(false);
		} else {
			const recordData = allPhoneNumbers.find((p) => p.entity_contact_phone_number_id === phoneId);
			setData([recordData]);
			setLoading(false);
		}
	}, [allPhoneNumbers, phoneId, contactId]);

	const handleClick = () => {
		console.log('this is handle click phone data: ', data);
		const proccessFunc = async () => {
			try {
				const runQuery = await insertOrUpdatePhoneNumbers(data);
				if (runQuery.data.affectedRows > 0) {
					setAllPhoneNumbers();
					console.log('runQueryy : ', runQuery);
				}
			} catch (error) {
				console.log('error: insertOrUpdatePhoneNumbers: ', error);
			}
		};
		proccessFunc();
	};

	const handleDeleteClick = async () => {
		try {
			const getPhoneNumberCount = await getCountPhoneNumberUsed(phoneId);
			console.log('getPhoneNumberCount: ' + getPhoneNumberCount.data[0].numOfRows);
			if (getPhoneNumberCount.data[0].numOfRows > 0) {
				setSnackBarObj((prev) => ({
					...prev,
					open: true,
					message: `This number is currently used ${getPhoneNumberCount} times!`,
					severity: 'error',
				}));
			} else {
				try {
					const doDeletePhoneNumber = await deletePhoneNumber(phoneId);
					console.log('doDeletePhoneNumber: ', doDeletePhoneNumber);
					if (doDeletePhoneNumber.data.affectedRows < 1) {
						setSnackBarObj((prev) => ({
							...prev,
							open: true,
							message: `Phone Number Did Not Delete!`,
							severity: 'error',
						}));
					} else {
						console.log('this else ran');
						setDisplayValue('none');
						setSnackBarObj((prev) => ({
							...prev,
							open: true,
							message: `Phone Number Deleted!`,
							severity: 'success',
						}));
					}
				} catch (error) {
					console.log('error: ', error);
				}
			}
		} catch (error) {
			console.log('error: insertOrUpdatePhoneNumbers: ', error);
		}
	};

	const handlePrimaryCheckBoxClick = async (e) => {
		const isChecked = data[0].primary_phone;

		try {
			if (!isChecked) {
				const zeroReturn = await updatePrimaryPhoneToZero(data[0].entity_contact_id);
				const callBackFunc = async () => {
					const numOfRows = await updatePrimaryPhoneToOneByPhoneNumberId(phoneId);
					numOfRows.data.affectedRows > 0 && setData((prev) => [{ ...prev[0], primary_phone: 1 }]);
					setAllPhoneNumbers();
				};
				zeroReturn.data.affectedRows > 0 && callBackFunc();
			} else {
				updatePrimaryPhoneToZero(data[0].entity_contact_id);
				setData((prev) => [{ ...prev[0], primary_phone: 0 }]);
				setAllPhoneNumbers();
			}
		} catch (error) {
			console.log('error: ', error);
		}
	};

	if (loading) {
		return (
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				<CircularProgress />
			</Box>
		);
	}

	if (error) {
		return (
			<Typography variant='h6' sx={{ textAlign: 'center' }}>
				{error}
			</Typography>
		);
	}

	return (
		<>
			<Box
				sx={{
					width: '90%',
					maxWidth: '345px',
					border: `solid 4px ${theme.palette.primary.alt}`,
					borderRadius: '24px',
					padding: '24px',
					boxShadow: '4px 4px 12px rgba(0,0,0,.3)',
					backgroundColor: 'white',
					display: displayValue,
					gridTemplateColumns: '1fr 80px',
					gap: '16px',
				}}
			>
				<Box
					sx={{
						color: theme.palette.primary.main,
						mb: '24px',
						fontWeight: 'bold',
						fontSize: '1.3em',
						gridColumn: 'span 2',
					}}
				>
					Phone Number: ({phoneId === 0 ? 'New' : phoneId})
				</Box>

				<TextField
					sx={{ gridColumn: 'span 2' }}
					select
					name='phone_type_id'
					label='Phone Type'
					type='text'
					value={data[0].phone_type_id}
					InputLabelProps={{
						shrink: true,
					}}
					onChange={(e) => setData((prev) => [{ ...prev[0], phone_type_id: e.target.value }])}
				>
					<MenuItem key='0' value={undefined}>
						Choose Type
					</MenuItem>
					{phoneTypes.map((phone) => (
						<MenuItem key={phone.phone_type_id} value={phone.phone_type_id}>
							{phone.description}
						</MenuItem>
					))}
				</TextField>

				<TextField
					sx={{ gridColumn: 'span 1' }}
					name='phone_number'
					label='Phone Number'
					type='text'
					value={data[0].phone_number}
					InputLabelProps={{
						shrink: true,
					}}
					onChange={(e) => setData((prev) => [{ ...prev[0], phone_number: e.target.value }])}
				/>

				<TextField
					sx={{ gridColumn: 'span 1' }}
					name='extension'
					label='Ext:'
					type='text'
					value={data[0].extension}
					InputLabelProps={{
						shrink: true,
					}}
					onChange={(e) => setData((prev) => [{ ...prev[0], extension: e.target.value }])}
				/>

				<Box
					sx={{
						textAlign: 'center',
						gridColumn: 'span 2',
						display: data[0].entity_contact_phone_number_id === 0 ? 'none' : 'block',
					}}
				>
					<FormControlLabel
						control={
							<Checkbox
								color='success'
								checked={data[0].primary_phone}
								onChange={(e) => handlePrimaryCheckBoxClick(e)} //setData((prev) => [{ ...prev[0], primary_phone: e.target.checked }])}
								inputProps={{ 'aria-label': 'controlled' }}
							/>
						}
						label='Primary'
						sx={{
							'& .MuiFormControlLabel-label': {
								fontWeight: 'bold',
							},
						}}
					/>
				</Box>

				<TextField
					sx={{ gridColumn: 'span 2' }}
					multiline
					rows={2}
					name='description'
					label='Description'
					type='text'
					value={data[0].description}
					InputLabelProps={{
						shrink: true,
					}}
					onChange={(e) => setData((prev) => [{ ...prev[0], description: e.target.value }])}
				/>

				<Box
					sx={{
						gridColumn: 'span 2',
						display: 'flex',
						justifyContent: data[0].entity_contact_phone_number_id === 0 ? 'center' : 'space-between',
					}}
				>
					<Button
						sx={{
							fontWeight: 'bold',
							display: data[0].entity_contact_phone_number_id === 0 ? 'none' : 'flex',
						}}
						variant='contained'
						color='error'
						onClick={() => handleDeleteClick(data)}
						startIcon={<DeleteForever />}
					>
						Delete
					</Button>
					<Button
						sx={{ fontWeight: 'bold' }}
						variant='contained'
						color='success'
						onClick={() => handleClick(data)}
						startIcon={<Save />}
					>
						Save
					</Button>
				</Box>
			</Box>
			<SnackBarV2
				doOpen={snackBarObj.open}
				ResetOpen={ResetOpen}
				message={snackBarObj.message}
				severity={snackBarObj.severity}
			/>
		</>
	);
};

export default PhoneEditCard;
