import { createSlice } from '@reduxjs/toolkit';

export const reductionDataSlice = createSlice({
  name: 'reductionData',
  initialState: {
    as400ExtendedDescriptionFullData: [],
    as400ExtendedDescriptionFilteredData: [],
    as400ExtendedDescriptionInitialIds: [],
    as400ExtendedDescriptionProductIds: [],
    as400ExtendedDescriptionFilteredProducts: [],
  },
  reducers: {
    as400ExtendedDescriptionFullDataValues: (state, action) => {
      state.as400ExtendedDescriptionFullData = action.payload;
    },
    as400ExtendedDescriptionFilteredDataValues: (state, action) => {
      state.as400ExtendedDescriptionFilteredData = action.payload;
    },
    as400ExtendedDescriptionInitialIdsValues: (state, action) => {
      state.as400ExtendedDescriptionInitialIds = action.payload;
    },
    as400ExtendedDescriptionProductIdsValues: (state, action) => {
      state.as400ExtendedDescriptionProductIds = action.payload;
    },
    as400ExtendedDescriptionFilteredProductsValues: (state, action) => {
      state.as400ExtendedDescriptionFilteredProducts = action.payload;
    },
  },
});
export const {
  as400ExtendedDescriptionFullDataValues,
  as400ExtendedDescriptionFilteredDataValues,
  as400ExtendedDescriptionInitialIdsValues,
  as400ExtendedDescriptionProductIdsValues,
  as400ExtendedDescriptionFilteredProductsValues,
} = reductionDataSlice.actions;
export default reductionDataSlice.reducer;
