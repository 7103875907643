import axios from 'axios';

// GET ALL PUBLICATIONS
export const getAllPublications = async () => {
	try {
		const response = await axios.get('/advertising/getAllPublications');
		//console.log('respone all pubs: ', response.data);
		return [...response.data]; // Assuming the response data is an array of objects
	} catch (error) {
		console.error('Error fetching data:', error);
		return [];
	}
};

// GET PUBLICATIONS BY USER ID (userid)
export const getAllPublicationsByUserId = async (userid) => {
	try {
		const response = await axios.get(`/advertising/getAllPublicationsByUserId/${userid}`);
		//console.log('respone all pubs: ', response.data);
		return [...response.data]; // Assuming the response data is an array of objects
	} catch (error) {
		console.error('Error fetching data:', error);
		return [];
	}
};
