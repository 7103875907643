import { Modal, Paper } from '@mui/material';
import React from 'react';
import LookupForm from './Components/LookupForm';

const WebServicesItemLookup = ({ open, onClose }) => {
  return (
    <Modal onClose={onClose} open={open} sx={{ overflowY: 'none' }}>
      <Paper sx={{ minHeight: '85vh',maxHeight: '85vh', mx: '1em', mt: '5em', top: '20'  }}>
        <LookupForm />
      </Paper>
    </Modal>
  );
};

export default WebServicesItemLookup;
