import { Autocomplete, Box, Button, Modal, Typography, TextField, Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Build, Troubleshoot } from "@mui/icons-material";

export default function PDMMaint(props) {
    const [openFOR, setOpenFOR] = useState(false)
    const [facets, setFacets] = useState([{label: 'No Results', id:0}])
    const [facetOptions, setFacetOptions] = useState([{label: 'No Options', id:0}])
    const [facetSelected, setFacetSelected] = useState(false)
    const [facetOptionSelected, setFacetOptionSelected] = useState(false)

    let facetData = useRef({})
    let facetOptionData = useRef({})


    useEffect(() => {
        const getAllActiveFacets = () => {
            axios.get("/pdm/getAllActiveFacets"
            ).then((res) => {
                setFacets(res.data)
            }).catch((err) => {
                console.log(err)
            })
        }
        getAllActiveFacets()
    }, [])

    const getOptions = async (id) => {
        await axios.get("/pdm/getAllValueForAttribute/" + id)
        .then((res) => {
            console.log(id)
            console.log(res.data)
            setFacetOptions(res.data)
        })
        .catch((err) => {
            console.log(err)
        })
      }

    const toggleFOR = () => {
        setOpenFOR(!openFOR)
    }

    const returnFORSearch = () => {
        toggleFOR()
        props.maintCallback({facet: facetData.current, facet_option: facetOptionData.current, type: 'FOR'})
    }

    return(
        <div>
            <Stack alignItems={'flex-start'} justifyItems={'center'}  >
                <Stack 
                    direction={"row"} 
                    marginBottom={3}  
                    alignItems={'center'}
                    bgcolor={'#266391'}
                    width={'100%'}
                    paddingLeft={1}
                >
                <Build sx={{color:'#f5f5f5'}} fontSize="small"/>
                <Typography 
                    alignItems={'flex-start'}
                    alignContent={'center'}
                    paddingLeft={1}
                    fontWeight={'bold'}
                    color={'whitesmoke'}
                    fontStyle={'italic'}
                >
                    Product Data Management Maintenace Options
                </Typography>
                </Stack>
                <Stack 
                    direction={'row'}
                    spacing={2}
                    paddingBottom={2}
                    paddingLeft={1}
                    alignSelf={'center'}
                ></Stack>
                <Grid2
                    container
                    spacing={{xs: 1, md: 2}} 
                    alignItems={'center'} 
                    alignSelf={'center'}
                    columns={{xs: 4, sm: 8, md:12}}
                    paddingLeft={1}
                    paddingBottom={2}
                    maxWidth={1400}
                >
                    <Grid2 >
                        <Button  variant={'contained'}  onClick={toggleFOR} >
                            Facet Option Replacement
                        </Button>
                    </Grid2>
                </Grid2>
                <Modal
                    open={openFOR}
                    onClose={toggleFOR}           
                >
                    <Box
                        sx={{
                            top: '25%', 
                            left:'25%',
                            position: 'absolute',
                            border: '2px',
                            boxShadow: 24,
                            padding: 5,
                            alignItems:'center',
                            alignSelf:'center'
                        }}
                        textAlign={'center'}
                        minHeight={500}
                        minWidth={500}
                        height={'33%'}
                        width={'33%'}
                        bgcolor={"whitesmoke"}
                    >
                        <Grid2
                            container
                            spacing={{xs: 1, md: 2}} 
                            alignItems={'center'} 
                            alignSelf={'center'}
                            columns={{xs: 1, sm: 1, md:1}}
                            paddingLeft={1}
                            paddingBottom={2}
                            maxWidth={1400}
                        >
                            <Grid2 xs={1} sm={2} md={5}>
                            <Typography align="center" paddingBottom={1}>
                                Select Facet
                            </Typography>
                            <Autocomplete
                                options={facets}
                                isOptionEqualToValue={(option, newValue) => option.label === newValue.label}
                                onChange={(event, val, reason) => {
                                    if(reason === 'selectOption'){
                                        getOptions(val.id)
                                        facetData.current = val
                                        setFacetSelected(true)
                                    }
                                    console.log(val)
                                    console.log(reason)
                                    if(reason === 'clear'){
                                        setFacetSelected(false)
                                        setFacetOptionSelected(false)
                                        setFacetOptions([{id: 0, label:'No Options'}])
                                        facetData.current = {}
                                        facetOptionData = {}
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} label="Attributes"/>}
                            />
                            </Grid2>
                            <Grid2 xs={1} sm={2} md={5}>
                            <Typography align="center" padding={1}>
                                Select Facet option
                            </Typography>
                            <Autocomplete
                                disabled={!facetSelected}
                                options={facetOptions}
                                isOptionEqualToValue={(option, newValue) => option.label === newValue.label}
                                onChange={(event, val, reason) => {
                                    if(reason === 'selectOption'){
                                        setFacetOptionSelected(true)
                                        facetOptionData.current = val
                                    }
                                    if(reason === 'clear') {
                                        setFacetOptionSelected(false)
                                        facetOptionData = {}
                                    }
                                    console.log(val)
                                }}
                                renderInput={(params) => <TextField {...params} label="Options"/>}
                            />
                            </Grid2>
                        </Grid2>
                        <Button 
                            variant={'contained'} 
                            onClick={returnFORSearch} 
                            disabled={!facetOptionSelected || !facetSelected}
                        >
                            Search
                        </Button>
                    </Box>
                </Modal>
            </Stack>
        </div>
    )
}