import React from 'react';
import { Route, Routes } from 'react-router-dom';
import EntityUpdater from './components/EntityUpdater/EntityUpdater';

const EntityManager = () => {
  return (
    <Routes>
      <Route path='/entity-updater' element={<EntityUpdater />} />
    </Routes>
  );
};

export default EntityManager;
