import { Flag } from "@mui/icons-material";
import { Box, Button, CircularProgress, Drawer, LinearProgress, MenuItem, Typography } from "@mui/material";
import axios from "axios";
import {
    MaterialReactTable,
    useMaterialReactTable,
    MRT_GlobalFilterTextField,
    MRT_ToggleFiltersButton
  } from 'material-react-table';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import PDMBatch from "./PDMBatch"
import PDMAttributeDrawer from "./PDMAttrDrawer";


export default function PDMSearchTable(props) {
    const terms = props.searchTerms
    const [data, setData] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [openBatch, setOpenBatch] = useState(false)
    const [openAttributes, setOpenAttributes] = useState(false)
    const [prodCode, setProdCode] = useState()
    const [firstLoad, setFirstLoad] = useState(true)

    useEffect(() => {
        setLoading(true)
        getData(terms)
    }, [])

    const toggleAttributes = () => {
        setOpenAttributes(!openAttributes)
    }

    const closeCallback = useCallback(() => {
        toggleAttributes()
    })

    const getData = async (info) => {
        //console.log(info)
        let newData =[]
            try{
                const response = await axios.get('/productData/getProductData/' + info.value.search + '/' + info.type)
                console.log(response.data)
                response.data.forEach((val) => {
                    let valElement = (<div>
                        {val.flags.map((valFlag) => (<div>
                           <Typography fontSize={12}> <Flag style={{ color: valFlag.color, fontSize:'12px'}}/>{(valFlag.description = '400 Change' ? 'AS400 Change' : valFlag.description)} - {valFlag.date}</Typography></div>
                        ))}
                    </div>)
                    let newVal = {
                        category: val.category,
                        department: val.department,
                        flags: valElement,
                        manufacturer: val.manufacturer,
                        model: val.model,
                        product_description: val.product_description,
                        product_id: val.product_id,
                        product_type: val.product_type,
                        scan1: val.scan1,
                        scan2: val.scan2,
                        series: val.series,
                        sports_south_number: val.sports_south_number,
                        upc: val.upc
                    }
                     newData.push(newVal)
                });
                setData(newData)
                setLoading(false)
        }catch(err){
            dataError(err)
            return false
        }
    }

    const dataError = (err) => {
        setLoading(false)
        let errMsg = '';
                    if(err.response.data.hasOwnProperty('message')){
                        if(err.response.data.message == 'No Info'){
                            errMsg='No table data for search results: IDs did not return valid results'
                        }else{
                            errMsg = err.response.data.message
                        }
                    }
                    alert('Error:')
    }


    const columns = useMemo(() => [
        {
            accessorKey: 'product_description', 
            header: 'Product Description',
            size: 250,
          },{
            accessorKey: 'manufacturer', 
            header: 'Description',
            size: 200,
          },{
            accessorKey: 'product_id', 
            header: 'Product ID',
            size: 200,
          },
          {
            accessorKey: 'flags', 
            header: 'Flags',
            size: 250,
          },
          {
            accessorKey: 'product_type', 
            header: 'Type',
            size: 200,
          },{
            accessorKey: 'upc', 
            header: 'UPC Code',
            size: 200,
          },{
            accessorKey: 'sports_south_number', 
            header: 'Sports South Number',
            size: 200,
          },{
            accessorKey: 'department', 
            header: 'Department Description',
            size: 200,
          },{
            accessorKey: 'category', 
            header: 'Category Description',
            size: 200,
          },{
            accessorKey: 'model', 
            header: 'Model',
            size: 200,
          },{
            accessorKey: 'series', 
            header: 'Series',
            size: 200,
          },{
            accessorKey: 'scan1', 
            header: 'Scan1',
            size: 200,
          },{
            accessorKey: 'scan2', 
            header: 'Scan2',
            size: 200,
          },
    ])

    const table = useMaterialReactTable({
        columns,
        data, 
        enableColumnFilters: true,
        enableColumnFilterModes: true,
        enableRowSelection: true,
        enableRowActions: true,
        renderRowActionMenuItems: ({ row }) => [
            <MenuItem
                key="editAttributes"
                onBlur={() => {}} 
                onClick={() => {
                setProdCode(row.original.sports_south_number)
                setOpenAttributes(true)
                }}>
                Edit Attributes
            </MenuItem>    
        ],
        renderTopToolbar: ({ table }) => {
            


            const toggleBatch = () => {
                setOpenBatch(!openBatch)
            }

            return(
                <Box sx={{
                    display: 'flex',
                    padding: '8px',
                    paddingTop: '35px',
                    justifyContent: 'space-between',
                  }}>
                    <Button 
                        
                        onClick={toggleBatch} 
                        variant='outlined'
                        sx={{margin:1}}
                    >
                        Batch Processing
                    </Button>
                    <Drawer
                        open={openBatch}
                        onClose={toggleBatch}
                        anchor="right"
                    >
                        <PDMBatch />
                    </Drawer>
                   
                </Box>
            )
        }
        
      });

    return (
        <div>
        {isLoading? <div style={{paddingTop:10, paddingBottom:10, textAlign:'center'}}><LinearProgress/></div> :
        <Box>
            <Box>
          <Typography
            paddingLeft={3}
            textAlign={'Left'}
            fontWeight={'bold'}
          >
            Results for {terms.value.label}
          </Typography>
          </Box>
          <MaterialReactTable table={table} />
        </Box>
        }
        <Drawer
            sx={{width:'300'}}
            open={openAttributes}
            onClose={toggleAttributes}
            anchor="right"
        >
            <PDMAttributeDrawer productCode={prodCode} closeCallback={closeCallback}/>
        </Drawer>
        </div>
      );
}