import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { EditProductDataContextState } from '../../../../../../../Context/pdm/EditProductDataContext';

import { Autocomplete, TextField } from '@mui/material';

const initArray = [{ id: 0, label: 'Choose Model Please' }];

const InputModels = () => {
	const { selectedMfrId, selectedCatId, selectedSeriesId, updateSelectedModelId } =
		useContext(EditProductDataContextState);

	const [data, setData] = useState([]);
	const [value, setValue] = useState(...initArray);
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		setMounted(false);
		const fetchModelsListFromMfrId = async () => {
			//alert('this ran');
			try {
				const response = await axios.get(
					`/pdm/getUniqueModelsByMfrIdCatIdSerId/${selectedMfrId}/${selectedCatId}/${selectedSeriesId}`
				);
				setValue(initArray[0]);
				setData([...initArray, ...response.data]); // Assuming the response has a data object
				response.data.length > 1 && setMounted(true);
				console.log('response.data: ' + response.data.length);
			} catch (error) {
				console.log('fetchCategoryListFromMfrId error: ', error);
			}
		};

		selectedSeriesId > 0 && fetchModelsListFromMfrId();
	}, [selectedMfrId, selectedCatId, selectedSeriesId]);

	return (
		<>
			{mounted ? (
				<Autocomplete
					value={value}
					onChange={(event, newValue) => {
						setValue(newValue);
						updateSelectedModelId(newValue.id);
					}}
					options={data}
					disableClearable={true}
					getOptionLabel={(option) => option.label}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					renderInput={(params) => <TextField {...params} label={`Filter By Model (Optional)`} />}
					sx={{ width: 300 }}
				/>
			) : null}{' '}
		</>
	);
};

export default InputModels;
