import React, { useEffect, useState } from 'react';
import { getAllPublications, getAllPublicationsByUserId } from './../../../DataFeeds/Publications';

const Contracts = ({ userId }) => {
	// LOCAL STATE
	const [allPubs, setAllPubs] = useState([]);
	const [usersAds, setUsersAds] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const [allPublications, userPublications] = await Promise.all([
					getAllPublications(),
					getAllPublicationsByUserId(userId),
				]);

				setAllPubs(allPublications);
				setUsersAds(
					userPublications.map((e) => ({
						ss_publication_contract_id: e.ss_publication_contract_id,
						entity_id: e.entity_id,
						business_name: e.business_name,
						deleted: e.deleted,
						last_status_change: e.last_status_change,
						ss_publication_contract_status_id: e.ss_publication_contract_status_id,
						status: e.status,
						ss_publication_id: e.ss_publication_id,
						time_created: e.time_created,
						user_id: e.user_id,
						user_name: e.user_name,
						sold_price: e.sold_price,
						product_manufacturers_json: e.product_manufacturers ? JSON.parse(e.product_manufacturers) : [],
					}))
				);
				setLoading(false);
			} catch (error) {
				console.error('Error fetching publications:', error);
				setError('Error fetching publications');
				setLoading(false);
			}
		};

		fetchData();
	}, [userId]);

	if (loading) {
		return <div>Loading...</div>;
	}

	if (error) {
		return <div>{error}</div>;
	}

	return (
		<div>
			Contracts: {userId}
			<div>
				{usersAds.map((ad) => (
					<div key={ad.ss_publication_contract_id}>
						<p>{ad.ss_publication_contract_id}</p>
						<p>{ad.business_name}</p>
						<p>{ad.sold_price}</p>
						<p>
							name:{' '}
							{ad.product_manufacturers_json.length > 0
								? ad.product_manufacturers_json.map((e) => e.name)
								: '---'}
						</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default Contracts;
