import React from 'react';
import { Paper, Typography, Box } from '@mui/material';

const ColorKey = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        direction: 'row',
        justifyContent: 'center',
        maxWidth: '600px',
        margin: 'auto',
        width: '100%',
      }}
    >
      <Typography
        variant='h6'
        sx={{
          textAlign: 'center',
          color: '#333',
          backgroundColor: '#fdd97c',
          width: '100%',
        }}
      >
        General
      </Typography>

      <Typography
        variant='h6'
        sx={{
          textAlign: 'center',
          color: '#333',
          backgroundColor: '#fbfdaa',
          width: '100%',
        }}
      >
        Preferred
      </Typography>

      <Typography
        variant='h6'
        sx={{
          textAlign: 'center',
          color: '#333',
          backgroundColor: '#c1f0b2',
          width: '100%',
        }}
      >
        Elite
      </Typography>

      <Typography
        variant='h6'
        sx={{
          textAlign: 'center',
          color: '#333',
          backgroundColor: '#d2e4f0',
          width: '100%',
        }}
      >
        Master
      </Typography>
    </Box>
  );
};

export default ColorKey;
