import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment-timezone';

const PromotionCard = ({ promotion }) => {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme(); // Use the theme for consistent styling

  moment.tz.setDefault('America/Chicago');

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const ExpandMore = styled(IconButton)(({ theme }) => ({
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      backgroundColor: 'transparent', // Set hover background to transparent
    },
  }));

  // Styles for maintaining uniform card sizes
  const cardStyles = {
    display: 'flex',
    flexDirection: 'column', // Stack the card content vertically
    justifyContent: 'space-between', // Distribute space evenly
    borderRadius: 2,
    boxShadow: 3,
    minHeight: '100%', // Ensure the card stretches in a grid layout
    '&:hover': {
      boxShadow: 6,
    },
  };

  const currentTime = moment();
  const startTime = moment(promotion.start_time);
  const endTime = moment(promotion.end_time);
  const hasStarted = currentTime.isSameOrAfter(startTime);

  let daysCount;
  let daysText;

  if (hasStarted) {
    // Promotion has started
    daysCount = endTime.diff(currentTime, 'days');
    daysText = `${daysCount} Day${daysCount === 1 ? '' : 's'} Remaining`;
  } else {
    // Promotion has not started
    daysCount = startTime.diff(currentTime, 'days');
    daysText = `Starts in ${daysCount} Day${daysCount === 1 ? '' : 's'}`;
  }

  return (
    <Card sx={cardStyles}>
      <CardHeader
        sx={{
          textAlign: 'center',
          backgroundColor: theme.palette.grey[200], // Subtle background for the header
        }}
        title={
          <Typography variant='h5' component='div' sx={{ fontWeight: 'bold' }}>
            {promotion.description}
          </Typography>
        }
        subheader={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                backgroundColor: `${promotion.background_color}`,
                color: theme.palette.getContrastText(promotion.background_color),
                padding: '4px 8px',
                borderRadius: 2,
                fontSize: '0.875rem', // Smaller font size for the category
              }}
            >
              {promotion.calendar_category_description}
            </Typography>
            {promotion.manufacturers &&
              promotion.manufacturers.length > 0 &&
              promotion.manufacturers.map((manufacturer, index) => (
                <Typography
                  key={index}
                  sx={{
                    backgroundColor: theme.palette.grey[300], // Consistent background for tags
                    borderRadius: 2,
                    padding: '4px 8px',
                    fontSize: '0.875rem', // Smaller font size for consistency
                  }}
                >
                  {manufacturer.manufacturer}
                </Typography>
              ))}
            {promotion.tags &&
              promotion.tags.length > 0 &&
              promotion.tags.map((tag, index) => (
                <Typography
                  key={index}
                  sx={{
                    backgroundColor: theme.palette.grey[400], // Consistent background for tags
                    borderRadius: 2,
                    padding: '4px 8px',
                    fontSize: '0.875rem', // Smaller font size for consistency
                  }}
                >
                  {tag.tag}
                </Typography>
              ))}
          </div>
        }
      />
      <CardContent sx={{ textAlign: 'center', padding: '16px', minHeight: '100px' }}>
        <Typography variant='h6'>
          Start: {moment(promotion.start_time).format('MM/DD/YY')}
        </Typography>
        <Typography variant='h6'>End: {moment(promotion.end_time).format('MM/DD/YY')}</Typography>
        <Typography
          sx={{
            marginTop: '10px', // Add some spacing above the notification
            color: theme.palette.primary.main, // Use the secondary color from the theme
            fontWeight: 'bold', // Make it bold to catch attention
          }}
        >
          {daysText}
        </Typography>
      </CardContent>
      <CardActions
        disableSpacing
        sx={{ justifyContent: 'flex-end', padding: '0 16px 16px', minHeight: '56px' }}
      >
        {promotion.detailed_description && (
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label='show more'
          >
            <Typography variant='body2'>Details</Typography>
            <ExpandMoreIcon
              sx={{
                transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </ExpandMore>
        )}
      </CardActions>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent
          sx={{ justifyContent: 'center', alignContent: 'center', padding: '0 16px 16px' }}
        >
          <Typography variant='body1'>{promotion.detailed_description}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default PromotionCard;
