import React, { useContext, useEffect, useState } from 'react';
import { EntityContactContextState } from '../../../Context/Marketing/EntityContactContext';
import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	MenuItem,
	TextField,
	useTheme,
} from '@mui/material';
import {
	updatePrimaryEmailToZero,
	updatePrimaryEmailToOneByEmailId,
	getCountEmailUsed,
	deleteEmail,
	insertOrUpdateEmail,
} from './EntityContactMiddleWare';
import { DeleteForever, Save } from '@mui/icons-material';
import SnackBarV2 from '../../_GlobalComponents/SnackBarV2';
const EmailEditCard = ({ emailId, contactId }) => {
	// INIT's
	const theme = useTheme();

	// CONTEXT STATE
	const { allEmails, setAllEmails } = useContext(EntityContactContextState);

	// LOCAL STATE
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({});
	const [snackBarObj, setSnackBarObj] = useState({
		open: false,
		message: 'default message',
		severity: 'success',
	});
	const [displayValue, setDisplayValue] = useState('grid');

	useEffect(() => {
		const funcExisting = () => {
			const grabEmailProperties = allEmails.filter((e) => e.entity_contact_email_id === emailId);
			console.log('grabEmailProperties: ', grabEmailProperties);
			setData(grabEmailProperties);
			setLoading(false);
		};

		const funcNew = () => {
			setData([
				{
					entity_contact_email_id: 0,
					entity_contact_id: contactId,
					description: '',
					email_address: '',
					primary_email: 0,
				},
			]);
			setLoading(false);
		};

		emailId === 0 ? funcNew() : funcExisting();
	}, [emailId, allEmails, contactId]);

	if (loading) {
		return (
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				<CircularProgress />
			</Box>
		);
	}

	const ResetOpen = () => {
		setSnackBarObj((prev) => ({ ...prev, open: false }));
	};

	const handlePrimaryCheckBoxClick = async (e) => {
		const isChecked = data[0].primary_email;

		try {
			if (!isChecked) {
				const zeroReturn = await updatePrimaryEmailToZero(data[0].entity_contact_id);
				const callBackFunc = async () => {
					const numOfRows = await updatePrimaryEmailToOneByEmailId(emailId);
					numOfRows.data.affectedRows > 0 && setData((prev) => [{ ...prev[0], primary_email: 1 }]);
					setAllEmails();
				};
				zeroReturn.data.affectedRows > 0 && callBackFunc();
			} else {
				await updatePrimaryEmailToZero(data[0].entity_contact_id);
				setData((prev) => [{ ...prev[0], primary_email: 0 }]);
				setAllEmails();
			}
		} catch (error) {
			console.log('error: ', error);
		}
	};

	const handleDeleteClick = async () => {
		try {
			const getEmailCount = await getCountEmailUsed(emailId);
			console.log('getEmailCount: ' + getEmailCount.data[0].numOfRows);
			if (getEmailCount.data[0].numOfRows > 0) {
				setSnackBarObj((prev) => ({
					...prev,
					open: true,
					message: `This email is currently used ${getEmailCount} times!`,
					severity: 'error',
				}));
			} else {
				try {
					const doDeleteEmail = await deleteEmail(emailId);
					console.log('doDeleteEmail: ', doDeleteEmail);
					if (doDeleteEmail.data.affectedRows < 1) {
						setSnackBarObj((prev) => ({
							...prev,
							open: true,
							message: `Email Number Did Not Delete!`,
							severity: 'error',
						}));
					} else {
						console.log('this else ran');
						setDisplayValue('none');
						setSnackBarObj((prev) => ({
							...prev,
							open: true,
							message: `Email Number Deleted!`,
							severity: 'success',
						}));
					}
				} catch (error) {
					console.log('error: ', error);
				}
			}
		} catch (error) {
			console.log('error: insertOrUpdateEmail: ', error);
		}
	};

	const handleClick = () => {
		console.log('this is handle click phone data: ', data);
		const proccessFunc = async () => {
			try {
				const runQuery = await insertOrUpdateEmail(data);
				if (runQuery.data.affectedRows > 0) {
					setAllEmails();
					console.log('runQueryy : ', runQuery);
				}
			} catch (error) {
				console.log('error: insertOrUpdatePhoneNumbers: ', error);
			}
		};
		proccessFunc();
	};

	console.log('email card data contactId: ', data);

	return (
		<>
			<Box
				sx={{
					width: '90%',
					maxWidth: '345px',
					border: `solid 4px ${theme.palette.primary.alt}`,
					borderRadius: '24px',
					padding: '24px',
					boxShadow: '4px 4px 12px rgba(0,0,0,.3)',
					backgroundColor: 'white',
					display: displayValue,
					gridTemplateColumns: '1fr 80px',
					gap: '16px',
				}}
			>
				<Box
					sx={{
						color: theme.palette.primary.main,
						mb: '24px',
						fontWeight: 'bold',
						fontSize: '1.3em',
						gridColumn: 'span 2',
					}}
				>
					Email Address: ({emailId === 0 ? 'New' : emailId})
				</Box>
				<TextField
					sx={{ gridColumn: 'span 2' }}
					name='email_address'
					label='Email Address:'
					type='text'
					value={data[0].email_address}
					InputLabelProps={{
						shrink: true,
					}}
					onChange={(e) => setData((prev) => [{ ...prev[0], email_address: e.target.value }])}
				/>

				<Box
					sx={{
						textAlign: 'center',
						gridColumn: 'span 2',
						display: data[0].entity_contact_email_id === 0 ? 'none' : 'block',
					}}
				>
					<FormControlLabel
						control={
							<Checkbox
								color='success'
								checked={data[0].primary_email}
								onChange={(e) => handlePrimaryCheckBoxClick(e)} //setData((prev) => [{ ...prev[0], primary_phone: e.target.checked }])}
								inputProps={{ 'aria-label': 'controlled' }}
							/>
						}
						label='Primary'
						sx={{
							'& .MuiFormControlLabel-label': {
								fontWeight: 'bold',
							},
						}}
					/>
				</Box>

				<Box
					sx={{
						gridColumn: 'span 2',
						display: 'flex',
						justifyContent: data[0].entity_contact_email_id === 0 ? 'center' : 'space-between',
					}}
				>
					<Button
						sx={{
							fontWeight: 'bold',
							display: data[0].entity_contact_email_id === 0 ? 'none' : 'flex',
						}}
						variant='contained'
						color='error'
						onClick={() => handleDeleteClick(data)}
						startIcon={<DeleteForever />}
					>
						Delete
					</Button>

					<Button
						sx={{ fontWeight: 'bold' }}
						variant='contained'
						color='success'
						onClick={() => handleClick(data)}
						startIcon={<Save />}
					>
						Save
					</Button>
				</Box>
			</Box>
			<SnackBarV2
				doOpen={snackBarObj.open}
				ResetOpen={ResetOpen}
				message={snackBarObj.message}
				severity={snackBarObj.severity}
			/>
		</>
	);
};

export default EmailEditCard;
