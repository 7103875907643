import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import EntityContext from '../Context/Entity/EntityContext';
import Manufacturers from '../Components/Manufacturers/Manufacturers';
import Advertising from '../Components/Manufacturers/components/Advertising/Advertising';
const EntityRoutes = () => {
	//const userPermission = useSelector((state) => state.userPermission.value);

	return (
		<EntityContext>
			<Routes>
				<Route path='/product-audit' element={<h2>/product-audit</h2>} />
				<Route path='/marketing-opportunities' element={<h2>/marketing-opportunities</h2>} />
				<Route path='/product-performance' element={<h2>/product-performance</h2>} />
				<Route path='/advertising' element={<Advertising />} />
				<Route path='/*' element={<Manufacturers />} />
			</Routes>
		</EntityContext>
	);
};

export default EntityRoutes;
