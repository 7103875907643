import React, { useContext, useEffect, useState } from 'react';
import { EntitiesV2ContextState } from '../../../../../Context/Marketing/EntitiesV2Context';
import { Box, CircularProgress, MenuItem, TextField } from '@mui/material';
import { insertOrUpdateAssociation } from '../../EntityContactMiddleWare';

const RolesSelect = ({ data }) => {
	// CONTEXT STATE
	const { entityAssoTypes } = useContext(EntitiesV2ContextState);

	// LOCAL STATE
	const [loading, setLoading] = useState(true);
	const [dataState, setDataState] = useState([]);

	// USE EFFECT
	useEffect(() => {
		setDataState([data]);
		setLoading(false);
	}, [data]);

	// FUNCTIONS
	const insertOrUpdateAssociationFunc = async (obj) => {
		try {
			const callFunc = await insertOrUpdateAssociation(obj);
			return callFunc;
		} catch (error) {
			console.log('error insertOrUpdateAssociation: ', error);
		}
	};

	const handleRoleChange = async (e) => {
		setDataState((prev) => [{ ...prev[0], entity_contact_role_id: e.target.value }]);
		const response = await insertOrUpdateAssociationFunc([
			{ ...dataState[0], entity_contact_role_id: e.target.value },
		]);
		console.log('should have update', response);
	};

	// LOADING FUNCTION
	if (loading) {
		return (
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				<CircularProgress />
			</Box>
		);
	}

	// console.log('dataState: ', dataState);
	// console.log('entityAssoTypes: ', entityAssoTypes);
	return (
		<>
			<TextField
				sx={{ gridColumn: 'span 2' }}
				select
				name='entity_contact_role_id'
				label='Roles'
				type='text'
				value={dataState[0].entity_contact_role_id}
				InputLabelProps={{
					shrink: true,
				}}
				onChange={(e) => handleRoleChange(e)}
			>
				<MenuItem key='0' value={undefined}>
					Choose Type
				</MenuItem>
				{entityAssoTypes.map((role) => (
					<MenuItem key={role.entity_contact_role_id} value={role.entity_contact_role_id}>
						{role.description}
					</MenuItem>
				))}
			</TextField>
		</>
	);
};

export default RolesSelect;
