import { createSlice } from '@reduxjs/toolkit';

export const pdmSlice = createSlice({
  name: 'dataInformation',
  initialState: {
    user_id: 0,
    // CLOUD SEARCH RESULTS FROM PDM
    pdmCloudSearchResults: [],

    // AS400 DESCRIPTION VALUES
    as400DescItemList: [],
    as400DescItemFilteredList: [],
    as400DescItemFilter: '',
    as400DescItemCount: 0,
    as400DescPerPageCount: 50,
    as400DescCurrentPageNumber: 1,
    as400DescSelectedMfr: 0,
    as400DescSelectedBrandCat: 0,
    as400DescSelectedMfrName: '',
    as400DescSelectedCatName: '',
    as400DescriptionSearchTerm: '',
    as400DescProgressModal: false,
    as400DescSelectedProductId: 0,
    as400DescSelectedCategoryId: 0,

    // PDM SNACKBAR VALUES
    PDMSnackBarOpen: false,
    PDMSnackBarMessage: '',
    PDMSnackBarSeverty: '',

    // PDM Modal Flags
    PDMModalFlagsNoteFormWrapper: false,

    // PDM Category Attributes 777
    getPDMCategoryAttributes: [],
  },

  reducers: {
    pdmCloudSearchResultsValue: (state, action) => {
      state.pdmCloudSearchResults = action.payload;
    },
    manufacturersValue: (state, action) => {
      state.manufacturers = action.payload;
    },
    as400DescSelectedMfrValue: (state, action) => {
      state.as400DescSelectedMfr = action.payload;
    },
    as400DescSelectedBrandCatValue: (state, action) => {
      state.as400DescSelectedBrandCat = action.payload;
    },
    as400DescProgressModalValue: (state, action) => {
      state.as400DescProgressModal = action.payload;
    },
    as400DescriptionSearchTermValue: (state, action) => {
      state.as400DescriptionSearchTerm = action.payload;
    },
    as400DescSelectedProductIdValue: (state, action) => {
      state.as400DescSelectedProductId = action.payload;
    },
    as400DescSelectedCategoryIdValue: (state, action) => {
      state.as400DescSelectedCategoryId = action.payload;
    },
    as400DescSelectedMfrNameValue: (state, action) => {
      state.as400DescSelectedMfrName = action.payload;
    },
    as400DescSelectedCatNameValue: (state, action) => {
      state.as400DescSelectedCatName = action.payload;
    },
    user_idValue: (state, action) => {
      state.user_id = action.payload;
    },
    PDMSnackBarOpenValue: (state, action) => {
      state.PDMSnackBarOpen = action.payload;
    },
    PDMSnackBarMessageValue: (state, action) => {
      state.PDMSnackBarMessage = action.payload;
    },
    PDMSnackBarSevertyValue: (state, action) => {
      state.PDMSnackBarSeverty = action.payload;
    },
    PDMModalFlagsNoteFormWrapperValue: (state, action) => {
      state.PDMModalFlagsNoteFormWrapper = action.payload;
    },

    as400DescItemListValue: (state, action) => {
      state.as400DescItemList = action.payload;
    },
    as400DescItemFilteredListValue: (state, action) => {
      state.as400DescItemFilteredList = action.payload;
    },
    as400DescItemFilterValue: (state, action) => {
      state.as400DescItemFilter = action.payload;
    },
    as400DescItemCountValue: (state, action) => {
      state.as400DescItemCount = action.payload;
    },
    as400DescPerPageCountValue: (state, action) => {
      state.as400DescPerPageCount = action.payload;
    },
    as400DescCurrentPageNumberValue: (state, action) => {
      state.as400DescCurrentPageNumber = action.payload;
    },
    getPDMCategoryAttributesValue: (state, action) => {
      state.getPDMCategoryAttributes = action.payload;
    },
  },
});

export const {
  pdmCloudSearchResultsValue,
  manufacturersValue,
  as400DescSelectedMfrValue,
  as400DescSelectedBrandCatValue,
  as400DescProgressModalValue,
  as400DescriptionSearchTermValue,
  as400DescSelectedProductIdValue,
  as400DescSelectedCategoryIdValue,
  as400DescSelectedMfrNameValue,
  as400DescSelectedCatNameValue,
  user_idValue,
  PDMSnackBarOpenValue,
  PDMSnackBarMessageValue,
  PDMSnackBarSevertyValue,
  PDMModalFlagsNoteFormWrapperValue,
  as400DescItemListValue,
  as400DescItemFilteredListValue,
  as400DescItemFilterValue,
  as400DescItemCountValue,
  as400DescPerPageCountValue,
  as400DescCurrentPageNumberValue,
  getPDMCategoryAttributesValue,
} = pdmSlice.actions;
export default pdmSlice.reducer;
