import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { selectedContactIdValue, refreshAssociatedDataValue } from './../../../../Redux/EntitySlice';

import axios from 'axios';

// Material UI
import { Box, Chip, IconButton, Grid, Paper, styled, TextField, Typography, useTheme, Divider, Stack } from '@mui/material';
import { DeleteForever } from '@mui/icons-material';

import SectionTitle from '../../../_GlobalComponents/SectionTitle.jsx';

// Material Styled Components
const Wrapper = styled(Box)(({ theme }) => ({}));

const Item = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  marginBottom: 25,
  backgroundColor: theme.palette.primary.mainScreen,
  padding: 15,
  borderRadius: 12,
}));

// Primary Component
const AssociatedEntities = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  // REDUX STATE
  const selectedContactId = useSelector((state) => state.entitySlice.selectedContactId);
  const refreshAssociatedData = useSelector((state) => state.entitySlice.refreshAssociatedData);

  // LOCAL STATE
  const [associatedEntitiesList, setAssociatedEntitiesList] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      selectedContactId > 0 &&
        axios
          .get(`/entities/getContactsAssociatedVendors/${selectedContactId}`)
          .then(function (response) {
            const data = response.data;
            setAssociatedEntitiesList(data);
          })
          .catch((error) => {
            console.log('error: ' + error);
          });
    };

    fetchData();
  }, [selectedContactId, refreshAssociatedData]);

  // FUNCTIONS
  const deleteContactEntityRecord = (rowid) => {
    axios
      .post(`/entities/deleteContactToEntity`, { rowid: rowid })
      .then(function (response) {
        console.log(response.data);
        dispatch(refreshAssociatedDataValue(Date.now()));
      })
      .catch((error) => {
        console.log('error: ' + error);
      });
  };

  return (
    <Wrapper>
      <Paper elevation={3} sx={{ padding: '15px', mt: 2 }}>
        <SectionTitle title='Associated Entities' />
        <Box sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
          {associatedEntitiesList.map((item) => (
            <Item key={item.ss_entity_id}>
              <Box sx={{ textAlign: 'left' }}>Entity ID: {item.ss_entity_id}</Box>
              <Box sx={{ textAlign: 'right' }}>Vendor ID: {item.primary_identifier}</Box>
              <Box sx={{ gridColumn: '1 / -1', fontSize: '20px', mb: 1 }}>{item.business_name}</Box>
              <Box sx={{ gridColumn: '1 / -1' }}>
                {item.types.split(',').map((type) => (
                  <Chip sx={{ fontSize: '.9em' }} label={type.split('~~')[1]} variant='outlined' onDelete={() => deleteContactEntityRecord(type.split('~~')[2])} deleteIcon={<DeleteForever />} />
                ))}
              </Box>
            </Item>
          ))}
        </Box>
      </Paper>
    </Wrapper>
  );
};
export default AssociatedEntities;
