import React, { useState } from 'react';
import { Box, Divider, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import {
  MaterialReactTable,
  MRT_ToggleDensePaddingButton as MrtToggleDensePaddingButton,
  MRT_ToggleFiltersButton as MrtToggleFiltersButton,
  MRT_ToggleFullScreenButton as MrtToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton as MrtToggleGlobalFilterButton,
} from 'material-react-table';
import { ContentCopy, Help } from '@mui/icons-material';

const InnerTable = ({ data = [], groupByAddon = false, setSnackbarOpen = () => {} }) => {
  const columns = groupByAddon
    ? [
        {
          header: 'Individual Qty',
          accessorKey: 'addon_qty_selected',
          Cell: ({ renderedCellValue }) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant='body1'>{renderedCellValue}</Typography>
            </Box>
          ),
        },
        {
          header: 'Booth',
          accessorFn: (row) => `${row.booth_number} (${row.booth_type})`,
          Cell: ({ renderedCellValue }) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant='body1'>{renderedCellValue}</Typography>
            </Box>
          ),
        },
        {
          header: 'Company Name',
          accessorKey: 'exhibitor_name',
          Cell: ({ renderedCellValue }) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant='body1'>{renderedCellValue}</Typography>
            </Box>
          ),
        },
        {
          header: 'Company Email',
          accessorKey: 'exhibitor_email',
          Cell: ({ renderedCellValue, row }) => {
            const [isHovered, setIsHovered] = useState(false);

            const handleMouseEnter = () => {
              setIsHovered(true);
            };

            const handleMouseLeave = () => {
              setIsHovered(false);
            };

            const handleCopy = (text) => {
              navigator.clipboard.writeText(text);
              setSnackbarOpen(false);
              setTimeout(() => setSnackbarOpen(true), 100);
            };

            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1.5,
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {String(row.original.exhibitor_email).trim().length > 0 && (
                  <>
                    <a
                      href={`mailto:${row.original.exhibitor_email}`}
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                      }}
                      onMouseEnter={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                      onMouseLeave={(e) => (e.currentTarget.style.textDecoration = 'none')}
                    >
                      <Typography variant='body1'>{renderedCellValue}</Typography>
                    </a>
                    <IconButton
                      onClick={() => handleCopy(row.original.exhibitor_email)}
                      sx={{ m: -1, ...(!isHovered && { opacity: 0 }) }}
                    >
                      <ContentCopy
                        sx={{
                          cursor: 'pointer',
                          color: '#2471a3',
                          fontSize: 'large',
                        }}
                      />
                    </IconButton>
                  </>
                )}
              </Box>
            );
          },
        },
        {
          header: 'Company Phone #',
          accessorKey: 'exhibitor_phone',
          Cell: ({ renderedCellValue, row }) => {
            const [isHovered, setIsHovered] = useState(false);

            const handleMouseEnter = () => {
              setIsHovered(true);
            };

            const handleMouseLeave = () => {
              setIsHovered(false);
            };

            const handleCopy = (text) => {
              navigator.clipboard.writeText(text);
              setSnackbarOpen(false);
              setTimeout(() => setSnackbarOpen(true), 100);
            };

            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1.5,
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {String(row.original.exhibitor_phone).trim().length > 0 && (
                  <>
                    <a
                      href={`tel:${row.original.exhibitor_phone}`}
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                      }}
                      onMouseEnter={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                      onMouseLeave={(e) => (e.currentTarget.style.textDecoration = 'none')}
                    >
                      <Typography variant='body1'>{renderedCellValue}</Typography>
                    </a>
                    <IconButton
                      onClick={() => handleCopy(row.original.exhibitor_phone)}
                      sx={{ m: -1, ...(!isHovered && { opacity: 0 }) }}
                    >
                      <ContentCopy
                        sx={{
                          cursor: 'pointer',
                          color: '#2471a3',
                          fontSize: 'large',
                        }}
                      />
                    </IconButton>
                  </>
                )}
              </Box>
            );
          },
        },
      ]
    : [
        {
          header: 'Addon',
          accessorKey: 'addon_description',
          Cell: ({ renderedCellValue, row }) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant='body1'>{renderedCellValue}</Typography>
              <Tooltip
                title={
                  <Typography variant='body1' sx={{ fontSize: 14 }}>
                    {row.original.addon_detail}
                  </Typography>
                }
                placement='right'
              >
                <Help fontSize='small' sx={{ ml: 0.5, cursor: 'pointer' }} />
              </Tooltip>
            </Box>
          ),
        },
        {
          header: 'Type',
          accessorKey: 'addon_type_description',
          Cell: ({ renderedCellValue }) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant='body1'>{renderedCellValue}</Typography>
            </Box>
          ),
        },
        {
          header: 'Qty',
          accessorKey: 'addon_qty_selected',
          Cell: ({ renderedCellValue }) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant='body1'>{renderedCellValue}</Typography>
            </Box>
          ),
        },
        {
          header: 'Individual Price',
          accessorFn: (row) =>
            Number(row.addon_price).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }),
          sortingFn: (rowA, rowB) => {
            const valueA = Number(rowA.original.addon_price.replace(/[^0-9.-]+/g, ''));
            const valueB = Number(rowB.original.addon_price.replace(/[^0-9.-]+/g, ''));

            return valueA - valueB;
          },
          Cell: ({ renderedCellValue }) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant='body1'>{renderedCellValue}</Typography>
            </Box>
          ),
        },
        {
          header: 'Total Price',
          accessorFn: (row) =>
            (Number(row.addon_price) * Number(row.addon_qty_selected)).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }),
          Cell: ({ renderedCellValue }) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant='body1'>{renderedCellValue}</Typography>
            </Box>
          ),
        },
      ];

  return (
    <>
      {data.length > 0 ? (
        <MaterialReactTable
          columns={columns}
          data={data}
          enableHiding={false}
          initialState={{
            showColumnFilters: false,
            density: 'compact',
            pagination: { pageIndex: 0, pageSize: 10 },
          }}
          positionToolbarAlertBanner='bottom'
          renderToolbarInternalActions={({ table }) => {
            return (
              <Box sx={{ whiteSpace: 'pre' }}>
                <MrtToggleGlobalFilterButton table={table} />
                <MrtToggleFiltersButton table={table} />
                <MrtToggleDensePaddingButton table={table} />
                <MrtToggleFullScreenButton table={table} />
              </Box>
            );
          }}
          renderTopToolbarCustomActions={({ table }) => {
            return (
              <Box display='flex' alignItems='center' justifyContent='space-between' width='80%'>
                <Box display='flex' flexDirection='column' width='100%' justifyContent='center'>
                  <Typography
                    variant='h6'
                    sx={{
                      textAlign: 'center',
                      fontWeight: 600,
                      textTransform: 'uppercase',
                      letterSpacing: '0.05em',
                      color: 'primary.main',
                      fontFamily: 'Cabin, sans-serif',
                      lineHeight: 1.2,
                    }}
                  >
                    {groupByAddon ? 'Exhibitors' : 'Addons'}
                  </Typography>
                  <Divider
                    sx={{
                      width: '40%',
                      height: '2px',
                      backgroundColor: '#aaa',
                      margin: '0 auto',
                      marginTop: '0.25rem',
                    }}
                  />
                </Box>
              </Box>
            );
          }}
        />
      ) : (
        <Paper sx={{ height: '100px', pb: 1 }}>
          <Box
            sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Typography
              variant='h6'
              sx={{
                fontStyle: 'italic',
              }}
            >
              No {groupByAddon ? 'exhibitors' : 'addons'} to display
            </Typography>
          </Box>
        </Paper>
      )}
    </>
  );
};

export default InnerTable;
