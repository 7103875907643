import { Box } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { ContractContextState } from '../../../Context/Marketing/ContractContext';
import { PublicationContexV2tState } from '../../../Context/Marketing/PublicationContextV2';
import ContractCard from './ContractCard/ContractCard';

const ContractCards = ({ search, ids }) => {
	// CONTEXT STATE
	const { allContracts } = useContext(ContractContextState);
	const { allPublications } = useContext(PublicationContexV2tState);

	// LOCAL STATE
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);

	// USE EFFECT
	useEffect(() => {
		const fetch = () => {
			let tempArray = [];
			try {
				if (search === 'entities') {
					tempArray = allContracts.filter((e) => ids.includes(e.entity_id) && e.deleted !== 1);
					console.log('tempArray: ', tempArray);
					setData(tempArray);
				} else {
					tempArray = allContracts.filter((e) => ids.includes(e.ss_publication_id) && e.deleted !== 1);
				}
			} catch (error) {
				setLoading(false);
				return { error };
			} finally {
				setLoading(false);
			}
		};
		fetch();
	}, [allContracts, ids, search]);

	if (loading) {
		return <div>Loading...</div>;
	}

	console.log('sent ids: data ', data);

	return (
		<Box
			sx={{
				overflowY: 'auto',
				minWidth: '300px',
				maxWidth: '90vw',
				maxHeight: '90vh',
				display: 'flex',
				gridTemplateColumns: 'repeat(auto-fill, 375px)',
				gap: '24px',
				justifyContent: 'center',
				flexWrap: 'wrap',
			}}
		>
			{data.map((e, i) => (
				<Box sx={{ minWidth: '375px', maxWidth: '375px' }}>
					<ContractCard
						key={i}
						contractDetails={e}
						pubDetails={allPublications.filter((pub) => pub.ss_publication_id === e.ss_publication_id)}
					/>
				</Box>
			))}
		</Box>
	);
};

export default ContractCards;
