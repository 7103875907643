import React, { useState } from 'react';
import {
  TextField,
  IconButton,
  List,
  ListItem,
  Box,
  Typography,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';

const ZipSearch = ({ onAccountSelect }) => {
  const [zipCode, setZipCode] = useState('');
  const [results, setResults] = useState([]);

  const handleZipChange = (event) => {
    setZipCode(event.target.value);
  };

  const handleSearch = () => {
    console.log('Searching for zip code:', zipCode);
    axios
      .post(`/crm/accounts/zip`, {}, { params: { zipCode: `${zipCode}` } })
      .then((response) => {
        setResults(response.data.search_results);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleResultClick = (account) => {
    console.log('Account selected:', account.account_number);
    onAccountSelect(account.account_number, true);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <TextField
          value={zipCode}
          onChange={handleZipChange}
          onKeyDown={handleKeyPress}
          label='Enter Zip Code'
          variant='outlined'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box sx={{ maxHeight: 300, overflow: 'auto', mt: 2 }}>
        <List>
          {results.map((result, index) => (
            <ListItem
              key={index}
              onClick={() => handleResultClick(result)}
              sx={{ borderBottom: '1px solid #eee' }}
            >
              <Box>
                <Typography variant='body1'>{result.account_list}</Typography>
                <Typography variant='body2' color='textSecondary'>
                  Sales Rep: {result.sales_rep}
                </Typography>
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default ZipSearch;
