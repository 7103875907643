import React, { useContext, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
	PublicationContextState,
	updateTriggerRefreshPublicationData,
} from '../../../Context/Marketing/PublicationContext';
import { getPublicationByPubId, insertOrUpdatePublication, getPublicationTypes } from '../Feed/PrimaryFeed';
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { PublicationFormSubmitAddButton, PublicationFormSubmitUpdateButton } from './PublicationFormSubmitButtons';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dayjs from 'dayjs';
import SnackBarAlert from './../../_GlobalComponents/SnackBarAlert';

// Primary component
const PublicationForm = () => {
	const { selectedPublicationId, updateSelectedPublicationId, updateTriggerRefreshPublicationData } =
		useContext(PublicationContextState);

	// LOCAL STATE
	const [allPubTypes, setAllPubTypes] = useState([]);
	const [snackOpen, setSnackOpen] = useState(false);

	const {
		control,
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm();

	const ResetOpen = () => {
		setSnackOpen(false);
	};

	const onSubmit = async (data) => {
		try {
			const returnedData = await insertOrUpdatePublication(data, selectedPublicationId);
			console.log('returnedData: ', returnedData);
			console.log('submitted data: ', data);
			//updateSelectedPublicationId(selectedPublicationId === 0 ? returnedData : selectedPublicationId);
			updateTriggerRefreshPublicationData();
			setSnackOpen(true);
		} catch (error) {
			console.log('error: ', error);
		}
	};

	useEffect(() => {
		const getAllPublicationTypes = async () => {
			setAllPubTypes(await getPublicationTypes());
		};

		const isBefore1970 = (date) => {
			const thresholdDate = new Date('1970-01-01');
			return date < thresholdDate ? null : Dayjs(date);
		};

		const fetchData = async () => {
			const data = await getPublicationByPubId(selectedPublicationId);
			console.log('data: ', data);
			setValue('ss_publication_code', data[0].ss_publication_code);
			setValue('ss_publication_type_id', data[0].ss_publication_type_id);
			setValue('description', data[0].description);
			setValue('detailed_description', data[0].detailed_description);
			setValue('submit_contract_date', isBefore1970(data[0].submit_contract_date));
			setValue('submit_art_date', isBefore1970(data[0].submit_art_date));
			setValue('production_start_date', isBefore1970(data[0].production_start_date));
			setValue('start_date', isBefore1970(data[0].start_date));
			setValue('expiration_date', isBefore1970(data[0].expiration_date));
		};
		selectedPublicationId > 0 && fetchData();
		getAllPublicationTypes();
	}, [selectedPublicationId, setValue]);

	return (
		<Box>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(12,1fr)', gap: '24px', mb: '24px' }}>
					<Controller
						fullWidth
						name='ss_publication_code'
						control={control}
						render={({ field }) => (
							<TextField
								sx={{ gridColumn: '1 / 4' }}
								fullWidth
								{...field}
								label='Publication Code'
								variant='outlined'
								error={!!errors.ss_publication_code}
								helperText={errors.ss_publication_code ? errors.ss_publication_code.message : ''}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						)}
					/>

					<Controller
						name='ss_publication_type_id'
						control={control}
						render={({ field }) => (
							<FormControl
								fullWidth
								variant='outlined'
								error={!!errors.ss_publication_type_id}
								sx={{ gridColumn: '4 / 7' }}
							>
								<InputLabel>Publication Type</InputLabel>
								<Select {...field} label='Publication Type' value={field.value || ''}>
									{allPubTypes.map((option) => (
										<MenuItem
											key={option.ss_publication_type_id}
											value={option.ss_publication_type_id}
										>
											{option.description}
										</MenuItem>
									))}
								</Select>
								{errors.ss_publication_type_id && (
									<FormHelperText>{errors.ss_publication_type_id.message}</FormHelperText>
								)}
							</FormControl>
						)}
					/>

					<Controller
						fullWidth
						name='description'
						control={control}
						render={({ field }) => (
							<TextField
								sx={{ gridColumn: '7 / 13' }}
								fullWidth
								{...field}
								label='Description'
								variant='outlined'
								error={!!errors.description}
								helperText={errors.description ? errors.description.message : ''}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						)}
					/>

					<Controller
						fullWidth
						name='detailed_description'
						control={control}
						render={({ field }) => (
							<TextField
								sx={{ gridColumn: '1 / 13' }}
								fullWidth
								{...field}
								label='Detailed Description'
								variant='outlined'
								error={!!errors.detailedDescription}
								helperText={errors.detailedDescription ? errors.detailedDescription.message : ''}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						)}
					/>
				</Box>
				<Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(10,1fr)', gap: '20px', mb: '24px' }}>
					<Controller
						name='submit_contract_date'
						control={control}
						render={({ field }) => (
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									{...field}
									label='Contract Due'
									sx={{ gridColumn: 'span 2' }}
									renderInput={(params) => <TextField {...params} fullWidth />}
								/>
							</LocalizationProvider>
						)}
					/>
					<Controller
						name='submit_art_date'
						control={control}
						render={({ field }) => (
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									{...field}
									label='Art Due'
									sx={{ gridColumn: 'span 2' }}
									renderInput={(params) => <TextField {...params} fullWidth />}
								/>
							</LocalizationProvider>
						)}
					/>

					<Controller
						name='production_start_date'
						control={control}
						render={({ field }) => (
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									{...field}
									label='Production Date'
									sx={{ gridColumn: 'span 2' }}
									renderInput={(params) => <TextField {...params} fullWidth />}
								/>
							</LocalizationProvider>
						)}
					/>

					<Controller
						name='start_date'
						control={control}
						render={({ field }) => (
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									{...field}
									label='Start Date'
									sx={{ gridColumn: 'span 2' }}
									renderInput={(params) => <TextField {...params} fullWidth />}
								/>
							</LocalizationProvider>
						)}
					/>

					<Controller
						name='expiration_date'
						control={control}
						render={({ field }) => (
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									{...field}
									label='Expiration Date'
									sx={{ gridColumn: 'span 2' }}
									renderInput={(params) => <TextField {...params} fullWidth />}
								/>
							</LocalizationProvider>
						)}
					/>
				</Box>
				<Box sx={{ gridColumn: 'span 12', textAlign: 'right' }}>
					{selectedPublicationId === 0 || selectedPublicationId === undefined ? (
						<PublicationFormSubmitAddButton onClick={handleSubmit(onSubmit)} />
					) : (
						<PublicationFormSubmitUpdateButton onClick={handleSubmit(onSubmit)} />
					)}
				</Box>
			</form>
			<SnackBarAlert doOpen={snackOpen} ResetOpen={ResetOpen} message='Data Updated!' />
		</Box>
	);
};

export default PublicationForm;
