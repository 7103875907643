import React from 'react';
import DashBoardTable from './DashBoardTable';

const DashBoard = () => {
	return (
		<div>
			DashBoard
			<DashBoardTable />
		</div>
	);
};

export default DashBoard;
