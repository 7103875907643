import React from 'react';

import Attribute from './Attribute';

const Attributes = ({ data }) => {
	console.log('data: ', data);
	return (
		<>
			{data.map((e, i) => (
				<Attribute key={i} info={e} />
			))}
		</>
	);
};

export default Attributes;
