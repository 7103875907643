import PublicationsTable from './components/PublicationsTable';
import { Box } from '@mui/material';
import PublicationDrawerUpdater from './components/PublicationDrawerUpdater';

const Publications = () => {
	return (
		<div>
			<Box sx={{ width: '100%', maxWidth: '1920px', mx: 'auto' }}>
				<PublicationsTable />
			</Box>
			<PublicationDrawerUpdater />
		</div>
	);
};

export default Publications;
