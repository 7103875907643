import React from 'react';

const ContactPhoneNumbers = ({ items }) => {
	const Content = ({ record_id, phone_type, phone_number }) => {
		return (
			<div>
				<div>
					<span style={{ color: '#333', fontWeight: 'bold' }}>{phone_type}</span>
					<span style={{ padding: '0 8px' }}>-</span>
					<span>{phone_number}</span>
				</div>
			</div>
		);
	};
	return (
		<div>
			{items.map((item, idx) => (
				<React.Fragment key={item.phone_record_id}>
					{item.phone_type === '---' ? (
						'---'
					) : (
						<Content
							record_id={item.phone_record_id}
							phone_type={item.phone_type}
							phone_number={item.phone_number}
						/>
					)}
				</React.Fragment>
			))}
		</div>
	);
};

export default ContactPhoneNumbers;
