import { Box } from '@mui/material';
import React from 'react';
import PublicationsTable from './components/v2/PublicationsTable';

const PublicationsV2 = () => {
	return (
		<Box sx={{ width: '100%', maxWidth: '1920px', mx: 'auto' }}>
			<PublicationsTable />
		</Box>
	);
};

export default PublicationsV2;
