import React from 'react';
import MarketingLayoutMenuItem from './MarketingLayoutMenuItem';
import ModalContacts from './ModalContacts';
import ModalEntities from './ModalEntities';
import { Box } from '@mui/material';
const data = [
	{
		name: 'pages',
		items: [
			{ id: 10, label: 'Contacts archive', path: '/marketing/contact/manage-contacts/all-contacts' },
			{ id: 20, label: 'Entities', path: '/marketing/entity/manage-entities/all-entities' },
			{ id: 30, label: 'Publications', path: '/marketing/publications' },
			{ id: 40, label: 'PublicationsV2', path: '/marketing/publications/v2' },

			{ id: 50, label: 'Contacts', path: '/marketing/entity-contacts/manage-entity-contacts' },
		],
	},
	{
		name: 'pagesArchive',
		itemsArchive: [
			{ id: 10, label: 'Contacts', path: '/marketing/contact/manage-contacts/all-contacts' },
			{ id: 20, label: 'Entities', path: '/marketing/entity/manage-entities/all-entities' },
			{ id: 30, label: 'Publications', path: '/marketing/publications' },
			{ id: 40, label: 'Entities V2', path: '/marketing/entity-contacts/manage-entities' },
			{ id: 50, label: 'Contacts V2', path: '/marketing/entity-contacts/manage-entity-contacts' },
		],
	},
];

const MarketingLayoutMenu = () => {
	return (
		<Box sx={{ display: 'flex', gap: '24px', justifyContent: 'flex-end' }}>
			{data
				.filter((e) => e.name === 'pages')
				.map((page) => page.items.map((item) => <MarketingLayoutMenuItem key={item.id} item={item} />))}
			{/* <ModalContacts />
			<ModalEntities /> */}
		</Box>
	);
};

export default MarketingLayoutMenu;
