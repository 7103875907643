import React from 'react';
import { Box, Container } from '@mui/material';
import MenuItems from './components/MenuItems';

const EditProductNav = () => {
	return (
		<Box sx={{ backgroundColor: 'white', py: 4 }}>
			<Container maxWidth='xl'>
				<MenuItems />
			</Container>
		</Box>
	);
};

export default EditProductNav;
