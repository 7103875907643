import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Box, IconButton, Typography } from '@mui/material';
import {
  MaterialReactTable,
  MRT_ToggleDensePaddingButton as MrtToggleDensePaddingButton,
  MRT_ToggleFiltersButton as MrtToggleFiltersButton,
  MRT_ToggleFullScreenButton as MrtToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton as MrtToggleGlobalFilterButton,
} from 'material-react-table';
import { Refresh } from '@mui/icons-material';
import ExcelExport from '../_GlobalComponents/ExcelExport';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../Redux/LoadingSlice';

const Badges = () => {
  const [badges, setBadges] = useState([]);
  const dispatch = useDispatch();

  const getBadges = useCallback(() => {
    dispatch(setIsLoading(true));
    axios
      .get('/badges/all')
      .then((response) => {
        setBadges(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  }, [dispatch]);

  useEffect(() => {
    getBadges();
  }, [getBadges]);

  const columns = [
    {
      header: 'Type',
      accessorKey: 'badge_type',
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Name',
      accessorFn: (row) => `${row.last_name}, ${row.first_name}`,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Job Title',
      accessorKey: 'job_title',
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Company Name',
      accessorKey: 'company_name',
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Email',
      accessorKey: 'email_address',
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Cell Phone #',
      accessorKey: 'phone_number',
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'City',
      accessorKey: 'city',
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'State',
      accessorKey: 'state',
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Mfr Party',
      accessorKey: 'poi',
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box
        sx={{
          p: 2,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          '& > .MuiPaper-root': {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            '& > .MuiTableContainer-root': {
              flexGrow: 1,
            },
          },
        }}
      >
        <MaterialReactTable
          columns={columns}
          data={badges}
          enableHiding={false}
          enableStickyHeader
          initialState={{
            showColumnFilters: false,
            density: 'compact',
            pagination: { pageIndex: 0, pageSize: 20 },
          }}
          positionToolbarAlertBanner='bottom'
          renderToolbarInternalActions={({ table }) => {
            return (
              <Box sx={{ whiteSpace: 'pre' }}>
                <MrtToggleGlobalFilterButton table={table} />
                <MrtToggleFiltersButton table={table} />
                <MrtToggleDensePaddingButton table={table} />
                <MrtToggleFullScreenButton table={table} />
                <IconButton tabIndex={0} type='button' aria-label='Refresh' onClick={getBadges}>
                  <Refresh />
                </IconButton>
              </Box>
            );
          }}
          muiTopToolbarProps={() => {
            return {
              sx: {
                // mb: 0.5,
                '& .MuiBox-root': {
                  alignItems: 'center',
                  // '&::after': {
                  //   content: '""',
                  //   position: 'absolute',
                  //   bottom: 0,
                  //   left: '0%',
                  //   width: '100%',
                  //   height: '1px',
                  //   backgroundColor: 'rgba(224, 224, 224, 1)',
                  // },
                },
              },
            };
          }}
          renderTopToolbarCustomActions={() => {
            const data = badges.map((badge) => {
              return {
                'Badge Id': badge.badge_id,
                'Badge Type': badge.badge_type,
                'First Name': badge.first_name,
                'Last Name': badge.last_name,
                'Job Title': badge.job_title,
                'Company Name': badge.company_name,
                'Email Address': badge.email_address,
                'Phone Number': badge.phone_number,
                City: badge.city,
                State: badge.state,
                Poi: badge.poi,
              };
            });

            return (
              <>
                <ExcelExport
                  data={data}
                  fileName={`badges-${dayjs().format('YYYY-MM-DD_HH-mm-ss')}`}
                />
              </>
            );
          }}
        />
      </Box>
    </>
  );
};

export default Badges;
