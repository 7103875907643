import { Modal, Paper } from '@mui/material';
import React from 'react';
import LookupMfgAssignedBuyerForm from './Components/LookupMfgAssignedBuyerForm';

const ManufacturerAssignedToBuyer = ({ open, onClose }) => {
  return (
    <Modal onClose={onClose} open={open} sx={{ overflowY: 'none' }}>
      <Paper sx={{ minHeight: '87vh',maxHeight: '90vh', mx: '1em', mt: '5em', top: '20'  }}>
        <LookupMfgAssignedBuyerForm />
      </Paper>
    </Modal>
  );
};

export default ManufacturerAssignedToBuyer;
