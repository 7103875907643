import React from 'react';
import { Box, styled, Typography, useTheme } from '@mui/material';

const Wrapper = styled(Box)(({ theme }) => ({
	backgroundColor: 'white',
	borderRadius: '20px',
	padding: '20px',
	boxShadow: `2px 2px 6px ${theme.palette.primary.main}55`,
	display: 'flex',
	flexDirection: 'column',
	gap: '16px',
}));

const ContainerAlpha = ({ children }) => {
	return <Wrapper>{children}</Wrapper>;
};

export default ContainerAlpha;
