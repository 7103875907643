import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Edit, RequestQuote } from '@mui/icons-material';
import { Box, Button, Tooltip, useTheme } from '@mui/material';
import { PublicationContextState } from '../../../Context/Marketing/PublicationContext';
import { blueGrey } from '@mui/material/colors';

const RowEditAction = ({ id, contractCount, codeValue, pubTitle }) => {
	const navigate = useNavigate();

	const { updateSelectedPublicationId, updatePublicationDrawerOpener, selectedPublicationId } =
		useContext(PublicationContextState);

	const handleClick = () => {
		updateSelectedPublicationId(id);
		updatePublicationDrawerOpener(true);
	};

	const handleContractClick = () => {
		console.log('contract count: ' + contractCount + ' | ' + id + ' | ' + pubTitle);
		navigate(`/marketing/publications/contracts/dashboard/${id}/${pubTitle}`);
	};

	return (
		<Box sx={{ display: 'flex', gap: '16px' }}>
			<Tooltip title={`Edit Publication ${id}`}>
				<Button
					variant='contained'
					sx={{ borderRadius: '14px' }}
					size='small'
					color={selectedPublicationId === id ? 'success' : 'warning'}
					startIcon={<Edit />}
					onClick={() => handleClick()}
				>
					<span style={{ fontWeight: 'bold' }}>Edit</span>
				</Button>
			</Tooltip>

			<Tooltip title={`Contract Count ${contractCount}`}>
				<Button
					variant='contained'
					sx={{ borderRadius: '14px', backgroundColor: contractCount === 0 ? blueGrey[200] : 'primary' }}
					// disabled={contractCount === 0}

					size='small'
					startIcon={<RequestQuote />}
					onClick={handleContractClick}
				>
					<span style={{ fontWeight: 'bold' }}>({contractCount})</span>
				</Button>
			</Tooltip>
		</Box>
	);
};

export default RowEditAction;
