import { useSelector, useDispatch } from 'react-redux';
import { PDMSnackBarOpenValue, PDMSnackBarMessageValue } from './../../../Redux/PDMSlice';
// MUI
import { Alert, Snackbar } from '@mui/material';

// Primary Component
const PDMSnackBar = ({ severity, message }) => {
  const dispatch = useDispatch();

  const PDMSnackBarMessage = useSelector((state) => state.pdmSlice.PDMSnackBarMessage);
  const PDMSnackBarSeverty = useSelector((state) => state.pdmSlice.PDMSnackBarSeverty);
  const PDMSnackBarOpen = useSelector((state) => state.pdmSlice.PDMSnackBarOpen);

  const handleClose = () => {
    dispatch(PDMSnackBarOpenValue(false));
    dispatch(PDMSnackBarMessageValue(''));
  };

  return (
    <Snackbar open={PDMSnackBarOpen} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert onClose={handleClose} severity={PDMSnackBarSeverty ? PDMSnackBarSeverty : 'success'} sx={{ width: '100%' }}>
        {PDMSnackBarMessage}
      </Alert>
    </Snackbar>
  );
};
export default PDMSnackBar;
