import React from 'react';
import { Button } from '@mui/material';
export const PublicationFormSubmitUpdateButton = ({ onClick }) => {
	return (
		<Button type='submit' variant='contained' color='success' onClick={onClick}>
			Update Publication
		</Button>
	);
};

export const PublicationFormSubmitAddButton = ({ onClick }) => {
	return (
		<Button type='submit' variant='contained' color='success' onClick={onClick}>
			Add Publication
		</Button>
	);
};
