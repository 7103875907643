import { Switch } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { updateInactiveInternalUseOnly } from '../Feed/PrimaryFeed';

const InternalPublicaitonActionButton = ({ isChecked, publicationId }) => {
	const [checked, setChecked] = useState(false);
	//const { updateContactsActiveStatus } = useContext(EntityContextState);

	const handleChange = (e) => {
		setChecked(!checked);
		const data = {
			field_name: 'internal_use_only',
			ss_publication_id: publicationId,
			value: !checked,
		};
		updateInactiveInternalUseOnly(data);
	};

	useEffect(() => {
		setChecked(isChecked);
	}, [isChecked]);

	return (
		<Switch
			checked={checked}
			onChange={(e) => handleChange(e)}
			color='success'
			inputProps={{ 'aria-label': 'controlled' }}
		/>
	);
};

export default InternalPublicaitonActionButton;
