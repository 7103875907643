import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DropdownSelect from './Components/DropdownSelect';
import {
  Box,
  Grid,
  Tabs,
  Tab,
  Typography,
  Paper,
  ToggleButtonGroup,
  ToggleButton,
  Snackbar,
  BottomNavigationAction,
  BottomNavigation,
  Modal,
} from '@mui/material';
import AccountDetail from './Components/AccountDetail';
import { useTheme } from '@emotion/react';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddNote from './Components/AddNote';
import Notes from './Components/Notes';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MuiAlert from '@mui/material/Alert';
import AddLead from './Components/AddLead';
import LeadDetail from './Components/LeadDetail';
import ZipSearch from './Components/ZipSearch';
import Reps from './Components/Reps';
import PeopleIcon from '@mui/icons-material/People';
import { useDispatch } from 'react-redux';
import { setRepInfo } from '../../Redux/RepSlice';

const AccountNotes = () => {
  const [accounts, setAccounts] = useState([]);
  const [reps, setReps] = useState([]);
  const [leads, setLeads] = useState([]);
  const [notes, setNotes] = useState([]);
  const [selectedRep, setSelectedRep] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState([]);
  const [selectedLead, setSelectedLead] = useState([]);
  const [value, setValue] = useState(0);
  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [drawerMenuSelection, setDrawerMenuSelection] = useState('accounts');
  const [open, setOpen] = useState(false);
  const [bottomNavValue, setBottomNavValue] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLeadModalOpen, setIsLeadModalOpen] = useState(false);
  const [showReps, setShowReps] = useState(false);

  const theme = useTheme();

  const userID = useSelector((state) => state.userInformation.value.userID);
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get('/crm/repDetails')
      .then((response) => {
        dispatch(setRepInfo(response.data));
      })
      .catch((error) => {
        console.error('Error fetching reps:', error);
      });
  }, [dispatch]);

  const handleBottomNavChange = (event, newValue) => {
    setBottomNavValue(newValue);
    switch (newValue) {
      case 0:
        // Logic for selecting accounts
        setShowReps(false);
        toggleDrawer();
        break;
      case 1:
        // Logic for adding a note
        if (selectedAccount && selectedAccount.key) {
          setShowReps(false);
          toggleAddNoteModal();
        } else {
          setSnackbarOpen(true);
        }
        break;
      case 2:
        // Logic for adding a lead
        setShowReps(false);
        toggleLeadModal();
        break;
      case 3:
        // Logic for selecting reps
        setShowReps(true); // Show the Reps component
        break;
      default:
        break;
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const toggleAddNoteModal = () => {
    setIsAddNoteModalOpen(!isAddNoteModalOpen);
  };

  const toggleLeadModal = () => {
    setIsLeadModalOpen(!isLeadModalOpen);
  };

  //Get Sales Reps
  useEffect(() => {
    axios
      .post(`/crm/salesReps`)
      .then((response) => {
        const data = response.data.search_results;
        const formattedReps = data.map((rep) => ({
          ...rep,
          key: rep.account_number,
          label: rep.account_name,
        }));
        setReps(formattedReps);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleRepSelection = (selectedRep) => {
    if (selectedRep) {
      setSelectedRep(selectedRep);
      setSelectedAccount([]);
      setSelectedLead([]);
      const salesNumber = selectedRep.key;

      // Fetch Accounts
      axios
        .post(`/crm/accounts`, {}, { params: { salesNumber: salesNumber } })
        .then((accountsResponse) => {
          console.log('Accounts Response:', accountsResponse.data);
          if (accountsResponse.data && Array.isArray(accountsResponse.data.search_results)) {
            const accountsData = accountsResponse.data.search_results.map((account) => ({
              key: account.account_number,
              label: account.account_list,
            }));
            setAccounts(accountsData);
          } else {
            console.error('Error:', accountsResponse.data);
            setAccounts([]);
          }
        })
        .catch((error) => {
          console.error('Error fetching accounts:', error);
          setAccounts([]);
        });

      // Fetch Leads
      axios
        .get(`/crm/leads`, { params: { salesNumber: salesNumber } })
        .then((leadsResponse) => {
          console.log('Leads Response:', leadsResponse.data);
          if (leadsResponse.data && Array.isArray(leadsResponse.data)) {
            const leadsData = leadsResponse.data.map((lead) => ({
              ...lead,
              key: lead.lead_id,
              label: lead.company_name,
            }));
            setLeads(leadsData);
          }
        })
        .catch((error) => {
          console.error('Error fetching leads:', error);
          if (error.response && error.response.status === 404) {
            setLeads([]);
          }
        });
    } else {
      setSelectedRep(null);
      setAccounts([]);
      setLeads([]);
    }
  };

  const handleAccountSelection = (account, getInfo) => {
    setSelectedLead([]);
    if (getInfo === true) {
      handleFetchSingleAccount(account);
      toggleDrawer();
    } else {
      setSelectedAccount(account);
      console.log('Selected Account:', account);
      toggleDrawer();
      console.log('Account Notes Has Received Account Selection Event:', account);
    }
  };

  const handleLeadSelection = (lead) => {
    setSelectedAccount([]);
    setSelectedLead(lead);
    console.log('Selected Lead:', lead);
    toggleDrawer();
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDrawerMenu = (event, newSelection) => {
    setDrawerMenuSelection(newSelection);
  };

  const handleFetchSingleAccount = (accountNumber) => {
    axios
      .post(`/crm/accountInfo/`, {}, { params: { accountNumber: accountNumber } })
      .then((response) => {
        console.log('Account Details:', response.data);
        //set selected account key and label
        setSelectedAccount({
          key: response.data.account_number,
          label: response.data.account_name,
        });
      })
      .catch((error) => {
        console.error('Error fetching account details:', error);
      });
  };

  return (
    <Box sx={{ pb: 7, maxHeight: '100vh', overflow: 'auto' }}>
      {/* Snackbar Component */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          minWidth: '100vw',
        }}
        color='warning'
      >
        <MuiAlert elevation={6} variant='filled' severity='warning' onClose={handleSnackbarClose}>
          Please select an account first.
        </MuiAlert>
      </Snackbar>
      {/* Drawer Component */}
      <Modal
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{
          display: 'flex',
          alignItems: 'start',
          justifyContent: 'center',
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: theme.spacing(3),
            borderRadius: '4px',
            minWidth: '300px',
            maxWidth: '90%',
          }}
        >
          <Grid container spacing={2} justifyContent='center' alignItems='center'>
            <Grid item xs={12}>
              <ToggleButtonGroup
                value={drawerMenuSelection}
                exclusive
                onChange={handleDrawerMenu}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <ToggleButton
                  value='accounts'
                  sx={{
                    backgroundColor: 'default-color',
                    '&:hover': {
                      backgroundColor: 'hover-color',
                    },
                    '&.Mui-selected': {
                      backgroundColor: theme.palette.primary.main,
                      color: 'white',
                      '&:hover': {
                        backgroundColor: theme.palette.primary.main,
                      },
                    },
                  }}
                >
                  Accounts
                </ToggleButton>
                <ToggleButton
                  value='leads'
                  sx={{
                    backgroundColor: 'default-color',
                    '&:hover': {
                      backgroundColor: 'hover-color',
                    },
                    '&.Mui-selected': {
                      backgroundColor: theme.palette.primary.main,
                      color: 'white',
                      '&:hover': {
                        backgroundColor: theme.palette.primary.main,
                      },
                    },
                  }}
                >
                  Leads
                </ToggleButton>
                <ToggleButton
                  value='zipSearch'
                  sx={{
                    backgroundColor: 'default-color',
                    '&:hover': {
                      backgroundColor: 'hover-color',
                    },
                    '&.Mui-selected': {
                      backgroundColor: theme.palette.primary.main,
                      color: 'white',
                      '&:hover': {
                        backgroundColor: theme.palette.primary.main,
                      },
                    },
                  }}
                >
                  Zip Search
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12}>
              {drawerMenuSelection !== 'zipSearch' && (
                <DropdownSelect
                  data={reps}
                  placeholder='Sales Rep'
                  onChange={handleRepSelection}
                  value={selectedRep}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {drawerMenuSelection === 'accounts' && (
                <DropdownSelect
                  data={accounts}
                  placeholder='Select Account'
                  onChange={handleAccountSelection}
                  value={selectedAccount}
                />
              )}
              {drawerMenuSelection === 'leads' && (
                <DropdownSelect
                  data={leads}
                  placeholder='Select Lead'
                  onChange={handleLeadSelection}
                  value={selectedLead}
                />
              )}
              {drawerMenuSelection === 'zipSearch' && (
                <ZipSearch onAccountSelect={handleAccountSelection} />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Modal>
      <Box sx={{ mb: 1 }} />
      {showReps ? (
        <Reps />
      ) : (
        <>
          {(selectedAccount && selectedAccount.key) ||
          (selectedLead && selectedLead.ss_crm_lead_id) ? (
            <>
              <Tabs value={value} onChange={handleTabChange} centered>
                <Tab label='Details' />
                <Tab label='Notes' />
              </Tabs>
              {value === 0 && selectedAccount && selectedAccount.key && (
                <AccountDetail account={selectedAccount} />
              )}
              {value === 0 && selectedLead && selectedLead.ss_crm_lead_id && (
                <LeadDetail account={selectedLead} />
              )}

              {value === 1 && <Notes accNumber={selectedAccount.key} />}
            </>
          ) : (
            <Typography variant='h6' sx={{ textAlign: 'center' }}>
              Please select an account or lead.
            </Typography>
          )}
          {/* Modals for 'View Details' and 'Add Lead' */}
          <AddNote
            open={isAddNoteModalOpen}
            handleClose={toggleAddNoteModal}
            accountNumber={selectedAccount && selectedAccount.key}
            accountName={selectedAccount && selectedAccount.label}
          />
          <AddLead open={isLeadModalOpen} handleClose={toggleLeadModal} />
        </>
      )}
      <Paper
        sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: theme.zIndex.speedDial }}
        elevation={3}
      >
        <BottomNavigation
          value={bottomNavValue}
          onChange={handleBottomNavChange}
          showLabels
          sx={{
            backgroundColor: theme.palette.primary.main,
            '& .MuiBottomNavigationAction-root': {
              color: 'white',
              '& .MuiSvgIcon-root': {
                color: 'white',
              },
            },
            '& .Mui-selected': {
              '& .MuiBottomNavigationAction-root': {
                color: 'white',
                '& .MuiSvgIcon-root': {
                  color: 'white',
                },
              },
            },
            '& .MuiBottomNavigationAction-label': {
              '&.Mui-selected': {
                color: 'white',
                fontSize: '0.7rem',
              },
            },
          }}
        >
          <BottomNavigationAction label='Accounts' icon={<AccountBoxIcon />} />
          <BottomNavigationAction label='Add Note' icon={<NoteAddIcon />} />
          <BottomNavigationAction label='Add Lead' icon={<PersonAddIcon />} />
          <BottomNavigationAction label='Reps' icon={<PeopleIcon />} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
};

export default AccountNotes;
