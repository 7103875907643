import React, { useContext } from 'react';
import { EntityContextState } from '../../../../../../../Context/Entity/EntityContext';
import { EntityContactContextState } from '../../../../../../../Context/Marketing/EntityContactContext';
import axios from 'axios';
import { DeleteForever } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const DeleteAssociation = ({ identifier }) => {
	// CONTEXT DATA
	const { updateTriggerRefreshContactAssociationList } = useContext(EntityContextState);
	const { setAllAssociationLink } = useContext(EntityContactContextState);

	const handleClick = () => {
		const deleteFunc = async () => {
			try {
				const response = await axios.post('/entity/deleteContactEntityRecord', {
					entity_contact_link_id: identifier,
				});
				updateTriggerRefreshContactAssociationList();
				setAllAssociationLink();
			} catch (error) {
				console.error('Error:', error);
			}
		};

		deleteFunc();
	};

	return (
		<IconButton onClick={() => handleClick()}>
			<DeleteForever color='error' />
		</IconButton>
	);
};

export default DeleteAssociation;
