import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function PageTitle() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => {
    switch (location.pathname) {
      case '/calendar':
        setPageTitle('Calendar');
        document.title = 'Calendar';
        break;
      case '/image-library':
        setPageTitle('Image Library');
        document.title = 'Image Library';
        break;
      case '/email-builder':
        setPageTitle('Email Builder');
        document.title = 'Email Builder';
        break;
      case '/crm':
        setPageTitle('Customer Relationship Management');
        document.title = 'CRM';
        break;
      case '/account-notes':
        setPageTitle('Account Notes');
        document.title = 'Account Notes';
        break;
      case '/pdm/current-attributes-drawer-demo':
        setPageTitle('Product Data Management');
        document.title = 'Product Data Management';
        break;
      case '/entity-manager/entity-updater':
        setPageTitle('Entity Manager');
        break;
      case '/advertising/':
        setPageTitle('Advertising Manager');
        break;
      case '/publish-items':
        setPageTitle('Publish Items');
        break;
      case '/sales-promotions':
        setPageTitle('Sales Promotions');
        document.title = 'Sales Promotions';
        break;
      case '/image-management':
        setPageTitle('Image Management');
        document.title = 'Image Management';
        break;
      case '/entity/manage-entities':
        setPageTitle('Entity Management');
        document.title = 'Entity Management';
        break;
      case '/entity/manage-contacts':
        setPageTitle('Entity Contacts');
        document.title = 'Entity Contacts';
        break;
      case '/publications':
        setPageTitle('Publication Management');
        document.title = 'Publication Management';
        break;
      default:
        setPageTitle('');
        document.title = 'Sports South Hub';
        break;
    }
  }, [location]);

  return pageTitle;
}

export default PageTitle;
