import { Card, Grid, CardActionArea } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import WebPaymentReceipts from './WebPaymentReceipts/WebPaymentReceipts';

const Payments = () => {
  const [isWebPaymentReceiptsModalOpen, setIsWebPaymentReceiptsModalOpen] = useState(false);
  const userPermission = useSelector((state) => state.userPermission.value);
  return (
    <Grid container spacing={2}>
      {userPermission[962] &&
      <Grid item xs={3}>        
        <CardActionArea>
          <Card onClick={() => setIsWebPaymentReceiptsModalOpen(true)} sx={{ p:3, textAlign:'center' }}>WEB PAYMENT RECEIPTS</Card>
        </CardActionArea>
      </Grid>}
      
      <WebPaymentReceipts
        open={isWebPaymentReceiptsModalOpen}
        onClose={() => setIsWebPaymentReceiptsModalOpen(false)}
      />
    </Grid>
  );
};

export default Payments;
