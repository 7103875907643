import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

const PromotionDropdown = ({ label, options, onChange }) => {
  return (
    <Autocomplete
      multiple
      id={`autocomplete-${label}`}
      options={options}
      getOptionLabel={(option) => option.label}
      filterSelectedOptions
      renderInput={(params) => <TextField {...params} variant='outlined' label={label} />}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
    />
  );
};

export default PromotionDropdown;
