import React, { useState } from 'react';
export const ProductDataContextState = React.createContext({
	food: [],
	alertOne: () => {},
});

const ProductDataContext = ({ children }) => {
	const [fruit, setFruit] = useState(['apple']);

	const alertOne = () => alert(fruit[0]);

	const contextValue = {
		food: fruit,
		alertOne,
	};

	return <ProductDataContextState.Provider value={contextValue}>{children}</ProductDataContextState.Provider>;
};

export default ProductDataContext;
