import React from 'react';

import { Box, styled } from '@mui/material';

// Material Styled Components
const Wrapper = styled(Box)(({ theme }) => ({
	color: theme.palette.primary.main,
	fontWeight: 'bold',
	fontSize: '2.1em',
	marginBottom: 20,
	borderBottom: `1px solid ${theme.palette.primary.main} `,
}));

const TabBar = () => {
	return <Wrapper></Wrapper>;
};

export default TabBar;
