import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Marketing from '../Components/Marketing/Marketing';
import MarketingLayout from '../Components/Marketing/component/MarketingLayout/MarketingLayout';
import Publications from '../Components/Publications/Publications';
import EntityContext from '../Context/Entity/EntityContext';
import ManageContacts from '../Components/Entities/components/ManageContacts/ManageContacts';
import ManageEntities from '../Components/Entities/components/ManageEntities/ManageEntities';
import Entities from '../Components/Entities/Entities';
import DashBoard from '../Components/Contracts/components/DashBoard/DashBoard';
import PublicationContext from '../Context/Marketing/PublicationContextV2';
import MarketingContext from '../Context/Marketing/MarketingContext';
import ContractsByBrandId from '../Components/Contracts/components/ManageContracts/components/ContractsByBrandId';
import ContractCards from '../Components/Contracts/components/ManageContracts/components/ContractCards/ContractCards';
import ContactContext from '../Context/Entity/EntityContacts/EntityContactContext';
import EntityContactContext from '../Context/Marketing/EntityContactContext';
import EntContext from '../Context/Marketing/EntityContext';
import EntityContacts from '../Components/EntityContacts/EntityContacts';
//import ContractContext from '../Context/Contracts/ContractContext';
import ContractContext from '../Context/Marketing/ContractContext';
import EntitiesV2 from '../Components/Entities/EntitiesV2';
import EntitiesV2Context from '../Context/Marketing/EntitiesV2Context';
import PublicationsV2 from '../Components/Publications/PublicationsV2';

const MarketingRoutes = () => {
	const userPermission = useSelector((state) => state.userPermission.value);

	return (
		<MarketingContext>
			{/* <MarketingLayout> */}
			<EntityContext>
				<EntContext>
					<ContactContext>
						<EntityContactContext>
							<PublicationContext>
								<ContractContext>
									<MarketingLayout>
										<Routes>
											{/* ENTITY ROUTES */}
											{userPermission[966] && (
												<Route
													path='/entity/manage-entities/all-entities'
													element={<ManageEntities />}
												/>
											)}
											{/* <Route
												path='/entity-contacts/manage-entities'
												element={
													<EntitiesV2Context>
														<EntitiesV2 />
													</EntitiesV2Context>
												}
											/> */}
											{/* <Route path='/entity/*' element={<h1>Entity</h1>} /> */}

											{/* CONTACT ROUTES */}
											{userPermission[965] && (
												<Route
													path='/contact/manage-contacts/all-contacts'
													element={<ManageContacts />}
												/>
											)}
											{userPermission[965] && (
												<Route
													path='/entity-contacts/manage-entity-contacts'
													element={
														<EntitiesV2Context>
															<EntityContacts />
														</EntitiesV2Context>
													}
												/>
											)}
											{/* <Route path='/contact/manage-contacts/contact/:id' element={<ManageContacts />} /> */}

											{/* CONTRACT ROUTES */}
											<Route path='/contract/dashboard/:id/:title' element={<DashBoard />} />
											<Route path='/contract/view/:ids' element={<ContractCards />} />
											<Route
												path='/contracts/entity-contracts/:entityId/:subBrandId'
												element={<ContractsByBrandId />}
											/>
											{/* PUBLICATION ROUTES */}
											<Route
												path='/publications/contracts/dashboard/:id/:title'
												element={<DashBoard />}
											/>
											<Route path='/publications/v2' element={<PublicationsV2 />} />
											<Route path='/publications/*' element={<Publications />} />
											<Route path='/*' element={<Marketing />} />
										</Routes>
									</MarketingLayout>
								</ContractContext>
							</PublicationContext>
						</EntityContactContext>
					</ContactContext>
				</EntContext>
			</EntityContext>
			{/* </MarketingLayout> */}
		</MarketingContext>

		// <ManageContacts>
		// 	<MarketingLayout>
		// 		{/* ENTITY ROUTES */}

		// 		<Routes>
		// 			{userPermission[965] && (
		// 				<Route
		// 					path='/entity/manage-contacts'
		// 					element={
		// 						<EntityContext>
		// 							<ManageContacts />
		// 						</EntityContext>
		// 					}
		// 				/>
		// 			)}
		// 			{userPermission[966] && (
		// 				<Route
		// 					path='/entity/manage-entities'
		// 					element={
		// 						<EntityContext>
		// 							<ManageEntities />
		// 						</EntityContext>
		// 					}
		// 				/>
		// 			)}
		// 			<Route
		// 				path='/manage-contacts/contact/:id'
		// 				element={
		// 					<EntityContext>
		// 						<ManageContacts />
		// 					</EntityContext>
		// 				}
		// 			/>
		// 			{/* <Route path='/products/product-details/:id' element={<ProductDetails />} /> */}
		// 			{/* <Route path='/entity/*' element={<Entities />} /> */}
		// 			<Route
		// 				path='/contracts/dashboard/:id/:title'
		// 				element={
		// 					<EntityContext>
		// 						<DashBoard />
		// 					</EntityContext>
		// 				}
		// 			/>

		// 			<Route
		// 				path='/publications/contracts/dashboard/:id/:title'
		// 				element={
		// 					<PublicationContext>
		// 						<DashBoard />
		// 					</PublicationContext>
		// 				}
		// 			/>
		// 			<Route
		// 				path='/publications/*'
		// 				element={
		// 					<PublicationContext>
		// 						<Publications />
		// 					</PublicationContext>
		// 				}
		// 			/>
		// 		</Routes>
		// 	</MarketingLayout>
		// </ManageContacts>
	);
};

export default MarketingRoutes;
