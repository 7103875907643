export const MainTheme = {
	palette: {
		mode: 'light',
		primary: {
			main: '#266391',
			light: '#699fc6',
			alt: '#0469a8',
			mainScreen: '#f6fafe', //'#e6f2fc',
			lightScreen: '#e1eaf1',
			mediumGray: '#6c757d',
		},
		secondary: {
			main: '#f44902',
		},
		gray: {
			xtraLight: '#f8f9fa', // very light gray
			200: '#e9ecef',
			light: '#dee2e6',
			400: '#ced4da',
			//medium: '#adb5bd', // medium gray
			medium: '#6c757d',
			dark: '#495057',
			extraDark: '#343a40',
			//extraDark: '#212529', // very dark gray
		},

		background: {
			default: '#fbfbfb',
		},
		text: {
			primary: 'rgba(35,34,34,0.87)',
		},
		save: {
			main: '#2E7D32',
		},
		delete: {
			main: '#D32F2F',
		},
	},
	typography: {
		fontFamily: 'Lato',
	},
	deminsions: {
		maxWidth: '1600px',
	},
	elements: {
		borderColor: '#e6f2fc',
	},
};
