import React, { useEffect, useState } from 'react';
import axios from 'axios';

export const ProductDetailsContextState = React.createContext({
	productId: 0, // THE PRODUCT ID FOR THE ITEM TO GET DETAILS ON
	productsAttributesAndValuesOrig: [], // THIS STORES ALL THE ORIGNAL DATA THAT CAME BACK FOR THE ITEMS ATTRIBUTES AND VALUES
	productsAttributesAndValues: [], // THIS STORES ALL THE AGGREGATED DATA
});

const ProductDetailsContext = ({ children, activeProductId }) => {
	const [activeProductIdState, setActiveProductIdState] = useState(0);
	const [productsAttributesAndValuesOrigState, setProductsAttributesAndValuesOrigState] = useState([]);
	const [productsAttributesAndValuesState, setProductsAttributesAndValuesState] = useState([]);

	useEffect(() => {
		setActiveProductIdState(activeProductId);
	}, [activeProductId]);

	useEffect(() => {
		let tempAttr = [];
		const fetchData = async () => {
			try {
				const response = await axios.get(`/pdm/getAttributesProductIdStandardPendingData/${activeProductId}`);
				setProductsAttributesAndValuesOrigState(response.data);
				response.data.forEach((row) => {
					!tempAttr.find((r) => r.id === row.product_facet_id) &&
						tempAttr.push({ id: row.product_facet_id, label: row.attribute, value: [] });
				});
				response.data.forEach((row) => {
					tempAttr
						.find((r) => r.id === row.product_facet_id)
						.value.push({
							rowId: row.id,
							opitonId: row.product_facet_option_id,
							label: row.attribute_value,
							type: row.type,
						});
				});
			} catch (error) {
				console.log('DataSet error: ', error);
			}
			setProductsAttributesAndValuesState([...tempAttr]);
			console.log('tempAttr: ', tempAttr);
		};
		activeProductId > 0 && fetchData();
	}, [activeProductId]);

	const contextValue = {
		productId: activeProductIdState,
		productsAttributesAndValuesOrig: [...productsAttributesAndValuesOrigState],
		productsAttributesAndValues: [...productsAttributesAndValuesState],
	};

	console.log('activeProductId: ' + activeProductId);
	return <ProductDetailsContextState.Provider value={contextValue}>{children}</ProductDetailsContextState.Provider>;
};

export default ProductDetailsContext;
