import React, { useContext, useEffect, useState } from 'react';
import { EntityContactContextState } from '../../../../../Context/Marketing/EntityContactContext';
import { Diversity3 } from '@mui/icons-material';
import { IconButton, Modal } from '@mui/material';
import Associations from '../../EditContactMenu/components/Associations';

const ButtonToModalAssociations = ({ contactId }) => {
	// CONTEXT STATE
	const { allAssociationLink } = useContext(EntityContactContextState);

	// LOCAL STATE
	const [modalOpen, setModalOpen] = useState(false);

	// FUNCTIONS
	const handleMenuItemClose = () => {
		setModalOpen(false);
	};

	return (
		<>
			<IconButton onClick={() => setModalOpen(true)}>
				<Diversity3
					color={allAssociationLink.find((e) => e.entity_contact_id === contactId) ? 'warning' : 'disabled'}
				/>
			</IconButton>
			{/* MODAL */}
			<Modal
				open={modalOpen}
				onClose={() => handleMenuItemClose()}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				slotProps={{
					backdrop: {
						sx: {
							backdropFilter: 'blur(10px)',
							backgroundColor: 'rgba(255,255,255,.5)', // Change the backdrop color here
						},
					},
				}}
			>
				<div>
					<Associations contactId={contactId} />
				</div>
			</Modal>
		</>
	);
};

export default ButtonToModalAssociations;
