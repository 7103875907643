import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import PhoneEditCard from '../../PhoneEditCard';

const Phone = ({ allPhoneNumbersData, contactId }) => {
	// LOCAL STATE
	const [loading, setLoading] = useState(true);
	const [allPhoneNumbersDataState, setAllPhoneNumbersDataState] = useState([]);

	useEffect(() => {
		try {
			setAllPhoneNumbersDataState([...allPhoneNumbersData]);
			setLoading(false);
		} catch (error) {
			console.log('error: ', error);
		}
	}, [allPhoneNumbersData]);

	if (loading) {
		return (
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				<CircularProgress />
			</Box>
		);
	}

	const newPhoneNumberForm = () => {
		setAllPhoneNumbersDataState((prev) => [
			...prev,
			{
				entity_contact_phone_number_id: 0,
			},
		]);
	};
	console.log('allPhoneNumbersDataState length: ' + allPhoneNumbersDataState.length);

	return (
		<Box
			sx={{
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%,-50%)',
				width: `${allPhoneNumbersDataState.length * 365}px`,
				maxWidth: '90%',
				minWidth: '300px',
			}}
		>
			<Box sx={{ textAlign: 'center', mb: '24px' }}>
				<Button variant='contained' color='success' onClick={() => newPhoneNumberForm()}>
					Add Phone Number?
				</Button>
			</Box>
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					flexWrap: 'wrap',
					gap: '24px',
					justifyContent: 'center',
				}}
			>
				{allPhoneNumbersDataState.map((phone) => (
					<React.Fragment key={phone.entity_contact_phone_number_id}>
						<PhoneEditCard phoneId={phone.entity_contact_phone_number_id} contactId={contactId} />
					</React.Fragment>
				))}
			</Box>
		</Box>
	);
};

export default Phone;
