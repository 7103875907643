import { createSlice } from '@reduxjs/toolkit';

export const LoadingSlice = createSlice({
  name: 'loadingSlice',
  initialState: {
    value: false,
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setIsLoading } = LoadingSlice.actions;
export default LoadingSlice.reducer;
