import React, { useContext, useEffect, useState } from 'react';
import { PublicationContextState } from '../../../Context/Marketing/PublicationContext';
import { Box, Button, Drawer, MenuItem, TextField, useTheme } from '@mui/material';
import PublicationForm from './PublicationForm';
import ContainerAlpha from '../../Entities/components/ContainerAlpha';
import ContainerTitle from '../../Entities/components/ContainerTitle';

const PublicationDrawerUpdater = () => {
	// INIT
	const theme = useTheme();

	// LOCAL STORAGE

	// CONTEXT STORAGE AND FUNCTIONS
	const { publicationDrawerOpener, updatePublicationDrawerOpener, selectedPublicationId } =
		useContext(PublicationContextState);

	return (
		<Drawer open={publicationDrawerOpener} anchor='right' onClose={() => updatePublicationDrawerOpener(false)}>
			<Box
				sx={{
					maxWidth: '1600px',
					minWidth: '1600px',
					height: '100%',
					backgroundColor: '#fcfcfc',
					overflowX: 'hidden',
					overflowY: 'auto',
					padding: '32px',
				}}
			>
				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: 'repeat(12, 1fr)',
						gridTemplateRows: 'auto auto',
						gap: '24px',
					}}
				>
					<Box sx={{ gridColumn: 'span 12', minHeight: '400px' }}>
						<ContainerAlpha>
							<Box>
								<ContainerTitle title='Production Info:' />
							</Box>
							<PublicationForm />
						</ContainerAlpha>
					</Box>
				</Box>
			</Box>
		</Drawer>
	);
};

export default PublicationDrawerUpdater;
