import CategoryAttributeSwitchFilter from './CategoryAttributeSwitchFilter';

import { Grid } from '@mui/material';

const ValueCorrections = () => {
  return (
    <Grid container sx={{ height: '100%', overflow: 'hidden', backgroundColor: 'teal' }}>
      <Grid item style={{ width: 300, border: 'solid 1px teal', padding: 15, height: '100%', overflow: 'auto', backgroundColor: 'white' }}>
        <CategoryAttributeSwitchFilter />
      </Grid>
      <Grid item>Right Side</Grid>
    </Grid>
  );
};
export default ValueCorrections;
