import { Modal, Paper } from '@mui/material';
import React from 'react';
import EdiMfgForm from './Components/EdiMfgForm';

const EdiMfg = ({ open, onClose }) => {
  return (
    <Modal onClose={onClose} open={open} sx={{ overflowY: 'none', minHeight: '40em', maxHeight: '40em', display: 'flex',  justifyContent: 'center'}} >
      <Paper sx={{ mx: '1em', mt: '5em', top: '20', width: '50%' }}>
        <EdiMfgForm />
      </Paper>
    </Modal>
  );
};

export default EdiMfg;
