import axios from 'axios';
import Dayjs from 'dayjs';

// GET PUBLICATIONS BY PUBID
export const getPublicationTypes = async () => {
	try {
		const response = await axios.get(`/advertising/getAllPublicationTypes`);
		return [...response.data];
	} catch (error) {
		console.error('Error fetching data:', error);
		return [];
	}
};
