import { Grid } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import ResolutionContainer from './Components/ResolutionContainer';
import SearchBox from './Components/SearchBox';

const ImageLibrary = ({ pageTitle }) => {
  const [imageInfo, setImageInfo] = useState([]);
  const [userInput, setUserInput] = useState('');

  const handleInput = (e) => {
    setUserInput(e.target.value);
  };

  const handlePicRefEnterSubmit = (e) => {
    if (e.key === 'Enter' && userInput !== '') {
      axios.get(`/imagelibrary/checkpicref/${userInput}`).then((res) => {
        if (res.data.error) {
          alert(res.data.error);
        } else {
          // Extract the nested arrays from the response
          const { small, large, hires, thumbnail } = res.data['48586'];

          // Pass the extracted arrays to setImageInfo
          setImageInfo({ small, large, hires, thumbnail });
          console.log(res.data);
        }
      });
    }
  };

  const handlePicRefClickSubmit = () => {
    if (userInput !== '') {
      axios.get(`/imagelibrary/checkpicref/${userInput}`).then((res) => {
        if (res.data.error) {
          alert(res.data.error);
        } else {
          // Extract the nested arrays from the response
          const { small, large, hires, thumbnail } = res.data['48586'];

          // Pass the extracted arrays to setImageInfo
          setImageInfo({ small, large, hires, thumbnail });
          console.log(res.data);
        }
      });
    }
  };

  const handleItemEnterSubmit = (e) => {
    if (e.key === 'Enter' && userInput !== '') {
      axios.get(`/imageLibrary/picref/${userInput}`).then((res) => {
        if (res.data.length === 0) {
          alert('Picture Reference Does Not Exist For Item #' + userInput);
        } else {
          // Extract the "picRef" from the response data
          const picRef = res.data[0].picRef;

          // Use the extracted picRef to make the next Axios request
          axios.get(`/imageLibrary/aws/${picRef}`).then((res) => {
            // Extract the nested arrays from the new response
            const { small, large, hires, thumbnail } = res.data['48586'];

            // Pass the extracted arrays to setImageInfo
            setImageInfo({ small, large, hires, thumbnail });
            console.log(res.data);
          });
        }
      });
    }
  };

  const handleItemClickSubmit = () => {
    if (userInput !== '') {
      axios.get(`/imageLibrary/picref/${userInput}`).then((res) => {
        if (res.data.length === 0) {
          alert('Picture Reference Does Not Exist For Item #' + userInput);
        } else {
          // Extract the "picRef" from the response data
          const picRef = res.data[0].picRef;

          // Use the extracted picRef to make the next Axios request
          axios.get(`/imageLibrary/aws/${picRef}`).then((res) => {
            // Extract the nested arrays from the new response
            const { small, large, hires, thumbnail } = res.data['48586'];

            // Pass the extracted arrays to setImageInfo
            setImageInfo({ small, large, hires, thumbnail });
            console.log(res.data);
          });
        }
      });
    }
  };

  return (
    <Grid
      container
      spacing={1}
      justifyContent='center'
      alignItems='center'
      direction='row'
      sx={{ paddingTop: 2 }}
    >
      <Grid item xs={6} sx={{ textAlign: 'end' }}>
        <SearchBox
          label='Item #'
          handleInput={handleInput}
          handleUserEnterSubmit={handleItemEnterSubmit}
          handleUserClickSubmit={handleItemClickSubmit}
        />
      </Grid>
      <Grid item xs={6} sx={{ textAlign: 'start' }}>
        <SearchBox
          label='Picture Reference #'
          handleInput={handleInput}
          handleUserEnterSubmit={handlePicRefEnterSubmit}
          handleUserClickSubmit={handlePicRefClickSubmit}
        />
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <ResolutionContainer data={imageInfo} />
      </Grid>
    </Grid>
  );
};

export default ImageLibrary;
