import React, { useEffect, useContext, useState } from 'react';
//import { EntityContactContextState } from '../../../../../Context/Entity/EntityContacts/EntityContactContext';
import { EntityContactContextState } from '../../../../../Context/Marketing/EntityContactContext';
import { Alert, Box, Snackbar, useTheme } from '@mui/material';
import SnackBarAlert from '../../../../_GlobalComponents/SnackBarAlert';

const ContactEmails = ({ contactId }) => {
	const theme = useTheme();

	// CONTEXT STATE
	const { allEmails } = useContext(EntityContactContextState);

	// LOCAL STATE
	//const [emailsState, setEmailsState] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [open, setOpen] = useState(true);
	const [allEmailsState, setAllEmailsState] = useState([]);

	useEffect(() => {
		const filteredData = allEmails.filter((e) => e.entity_contact_id === contactId);

		setAllEmailsState([...filteredData]);
		setLoaded(true);
	}, [allEmails, contactId]);

	const Content = () => {
		return (
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'centers' }}>
				{allEmailsState.length > 0
					? allEmailsState.map((email, idx) => (
							<React.Fragment key={email.entity_contact_email_id}>
								<Box
									sx={{
										padding: '4px 8px',
										cursor: 'pointer',
										transition: '400ms ease-in-out',
										borderRadius: '4px',
										fontWeight: 'bold',
										color: '#777',
										'&:hover': { backgroundColor: theme.palette.primary.main, color: 'white' },
									}}
									onClick={() => {
										navigator.clipboard.writeText(email.email_address);
										setOpen(true);
									}}
								>
									{email.email_address}
								</Box>
							</React.Fragment>
					  ))
					: '---'}
			</div>
		);
	};

	const ResetOpen = () => {
		setOpen(false);
	};

	// useEffect(() => {
	// 	const emailList = Array.from(emails).map((item) => JSON.parse(item));
	// 	setEmailsState([...emailList]);
	// 	setLoaded(true);
	// }, [allEmails]);

	return (
		<>
			{loaded ? (
				<>
					<Content />
					<SnackBarAlert doOpen={open} ResetOpen={ResetOpen} message='Email Address Copied To Clipboard!' />
				</>
			) : (
				'---'
			)}
		</>
	);
};

export default ContactEmails;
