import { Web } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import React from 'react';

const EntityCompanyName = ({ item }) => {
	const handleButtonClick = (url) => {
		window.open(url, '_blank');
	};

	return (
		<Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
			<Box sx={{ width: '40px' }}>
				<Tooltip title={`Visit ${item.website}`}>
					<IconButton
						disabled={!item.website}
						color='warning'
						onClick={() => handleButtonClick(item.website)}
					>
						<Web />
					</IconButton>
				</Tooltip>
			</Box>
			<Box sx={{ flex: 1 }}>{item.business_name}</Box>
		</Box>
	);
};

export default EntityCompanyName;
