import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContractContextState } from '../../../../../../Context/Marketing/ContractContext';
import { PublicationContexV2tState } from '../../../../../../Context/Marketing/PublicationContextV2';
import { RequestQuote } from '@mui/icons-material';
import { Box, IconButton, Modal, useTheme } from '@mui/material';
import ContractCard from '../../../../../Contracts/components/ContractCard/ContractCard';

const ContractButton = ({ publicationId, publicationData }) => {
	// INITS
	const navigate = useNavigate();
	const theme = useTheme();

	// CONTEXT STATE
	const { allContracts } = useContext(ContractContextState);
	const { allPublications } = useContext(PublicationContexV2tState);

	// LOCAL STATE
	const [loading, setLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const [hasContract, setHasContract] = useState([]);

	// FUNCTIONS
	const handleClick = () => {
		setOpen(true);
		// navigate(
		// 	`/marketing/publications/contracts/dashboard/${publicationData.ss_publication_id}/${publicationData.description}`
		// );
	};
	const handleClose = () => setOpen(false);

	// USE EFFECT
	useEffect(() => {
		const has_Contract = allContracts.filter((e) => e.ss_publication_id === publicationId);
		console.log('has_contract', has_Contract);
		setHasContract(has_Contract);
		setLoading(false);
	}, [allContracts, publicationId]);

	if (loading) {
		return <div>Loading...</div>;
	}

	// console.log('publicationData: ', publicationData);
	//console.log('allContracts: ', allContracts);
	return (
		<>
			<IconButton color={hasContract.length > 0 ? 'success' : 'disabled'} onClick={() => handleClick()}>
				<RequestQuote />
			</IconButton>
			<Modal
				open={open}
				onClose={() => handleClose()}
				aria-labelledby='Contracts'
				aria-describedby="Contact's Contracts"
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: `${theme.palette.primary.light}33`,
				}}
			>
				<Box
					sx={{
						overflowY: 'auto',
						minWidth: '300px',
						maxWidth: '90vw',
						maxHeight: '90vh',
						display: 'flex',
						gridTemplateColumns: 'repeat(auto-fill, 375px)',
						gap: '24px',
						justifyContent: 'center',
						flexWrap: 'wrap',
					}}
				>
					{hasContract.map((e, i) => (
						<Box sx={{ minWidth: '375px', maxWidth: '375px' }}>
							<ContractCard
								key={i}
								contractDetails={e}
								pubDetails={allPublications.filter(
									(pub) => pub.ss_publication_id === e.ss_publication_id
								)}
							/>
						</Box>
					))}
				</Box>
			</Modal>
		</>
	);
};

export default ContractButton;
