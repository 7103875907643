import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment-timezone';
import axios from 'axios';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  FormControl,
  Grid,
  InputLabel,
  List,
  MenuItem,
  Select,
  ListItem,
  ListItemText,
  Modal,
  Typography,
  Divider,
} from '@mui/material';
import { useSelector } from 'react-redux';

const UserCalendar = ({ pageTitle }) => {
  const userPermission = useSelector((state) => state.userPermission.value);

  const userId = useSelector((state) => state.userInformation.value.userID);

  const [calendarHeight, setCalendarHeight] = useState('auto');

  const localizer = momentLocalizer(moment);
  moment.tz.setDefault('America/Chicago');
  //Set State for Calendar
  const [calendar, setCalendar] = useState('Holiday');
  const handleChange = (event) => {
    const newCalendar = event.target.value;
    setCalendar(newCalendar);
    fetchCalendarData(newCalendar);
    axios.put('/calendar/default/update', { userId: userId, calendar: newCalendar });
  };
  //Fetch Calendar Data and Set State
  const [eventList, setEventList] = useState([]);

  useEffect(() => {
    axios.get('/calendar/default', { params: { userId: userId } }).then((response) => {
      console.log(response.data);
      setCalendar(response.data.description);
    });
  }, []);

  useEffect(() => {
    fetchCalendarData(calendar);
  }, [calendar]);

  const fetchCalendarData = async (calendar) => {
    let data;
    let response;

    if (calendar === 'Marketing') {
      response = await axios.get('/marketing/events');
    } else if (calendar === 'NationalAcccountsAd') {
      response = await axios.get('/events/nationalaccountsad');
    } else if (calendar === 'Visitor') {
      response = await axios.get('/events/visitors');
    } else if (calendar === 'Holiday') {
      response = await axios.get('/events/holidays');
    }
    data = response.data;
    const transformed = data.map(
      ({
        ss_calendar_id,
        description,
        detailed_description,
        ss_calendar_category_id,
        creator_user_id,
        date_created,
        start_time,
        end_time,
        all_day,
        recurring,
        expiration_time,
        calendar_category_description,
        background_color,
        link_url,
        created_by,
      }) => ({
        id: ss_calendar_id,
        title: description,
        description: detailed_description,
        category_id: ss_calendar_category_id,
        creator_id: creator_user_id,
        created_at: date_created,
        start: moment(start_time).toDate(),
        end: moment(end_time).toDate(),
        allDay: all_day,
        recurring: recurring,
        expires: expiration_time,
        categoryDescription: calendar_category_description,
        background: background_color,
        link: link_url,
        createdBy: created_by,
      })
    );
    setEventList(transformed);
  };

  //Selected Event Modal
  const [modalState, setModalState] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(undefined);

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setModalState(true);
  };

  const handleCloseModal = () => {
    setSelectedEvent(undefined);
    setModalState(false);
  };

  useEffect(() => {
    const maxHeight = eventList.length * 7;
    if (maxHeight < 400) {
      setCalendarHeight('600px');
      return;
    }
    setCalendarHeight(`${maxHeight}px`);
  }, [eventList, calendar]);

  return (
    <>
      <Grid container justifyContent={'flex-start'} spacing={1} padding={2} sx={{ height: '100%' }}>
        <Grid item md={1} xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Selected Calendar</InputLabel>
                <Select value={calendar} onChange={handleChange} label='Selected Calendar'>
                  <MenuItem value='Holiday'>Holidays</MenuItem>
                  {userPermission[900] && <MenuItem value='Marketing'>Marketing</MenuItem>}
                  {userPermission[99999999] && (
                    <MenuItem value='NationalAcccountsAd'>National Accounts Ad</MenuItem>
                  )}
                  {userPermission[911] && <MenuItem value='Visitor'>Visitors</MenuItem>}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <List>
                {(() => {
                  const colorsByCategory = eventList.reduce((colors, event) => {
                    const category = event.categoryDescription;
                    const color = event.background;

                    if (!colors[category]) {
                      colors[category] = color;
                    }

                    return colors;
                  }, {});

                  return Object.keys(colorsByCategory).map((category) => (
                    <ListItem
                      key={category}
                      style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
                    >
                      <div
                        style={{
                          backgroundColor: colorsByCategory[category],
                          width: '10px',
                          height: '100%',
                          position: 'absolute',
                          left: '0',
                        }}
                      ></div>
                      <ListItemText primary={category} style={{ marginLeft: '20px' }} />
                    </ListItem>
                  ));
                })()}
              </List>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={11} xs={12} style={{ height: '100vh', overflowY: 'auto' }}>
          {' '}
          <Calendar
            localizer={localizer}
            events={eventList}
            startAccessor='start'
            endAccessor='end'
            eventPropGetter={(event) => ({
              style: {
                backgroundColor: event.background,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxHeight: '30px',
              },
            })}
            views={['month', 'week', 'day']}
            onSelectEvent={handleSelectEvent}
            showAllEvents={true}
            style={{ height: calendarHeight }}
          />
        </Grid>
      </Grid>
      <Modal
        open={modalState}
        onClose={handleCloseModal}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <div style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '5px' }}>
          <Typography style={{ padding: '6px', textAlign: 'center' }} variant='h5'>
            {selectedEvent?.title}
          </Typography>
          <Typography
            style={{
              padding: '6px',
              textAlign: 'center',
              minWidth: '400px',
              color: '#FFF',
              backgroundColor: `${selectedEvent?.background}`,
            }}
            variant='subtitle2'
          >
            {calendar === 'Visitor' && <b>Conference Room: </b>}
            {selectedEvent?.categoryDescription}
          </Typography>
          <Divider />
          <Grid container direction='column' justifyContent='center' alignItems='center'>
            {calendar !== 'Visitor' && (
              <Grid item xs={12}>
                <Typography style={{ padding: '6px' }} variant='overline'>
                  <b>{moment(selectedEvent?.start).format('LL')}</b>
                </Typography>
                <Typography style={{ padding: '6px' }} variant='overline'>
                  to
                </Typography>
                <Typography style={{ padding: '6px' }} variant='overline'>
                  <b>{moment(selectedEvent?.end).format('LL')}</b>
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              {calendar === 'Visitor' && (
                <Typography variant='overline'>
                  {calendar === 'Visitor' ? 'Booked from ' : ''}
                  <b>
                    <u>{moment(selectedEvent?.start).format('h:mm A')}</u>
                  </b>{' '}
                  until{' '}
                  <b>
                    <u>{moment(selectedEvent?.end).format('h:mm A')}</u>
                  </b>
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant='overline'></Typography>
            </Grid>
            <Grid item xs={12} zeroMinWidth>
              <Typography style={{ padding: '6px' }} variant='subtitle2'>
                {calendar === 'Visitor' && <b>Meeting Details: </b>}
                {calendar !== 'Holiday' && selectedEvent?.description}
              </Typography>
              <Typography style={{ padding: '6px' }} variant='subtitle2'>
                {calendar === 'Visitor' && <b>Meeting Organizer: </b>}
                {selectedEvent?.createdBy}
              </Typography>
            </Grid>
            <Grid item xs={12} zeroMinWidth>
              <a target={'_blank'} rel='noopener noreferrer' href={selectedEvent?.link}>
                <Typography variant='subtitle2'>{selectedEvent?.link}</Typography>
              </a>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </>
  );
};

export default UserCalendar;
