import React, { useContext, useEffect, useState } from 'react';

import { EditProductDataContextState } from '../../../../../../../Context/pdm/EditProductDataContext';
import { useTheme } from '@mui/material';

import ResultsTable from '../../ResultsTable/ResultsTable';
import ProductDetails from '../../ProductDetails/ProductDetails';
import ProductDataContext from '../../../../../../../Context/pdm/ProductDataContext';

const Panel = ({ details }) => {
	const { activeTab } = useContext(EditProductDataContextState);
	const theme = useTheme();

	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		activeTab === details.id ? setMounted(true) : setMounted(false);
	}, [activeTab, details.id]);

	console.log('panel details prop: ', details);
	return (
		<>
			{mounted && (
				<div
					style={{
						minHeight: '400px',
						border: `solid 1px ${theme.palette.primary.main}`,
						margin: '0 50px',
						borderRadius: '20px',
						borderTopRightRadius: 0,
						padding: '15px',
						backgroundColor: theme.palette.primary.lightScreen,
					}}
				>
					{details.type === 'list' ? (
						<ResultsTable details={details} />
					) : (
						<ProductDataContext activeProductId={details.productId}>
							<ProductDetails productId={details.productId} />
						</ProductDataContext>
					)}
				</div>
			)}
		</>
	);
};

export default Panel;
