import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getContractDetailsByContractId } from '../../Feeds/ContractsFeed';
import { Box, styled } from '@mui/material';
import ContractCard from './components/ContractCard';

// Material Styled Components
const CardsContainer = styled(Box)(({ theme }) => ({
	width: '90%',
	maxWidth: '1920px',
	height: '90%',
	position: 'relative',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%,-50%)',
	overflowY: 'auto',
	display: 'flex',
	flexDirection: 'column',
}));

const Cards = styled(Box)(({ theme }) => ({
	position: 'relative',
	color: theme.palette.primary.main,
	margin: '0 auto',

	width: '100%',
	padding: '0',
	display: 'grid',
	gap: '24px',

	flex: '1 0 auto',

	[theme.breakpoints.up('xs')]: {
		gridTemplateColumns: '1fr',
	},
	[theme.breakpoints.up('sm')]: {
		gridTemplateColumns: '1fr 1fr',
	},
	[theme.breakpoints.up('md')]: {
		gridTemplateColumns: '1fr 1fr',
	},
	[theme.breakpoints.up('lg')]: {
		gridTemplateColumns: '1fr 1fr 1fr',
	},
	[theme.breakpoints.up('xl')]: {
		gridTemplateColumns: '1fr 1fr 1fr 1fr',
	},
}));

const ContractCards = ({ data }) => {
	const { ids } = useParams();
	const [loaded, setLoaded] = useState(false);
	const [dataState, setDataState] = useState([]);

	useEffect(() => {
		const loadPropData = async () => {
			const dataResponse = await data;
			setDataState(dataResponse);
			setLoaded(true);
			console.log('ContractCards Data: ', data);
		};

		loadPropData();
	}, [data]);

	return (
		<>
			{loaded ? (
				<CardsContainer id='_contract_cards_container'>
					<Cards id='_contract_cards'>
						{dataState.map((item, i) => (
							<ContractCard key={item.ss_publication_contract_id} item={item} />
						))}
					</Cards>
				</CardsContainer>
			) : (
				<h2>...Loading</h2>
			)}
		</>
	);
};

export default ContractCards;
