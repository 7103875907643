import React, { useEffect, useState } from 'react';
import { getPublicationTypes } from '../../../../Publications/Feed/PrimaryFeed';
import { MenuItem, TextField } from '@mui/material';

const PublicationTypesSelect = ({ handleChange, selectedPublicationTypeId }) => {
	const [data, setData] = useState([]);
	const [initialValue, setInitialValue] = useState(selectedPublicationTypeId || 0);
	const [loaded, setLoaded] = useState(false);

	// const _handleChange = (event) => {
	// 	handleChange('ss_publication_type_id', event.target.value);
	// };

	useEffect(() => {
		const toDo = async () => {
			const dataTypes = await getPublicationTypes();
			setData([{ ss_publication_type_id: 0, description: 'Choose Publication Type' }, ...dataTypes]);

			setInitialValue(selectedPublicationTypeId);
			setLoaded(true);
		};
		toDo();
	}, [selectedPublicationTypeId]);

	return (
		<>
			{loaded && (
				<TextField
					select
					fullWidth
					label='Publication Typesss:'
					value={selectedPublicationTypeId}
					onChange={handleChange}
					variant='outlined'
					InputLabelProps={{ shrink: true }}
				>
					{data.map((pub) => (
						<MenuItem key={pub.ss_publication_type_id} value={pub.ss_publication_type_id}>
							{pub.description}
						</MenuItem>
					))}
				</TextField>
			)}
		</>
	);
};

export default PublicationTypesSelect;
