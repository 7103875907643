import { Paper, Typography, Box } from '@mui/material';
import React from 'react';

const LeadDetail = ({ account }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        width: '100%',
        height: '100%',
        padding: 2,
        margin: 'auto',
        boxShadow: '0px 3px 5px rgba(0,0,0,0.2)',
        backgroundColor: '#f5f5f5',
      }}
    >
      <Box
        sx={{
          width: '100%',
          borderRadius: '5px',
        }}
      >
        <Typography
          variant='h5'
          component='h2'
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#333',
            fontSize: '24px',
            marginBottom: 0,
          }}
        >
          {account.company_name}
        </Typography>
      </Box>
      {account && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: 'auto',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              p: 1,
              fontWeight: 'bold',
              width: '100%',
              maxWidth: '600px',
              margin: 'auto',
            }}
          >
            {Object.keys(account).map((key) => {
              // Exclude certain keys
              if (
                ['ss_crm_lead_id', 'time_created', 'last_modified', 'key', 'label'].includes(key)
              ) {
                return null;
              }
              // Capitalize the first letter of each word in the key
              const formattedKey = key
                .replace(/_/g, ' ')
                .replace(/\b(\w)/g, (char) => char.toUpperCase());

              // Format value based on key
              let value;
              switch (key) {
                case 'email_address':
                  value = <a href={`mailto:${account[key]}`}>{account[key]}</a>;
                  break;
                case 'phone_number':
                  // Formatting phone number
                  const phone = account[key];
                  const formattedPhone = `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(
                    6
                  )}`;
                  value = formattedPhone;
                  break;
                case 'address':
                  value = `${account[key]} \n ${account['city']}, ${account['state']} ${account['zip_code']}`;
                  break;

                default:
                  value = account[key];
              }

              return (
                <div
                  key={key}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '5px 0',
                    padding: '5px',
                  }}
                >
                  <strong>{formattedKey}:</strong>
                  <span style={{ whiteSpace: 'pre' }}>{value}</span>
                </div>
              );
            })}
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default LeadDetail;
