import React, { useContext, useEffect, useState } from 'react';
import { EntityContactContextState } from '../../../../../Context/Marketing/EntityContactContext';
import { Box } from '@mui/material';

const ContactPhoneNumbers = ({ contactId }) => {
	// CONTEXT STATE
	const { allPhoneNumbers, allPhoneTypes } = useContext(EntityContactContextState);

	// LOCAL STATE
	const [contactsPhoneNumbers, setContactsPhoneNumbers] = useState([]);

	useEffect(() => {
		const tempArray = [];

		const phoneNumbers = allPhoneNumbers.filter((e) => e.entity_contact_id === contactId);

		phoneNumbers.forEach((phone) => {
			const phoneType = allPhoneTypes.find((t) => t.phone_type_id === phone.phone_type_id);
			tempArray.push({
				phone_type_description: phoneType ? phoneType.description : 'Unknown', // Check if phoneType exists
				phone_number: phone.phone_number,
				extension: phone.extension,
			});
		});

		setContactsPhoneNumbers(tempArray);
	}, [allPhoneNumbers, allPhoneTypes, contactId]);

	return (
		<>
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
				{contactsPhoneNumbers.length > 0
					? contactsPhoneNumbers.map((phone, i) => (
							<Box key={i} sx={{ flex: 1, display: 'flex', gap: '8px', alignItems: 'center' }}>
								<Box sx={{ fontWeight: 'bold', width: '60px', textAlign: 'right' }}>
									{phone.phone_type_description}:
								</Box>

								<Box>{phone.phone_number}</Box>
								{phone.extension && <Box>{` Ext: ${phone.extension}`}</Box>}
							</Box>
					  ))
					: '---'}
			</Box>
		</>
	);
};

export default ContactPhoneNumbers;
