// Material UI
import { Box, styled } from '@mui/material';

// Material Styled Components
const Wrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  fontSize: '2.1em',
  marginBottom: 20,
}));

// Primary Component
const PageTitle = ({ title, padding }) => {
  return <Wrapper sx={padding ? `padding : ${padding}` : `padding:0`}>{title}</Wrapper>;
};
export default PageTitle;
