import { Box } from '@mui/material';
import React from 'react';
import EntityContactsTable from './components/EntityContactsTable/EntityContactsTable';

const EntityContacts = () => {
	return (
		<Box sx={{ width: '100%', maxWidth: '1920px', mx: 'auto' }}>
			<EntityContactsTable />
		</Box>
	);
};

export default EntityContacts;
