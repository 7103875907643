import {
  Grid,
  TextField,
  Typography,
  FormControl,
  Button,
  CircularProgress, 
  Backdrop,
  Select,
  MenuItem,
  InputLabel  
} from '@mui/material';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';
import axios from 'axios';
import React, { useState, useEffect } from 'react';

const LookupCustomerForm = () => {

  // State For Form Entry
  const [customerNumber, setCustomerNumber] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [locationId, setLocationId] = useState('');
  const [chainNumber, setChainNumber] = useState('');
  const [customerResult, setCustomerResult] = useState('');
  const [searchResult, setSearchResult] = useState('');
  const [searchResultMessage, setSearchResultMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Loading state
  
  useEffect(() => {
    const listener = e => {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        console.log("Enter key was pressed. Run your function.");
        e.preventDefault();
        // handleSubmit(customerNumber,locationId);
        console.log('Hit inside listener');
        console.log(customerNumber);
        console.log(locationId);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  // Handle Submit For Item Lookup
  const handleSubmit = () => {
    console.log('Hit inside handleSubmit');
    console.log(customerNumber);
    console.log(locationId);
    
    if (customerNumber && locationId) {
      console.log('customer Number  + Dib Number hit...');
      // --------------------------------------------------
      // THEN CALL THIS TO CONVERT            
      // --------------------------------------------------
      setIsLoading(true);
      axios
      .post('/hubRelay', {
        j: '/webservice/hub/helpdesk_utilities/edi/convert_customer_to_chain.php?CUSTOMER_NUMBER='+customerNumber+'&CUCRO_NUMBER='+locationId+'&CHAIN_NUMBER='+chainNumber
      })
      .then((response) => {
        console.log(response.data);
        
        setSearchResult(response.data.RESULT);
        setSearchResultMessage(response.data.MESSAGE);
        setIsLoading(false);
        
        setTimeout(function () {
          console.log('hit timeout');
          handleRefresh();
        }, 2000);

      })
      .catch((error) => {
        console.log(error);
      });
      // ---------------------
    
    } else if (customerNumber && !locationId) {
      console.log('customer Number hit...');
      setIsLoading(true);
      // --------------------------------------------------
      // CALL THIS FIRST TO CHECK CUSTOMER NUMBER IS ACTIVE            
      // --------------------------------------------------
      axios
      .post('/hubRelay', {
        j: '/webservice/hub/helpdesk_utilities/general/basic_customer_info.php?CUSTOMER_NUMBER='+customerNumber
      })
      .then((response) => {
        console.log(response.data);
        setCustomerResult(response.data.RESULT);

        // SET CUSTOMER NAME OR ERROR DEPENDING ON RESPONSE
        response.data.RESULT == 'ERROR' ? setCustomerName(response.data.RESULT) : setCustomerName(response.data.DATA.CUSTOMER_NAME);
        
        setIsLoading(false);
        
      })
      .catch((error) => {
        console.log(error);
      });
      // ---------------------

    } else {
      console.log('Missing parameters...');
      setSearchResultMessage('Missing Parameters....');
    }
    
  };

  const handleRefresh = () => {
    setLocationId('');
    setCustomerNumber(''); 
    setSearchResult('');
    setSearchResultMessage('');
    setCustomerResult('');
    setChainNumber('');
    setCustomerName('');
  };

  // const handleEnterKey = (e) => {
  //   if (e.key === 'Enter') {
  //     console.log('Enter Key Hit...');      
  //     handleSubmit(e);
  //   }
  // };


  return (
    <Grid container spacing={5} sx={{ p: 4 }}>      
      <Grid item xs={12}>
        <Typography variant='h6' sx={{textAlign: 'left', mt: 1}}>Select a chain...</Typography>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth={true}>
          <InputLabel id="chain_label">Chain</InputLabel>
          <Select
            labelId="chain_label"            
            value={chainNumber}
            label="Chain"
            onChange={(e) => setChainNumber(e.target.value)}
          >
            <MenuItem value={null}></MenuItem>
            <MenuItem value={901}>Do It Best</MenuItem>
            <MenuItem value={8283}>Orgill</MenuItem>          
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={8}></Grid>
      <Grid item xs={12}>
        <Typography variant='h6' sx={{textAlign: 'left'}}>then verify customer exists...</Typography>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth={true}>
          <TextField
            value={customerNumber}                        
            onChange={(e) => setCustomerNumber(e.target.value)} 
            label="Customer #"            
          />
        </FormControl>
      </Grid>      
      <Grid item xs={8} sx={{textAlign: 'left', mt: 2}}>
        {customerResult === "OK" && <Typography variant='h6' style={{color: "#198754"}}>{customerName}</Typography> }
        {customerResult === "ERROR" && <Typography variant='h6' style={{color: "#dc3545"}}>{customerName}</Typography> }
      </Grid>      
      <Grid item xs={12}>
        {customerResult === "OK" && <Typography variant='h6' sx={{textAlign: 'left'}}>now the location ID...</Typography>}
      </Grid>
      <Grid item xs={4}>
        {customerResult === "OK" && 
        <FormControl fullWidth={true}>
          <TextField 
            value={locationId} 
            onChange={(e) => setLocationId(e.target.value)}
            label="Location ID"
          />
        </FormControl>}        
      </Grid>      
      <Grid item xs={2} sx={{textAlign: 'left', mt: 2}}>
        {searchResult === "OK" && <CheckSharpIcon style={{color: "#198754"}} /> }
        {searchResult === "ERROR" && <ErrorOutlineSharpIcon style={{color: "#dc3545"}} />}
      </Grid> 
      <Grid item xs={6}></Grid>
      
      <Grid item xs={2}>
        <Button variant='contained' color='primary' onClick={handleSubmit}>
          Submit
        </Button>        
      </Grid>
      <Grid item xs={2}>
        { (customerNumber || chainNumber) && 
        <Button variant='contained' color='error' onClick={handleRefresh}>
          Clear
        </Button> }
      </Grid>
      <Grid item xs={8}></Grid>      
      <Grid item xs={12} sx={{textAlign: 'center'}}>
        <Typography 
          variant='span' 
          fontSize='small' 
          style={{color: searchResult === "OK" ? "#198754" : "#dc3545"}}
        >{searchResultMessage}
        </Typography>
      </Grid>      
      {isLoading && (
        <Backdrop
          open={isLoading}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}
        >
          <CircularProgress sx={{color: '#0FFF00'}} />
        </Backdrop>
      )}
    </Grid>
  );
};

export default LookupCustomerForm;
