import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

export const ContractContextState = React.createContext({
	allContracts: [],
	setAllContracts: () => {},
	allContractStatuses: [],
	setAllContractStatuses: () => {},
});

// PRIMARY COMPONENT
export const ContractContext = ({ children }) => {
	// LOCAL STATE
	const [allContractsState, setAllContractsState] = useState([]);
	const [allContractStatusesState, setAllContractStatusesState] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	// FUNCTIONS
	const setAllContracts = useCallback(async () => {
		try {
			const response = await axios.get(`/contracts/getAllContracts`);
			setAllContractsState([...response.data]);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	}, []);

	const setAllContractStatuses = useCallback(async () => {
		try {
			const response = await axios.get(`/contracts/getAllStatuses`);
			setAllContractStatusesState([...response.data]);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			await setAllContracts();
			await setAllContractStatuses();
			setIsLoading(false);
		};
		fetchData();
	}, [setAllContracts, setAllContractStatuses]);
	// const setAllContracts = async () => {
	// 	try {
	// 		const response = await axios.get(`/contracts/getAllContracts`);
	// 		setAllContractsState([...response.data]);
	// 	} catch (error) {
	// 		console.error('Error fetching data:', error);
	// 		return [];
	// 	}
	// };

	// const setAllContractStatuses = async () => {
	// 	try {
	// 		const response = await axios.get(`/contracts/getAllStatuses`);
	// 		setAllContractStatusesState([...response.data]);
	// 	} catch (error) {
	// 		console.error('Error fetching data:', error);
	// 		return [];
	// 	}
	// };

	// useEffect(() => {
	// 	setAllContracts();
	// 	setAllContractStatuses();
	// }, []);

	const contextValue = {
		allContracts: allContractsState,
		setAllContracts,
		allContractStatuses: allContractStatusesState,
		setAllContractStatuses,
		isLoading,
	};

	return <ContractContextState.Provider value={contextValue}>{children}</ContractContextState.Provider>;
};
export default ContractContext;
