import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { EditProductDataContextState } from '../../../../../../../Context/pdm/EditProductDataContext';
import { Box, useTheme } from '@mui/material';
import { DeleteForever } from '@mui/icons-material';

const Tab = ({ details }) => {
	const theme = useTheme();
	const { activeTab, updateActiveTab, removeTab, selectedMfrId, selectedCatId, selectedSeriesId, selectedModelId } =
		useContext(EditProductDataContextState);

	const [mount, setMount] = useState(true);
	const [labels, setLabels] = useState({});

	const deleteTab = (e) => {
		e.stopPropagation();
		removeTab(details.id);
		setMount(false);
	};

	useEffect(() => {
		// GET LABELS
		const fetchLabels = async (mfrid, catid, serid, modid) => {
			try {
				const response = await axios.get(
					`/pdm/getLabelsMfrIdCatIdSerIdModId/${mfrid}/${catid}/${serid}/${modid}`
				);

				setLabels({
					mfr: response.data[0].brand,
					cat: response.data[0].category,
					ser: response.data[0].series,
					mod: response.data[0].model,
				}); // Assuming the response has a data object
			} catch (error) {
				console.log('fetchSeriesListFromMfrIdCatid error: ', error);
			}
		};

		details.type === 'list' ? console.log('TAB IS A LIST') : console.log('TAB IS A PRODUCT');
		fetchLabels(
			details.label.split(',')[0],
			details.label.split(',')[1],
			details.label.split(',')[2],
			details.label.split(',')[3]
		);
	}, [details]);

	return (
		<>
			{mount ? (
				<Box
					sx={{
						display: 'flex',
						minHeight: '45px',
						backgroundColor: activeTab === details.id ? theme.palette.primary.main : 'transparent',
						color: activeTab === details.id ? 'white' : theme.palette.primary.main,
						py: 1,
						px: 3,
						paddingRight: 5,
						borderTopRightRadius: 20,
						borderTopLeftRadius: 20,
						border: `1px solid ${theme.palette.primary.main}`,
						borderBottomWidth: 0,
						cursor: 'pointer',
						position: 'relative',
						fontWeight: 'bold',
						transition: '200ms ease-in-out',
						'&:hover': {
							backgroundColor: theme.palette.primary.lightScreen,
							color: theme.palette.primary.main,
						},
					}}
					onClick={() => {
						updateActiveTab(details.id);
					}}
				>
					<div style={{ margin: 'auto 0' }}>
						{details.type === 'product' ? (
							<div>Product: {details.productId}</div>
						) : (
							<>
								<div>
									{labels.mfr} {labels.cat}
								</div>

								{labels.ser && (
									<div>
										Model {labels.ser} {labels.mod}
									</div>
								)}
							</>
						)}
					</div>

					<Box
						onClick={(e) => deleteTab(e)}
						sx={{
							position: 'absolute',
							bottom: '0',
							right: '0',
							height: '30px',
							width: '30px',
							border: '1px solid #ff0000',
							display: 'flex',
							//borderRadius: '50%',
							borderTopLeftRadius: '15px',

							backgroundColor: '#ff0000',
							// transform: 'translate(-50%, -50%)',
							boxShadow: '2px 2px 4px rgba(0,0,0,.3)',
							cursor: 'pointer',
						}}
					>
						<DeleteForever sx={{ color: '#ffffff', margin: 'auto', fontSize: '1.2em' }} />
					</Box>
				</Box>
			) : null}
		</>
	);
};

export default Tab;
