import { Button, Drawer } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ContractForm from './ContractForm';
import { getPublicationByPubId } from '../../../../Publications/Feed/PrimaryFeed';

const CreateNewContractButton = ({ publicationId }) => {
	// LOGOL STATE
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [dataObj, setDataObj] = useState({});

	useEffect(() => {
		const fetchData = async () => {
			const pubData = await getPublicationByPubId(publicationId);
			setDataObj({
				ss_publication_id: parseInt(publicationId, 10),
				ss_publication_type_id: pubData[0].ss_publication_type_id,
			});
			console.log('pubData: ', pubData);
		};

		fetchData();
	}, [publicationId]);

	console.log('dataObj: ', dataObj);
	return (
		<>
			<Button variant='contained' color='success' onClick={() => setDrawerOpen(true)}>
				Create Contract
			</Button>

			<Drawer
				anchor={'right'}
				open={drawerOpen}
				onClose={() => {
					setDrawerOpen(false);
				}}
			>
				{/* <ContractFormMobile contractId={contractId} userid={54155} /> */}
				<ContractForm contractId={0} userid={54155} initialData={[dataObj]} />
			</Drawer>
		</>
	);
};

export default CreateNewContractButton;
