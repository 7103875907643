import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

import { useTheme } from '@mui/material';
import AttributeAction from './../Panels/components/TableElements/components/AttributeAction';

const ResultsTable = ({ details }) => {
	const theme = useTheme();

	const [selectedRowIds, setSelectedRowIds] = useState({});
	const [products, setProducts] = useState([]);
	//const [mounted, setMounted] = useState(false);

	const columns = useMemo(
		() => [
			{
				id: 11000,
				accessorKey: 'product_id', //access nested data with dot notation
				header: 'Product ID',
				size: 125,
				grow: false,
			},
			{
				id: 12000,
				accessorKey: 'product_code',
				header: 'Product Code',
				size: 125,
				grow: false,
			},
			{
				accessorFn: (row) => `https://media.server.theshootingwarehouse.com/small/${row.product_code}.jpg`,
				id: 13000, // unique ID for the column
				header: 'Image',
				Cell: ({ cell }) => (
					<div>
						<img
							src={cell.getValue()}
							alt='Product'
							style={{ width: 100, height: 100, objectFit: 'contain' }}
						/>
					</div>
				),
				size: 150,
				grow: false,
			},

			{
				id: 14000,
				accessorKey: 'description', //normal accessorKey
				header: 'Description',
				grow: true,
			},
			{
				id: 15000,
				accessorKey: 'internal_use_only',
				header: 'IUO',
				size: 125,
				grow: false,
			},
			{
				id: 16000,
				accessorKey: 'inactive',
				header: 'Status',
				size: 125,
				grow: false,
			},
			{
				accessorFn: (row) => row.product_id,
				id: 17000, // unique ID for the column
				header: 'Action',
				Cell: ({ cell }) => <AttributeAction product_id={cell.getValue()} />,
				size: 150,
				grow: false,
			},
		],
		[]
	);

	const table = useMaterialReactTable({
		columns,
		data: products, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
		enableColumnResizing: true,
		enableRowSelection: true,
		state: { rowSelection: selectedRowIds },
		onRowSelectionChange: setSelectedRowIds,
		layoutMode: 'grid',
	});

	useEffect(() => {
		const fetchProducts = async (mfrid, catid, serid, modid) => {
			try {
				const response = await axios.get(
					`/pdm/getProductsMfrIdCatIdSerIdModId/${mfrid}/${catid}/${serid}/${modid}`
				);
				console.log('productsReturn: ', response.data, mfrid, catid, serid, modid);
				setProducts([...response.data]); // Assuming the response has a data object
			} catch (error) {
				console.log('fetchProducts error: ', error);
			}
		};

		details.id > 0 &&
			fetchProducts(
				details.label.split(',')[0],
				details.label.split(',')[1],
				details.label.split(',')[2],
				details.label.split(',')[3]
			);
	}, [details]);

	return (
		<>
			<MaterialReactTable table={table} />
		</>
	);
};

export default ResultsTable;
