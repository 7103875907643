import React, { useEffect, useState } from 'react';
import { getAllPublications } from '../../../../Publications/Feed/PrimaryFeed';
import { Box, Button } from '@mui/material';

const PublicationsByType = ({ ss_publication_type_id, handleChange, handlePublicationClick, setValue }) => {
	const [data, setData] = useState([]);

	const _handleClick = (title, id) => {
		handleChange(title, id);
		handlePublicationClick();
	};

	const _changeValue = (e) => {
		setValue(e);
	};

	useEffect(() => {
		const fetching = async () => {
			setData(await getAllPublications());
		};
		fetching();
	}, [ss_publication_type_id]);

	return (
		<Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '24px', mb: 2 }}>
			{data
				.filter((e) => e.ss_publication_type_id === ss_publication_type_id)
				.map((pub) => (
					<Button
						key={pub.ss_publication_id}
						variant='contained'
						onClick={() => _changeValue(pub.ss_publication_id)}
					>
						{pub.description}
					</Button>
				))}
		</Box>
		// <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '24px', mb: 2 }}>
		// 	{data
		// 		.filter((e) => e.ss_publication_type_id === ss_publication_type_id)
		// 		.map((pub) => (
		// 			<Button
		// 				key={pub.ss_publication_id}
		// 				variant='contained'
		// 				onClick={() => _handleClick('ss_publication_id', pub.ss_publication_id)}
		// 			>
		// 				{pub.description}
		// 			</Button>
		// 		))}
		// </Box>
	);
};

export default PublicationsByType;
