import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedEntityType } from '../../../../../Redux/EntitySlice';
import axios from 'axios';

import { usaStates } from '../assets/States';

// MUI IMPORTS
import {
	Alert,
	Box,
	Button,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	Snackbar,
	Switch,
	TextField,
} from '@mui/material';

import { AddCircleOutline } from '@mui/icons-material';

const DropDownLabels = [
	{
		label_id: 0,
		label_text: 'Select Entity Type',
	},
	{
		label_id: 1,
		label_text: 'Select Rep Group Entity',
	},
	{
		label_id: 2,
		label_text: 'Select Manufacturer Entity',
	},
	{
		label_id: 3,
		label_text: 'Selected Vendor Entity',
	},
	{
		label_id: 4,
		label_text: 'Selected Public Brand Entity',
	},
];

// PRIMARY COMPONENT
const EntityForm = () => {
	const dispatch = useDispatch();
	// REDUX SELECTORS
	const selectedEntityType = useSelector((state) => state.entitySlice.selectedEntityType);

	// LOCAL STATE
	// TRIGGERS
	const [callManufacturersList, setCallManufacturersList] = useState(0);

	// SNACKBAR STATE
	const [snackOpen, setSnackOpen] = useState(false);
	const [snackMessage, setSnackMessage] = useState('...');
	const [snackSeverity, setSnackSeverity] = useState('success');

	// Entity ID State
	const [entityIdOpen, setEntityIdOpen] = useState(false);
	const [newEntryEntityId, setNewEntryEntityId] = useState(0);
	const [entityId, setEntityId] = useState(0);
	const [selectedEntities, setSelectedEntities] = useState([]);
	const [entitySelectLabel, setEntitySelectLabel] = useState('');
	const [selectedEntityTypeDropdown, setSelectedEntityTypeDropdown] = useState(0);

	// Primary Identifier state
	const [primaryIdentifierOpen, setPrimaryIdentifierOpen] = useState(false);
	const [formPrimaryIdentifier, setFormPrimaryIdentifier] = useState(0);
	const [allManufacturers, setAllManufacturers] = useState([]);
	const [allVendors, setAllVendors] = useState([]);

	// Time Zones
	const [timeZoneOpen, setTimeZoneOpen] = useState(false);
	const [formTimeZone, setFormTimeZone] = useState(50);
	const [timeZones, setTimeZones] = useState([]);

	// Countries
	const [contriesOpen, setCountriesOpen] = useState(false);
	const [formCountry, setFormCountry] = useState(1000);
	const [countries, setCountries] = useState([]);

	const [formBusiness_name, setFormBusiness_name] = useState('');
	const [formAttentionLine, setFormAttentionLine] = useState('');
	const [formAddress1, setFormAddress1] = useState('');
	const [formAddress2, setFormAddress2] = useState('');
	const [formCity, setFormCity] = useState('');

	// States
	const [formUSAState, setFormUSAState] = useState('');
	const [usaStateOpen, setUSAStateOpen] = useState(false);

	const [formPostalCode, setFormPostalCode] = useState('');
	const [formLatitude, setFormLatitude] = useState('');
	const [formLongitude, setFormLongitude] = useState('');
	const [formWebPage, setFormWebPage] = useState('');
	const [formNotes, setFormNotes] = useState('');
	const [formDescription, setFormDescription] = useState('');
	const [formDetailDescription, setFormDetailDescription] = useState('');
	const [formMotto, setFormMotto] = useState('');
	const [formPrimaryColor, setFormPrimaryColor] = useState('');
	const [formSecondaryColor, setFormSecondaryColor] = useState('');
	const [formAccentColor, setFormAccentColor] = useState('');
	const [formDelete, setFormDelete] = useState(false);

	const resetFormState = () => {
		setEntityId(0);
		setFormPrimaryIdentifier(0);
		setFormBusiness_name('');
		setFormAttentionLine('');
		setFormAddress1('');
		setFormAddress2('');
		setFormCity('');
		setFormUSAState('');
		setFormPostalCode(0);
		setFormLatitude('');
		setFormLongitude('');
		setFormTimeZone(50);
		setFormCountry(1000);
		setFormWebPage('');
		setFormNotes('');
		setFormDescription('');
		setFormDetailDescription('');
		setFormMotto('');
		setFormPrimaryColor('');
		setFormSecondaryColor('');
		setFormAccentColor('');
		setFormDelete(false);
	};

	const handleAddNewEntityButtonClick = () => {
		dispatch(setSelectedEntityType(0));
		setSelectedEntityTypeDropdown(0);
		resetFormState();
		setFormTimeZone(50);
		setFormCountry(1000);
	};

	const handleEntityDropdownChange = (e) => {
		const func = () => {
			resetFormState();
			setEntityId(e.target.value);
		};
		e.target.value === 0 ? func() : setEntityId(e.target.value);
	};

	const handleOnSubmit = () => {
		const callInsert = () => {
			dispatch(setSelectedEntityType(selectedEntityTypeDropdown));
			insertEntityRecord();
		};
		entityId === 0 ? callInsert() : updateEntityRecord();
	};

	const handleSettingSelectedEntityTypeDropdown = (e) => {
		resetFormState();
		setSelectedEntityTypeDropdown(e);
		//dispatch(setSelectedEntityType(e));
	};

	const handleSettingPrimaryIdentifier = (e) => {
		setFormPrimaryIdentifier(e.target.value);
	};

	const updateEntityRecord = () => {
		const updateRecord = async () => {
			const currentEntityId = entityId;
			try {
				const response = await axios.post('/marketing/entities/postUpdateEntityRecord', {
					ss_entity_type_id: selectedEntityType,
					business_name: formBusiness_name,
					address_1: formAddress1,
					address_2: formAddress2,
					attention_line: formAttentionLine,
					city: formCity,
					state: formUSAState,
					postal_code: formPostalCode,
					country_id: formCountry,
					latitude: formLatitude,
					longitude: formLongitude,
					timezone_id: formTimeZone,
					web_page: formWebPage,
					image_id: null,
					notes: formNotes,
					description: formDescription,
					detailed_description: formDetailDescription,
					motto: formMotto,
					primary_color: formPrimaryColor,
					secondary_color: formSecondaryColor,
					accent_color: formAccentColor,
					primary_identifier: formPrimaryIdentifier,
					deleted: formDelete,
					ss_entity_id: entityId,
				});
				console.log('updated id: ' + currentEntityId);
				setSnackSeverity('success');
				setSnackMessage('Entry Is Updated... Thanks!');
				setSnackOpen(true);
				setCallManufacturersList(callManufacturersList + 1);
				setEntityId(0);
				setTimeout(() => {
					setEntityId(currentEntityId);
				}, 1500);

				//setEntityId(currentEntityId);
				//setSelectedEntityTypeDropdown(response.data.insertId);
				// Optionally return response.data if you need to use it further
			} catch (error) {
				console.error('Error updating record:', error);
				setSnackSeverity('error');
				setSnackMessage('OOPs... Something Went Way South :(');
				setSnackOpen(true);
			}
		};
		const error = () => {
			setSnackSeverity('error');
			setSnackMessage('OOPs!.... A Required Field is not Populated');
			setSnackOpen(true);
		};

		formCountry === 0 || formTimeZone === 0 ? error() : updateRecord();
	};

	const insertEntityRecord = () => {
		const insertRecord = async () => {
			try {
				const response = await axios.post('/marketing/entities/postCreateEntityRecord', {
					ss_entity_type_id: selectedEntityTypeDropdown,
					business_name: formBusiness_name,
					address_1: formAddress1,
					address_2: formAddress2,
					attention_line: formAttentionLine,
					city: formCity,
					state: formUSAState,
					postal_code: formPostalCode,
					country_id: formCountry > 0 ? formCountry : null,
					latitude: formLatitude,
					longitude: formLongitude,
					timezone_id: formTimeZone > 0 ? formTimeZone : null,
					web_page: formWebPage,
					image_id: null,
					notes: formNotes,
					description: formDescription ? formDescription : '',
					detailed_description: formDetailDescription,
					motto: formMotto,
					primary_color: formPrimaryColor,
					secondary_color: formSecondaryColor,
					accent_color: formAccentColor,
					primary_identifier: formPrimaryIdentifier,
					deleted: formDelete,
				});
				setSnackSeverity('success');
				setSnackMessage('New Entry Created... Thanks!');
				setSnackOpen(true);
				setCallManufacturersList(callManufacturersList + 1);
				setEntityId(response.data.insertId);
			} catch (error) {
				setSnackSeverity('error');
				setSnackMessage('OOPs... Something Went Way South :(');
				setSnackOpen(true);
			}
		};
		const error = () => {
			setSnackSeverity('error');
			setSnackMessage('OOPs!.... A Required Field is not Populated');
			setSnackOpen(true);
		};

		formCountry === 0 || formTimeZone === 0 ? error() : insertRecord();
	};

	useEffect(() => {
		axios
			.get(`/marketing/entities/getEntityById/${entityId}`)
			.then((response) => {
				setFormPrimaryIdentifier(response.data[0]?.primary_identifier || 0);
				setFormBusiness_name(response.data[0]?.business_name || '');
				setFormAttentionLine(response.data[0]?.attention_line || '');
				setFormAddress1(response.data[0]?.address_1 || '');
				setFormAddress2(response.data[0]?.address_2 || '');
				setFormCity(response.data[0]?.city || '');
				setFormUSAState(response.data[0]?.state || '');
				setFormPostalCode(response.data[0]?.postal_code || 0);
				setFormLatitude(response.data[0]?.latitude || '');
				setFormLongitude(response.data[0]?.longitude || '');
				setFormTimeZone(response.data[0]?.timezone_id || 50);
				setFormCountry(response.data[0]?.country_id || 1000);
				setFormWebPage(response.data[0]?.web_page || '');
				setFormNotes(response.data[0]?.notes || '');
				setFormDescription(response.data[0]?.description || '');
				setFormDetailDescription(response.data[0]?.detailed_description || '');
				setFormMotto(response.data[0]?.motto || '');
				setFormPrimaryColor(response.data[0]?.primary_color || '');
				setFormSecondaryColor(response.data[0]?.secondary_color || '');
				setFormAccentColor(response.data[0]?.accent_color || '');
				setFormDelete(response.data[0]?.deleted || false);
			})
			.catch((error) => {
				// Handle error
				console.error('Error fetching data:', error);
			});
	}, [entityId]);

	useEffect(() => {
		axios
			.get(`/marketing/getAllVendors`)
			.then((response) => {
				setAllVendors(response.data);
			})
			.catch((error) => {
				// Handle error
				console.error('Error fetching data:', error);
			});
	}, []);

	useEffect(() => {
		axios
			.get(`/manufacturers/`)
			.then((response) => {
				setAllManufacturers(response.data);
			})
			.catch((error) => {
				// Handle error
				console.error('Error fetching data:', error);
			});
	}, [callManufacturersList]);

	// useEffect(() => {
	// 	const func = () => {
	// 		axios
	// 			.get(`/marketing/entities/getEntitiesByTypeId/${selectedEntityType}`)
	// 			.then((response) => {
	// 				// Populate the drop down for when selectedEntityType is not zero
	// 				setSelectedEntities(response.data);

	// 				// upadate dropdown label based upon selectedEntityType
	// 				switch (selectedEntityType) {
	// 					case 1:
	// 						setEntitySelectLabel('Selected Rep Group Entity');
	// 						break;
	// 					case 4:
	// 						setEntitySelectLabel('Selected Public Entity');
	// 						break;
	// 					case 3:
	// 						setEntitySelectLabel('Selected Vendor Entity');
	// 						break;
	// 					case 2:
	// 						setEntitySelectLabel('Selected Manufacturer Entity');
	// 						break;
	// 					default:
	// 						setEntitySelectLabel('Select Entity Type');
	// 				}
	// 				//setSelectedEntityTypeDropdown(0);
	// 				//resetFormState();
	// 				setSelectedEntityTypeDropdown(3416);
	// 			})
	// 			.catch((error) => {
	// 				// Handle error
	// 				console.error('Error fetching data:', error);
	// 			});
	// 	};

	// 	const zeroType = () => {
	// 		resetFormState();
	// 		setEntitySelectLabel('Select Entity Type');
	// 	};

	// 	selectedEntityType !== 0 ? func() : zeroType();
	// }, [selectedEntityType]);

	useEffect(() => {
		const func = () => {
			axios
				.get(`/marketing/entities/getEntitiesByTypeId/${selectedEntityType}`)
				.then((response) => {
					// Populate the drop down for when selectedEntityType is not zero
					setSelectedEntities(response.data);

					// upadate dropdown label based upon selectedEntityType
					// switch (selectedEntityType) {
					// 	case 1:
					// 		setEntitySelectLabel('Selected Rep Group Entity');
					// 		break;
					// 	case 4:
					// 		setEntitySelectLabel('Selected Public Entity');
					// 		break;
					// 	case 3:
					// 		setEntitySelectLabel('Selected Vendor Entity');
					// 		break;
					// 	case 2:
					// 		setEntitySelectLabel('Selected Manufacturer Entity');
					// 		break;
					// 	default:
					// 		setEntitySelectLabel('Select Entity Type');
					// }
					//setSelectedEntityTypeDropdown(0);
					//resetFormState();
					setSelectedEntityTypeDropdown(entityId);
				})
				.catch((error) => {
					// Handle error
					console.error('Error fetching data:', error);
				});
		};

		const zeroType = () => {
			resetFormState();
			setEntitySelectLabel('Select Entity Type');
		};

		selectedEntityType !== 0 ? func() : zeroType();
	}, [selectedEntityType, entityId, callManufacturersList]);

	useEffect(() => {
		axios
			.get(`/marketing/entities/getTimeZones`)
			.then((response) => {
				setTimeZones(response.data);
			})
			.catch((error) => {
				// Handle error
				console.error('Error fetching data:', error);
			});
	}, []);

	useEffect(() => {
		axios
			.get(`/marketing/entities/getCountries`)
			.then((response) => {
				setCountries(response.data);
			})
			.catch((error) => {
				// Handle error
				console.error('Error fetching data:', error);
			});
	}, []);

	console.log('onload selectedEntityTypeDropdown: ' + selectedEntityTypeDropdown);
	console.log('onload entity id: ' + entityId);
	console.log('selected entity type: ' + selectedEntityType);
	return (
		<React.Fragment>
			<Box
				sx={{ my: 5, border: formDelete === 1 ? 'solid 2px red' : 'none', p: 2 }}
				display='grid'
				gridTemplateColumns={'repeat(12, 1fr)'}
				gap={4}
			>
				<Box
					sx={{
						gridColumn: 'span 12',
						textAlign: 'right',
					}}
				>
					<Button
						variant='contained'
						sx={{ fontWeight: 'bold' }}
						color='warning'
						endIcon={<AddCircleOutline />}
						onClick={() => handleAddNewEntityButtonClick()}
					>
						Add New Entity?
					</Button>
				</Box>
				<FormControl sx={{ gridColumn: 'span 3' }}>
					<InputLabel id='manufacturer-entity'>
						{DropDownLabels.filter((e) => e.label_id === selectedEntityType).map((l) => (
							<React.Fragment key={l.label_id}>{l.label_text}</React.Fragment>
						))}
					</InputLabel>
					{selectedEntityType === 0 ? (
						<Select
							labelId='manufacturer-entity'
							open={entityIdOpen}
							onClose={() => setEntityIdOpen(false)}
							onOpen={() => setEntityIdOpen(true)}
							value={selectedEntityTypeDropdown}
							label={DropDownLabels.filter((e) => e.label_id === selectedEntityType).map((l) => (
								<React.Fragment key={l.label_id}>{l.label_text}</React.Fragment>
							))}
							onChange={(e) => handleSettingSelectedEntityTypeDropdown(e.target.value)}
						>
							<MenuItem value={0}>
								<em>{selectedEntityType === 0 ? 'Choose Entity Type' : 'Choose Entity'}</em>
							</MenuItem>
							<MenuItem value={1}>Rep Groups</MenuItem>
							<MenuItem value={2}>Manufacturers</MenuItem>
							<MenuItem value={3}>Vendors</MenuItem>
							<MenuItem value={4}>Public Brands</MenuItem>
						</Select>
					) : (
						<Select
							labelId='manufacturer-entity'
							open={entityIdOpen}
							onClose={() => setEntityIdOpen(false)}
							onOpen={() => setEntityIdOpen(true)}
							value={entityId}
							label={DropDownLabels.filter((e) => e.label_id === selectedEntityType).map((l) => (
								<React.Fragment key={l.label_id}>{l.label_text}</React.Fragment>
							))}
							onChange={(e) => handleEntityDropdownChange(e)}
						>
							<MenuItem value={0}>
								<em>{selectedEntityType === 0 ? 'Choose Entity Type' : 'Choose Entity'}</em>
							</MenuItem>
							{selectedEntities.map((e, i) => (
								<MenuItem
									key={i}
									value={e.ss_entity_id}
									sx={{ color: e.deleted === 1 ? 'red' : 'inherit' }}
								>
									{e.business_name}
									{e.primary_identifier < 1 && [2, 3].includes(selectedEntityType) ? (
										<span
											style={{
												color: 'orange',
												display: 'inline-block',
												paddingLeft: '6px',
												fontSize: '.7em',
											}}
										>
											NO PI
										</span>
									) : null}
								</MenuItem>
							))}
						</Select>
					)}
				</FormControl>
				{(selectedEntityType === 3 || selectedEntityTypeDropdown === 3) && (
					<FormControl sx={{ gridColumn: 'span 3' }}>
						<InputLabel id='primary-identifier'>TSW Vendors</InputLabel>
						<Select
							labelId='primary-identifier'
							open={primaryIdentifierOpen}
							onClose={() => setPrimaryIdentifierOpen(false)}
							onOpen={() => setPrimaryIdentifierOpen(true)}
							value={formPrimaryIdentifier}
							label='TSW Vendor'
							onChange={(e) => setFormPrimaryIdentifier(e.target.value)}
						>
							<MenuItem value={0}>
								<em>None</em>
							</MenuItem>
							{allVendors.map((m, i) => (
								<MenuItem
									key={i}
									value={m.vendor_id}
									sx={{ color: m.has_entry === 0 ? 'red' : 'inherit' }}
								>
									{m.description}{' '}
									{m.primary_identifier < 1 && [2, 3].includes(selectedEntityType) ? (
										<span
											style={{
												color: 'orange',
												display: 'inline-block',
												paddingLeft: '6px',
												fontSize: '.7em',
											}}
										>
											NO PI
										</span>
									) : null}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)}

				{(selectedEntityType === 2 || selectedEntityTypeDropdown === 2) && (
					<FormControl sx={{ gridColumn: 'span 3' }}>
						<InputLabel id='primary-identifier'>TSW Manufacturer</InputLabel>
						<Select
							labelId='primary-identifier'
							open={primaryIdentifierOpen}
							onClose={() => setPrimaryIdentifierOpen(false)}
							onOpen={() => setPrimaryIdentifierOpen(true)}
							value={formPrimaryIdentifier}
							label='TSW Manufacturer'
							onChange={(e) => handleSettingPrimaryIdentifier(e)}
						>
							<MenuItem value={0}>
								<em>None</em>
							</MenuItem>
							{allManufacturers.map((m, i) => (
								<MenuItem
									key={i}
									value={m.product_manufacturer_id}
									sx={{ color: m.has_entry === 0 ? 'red' : 'inherit' }}
								>
									{m.description}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)}
				<FormControl
					sx={{
						gridColumn:
							[2, 3].includes(selectedEntityType) || [2, 3].includes(selectedEntityTypeDropdown)
								? 'span 3'
								: 'span 5',
					}}
				>
					<TextField
						label='Business Name'
						value={formBusiness_name}
						onChange={(e) => setFormBusiness_name(e.target.value)}
					/>
				</FormControl>
				<FormControl
					sx={{
						gridColumn:
							[2, 3].includes(selectedEntityType) || [2, 3].includes(selectedEntityTypeDropdown)
								? 'span 3'
								: 'span 4',
					}}
				>
					<TextField
						label='Attention Name'
						value={formAttentionLine}
						onChange={(e) => setFormAttentionLine(e.target.value)}
					/>
				</FormControl>
				<FormControl sx={{ gridColumn: 'span 6' }}>
					<TextField
						label='Address 1'
						value={formAddress1}
						onChange={(e) => setFormAddress1(e.target.value)}
					/>
				</FormControl>
				<FormControl sx={{ gridColumn: 'span 6' }}>
					<TextField
						label='Address 2'
						value={formAddress2}
						onChange={(e) => setFormAddress2(e.target.value)}
					/>
				</FormControl>
				<FormControl sx={{ gridColumn: 'span 2' }}>
					<TextField label='City' value={formCity} onChange={(e) => setFormCity(e.target.value)} />
				</FormControl>
				{/* USA STATES */}
				<FormControl sx={{ gridColumn: 'span 2' }}>
					<InputLabel id='usa-state'>State</InputLabel>
					<Select
						labelId='usa-state'
						open={usaStateOpen}
						onClose={() => setUSAStateOpen(false)}
						onOpen={() => setUSAStateOpen(true)}
						value={formUSAState}
						label='State'
						onChange={(e) => setFormUSAState(e.target.value)}
					>
						<MenuItem value={0}>
							<em>Choose State</em>
						</MenuItem>
						{usaStates.map((e, i) => (
							<MenuItem key={i} value={e}>
								{e}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl sx={{ gridColumn: 'span 2' }}>
					<TextField
						label='Zip Code'
						value={formPostalCode}
						onChange={(e) => setFormPostalCode(e.target.value)}
					/>
				</FormControl>
				<FormControl sx={{ gridColumn: ' 7 / span 3' }}>
					<TextField
						label='Latitude'
						value={formLatitude}
						onChange={(e) => setFormLatitude(e.target.value)}
						InputProps={{
							readOnly: true,
						}}
					/>
				</FormControl>
				<FormControl sx={{ gridColumn: 'span 3' }}>
					<TextField
						label='Longitude'
						value={formLongitude}
						onChange={(e) => setFormLongitude(e.target.value)}
						InputProps={{
							readOnly: true,
						}}
					/>
				</FormControl>
				{/* SET TIME ZONES */}
				<FormControl sx={{ gridColumn: 'span 3' }}>
					<InputLabel id='time-zone'>Time Zone</InputLabel>
					<Select
						labelId='time-zone'
						open={timeZoneOpen}
						onClose={() => setTimeZoneOpen(false)}
						onOpen={() => setTimeZoneOpen(true)}
						value={formTimeZone}
						label='Time Zone'
						onChange={(e) => setFormTimeZone(e.target.value)}
					>
						<MenuItem value={null}>
							<em>UnKnown</em>
						</MenuItem>
						{timeZones.map((m, i) => (
							<MenuItem key={i} value={m.timezone_id}>
								{m.timezone_identifier}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				{/* SET COUNTRY */}
				<FormControl sx={{ gridColumn: 'span 3' }}>
					<InputLabel id='country'>Country</InputLabel>
					<Select
						labelId='country'
						open={contriesOpen}
						onClose={() => setCountriesOpen(false)}
						onOpen={() => setCountriesOpen(true)}
						value={formCountry}
						label='Country'
						onChange={(e) => setFormCountry(e.target.value)}
					>
						<MenuItem value={null}>
							<em>UnKnown</em>
						</MenuItem>
						{countries.map((e, i) => (
							<MenuItem key={i} value={e.country_id}>
								{e.country_name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl sx={{ gridColumn: 'span 3' }}>
					<TextField label='Website' value={formWebPage} onChange={(e) => setFormWebPage(e.target.value)} />
				</FormControl>
				<FormControl sx={{ gridColumn: 'span 3' }}>
					<TextField label='Note' value={formNotes} onChange={(e) => setFormNotes(e.target.value)} />
				</FormControl>
				<FormControl sx={{ gridColumn: 'span 12' }}>
					<TextField
						label='Description'
						multiline
						rows={2}
						value={formDescription}
						onChange={(e) => setFormDescription(e.target.value)}
					/>
				</FormControl>
				<FormControl sx={{ gridColumn: 'span 12' }}>
					<TextField
						label='Detailed Description'
						multiline
						rows={4}
						value={formDetailDescription}
						onChange={(e) => setFormDetailDescription(e.target.value)}
					/>
				</FormControl>
				<FormControl sx={{ gridColumn: 'span 6' }}>
					<TextField label='Motto' value={formMotto} onChange={(e) => setFormMotto(e.target.value)} />
				</FormControl>
				<FormControl sx={{ gridColumn: 'span 2' }}>
					<TextField
						label='Primary Color'
						value={formPrimaryColor}
						onChange={(e) => setFormPrimaryColor(e.target.value)}
					/>
				</FormControl>
				<FormControl sx={{ gridColumn: 'span 2' }}>
					<TextField
						label='Secondary Color'
						value={formSecondaryColor}
						onChange={(e) => setFormSecondaryColor(e.target.value)}
					/>
				</FormControl>
				<FormControl sx={{ gridColumn: 'span 2' }}>
					<TextField
						label='Accent Color'
						value={formAccentColor}
						onChange={(e) => setFormAccentColor(e.target.value)}
					/>
				</FormControl>
				{entityId !== 0 && (
					<FormControlLabel
						sx={{ gridColumn: 'span 12' }}
						control={
							<Switch
								sx={{ ml: 'auto', mr: 0 }}
								checked={formDelete}
								color='error'
								onChange={(e) => setFormDelete(e.target.checked)}
							/>
						}
						label='Delete?'
					/>
				)}
				<FormControl sx={{ gridColumn: 'span 12' }}>
					<Button
						sx={{ ml: 'auto', mr: 0 }}
						variant='contained'
						onClick={() => handleOnSubmit()}
						color='success'
					>
						{entityId === 0 ? 'Create Entity?' : 'Update Data'}
					</Button>
				</FormControl>
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snackOpen}
				autoHideDuration={3000}
				onClose={() => setSnackOpen(false)}
			>
				<Alert variant='filled' severity={snackSeverity}>
					{snackMessage}
				</Alert>
			</Snackbar>
		</React.Fragment>
	);
};

export default EntityForm;
