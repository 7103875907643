import {
  Grid,
  TextField,
  Typography,
  FormControl,
  Button,
  CircularProgress, 
  Backdrop,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper 
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';

const UnbusyOrderForm = () => {
  // onchange states
  const [orderNumber, setOrderNumber] = useState('');
  const [textSpan, setTextSpan] = useState('');
  const [orderSpan, setOrderSpan] = useState(false);  
  const [tableData, setTableData] = useState([]);
  // Loading state
  const [isLoading, setIsLoading] = useState(false); 

  const handleSearch = (e) => {
    e.preventDefault();

    setIsLoading(true);
    // console.log('orderNumber', orderNumber);
    
    if (orderNumber != '') {
      axios
      .post('/hubRelay', {
        j: '/webservice/hub/helpdesk_utilities/orders/unbusy_order.php?INITIAL_PULL=Y&ORDER_NUMBER='+orderNumber
      })
      .then((response) => {
        // handle the response
        console.log(response.data);
        
        if (response.data.RESULT == 'OK') {
          setOrderSpan(true);
          setTableData(response.data.EXISTS);
        } 
        
        setTextSpan(response.data.MESSAGE);        
        setIsLoading(false);
      })
      .catch((error) => {
        // handle errors
        console.log(error);
        setIsLoading(false);
      });

    } else {
      setIsLoading(false);
      console.log('missing order#...');
    } 
  };

  const handleUnBusy = (e) => {
    e.preventDefault();

    setIsLoading(true);
    // console.log('orderNumber', orderNumber);
    
    if (orderSpan === true && orderNumber !== '') {
      axios
      .post('/hubRelay', {
        j: '/webservice/hub/helpdesk_utilities/orders/unbusy_order.php?ORDER_NUMBER='+orderNumber
      })
      .then((response) => {
        // handle the response
        console.log(response.data);

        if (response.data.RESULT == 'OK') {
          setTableData(response.data.UPDATED);
        }         
        setTextSpan(response.data.MESSAGE);
        setIsLoading(false);
      })
      .catch((error) => {
        // handle errors
        console.log('error', error);        
        setIsLoading(false);
      });

    } else {
      setIsLoading(false);
      console.log('inside the else...');      
    } 
  };

  
  const handleClear = (e) => {
    e.preventDefault();
    console.log('checking states');
    setTableData([]);
    setOrderNumber('');
    setTextSpan('');
    setOrderSpan(false);
  };


  return (
    <Grid container spacing={5} sx={{ p: 4 }}>
    
      <Grid item xs={12} style={{paddingTop: '10px', marginBottom: 40}}>
        <Typography variant='h6' sx={{textAlign: 'left', mt: 1}}>Unbusy Orders</Typography>
      </Grid>
      <Grid item xs={12} style={{paddingTop: '20px'}}>Search for Order...</Grid>

      <Grid item xs={4} md={3} sx={{textAlign: 'left', mt: 1}} style={{paddingTop: '10px'}}>
        <FormControl fullWidth={true}>
          <TextField
            value={orderNumber}
            onChange={(e) => setOrderNumber(e.target.value)}
            label="ORDER #"
          />
        </FormControl>
      </Grid>
      <Grid item xs={2}></Grid>

      <Grid item xs={5} md={6} style={{paddingTop: '20px'}}>
        {(orderSpan ==true) &&
          <Paper sx={{ width: '100%', overflowX: 'initial' }}>
            <TableContainer sx={{ maxHeight: 350 }}>          
              <Table stickyHeader size="small" aria-label="a dense table">
                <TableHead>                  
                    <TableRow>                        
                      <TableCell component="th" scope="row">ORDER#</TableCell>
                      <TableCell component="th" scope="row">ORDER SLS#</TableCell>
                      <TableCell component="th" scope="row">BUSY SLS#</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>                                                          
                  <TableRow  sx={{ '&:last-child td, &:last-child th, & td': { border: 0 }}}>                      
                    {Object.entries(tableData).map(([key, detail]) => (                                
                      <TableCell key={key} value={detail} component="td" scope="row">{detail}</TableCell>                                       
                    ))}
                  </TableRow>
                </TableBody>
              </Table>            
            </TableContainer>
          </Paper>
        }
      </Grid>
      
      {(orderSpan ==true) && <Grid item xs={12} style={{paddingTop: '20px'}}>confirm the order# is correct then unbusy...</Grid>} 
      <Grid item xs={3} md={2}>
        {orderSpan === false && <Button variant='contained' color='success' onClick={handleSearch}>
          Search
        </Button>}
        {orderSpan === true &&
          <Button variant='contained' color='primary' onClick={handleUnBusy}>
            UnBusy
          </Button>
        }
      </Grid>
      <Grid item xs={3} md={2} style={{paddingLeft: '0px'}}>
        <Button variant='contained' color='error' onClick={handleClear}>
          Clear
        </Button>
      </Grid>
      <Grid item xs={6} md={8} style={{paddingTop: '20px'}}>
        {textSpan && <Typography variant='h6' sx={{textAlign: 'left', mt: 1}}>{textSpan}</Typography>}
      </Grid>
      
      {isLoading && (
        <Backdrop
          open={isLoading}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}
        >
          <CircularProgress sx={{color: '#0FFF00'}} />
        </Backdrop>
      )}
    </Grid>
  );
};

export default UnbusyOrderForm;
