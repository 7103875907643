import React, { useContext, useEffect, useState } from 'react';
import { Autocomplete, Box, CircularProgress, TextField, useTheme } from '@mui/material';
import AssociationEditCard from '../../EditContact/components/AssociationEditCard';
import { EntityContextState } from '../../../../../Context/Marketing/EntityContext';
import { EntityContactContextState } from '../../../../../Context/Marketing/EntityContactContext';
import AssociationEditCardV2 from '../../EditContact/components/AssociationEditCardV2';

const Associations = ({ allAssociationsData, contactId }) => {
	// INIT's
	const theme = useTheme();

	// CONTEXT STATE
	const { allEntities, allTypes } = useContext(EntityContextState);
	const { allAssociationLink, allEmails, allPhoneNumbers, allAssociationRolesTypes, allPhoneTypes } =
		useContext(EntityContactContextState);

	// LOCAL STATE
	const [loading, setLoading] = useState(true);
	const [allEntitiesState, setAllEntitiesState] = useState([]);
	const [allAssociationsState, setAllAssociationsState] = useState([]);
	const [selectedEntity, setSelectedEntity] = useState(null);
	const [selectedEntityInputValue, setSelectedEntityInputValue] = useState('');

	// USE EFFECTS
	useEffect(() => {
		try {
			const filteredAssociations = allAssociationLink.filter((e) => e.entity_contact_id === contactId);
			setAllAssociationsState([...filteredAssociations]);
			setLoading(false);
		} catch (error) {
			console.log('error: ', error);
		}
	}, [allAssociationLink, allEntities, contactId]);

	useEffect(() => {
		let options = [];
		try {
			allEntities.forEach((e) => {
				options.push({ id: e.entity_id, label: e.business_name });
			});
			setAllEntitiesState([...options]);
			setLoading(false);
		} catch (error) {
			console.log('error: ', error);
		}
	}, [allAssociationsData, allEntities]);

	if (loading) {
		return (
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				<CircularProgress />
			</Box>
		);
	}

	const newAssociationForm = (value) => {
		if (value !== null) {
			setSelectedEntityInputValue(value || '');
			setAllAssociationsState((prev) => [
				{
					entity_contact_link_id: 0,
					entity_contact_id: contactId,
					entity_id: value.id,
					business_name: allEntities.find((e) => e.entity_id === value.id).business_name,
				},
				...prev,
			]);
		}
	};

	return (
		<Box
			sx={{
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%,-50%)',
				width: `${allAssociationsState.length * 385}px`,
				maxWidth: '90%',
			}}
		>
			<Box
				sx={{
					textAlign: 'center',
					p: '16px 16px',
					mb: '24px',
					width: '328px',
					mx: 'auto',
					borderRadius: '16px',
					border: `solid 0px ${theme.palette.primary.alt}`,
					backgroundColor: 'transparent',
				}}
			>
				<Box
					sx={{
						color: theme.palette.primary.alt,
						fontWeight: 'bold',
						fontSize: '1.2em',
						textAlign: 'left',
					}}
				>
					Choose Entity To Add:
				</Box>
				<Autocomplete
					options={allEntitiesState}
					value={selectedEntity}
					onChange={(event, newValue) => {
						if (newValue !== null) {
							console.log('newValue: ', newValue);
							setSelectedEntity(newValue);
							newAssociationForm(newValue);
						} else {
							console.log('newValuenull: ', newValue);
							setSelectedEntity(null);
						}
					}}
					getOptionLabel={(option) => option.label}
					isOptionEqualToValue={(option, value) => {
						return option?.entity_id === value?.entity_id;
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							label=''
							variant='standard'
							sx={{
								'& .MuiInput-root::before ': {},
							}}
						/>
					)}
				/>

				{/* <Autocomplete
					value={selectedEntity}
					onChange={(event, newValue) => {
						setSelectedEntity(newValue);
						newAssociationForm(newValue);
					}}
					inputValue={selectedEntityInputValue}
					// onInputChange={(event, newInputValue) => {
					// 	//newAssociationForm(newInputValue);
					// }}
					options={allEntitiesState}
					getOptionLabel={(option) => option.label}
					isOptionEqualToValue={(option, value) => {
						return option?.entity_id === value?.entity_id;
					}}
					sx={{ width: 300, mx: 'auto' }}
					renderInput={(params) => <TextField {...params} label='Controllable' />}
					clearOnEscape
				/> */}

				{/* <Autocomplete
					disablePortal
					value={selectedEntity}
					// onChange={(event, newValue) => {
					// 	if (newValue === null) {
					// 		setSelectedEntityInputValue('');
					// 		setSelectedEntity(null);
					// 	} else {
					// 		setSelectedEntity(newValue);
					// 	}
					// }}
					onChange={(event, newValue) => {
						if (!newValue) {
							setSelectedEntity(null);
							setSelectedEntityInputValue('');
						} else {
							setSelectedEntity(newValue);
						}
					}}
					inputValue={selectedEntityInputValue}
					onInputChange={(event, newInputValue) => {
						newAssociationForm(newInputValue);
					}}
					options={allEntitiesState}
					getOptionLabel={(option) => (option && option.business_name ? option.business_name : 'abc')}
					// isOptionEqualToValue={(option, value) => option.entity_id === value?.entity_id}
					isOptionEqualToValue={(option, value) => {
						console.log('Option:', option.business_name, ' === ', value.business_name);

						return option?.entity_id === value?.entity_id;
					}}
					sx={{ width: 300, backgroundColor: 'white', border: 'none', outline: 'none', color: 'red' }}
					renderInput={(params) => <TextField {...params} label='Choose Entity' sx={{ color: 'red' }} />}
					clearOnEscape
				/> */}
			</Box>
			{/* <Box
				sx={{
					width: '100%',
					display: 'flex',
					flexWrap: 'wrap',
					gap: '24px',
					justifyContent: 'center',
					overflowY: 'auto',
					maxHeight: '90vh',
				}}
			>
				{allAssociationsState
					.filter((e) => e.entity_contact_link_id !== null)
					.map((asso, i) => (
						<React.Fragment key={i}>
							<AssociationEditCard
								item={asso}
								associationId={asso.entity_contact_link_id}
								contactId={contactId}
								selectedEntityId={selectedEntity?.id}
							/>
						</React.Fragment>
					))}
			</Box> */}

			<Box
				sx={{
					width: '100%',
					display: 'flex',
					flexWrap: 'wrap',
					gap: '24px',
					justifyContent: 'center',
					overflowY: 'auto',
					maxHeight: '90vh',
				}}
			>
				{allAssociationsState
					.filter((e) => e.entity_contact_link_id !== null)
					.map((asso, i) => (
						<React.Fragment key={i}>
							<AssociationEditCardV2
								allEmails={allEmails}
								allAssociationRolesTypes={allAssociationRolesTypes}
								allPhoneNumbers={allPhoneNumbers}
								allPhoneTypes={allPhoneTypes}
								item={asso}
								associationId={asso.entity_contact_link_id}
								contactId={contactId}
								selectedEntityId={selectedEntity?.id}
							/>
						</React.Fragment>
					))}
			</Box>
		</Box>
	);
};

export default Associations;
