import React, { useContext, useState } from 'react';
import { EntityContextState } from '../../../../../../Context/Entity/EntityContext';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { IconButton } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export default function DeleteEmailFromContact({ entity_contact_email_id, removeDeletedEmail }) {
	const [open, setOpen] = useState(false);

	const { updateTriggerRefreshContactData } = useContext(EntityContextState);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const deleteEmailAddress = async () => {
		try {
			const response = await axios.post('/entity/deleteEmailFromContact', {
				entity_contact_email_id: entity_contact_email_id,
			});

			removeDeletedEmail(entity_contact_email_id);
			updateTriggerRefreshContactData();
			handleClose();
		} catch (error) {
			console.error('Error:', error);
		}
	};

	return (
		<React.Fragment>
			<IconButton onClick={() => handleClickOpen()}>
				<DeleteOutline color='error' />
			</IconButton>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-describedby='alert-dialog-slide-description'
			>
				<DialogTitle>{'Deleteing Email Address!'}</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-slide-description'>
						Are you sure you're want to permentily remove this email address?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button color='error' onClick={handleClose}>
						No
					</Button>
					<Button color='success' onClick={() => deleteEmailAddress()}>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}
