import { TextField, Typography, Stack, Button, Grid, Autocomplete, Paper, InputBase, IconButton, Select, MenuItem } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { ManageSearch, Search, Construction, Flag, Rule, PlaylistRemove } from "@mui/icons-material";
import { DateCalendar, DatePicker } from "@mui/x-date-pickers";
import { useEffect, useRef, useState } from "react";
import axios from "axios";

export default function PDMSearch(props){
    let value = useRef('')
    let date = useRef('')
    let type = useRef('')
    let department = useRef('')
    let category = useRef('')
    let brand = useRef('')
    const [categoryOptions, setCategoryOptions] = useState([{label: 'Loading', id: 0}])
    const [departmentOptions, setDepartmentOptions] = useState([{label: 'Loading', id: 0}])
    const [brandOptions, setBrandOptions] = useState([{label: 'Loading', id: 0}])
    const [searchType, setSearchType] = useState('upc')
      
    const searchValues = (val, type) => {

        let results = {
            value: val, 
            type: type
        }
        //console.log(results)
        props.searchCallback(results)
    }

    useEffect(() => {
        const getCategories = () => {
        axios.get('/productData/getActiveCategories')
        .then((res) => {
            let newList =[]
            res.data.map((res) => {
                newList.push({'label': res.description, 'search': res.product_category_id})
            })
            setCategoryOptions(newList)
        }).catch((err) => {
            console.log(err)
        })
        } 

        const getDepartments = () => {
            axios.get('/productData/getActiveDepartments').then((res) => {
                let newList = []
                res.data.map((res) => {
                    newList.push({'label': res.description, 'search': res.product_department_id})
                })
                setDepartmentOptions(newList)
            }).catch((err) => {
                console.log(err)
            })

        }

        const getBrands = () => {
            axios.get('/productData/getVendors').then((res) => {
                let newList = []
                res.data.map((res) => {
                    newList.push({'label': res.description, 'search': res.product_department_id})
                })
                setBrandOptions(newList)
            }).catch((err) => {
                console.log(err)
            })

        }

        getDepartments()
        getCategories()
        getBrands()
    },[])
    
    const handleSearchTypeChange = (val) => {
        console.log(val)
        setSearchType(val.target.value)
    }

    return(
        <div style={{ alignItems:'flex-start', justifyContent:'center'} }>
            <Stack alignItems={'flex-start'} justifyItems={'center'}  >
                <Stack 
                    direction={"row"} 
                    marginBottom={3}  
                    alignItems={'center'}
                    bgcolor={'#266391'}
                    width={'100%'}
                    paddingLeft={1}
                >
                <Search sx={{color:'#f5f5f5'}} fontSize="small"/>
                <Typography 
                    alignItems={'flex-start'}
                    alignContent={'center'}
                    paddingLeft={1}
                    fontWeight={'bold'}
                    color={'whitesmoke'}
                    fontStyle={'italic'}
                >
                    Product Data Management Search
                </Typography>
                </Stack>
                <Stack 
                    direction={'row'}
                    spacing={2}
                    paddingBottom={2}
                    paddingLeft={1}
                    alignSelf={'center'}
                >
                    <Stack 
                        direction={'row'}
                        spacing={0}
                        alignSelf={'center'}
                    >
                        <Paper
                            sx={{ paddingLeft:'12px', display:'flex', alignItems: 'center'}}
                        >
                            <InputBase
                                placeholder="Search"
                                aria-label="Search"
                                onInput={(event) => {
                                    value.current = event.target.value
                                }}
                                onKeyUp={(event) => {
                                    if(event.code ==="Enter" || event.code === "NumpadEnter"){
                                        console.log('keyUp')
                                        searchValues({label: searchType, search: value.current}, searchType)
                                    }
                                }}
                            />
                        <IconButton onClick={() => searchValues({label: searchType, search: value.current}, searchType)}>
                            <Search />
                        </IconButton>
                    </Paper>
                    <Select
                        labelId="search-type"
                        id="search-type-box"
                        value={searchType}
                        label={'searchType'}
                        onChange={handleSearchTypeChange}
                    >
                        <MenuItem value={'upc'}>UPC Code</MenuItem>
                        <MenuItem value={'manufacturer'}>Manufacturer #</MenuItem>
                        <MenuItem value={'scan'}>Scan</MenuItem>
                        <MenuItem value={'sports_south'}>Sports South #</MenuItem>
                    </Select>
                    </Stack>
                    <DatePicker onChange={(value) => {
                        searchValues(value.$d, 'dateSearch')
                    }} 
                    label={'Recently Worked'}
                    />
                </Stack>
                <Grid2 
                    container
                    spacing={{xs: 1, md: 2}} 
                    alignItems={'center'} 
                    alignSelf={'center'}
                    columns={{xs: 4, sm: 8, md:12}}
                    paddingLeft={1}
                    maxWidth={1400}
                >
                    <Grid2 xs={3} sm={5} md={4}>
                        <Autocomplete
                            disableClearable
                            options={departmentOptions}
                            isOptionEqualToValue={(option, newValue) => option.label === newValue.label}
                            onChange={(event, val) => {
                                searchValues(val, 'department')
                            }}
                            renderInput={(params) => <TextField {...params} label="Search Department"/>}
                        />
                    </Grid2>
                    <Grid2 xs={3} sm={5} md={4} >
                        <Autocomplete
                            isOptionEqualToValue={(option, newValue) => option.label === newValue.label}
                            onChange={(event, val) => {
                                searchValues(val, 'category')
                            }}
                            options={categoryOptions}
                            renderInput={(params) => <TextField {...params} label="Search Category"/>}
                        />
                    </Grid2>
                    <Grid2 xs={3} sm={5} md={4} >
                        <Autocomplete
                            isOptionEqualToValue={(option, newValue) => option.label === newValue.label}
                            onChange={(event, val) => {
                                searchValues(val, 'brand')
                            }}
                            options={brandOptions} 
                            renderInput={(params) => <TextField {...params} label="Search Brand"/>}
                        />
                    </Grid2>
                   
                <Grid2 xs={2} sm={4} md={4} alignContent={'flex-end'} >
                    <Button onClick={() => searchValues('Uncategorized', 'special')}><ManageSearch/>Uncategorized Items</Button>
                </Grid2>
                <Grid2 xs={2} sm={4} md={4} >
                    <Button onClick={() => searchValues('Unattributed', 'special')}><ManageSearch/>Unattributed Items</Button>
                </Grid2>
                <Grid2 xs={2} sm={4} md={4} >
                    <Button onClick={() => searchValues('Worn & Dented', 'special')}><Construction/>Worn & Dented</Button>
                </Grid2>
                <Grid2 xs={2} sm={4} md={4} >
                    <Button onClick={() => searchValues('My Flagged Items', 'special')}><Flag/>My Flagged Items</Button>
                </Grid2>
                <Grid2 xs={2} sm={4} md={4} >
                    <Button onClick={() => searchValues('All Flagged Items', 'special')}><Flag/>All Flagged Items</Button>
                </Grid2>
                <Grid2 xs={2} sm={4} md={4} >
                    <Button onClick={() => searchValues('Items w/ Few Attr', 'special')}><Rule/>Items w/ Few Attr</Button>
                </Grid2>
                <Grid2 xs={2} sm={4} md={4} >
                    <Button onClick={() => searchValues('Items without COO', 'special')}><PlaylistRemove/>Items without COO</Button>
                </Grid2>
                <Grid2 xs={2} sm={4} md={4} >
                    <Button onClick={() => searchValues('Missing True Value', 'special')}><Rule/>Missing True Value</Button>
                </Grid2>
                <Grid2 xs={2} sm={4} md={4} >   
                    <Button onClick={() => searchValues('Missing Max Capacity', 'special')}><Rule/>Missing Max Capacity</Button>
                </Grid2>
                </Grid2>
                <Stack 
                    direction={'row'} 
                    spacing={2} 
                    paddingTop={2} 
                    paddingLeft={1} 
                    paddingBottom={2}
                    alignSelf={'center'}
                >
                    <Button variant="contained">Logo Manager</Button>
                    <Button variant='contained'>Value Corrections</Button>
                </Stack>
            </Stack>
        </div>
    )
}