import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { selectedContactIdValue } from './../../../../Redux/EntitySlice';

import axios from 'axios';

// Material UI
import { Box, IconButton, Grid, Paper, Stack, styled, TextField, Typography, useTheme } from '@mui/material';
import SectionTitle from '../../../_GlobalComponents/SectionTitle.jsx';
import { Edit } from '@mui/icons-material';

// Material Styled Components
const Wrapper = styled(Box)(({ theme }) => ({}));

// Primary Component
const SearchContacts = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  // LOCAL STATE
  const [searchContactTerm, setSearchContactTerm] = useState('');
  const [searchContactResults, setSearchContactResults] = useState([]);
  const [contactEssentialInfo, setContactEssentialInfo] = useState([]);

  // FUNCTIONS
  const handleContactItemClick = (contact_id) => {
    dispatch(selectedContactIdValue(contact_id));
    searchContactResults
      .filter((items) => items.contact_id === contact_id)
      .map((item) =>
        setContactEssentialInfo({
          contact_id: item.contact_id,
          user_id: item.user_id,
          user_name: item.user_name,
          first_name: item.first_name,
          last_name: item.last_name,
          business_name: item.business_name,
          job_title: item.job_title,
          email_address: item.email_address,
          responsible_user_id: item.responsible_user_id,
          address_1: item.address_1,
          address_2: item.address_2,
          city: item.city,
          state: item.state,
          postal_code: item.postal_code,
          last_login: item.last_login,
        })
      );
  };

  const handleEditContactClick = (e, idx) => {
    e.stopPropagation();
    console.log('edit contact: ' + idx);
  };

  useEffect(() => {
    const fetchData = () => {
      searchContactTerm.length < 1
        ? setSearchContactResults([])
        : axios
            .get(`/entities/getContactsFromSearch/${searchContactTerm}`)
            .then(function (response) {
              const data = response.data;
              setSearchContactResults(data);
            })
            .catch((error) => {
              console.log('error: ' + error);
            });
    };

    fetchData();
  }, [searchContactTerm]);

  console.log(contactEssentialInfo);

  return (
    <Wrapper elevation={3}>
      <Paper elevation={3} sx={{ padding: '15px', mt: 2 }}>
        <SectionTitle title='Search Contacts' />
        <TextField label='Contact Name' variant='outlined' onKeyUp={(e) => e.code === 'Enter' && setSearchContactTerm(e.target.value)} sx={{ mb: 2 }} />

        <Stack>
          <Box>
            USER ID: {contactEssentialInfo.user_id} | Contact ID: {contactEssentialInfo.contact_id}
          </Box>
          <Box sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
            {contactEssentialInfo.first_name} {contactEssentialInfo.last_name}
          </Box>
          <Box sx={{ fontWeight: 'bold' }}>{contactEssentialInfo.business_name}</Box>
          <Box>{contactEssentialInfo.job_title}</Box>
          <Box>{contactEssentialInfo.address_1}</Box>
          <Box>{contactEssentialInfo.address_2}</Box>
          <Box>
            {contactEssentialInfo.city}, {contactEssentialInfo.city} {contactEssentialInfo.postal_code}
          </Box>
          <Box>{contactEssentialInfo.email_address}</Box>
        </Stack>
      </Paper>

      <Paper elevation={3} sx={{ padding: '15px', mt: 2 }}>
        <SectionTitle title='Search Results' />
        <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
          {searchContactResults.map((item, idx) => (
            <Box
              key={item.contact_id}
              sx={{
                p: 2,
                mb: 2,
                cursor: 'pointer',
                borderRadius: 2,
                border: `solid 1px transparent`,
                '&:hover': { borderColor: theme.palette.primary.main, backgroundColor: theme.palette.primary.mainScreen },
              }}
              onClick={() => handleContactItemClick(item.contact_id)}
            >
              <Grid container alignItems='center'>
                <Grid item>
                  <Typography component='div' sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
                    {item.last_name}, {item.first_name}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton color='success' aria-label='Edit Contact' component='label' onClick={(e) => handleEditContactClick(e, `${item.contact_id}`)}>
                    <Edit sx={{ fontSize: '1.2rem' }} />
                  </IconButton>
                </Grid>
              </Grid>
              <Typography component='div'>{item.business_name}</Typography>
              <Typography component='div'>{item.job_title}</Typography>
            </Box>
          ))}
        </Box>
      </Paper>
    </Wrapper>
  );
};

export default SearchContacts;
