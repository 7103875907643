// Material UI
import { Box, styled } from '@mui/material';

// Material Styled Components
const Wrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: 25,
  gridTemplateColumns: 'repeat(24, 1fr)',
  '&.fixed': {
    gridTemplateColumns: '1fr repeat(12, minmax(auto, 133px) ) 1fr',
  },
}));

// Primary Component
const GridContainer = ({ classes, children, styles }) => {
  return (
    <Wrapper className={classes} sx={styles}>
      {children}
    </Wrapper>
  );
};
export default GridContainer;
