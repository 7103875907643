import { Modal, Paper } from '@mui/material';
import React from 'react';
import WebPaymentReceiptsForm from './Components/WebPaymentReceiptsForm';

const WebPaymentReceipts = ({ open, onClose }) => {
  return (
    <Modal onClose={onClose} open={open} sx={{ overflowY: 'none'}} >
      <Paper sx={{ minHeight: '94vh',maxHeight: '94vh', mx: '1em', mt: '5em', top: '20'  }}>
        <WebPaymentReceiptsForm />
      </Paper>
    </Modal>
  );
};

export default WebPaymentReceipts;
