// columns.js
import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import RowEditAction from './RowEditAction';
import DeletePublicaitonActionButton from './DeletePublicationActionButton';
import InternalPublicaitonActionButton from './InternalPublicationActionButton';

const useColumns = () => {
	return useMemo(
		() => [
			{
				accessorFn: (row) => `${row.ss_publication_id} ${row.ss_publication_code}`,
				id: 'ss_publication_id',
				header: 'Publication Code',
				Cell: ({ cell }) => (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-around',
							gap: 4,
							alignItems: 'center',
							width: '100%',
						}}
					>
						<div style={{ width: '100%' }}>
							<RowEditAction
								id={cell.row.original.ss_publication_id}
								contractCount={cell.row.original.contract_count}
								codeValue={cell.row.original.ss_publication_code}
								pubTitle={cell.row.original.description}
							/>
						</div>
					</Box>
				),
				size: 200,
				grow: false,
			},

			{
				accessorKey: 'publication_type_description', // accessor is the "key" in the data
				header: 'Publication Type',
				size: 170,
				grow: false,
			},

			{
				accessorFn: (row) => `${row.description} ${row.detailed_description}`,
				id: 'description',
				header: 'Description',
				Cell: ({ cell }) => {
					return (
						<Box>
							<div style={{ fontWeight: 'bold' }}>{cell.row.original.description}</div>
							<div>{cell.row.original.detailed_description}</div>
						</Box>
					);
				},
				size: 250,
				grow: true,
			},
			{
				accessorKey: 'submit_contract_date', // accessor is the "key" in the data
				id: 'submit_contract_date',
				header: 'Contract Due',
				size: 170,
				grow: false,
			},
			{
				accessorKey: 'submit_art_date', // accessor is the "key" in the data
				id: 'submit_art_date',
				header: 'Art Due',
				size: 150,
				grow: false,
			},
			{
				accessorKey: 'production_start_date', // accessor is the "key" in the data
				id: 'production_start_date',
				header: 'Production',
				size: 150,
				grow: false,
			},
			{
				accessorKey: 'start_date', // accessor is the "key" in the data
				id: 'start_date',
				header: 'Start Date',
				size: 150,
				grow: false,
			},
			{
				accessorKey: 'expiration_date', // accessor is the "key" in the data
				id: 'expiration_date',
				header: 'Expiration Date',
				size: 166,
				grow: false,
			},
			{
				accessorFn: (row) => row.internal_use_only,
				id: 'internal_use_only',
				header: 'IUO',
				Cell: ({ cell, row }) => {
					return (
						<InternalPublicaitonActionButton
							className={`value${cell.getValue()}`}
							isChecked={parseInt(cell.getValue()) === 0 ? false : true}
							publicationId={cell.row.original.ss_publication_id}
						/>
					);
				},
				size: 100,
				grow: false,
			},
			{
				accessorFn: (row) => row.inactive,
				id: 'inactive',
				header: 'Active',
				Cell: ({ cell, row }) => {
					return (
						<DeletePublicaitonActionButton
							className={`value${cell.getValue()}`}
							isChecked={parseInt(cell.getValue()) === 1 ? false : true}
							publicationId={cell.row.original.ss_publication_id}
						/>
					);
				},
				size: 115,
				grow: false,
			},
			// Add more column definitions as needed
		],
		[]
	);
};

export default useColumns;
