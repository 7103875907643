import { Card, Grid, CardActionArea } from '@mui/material';
import React, { useState } from 'react';
// import LabelPrinting from './LabelPrinting/LabelPrinting';


const Invoices = () => {
  // const [isLabelPrintingOpen, setIsLabelPrintingOpen ] = useState(false);
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        {/* <CardActionArea>
          <Card onClick={() => setIsLabelPrintingOpen(true)} sx={{ pt:3,pb:3,pl:1,pr:1, textAlign:'center' }}>LABEL PRINTING</Card>
        </CardActionArea> */}
      </Grid>            
      {/* <Grid item xs={3}>
        <Card>EDI 3</Card>
      </Grid>
      <Grid item xs={3}>
        <Card>EDI 4</Card>
      </Grid> */}
      {/* <LabelPrinting
        open={isLabelPrintingOpen}
        onClose={() => setIsLabelPrintingOpen(false)}
      />       */}
    </Grid>
  );
};

export default Invoices;