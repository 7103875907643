import { Route, Routes } from 'react-router-dom';
import React from 'react';
// import NavBar from '../Components/PDM/NavBar/NavBar';
// import Footer from '../Components/PDM/Footer/Footer';
import EditProductData from '../Components/PDM/components/EditProductData/EditProductData';
import PDM from '../Components/PDM/PDM';
//import ProductDetails from '../Components/PDM/components/EditProductData/components/ProductDetails/ProductDetails';

const ProductDataManagementRoute = () => {
	return (
		<PDM>
			<Routes>
				<Route path='/edit-product-data' element={<EditProductData />} />
				{/* <Route path='/products/product-details/:id' element={<ProductDetails />} /> */}
				<Route path='/*' element={<h2>Page Not Found!</h2>} />
			</Routes>
		</PDM>
	);
};

export default ProductDataManagementRoute;
