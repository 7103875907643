import React, { useEffect, useState } from 'react';
import { getPublicationProductsByType } from '../../../../Publications/Feed/PrimaryFeed';
import { Box, MenuItem, TextField, Typography } from '@mui/material';

const PublicationProductsByType = ({ ss_publication_type_id, handleChange, selectedPublicationProductId }) => {
	const [data, setData] = useState([]);
	const [ssPublicationTypeId, setSsPublicationTypeId] = useState(0);

	const handleProductChange = (value) => {
		const [product_id, cost] = value.split('~');
		// handleChange('ss_publication_product_id', product_id);
		// cost !== undefined && handleChange('ss_publication_product_cost', cost);
		handleChange(product_id, cost);
	};

	// const handleClick = (product_id, cost) => {
	// 	handleChange('ss_publication_product_id', product_id);
	// 	handleChange('ss_publication_product_cost', cost);
	// };

	useEffect(() => {
		const fetching = async () => {
			setData(await getPublicationProductsByType());
		};
		fetching();
		setSsPublicationTypeId(ss_publication_type_id);
	}, [ss_publication_type_id]);

	return (
		<TextField
			sx={{ mb: '24px' }}
			fullWidth
			select
			label='Choose Publication Option'
			defaultValue={selectedPublicationProductId !== undefined ? selectedPublicationProductId : 0}
			onChange={(e) => handleProductChange(e.target.value)}
		>
			{data
				.filter((e) => e.ss_publication_type_id === ssPublicationTypeId)
				.map((pub) => (
					<MenuItem
						key={pub.ss_publication_product_id}
						value={`${pub.ss_publication_product_id}~${pub.standard_price}`}
					>
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<Typography variant='h6'>{`${pub.description} - ${pub.standard_price}`}</Typography>
						</Box>
					</MenuItem>
				))}
		</TextField>
	);
};

export default PublicationProductsByType;
