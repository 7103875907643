import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

const DropdownSelect = ({ data, placeholder, onChange, value }) => {
  const getLabel = (option) => {
    return option.label || '';
  };

  return (
    <Autocomplete
      disablePortal
      options={data}
      getOptionLabel={getLabel}
      isOptionEqualToValue={(option, value) => option.key === value.key}
      onChange={(event, newValue) => {
        if (onChange) {
          onChange(newValue);
        }
      }}
      value={value}
      renderInput={(params) => <TextField {...params} label={placeholder} />}
    />
  );
};

export default DropdownSelect;
