import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Publications from '../Components/Publications/Publications';
import PublicationContext from '../Context/Marketing/PublicationContext';
import DashBoard from '../Components/Contracts/components/DashBoard/DashBoard';
const PublicationsRoute = () => {
	return (
		<PublicationContext>
			<Routes>
				<Route path='/contracts/dashboard/:id/:title' element={<DashBoard />} />
				<Route path='/*' element={<Publications />} />
			</Routes>
		</PublicationContext>
	);
};

export default PublicationsRoute;
