import React from 'react';

import EditProductDataContext from './../../../../Context/pdm/EditProductDataContext';

import EditProductNav from './components/EditProductNav/EditProductNav';

import { Box } from '@mui/material';
import Tabs from './components/Tabs/Tabs';
import Panels from './components/Panels/Panels';
import TabBar from './components/TabBar/TabBar';

const EditProductData = () => {
	return (
		<EditProductDataContext>
			{/* TAB BAR */}
			<TabBar />
			<EditProductNav />
			<Box sx={{ mx: '25px', my: '50px' }}>
				<Tabs />
				<Panels />
			</Box>
		</EditProductDataContext>
	);
};

export default EditProductData;
