import React, { useContext, useEffect, useState } from 'react';
import { EntityContextState } from './../../../../../../Context/Entity/EntityContext';
import axios from 'axios';
import { Box, IconButton, Tooltip, useTheme } from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import EmailEntryForm from './EmailEntryForm';
import ContainerAlpha from '../../../ContainerAlpha';
import ContainerTitle from '../../../ContainerTitle';

const EmailManager = ({ contact_id }) => {
	const theme = useTheme();

	const [data, setData] = useState([]);

	const { triggerRefreshContactEmailList, updateTriggerRefreshContactEmailList } = useContext(EntityContextState);

	const addBlankEmailEntry = () => {
		setData((prev) => [...prev, { id: 999999, email: '' }]);
	};

	useEffect(() => {
		let tempArray = [];
		const fetchData = async () => {
			try {
				const response = await axios.get(`/entity/getContactsEmails/${contact_id}`);

				response.data.forEach((e) => {
					tempArray.push({ id: e.entity_contact_email_id, email: e.email_address });
				});
			} catch (error) {
				console.log('fetchContacts error: ', error);
			}

			setData([...tempArray]);
		};

		fetchData();
	}, [contact_id, triggerRefreshContactEmailList]);

	return (
		<ContainerAlpha>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
				<ContainerTitle title='Email Manager' />
				<Box>
					<Tooltip title='Add Contact Email' placement='top'>
						<IconButton
							variant='contained'
							color='success'
							size='small'
							sx={{}}
							//onClick={() => addEmailAddressToContact()}
							onClick={() => addBlankEmailEntry()}
						>
							<AddCircle />
						</IconButton>
					</Tooltip>
				</Box>
			</Box>
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
				{data.map((e, i) => (
					<EmailEntryForm key={e.id} contactId={contact_id} item={e} />
				))}
			</Box>
		</ContainerAlpha>
	);
};

export default EmailManager;
