import React from 'react';
import { Box } from '@mui/material';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import ProductDataContext from '../../Context/pdm/ProductDataContext';

const PDM = ({ children }) => {
	return (
		<ProductDataContext>
			<Box>
				<NavBar />
				<div>abc</div>
				<main style={{ paddingTop: '55px' }}>{children}</main>
				<Footer />
			</Box>
		</ProductDataContext>
	);
};

export default PDM;
