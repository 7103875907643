import axios from 'axios';
import Dayjs from 'dayjs';

export const getContactDetailsByEntityId = async (entity_id) => {
	try {
		const response = await axios.get(`/entity/getContactDetailsByEntityId/${entity_id}`);
		return [...response.data]; // Assuming the response data is an array of objects
	} catch (error) {
		console.error('Error fetching data:', error);
		return [];
	}
};

export const getContractsByPublicationId = async (ss_publication_id) => {
	try {
		const response = await axios.get(`/advertising/getContractsByPublicationId/${ss_publication_id}`);
		return [...response.data]; // Assuming the response data is an array of objects
	} catch (error) {
		console.error('Error fetching data:', error);
		return [];
	}
};

// GET ALL PUBLICATIONS
export const getAllManufacturersByUserId = async (userid) => {
	try {
		const response = await axios.get(`/advertising/getAllManufacturersByUserId/${userid}`);
		return [...response.data]; // Assuming the response data is an array of objects
	} catch (error) {
		console.error('Error fetching data:', error);
		return [];
	}
};

export const getSubBrandsByMfrId = async (mfrid) => {
	try {
		const response = await axios.get(`/advertising/getSubBrandsByMfrId/${mfrid}`);
		return [...response.data]; // Assuming the response data is an array of objects
	} catch (error) {
		console.error('Error fetching data:', error);
		return [];
	}
};

// GET PUBLICATIONS BY PUBID
export const insertOrUpdateContracts = async (data, id) => {
	// Format dates using Dayjs
	const formatDate = (date) => Dayjs(date).format('YYYY-MM-DD');
	console.log('passed obj: ', data, ' id: ' + id);

	const record = {
		ss_publication_contract_id: data.ss_publication_contract_id,
		ss_publication_id: data.ss_publication_id,
		ss_publication_product_id: data.ss_publication_product_id,
		ss_publication_contract_status_id: data.ss_publication_contract_status_id,
		entity_id: data.entity_id,
		user_id: data.user_id,
		promotion_set_id: data.promotion_set_id,
		product_manufacturers: data.product_manufacturers.join(','),
		last_status_change: formatDate(data.last_status_change),
		sold_price: data.sold_price,
		time_created: formatDate(data.time_created),
		notes: data.notes,
	};

	try {
		const endpoint = id === 0 ? '/advertising/insertContract' : '/advertising/updateContract';
		const response = await axios.post(endpoint, { record });

		if (id === 0) {
			console.log('Inserted object ID:', response.data.insertId);
			return response.data.insertId;
		} else {
			console.log('Updated object:', response.data);
			return response.data;
		}
	} catch (err) {
		console.error(err.response ? err.response.data : 'An error occurred');
		throw err;
	}
};

export const getAllContractStatuses = async (mfrid) => {
	try {
		const response = await axios.get(`/advertising.getAllContractStatuses`);
		return [...response.data];
	} catch (error) {
		console.error('Error fetching data:', error);
		return [];
	}
};

export const getContractDetailsByContractId = async (contractId) => {
	try {
		const response = await axios.get(`/advertising/getContractDetailsByContractId/${contractId}`);
		console.log('getContractDetailsByContractId: ', contractId);
		return [...response.data];
	} catch (error) {
		console.error('Error fetching data:', error);
		return [];
	}
};

export const getContractsByBrandId = async (entityId, parentEntityId, entityTypeId, parentTypeId) => {
	console.log(
		`Passed: entityId=${entityId}, parentEntityId=${parentEntityId}, entityTypeId=${entityTypeId}, parentTypeId=${parentTypeId}`
	);
	try {
		const response = await axios.get(
			`/advertising/getContractsByBrandId/${entityId}/${parentEntityId}/${entityTypeId}/${parentTypeId}`
		);
		console.log('brandcontracts: ', response.data);
		return [...response.data];
	} catch (error) {
		console.log('error getContractsByBrandId Feed: ', error);
		return [];
	}
};

export const getEntitiesByIds = async (entityIds) => {
	try {
		const response = await axios.get(`/entity/getEntityById/${entityIds}`);
		console.log('entities by ids: ', entityIds);
		return [...response.data];
	} catch (error) {
		console.error('Error fetching data:', error);
		return [];
	}
};
