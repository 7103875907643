import React, { useContext } from 'react';
import { EntityContextState } from './../../../../../../Context/Entity/EntityContext';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { IconButton } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export default function DeletePhoneNumberFromContact({ phone_number_id, removeDeletedPhoneNumber }) {
	const [open, setOpen] = React.useState(false);
	const { updateTriggerRefreshContactPhoneList, deletePhoneNumberFromContact } = useContext(EntityContextState);

	// const _callBack = () => {
	// 	callBack();
	// };

	// const _handleCloseAndOpenPopover = () => {
	// 	handleCloseAndOpenPopover();
	// };

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		//updateTriggerRefreshContactPhoneList();
	};

	const deletePhoneNumber = async (phone_number_id) => {
		try {
			const response = await axios.post('/entity/deletePhoneNumberFromContact', {
				entity_contact_phone_number_id: phone_number_id,
			});

			removeDeletedPhoneNumber(phone_number_id);
			handleClose();
			updateTriggerRefreshContactPhoneList();
			console.log('deletePhoneNumber Function ran: ' + phone_number_id);
		} catch (error) {
			console.error('Error:', error);
		}
	};

	return (
		<React.Fragment>
			<IconButton onClick={() => handleClickOpen()}>
				<DeleteOutline color='error' />
			</IconButton>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-describedby='alert-dialog-slide-description'
			>
				<DialogTitle>{'Deleteing Phone Number!'}</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-slide-description'>
						Are you sure you're want to permentily remove this phone number?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button color='error' onClick={handleClose}>
						No
					</Button>
					<Button color='success' onClick={() => deletePhoneNumber(phone_number_id)}>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}
