import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import flap1 from '../../Assets/flap_1.png';
import flap2 from '../../Assets/flap_2.png';
import flap3 from '../../Assets/flap_3.png';
import flap4 from '../../Assets/flap_4.png';
import flap5 from '../../Assets/flap_5.png';

const LoadingSpinner = () => {
  const isLoading = useSelector((state) => state.loadingSlice.value);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(1);
  const [loadingDots, setLoadingDots] = useState('');

  const images = [flap1, flap2, flap3, flap4, flap5];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const newIndex = prevIndex + direction;
        if (newIndex === images.length - 1 || newIndex === 0) {
          setDirection(-direction);
        }
        return newIndex;
      });
    }, 150);

    return () => clearInterval(interval);
  }, [direction, images.length]);

  useEffect(() => {
    const loadingStages = ['', '.', '..', '...'];
    let textIndex = 0;

    const textInterval = setInterval(() => {
      setLoadingDots(loadingStages[textIndex]);
      textIndex = (textIndex + 1) % loadingStages.length;
    }, 400);

    return () => clearInterval(textInterval);
  }, []);

  if (!isLoading) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        zIndex: 1000,
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        <img
          src={images[currentIndex]}
          alt={`Duck Flap ${currentIndex + 1}`}
          style={{ width: '100px', height: '100px' }}
        />
        <Typography variant='h6' sx={{ color: '#00325A', display: 'block', marginTop: '10px' }}>
          LOADING
          <Box component='span' sx={{ display: 'inline-block', width: '1em', textAlign: 'left' }}>
            {loadingDots}
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};

export default LoadingSpinner;
