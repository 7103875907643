import React from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedEntityType } from '../../../../../Redux/EntitySlice';

import { Box, Button } from '@mui/material';

const ChooseEntityTypeButtons = () => {
	const dispatch = useDispatch();

	return (
		<Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(12, 1fr)', gap: 1 }}>
			{/* <Button variant='contained' sx={{ gridColumn: 'span 12' }} onCl>
				All Types
			</Button> */}
			<Button
				variant='contained'
				sx={{ gridColumn: 'span 6' }}
				onClick={() => dispatch(setSelectedEntityType(1))}
			>
				Rep Groups
			</Button>
			<Button
				variant='contained'
				sx={{ gridColumn: 'span 6' }}
				onClick={() => dispatch(setSelectedEntityType(4))}
			>
				Public Brands
			</Button>
			<Button
				variant='contained'
				sx={{ gridColumn: 'span 6' }}
				onClick={() => dispatch(setSelectedEntityType(3))}
			>
				Vendors
			</Button>
			<Button
				variant='contained'
				sx={{ gridColumn: 'span 6' }}
				onClick={() => dispatch(setSelectedEntityType(2))}
			>
				Manufacturers
			</Button>
		</Box>
	);
};

export default ChooseEntityTypeButtons;
