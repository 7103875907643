import { Edit } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import React, { useContext } from 'react';
import { EntityContextState } from '../../../../../Context/Entity/EntityContext';

const RowEditAction = ({ id }) => {
	const { toggleUpdateContactDrawerOpener, addSelectedContactIds } = useContext(EntityContextState);

	const handleClick = () => {
		addSelectedContactIds(id);
		toggleUpdateContactDrawerOpener(true);
	};
	return (
		<Tooltip title={`Edit Contact ${id}`}>
			<Button
				variant='contained'
				sx={{ borderRadius: '14px' }}
				size='small'
				color='warning'
				startIcon={<Edit />}
				onClick={() => handleClick()}
			>
				<span style={{ fontWeight: 'bold' }}>Edit</span>
			</Button>
		</Tooltip>
	);
};

export default RowEditAction;
