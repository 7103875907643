import { DeleteForever } from '@mui/icons-material';
import { Box, Button, CircularProgress, IconButton, MenuItem, TextField, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { insertOrUpdateAssociation, deleteAssociation } from '../../EntityContactMiddleWare';
import { truncateString } from '../../../../_GlobalFunctions/TruncateString';
import RolesSelect from './RolesSelect';

const AssociationEditCardV2 = ({ item, allEmails, allPhoneNumbers, allPhoneTypes, allAssociationRolesTypes }) => {
	// INIT's
	const theme = useTheme();

	// CONTEXT STATE

	// LOCAL STATE
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [contactPhoneNumbers, setContactPhoneNumbers] = useState([]);
	//const [contactEmails, setContactEmails] = useState([]);
	const [displayValue, setDisplayValue] = useState('grid');

	// USE EFFECTS
	useEffect(() => {
		try {
			setData([item]);
			setLoading(false);
		} catch (error) {
			console.log('error: ', error);
		}

		// try {
		// 	if (associationId === 999999) {
		// 		console.log('it did = 999999');
		// 		if (selectedEntityId !== null) {
		// 			filteredData.push({
		// 				entity_contact_link_id: 0,
		// 				entity_id: selectedEntityId,
		// 				entity_contact_id: contactId,
		// 			});
		// 		}
		// 	} else {
		// 		const existingAssociations = allAssociationLink.find((e) => e.entity_contact_link_id === associationId);
		// 		filteredData.push(existingAssociations);
		// 	}

		// 	setData([...filteredData]);
		//
		// } catch (error) {
		// 	console.log('error: ', error);
		// 	setLoading(false);
		// }
	}, [item]);

	useEffect(() => {
		let tempObj = [];
		try {
			const filteredData = allPhoneNumbers.filter((e) => e.entity_contact_id === item.entity_contact_id);
			filteredData.forEach((f) => {
				tempObj.push({
					...f,
					phone_type_description: allPhoneTypes.find(
						(e) =>
							e.phone_type_id ===
							allPhoneNumbers.find(
								(p) => p.entity_contact_phone_number_id === f.entity_contact_phone_number_id
							).phone_type_id
					).description,
				});
			});

			setContactPhoneNumbers([...tempObj]);
		} catch (error) {
			console.log('error: ', error);
		}
	}, [allPhoneNumbers, item, allPhoneTypes]);

	// FUNCTIONS

	const insertOrUpdateAssociationFunc = async (obj) => {
		try {
			const callFunc = await insertOrUpdateAssociation(obj);
			return callFunc;
		} catch (error) {
			console.log('error insertOrUpdateAssociation: ', error);
		}
	};

	const handleRoleChange = async (e) => {
		setData((prev) => [{ ...prev[0], entity_contact_role_id: e.target.value }]);
		if (
			data[0].entity_contact_link_id === undefined ||
			data[0].entity_contact_link_id === null ||
			data[0].entity_contact_link_id === 0
		) {
			console.log('no id available to update');
		} else {
			await insertOrUpdateAssociationFunc([{ ...data[0], entity_contact_role_id: e.target.value }]);
		}
	};

	const handleEmailChange = async (e) => {
		setData((prev) => [{ ...prev[0], entity_contact_email_id: e.target.value }]);
		if (
			data[0].entity_contact_link_id === undefined ||
			data[0].entity_contact_link_id === null ||
			data[0].entity_contact_link_id === 0
		) {
			console.log('no id available to update');
		} else {
			await insertOrUpdateAssociationFunc([{ ...data[0], entity_contact_email_id: e.target.value }]);
		}
	};

	const handlePhoneNumberChange = async (e) => {
		console.log('phone num id passed: ' + e.target.value);
		setData((prev) => [{ ...prev[0], entity_contact_phone_number_id: e.target.value }]);
		if (
			data[0].entity_contact_link_id === undefined ||
			data[0].entity_contact_link_id === null ||
			data[0].entity_contact_link_id === 0
		) {
			console.log('no id available to update');
		} else {
			await insertOrUpdateAssociationFunc([{ ...data[0], entity_contact_phone_number_id: e.target.value }]);
		}
	};

	const handleOnSaveClick = async () => {
		const insertNew = await insertOrUpdateAssociationFunc([{ ...data[0], entity_contact_link_id: 0 }]);
		insertNew.data.affectedRows > 0 &&
			setData((prev) => [{ ...prev[0], entity_contact_link_id: insertNew.data.insertId }]);
	};

	const handleDeleteClick = async () => {
		const deleteRecord = await deleteAssociation(data[0].entity_contact_link_id);
		console.log('deleteRecord.data.affectedRows: ', deleteRecord.data.affectedRows);
		deleteRecord.data.affectedRows > 0 && setDisplayValue('none');
	};

	// LOADING FUNCTION
	if (loading) {
		return (
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				<CircularProgress />
			</Box>
		);
	}

	console.log('data[0]: ', data[0]);

	return (
		<>
			<Box
				sx={{
					width: '90%',
					maxWidth: '375px',
					border: `solid 4px ${theme.palette.primary.alt}`,
					borderRadius: '24px',
					padding: '24px',
					boxShadow: '4px 4px 12px rgba(0,0,0,.3)',
					backgroundColor: 'white',
					display: displayValue,
					gridTemplateColumns: '1fr 80px',
					gap: '16px',
				}}
			>
				<Box
					sx={{
						color: theme.palette.primary.main,
						mb: '24px',
						fontWeight: 'bold',
						fontSize: '1.3em',
						gridColumn: 'span 2',
					}}
				>
					{`${data[0].business_name} (${data[0].entity_id} | ${data[0].entity_contact_link_id})`}
				</Box>

				<RolesSelect
					data={data[0]}
					assoId={data[0].entity_contact_link_id}
					typeId={data[0].entity_contact_role_id}
					allAssociationRolesTypes={allAssociationRolesTypes}
				/>

				{/* <TextField
					sx={{ gridColumn: 'span 2' }}
					select
					name='entity_contact_role_id'
					label='Roles'
					type='text'
					value={data[0].entity_contact_role_id}
					InputLabelProps={{
						shrink: true,
					}}
					onChange={(e) => handleRoleChange(e, data)}
				>
					<MenuItem key='0' value={undefined}>
						Choose Type
					</MenuItem>
					{allAssociationRolesTypes.map((role) => (
						<MenuItem key={role.entity_contact_role_id} value={role.entity_contact_role_id}>
							{role.description}
						</MenuItem>
					))}
				</TextField> */}

				<TextField
					sx={{ gridColumn: 'span 2' }}
					select
					name='entity_contact_email_id'
					label='Emails'
					type='text'
					value={data[0].entity_contact_email_id}
					InputLabelProps={{
						shrink: true,
					}}
					onChange={(e) => handleEmailChange(e)}
				>
					<MenuItem key='0' value={undefined}>
						Choose Email
					</MenuItem>
					{allEmails
						.filter((e) => e.entity_contact_id === item.entity_contact_id)
						.map((email) => (
							<MenuItem key={email.entity_contact_email_id} value={email.entity_contact_email_id}>
								<Box
									sx={{
										fontWeight: 'bold',
										color: email.primary_email === 1 ? theme.palette.secondary.main : 'inherit',
									}}
								>
									{truncateString(email.email_address, 25)}
								</Box>
							</MenuItem>
						))}
				</TextField>

				<TextField
					sx={{ gridColumn: 'span 2' }}
					select
					name='entity_contact_phone_number_id'
					label='Phone Numbers'
					type='text'
					value={data[0].entity_contact_phone_number_id}
					InputLabelProps={{
						shrink: true,
					}}
					onChange={(e) => handlePhoneNumberChange(e)}
				>
					<MenuItem key='0' value={undefined}>
						Choose Phone Number
					</MenuItem>
					{contactPhoneNumbers
						.filter((e) => e.entity_contact_id === item.entity_contact_id)
						.map((phone) => (
							<MenuItem
								key={phone.entity_contact_phone_number_id}
								value={phone.entity_contact_phone_number_id}
							>
								<Box
									sx={{
										color: phone.primary_phone === 1 ? theme.palette.secondary.main : 'inherit',
									}}
								>
									<span style={{ fontWeight: 'bold' }}>{phone.phone_type_description}</span> -{' '}
									<span>{phone.phone_number}</span>
									<span style={{ color: 'black' }}>
										{phone.extension ? ` Ext: ${phone.extension}` : ''}
									</span>
								</Box>
							</MenuItem>
						))}
				</TextField>

				<Box sx={{ gridColumn: 'span 2', textAlign: 'center' }}>
					{data[0].entity_contact_link_id === undefined ||
					data[0].entity_contact_link_id === null ||
					data[0].entity_contact_link_id === 0 ? (
						<Button variant='contained' color='success' onClick={() => handleOnSaveClick()}>
							Save New Association
						</Button>
					) : (
						<IconButton onClick={() => handleDeleteClick()}>
							<DeleteForever color='error' />
						</IconButton>
					)}
				</Box>
			</Box>
		</>
	);
};

export default AssociationEditCardV2;
