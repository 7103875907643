import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { refreshAssociatedDataValue } from './../../../../Redux/EntitySlice';

import axios from 'axios';

// Material UI
import { Box, Button, Checkbox, Chip, FormControlLabel, IconButton, Paper, Radio, RadioGroup, Stack, styled, TextField, useTheme } from '@mui/material';
import { DeleteForever, AddCircle } from '@mui/icons-material';

import SectionTitle from '../../../_GlobalComponents/SectionTitle.jsx';

// Material Styled Components
const Wrapper = styled(Box)(({ theme }) => ({
  '.itemWrapper': {
    borderBottom: `solid 1px ${theme.elements.borderColor}`,
  },
}));

const EntityItemRow = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '80px 80px 80px 1fr',
  gap: 15,
  alignItems: 'center',
  backgroundColor: theme.palette.primary.mainScreen,
  marginBottom: 8,
  marginTop: 8,
  padding: 8,
  '&.headerRow': {
    backgroundColor: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 15,
    color: 'white',
  },
}));

// Primary Component
const CreateAssociation = () => {
  const dispatch = useDispatch();

  const [entityType, setEntityType] = useState(3);
  const [allEntities, setAllEntities] = useState([]);
  const [allEntitiesCopy, setAllEntitiesCopy] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);

  // REDUX STATE
  const selectedContactId = useSelector((state) => state.entitySlice.selectedContactId);

  // FUNCTIONS
  const handleCheckingItems = (id, isChecked) => {
    isChecked ? setSelectedItems((prev) => [...prev, id]) : setSelectedItems((prev) => [...prev.filter((i) => i !== id)]);
  };

  const handleCheckingTypes = (id, isChecked) => {
    isChecked ? setSelectedTypes((prev) => [...prev, id]) : setSelectedTypes((prev) => [...prev.filter((i) => i !== id)]);
  };

  const handleCreateAssociationClick = () => {
    const insertAssoReacord = (contactid, entityid, roleid) => {
      axios
        .post(`/entities/add_entity_to_contact/`, {
          contactid: contactid,
          entityid: entityid,
          roleid: roleid,
        })
        .then(function (response) {
          console.log(response.data);
          dispatch(refreshAssociatedDataValue(Date.now()));
        })
        .catch((error) => {
          console.log('error: ' + error);
        });
    };

    selectedContactId > 0 ? selectedTypes.map((type) => selectedItems.map((item) => insertAssoReacord(selectedContactId, item, type))) : alert('Please Select A Contact!');
  };

  console.log('checked items. ' + selectedItems);
  console.log('checked types. ' + selectedTypes);

  // USE EFFECTS
  useEffect(() => {
    const fetchData = () => {
      axios
        .get(`/enitites/getAllActiveVendorsAndRepGroups`)
        .then(function (response) {
          const data = response.data;
          setAllEntities(data);
          setAllEntitiesCopy(data);
        })
        .catch((error) => {
          console.log('error: ' + error);
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    setAllEntitiesCopy([]);
    setAllEntitiesCopy(allEntities.filter((items) => items.ss_entity_type_id === parseInt(entityType)));
  }, [entityType, allEntities]);

  return (
    <Wrapper>
      <Paper elevation={3} sx={{ padding: '15px', mt: 2 }}>
        <SectionTitle title='Create Association' />
        <Box>
          <RadioGroup aria-labelledby='demo-radio-buttons-group-label' value={entityType} name='radio-buttons-group' onChange={(e) => setEntityType(e.target.value)}>
            <FormControlLabel value='3' control={<Radio />} label='Vendor' />
            <FormControlLabel value='1' control={<Radio />} label='Rep Group' />
          </RadioGroup>
        </Box>
        <Box>
          <Box>
            <Box>
              <TextField
                label='Filter Entities'
                variant='outlined'
                onKeyUp={(e) =>
                  e.code === 'Enter' &&
                  setAllEntitiesCopy(
                    allEntities.filter(
                      (items) => items.ss_entity_id === parseInt(e.target.value) || items.vendor_id === e.target.value || items.business_name.toLowerCase().includes(e.target.value.toLowerCase())
                    )
                  )
                }
                sx={{ mb: 2 }}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <EntityItemRow className='headerRow'>
                <Box>Selected</Box>
                <Box>Entitiy Id:</Box>
                <Box>Vendor Id:</Box>
                <Box>Business Name:</Box>
              </EntityItemRow>
              <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
                {allEntitiesCopy.map((item) => (
                  <Box className='itemWrapper' key={item.ss_entity_id}>
                    <EntityItemRow className='itemRow'>
                      <Box>
                        {/* <Checkbox onChange={(e) => handleCheckingItems(item.ss_entity_id, e.target.checked)} /> */}
                        <IconButton color='success' aria-label='add entity to list' component='label' onClick={(e) => handleCheckingItems(item.ss_entity_id, true)}>
                          <AddCircle />
                        </IconButton>
                      </Box>
                      <Box>{item.ss_entity_id}</Box>
                      <Box>{item.vendor_id}</Box>
                      <Box>{item.business_name}</Box>
                    </EntityItemRow>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box sx={{ mb: 4 }}>
              <SectionTitle title='Selected Entities' />
              <Box>
                {selectedItems.map((item, idx) => (
                  <Chip
                    key={idx}
                    color='success'
                    sx={{ fontSize: '.9em', mx: 1, fontWeight: 'bold', textTransform: 'uppercase' }}
                    label={allEntities.filter((ent) => ent.ss_entity_id === item).map((i) => i.business_name)}
                    variant='contained'
                    onDelete={() => handleCheckingItems(item, false)}
                    deleteIcon={<DeleteForever />}
                  />
                ))}
              </Box>
            </Box>
            <Box>
              <SectionTitle title='Association Types:' sx={{ mb: 0 }} />
              <Stack direction='row' flexWrap='wrap'>
                <FormControlLabel label='Manufacturer Representative' control={<Checkbox onChange={(e) => handleCheckingTypes(1, e.target.checked)} />} />
                <FormControlLabel label='Marketing Contact' control={<Checkbox onChange={(e) => handleCheckingTypes(2, e.target.checked)} />} />
                <FormControlLabel label='Data Contact' control={<Checkbox onChange={(e) => handleCheckingTypes(3, e.target.checked)} />} />
                <FormControlLabel label='Vendor Manager' control={<Checkbox onChange={(e) => handleCheckingTypes(4, e.target.checked)} />} />
                <FormControlLabel label='Member' control={<Checkbox onChange={(e) => handleCheckingTypes(5, e.target.checked)} />} />
                <FormControlLabel label='PDM Agent' control={<Checkbox onChange={(e) => handleCheckingTypes(6, e.target.checked)} />} />
              </Stack>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', mt: 4 }}>
          <Button sx={{ ml: 'auto' }} variant='contained' onClick={() => handleCreateAssociationClick()}>
            Associate Selected Entities
          </Button>
        </Box>
      </Paper>
    </Wrapper>
  );
};
export default CreateAssociation;
