import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  IconButton,
  Typography,
  styled,
  Switch,
  Grid,
  Paper,
  Snackbar,
  Tooltip,
  Modal,
  Button,
  TextField,
  CircularProgress,
} from '@mui/material';
import {
  Refresh,
  Email,
  Phone,
  Business,
  LocationOn,
  Notes,
  Person,
  Language,
  ContentCopy,
  Announcement,
} from '@mui/icons-material';
import {
  MaterialReactTable,
  MRT_ToggleDensePaddingButton as MrtToggleDensePaddingButton,
  MRT_ToggleFiltersButton as MrtToggleFiltersButton,
  MRT_ToggleFullScreenButton as MrtToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton as MrtToggleGlobalFilterButton,
} from 'material-react-table';
import DirectoryEmail from '../Emails/DirectoryEmail';

const BlueSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#2471a3',
    '&:hover': {
      backgroundColor: 'rgba(0, 50, 90, 0.08)',
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#2471a3',
  },
  '& .MuiSwitch-switchBase': {
    color: '#2471a3',
    '&:hover': {
      backgroundColor: 'rgba(0, 50, 90, 0.08)',
    },
  },
  '& .MuiSwitch-track': {
    backgroundColor: '#2471a3',
  },
}));

const EntityViewer = () => {
  const [entitiesByBusiness, setEntitiesByBusiness] = useState([]);
  const [entitiesByContact, setEntitiesByContact] = useState([]);
  const [groupByContact, setGroupByContact] = useState(false);

  const [hoveredPhone, setHoveredPhone] = useState(null);
  const [hoveredEmail, setHoveredEmail] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarReportOpen, setSnackbarReportOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowDetails, setSelectedRowDetails] = useState(null); // State for selected row details
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [reportDescription, setReportDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const getEntitiesByBusiness = () => {
    axios
      .get('/entity/groupByBusiness')
      .then((response) => {
        const transformedData = response.data.map((entity) => {
          return {
            ...entity,
            business_name: entity.business_name || '', // Replace null with empty string
            entity_type: entity.entity_type || '', // Replace null with empty string
            buyer: entity.buyer || '', // Replace null with empty string
            mfr_rep: entity.mfr_rep || '', // Replace null with empty string
            mfr_rep_business_name: entity.mfr_rep_business_name || '', // Replace null with empty string
          };
        });
        setEntitiesByBusiness(transformedData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getEntitiesByBusiness();
  }, []);

  const getEntitiesByContact = () => {
    axios
      .get('/entity/groupByContact')
      .then((response) => {
        const transformedData = response.data.map((entity) => {
          return {
            ...entity,
            name: entity.contact_name || '', // Replace null with empty string
            address_1: entity.address_1 || '', // Replace null with empty string
            address_2: entity.address_2 || '', // Replace null with empty string
            city: entity.city || '', // Replace null with empty string
            state: entity.state_id || '', // Replace null with empty string
            zip_code: entity.zip_code || '', // Replace null with empty string
            notes: entity.notes || '', // Replace null with empty string
          };
        });
        setEntitiesByContact(transformedData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getEntitiesByContact();
  }, []);

  useEffect(() => {
    getEntitiesByContact();
  }, []);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSnackbarReportClose = () => {
    setSnackbarReportOpen(false);
  };

  const handleReportModalClose = () => {
    setReportModalOpen(false);
    setSelectedRowDetails(null); // Clear row details when modal closes
  };

  const handleSubmitReport = () => {
    setIsLoading(true); // Start loading

    DirectoryEmail(selectedRowDetails, reportDescription)
      .then(() => {
        setSnackbarReportOpen(true);
        setReportModalOpen(false);
        setSelectedRowDetails(null);
        setReportDescription('');
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false); // Stop loading once the request completes
      });
  };

  const businessColumns = [
    {
      header: 'Business (Mfg #)',
      accessorKey: 'business_name',
      Cell: ({ row }) => {
        const brand = row.original.business_name || '';
        const mfgNum = row.original.mfg_num || '';
        const combinedValue = brand && mfgNum ? `${brand} (${mfgNum})` : brand || mfgNum;

        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body1'>{combinedValue}</Typography>
          </Box>
        );
      },
    },
    {
      header: 'Type',
      accessorKey: 'entity_type',
      Cell: ({ renderedCellValue }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Buyer (Ext)',
      accessorKey: 'buyer',
      Cell: ({ row }) => {
        const buyer = row.original.buyer;
        const buyerExtension = row.original.buyer_extension;
        const combinedValue =
          row.original.buyer && row.original.buyer_extension ? `${buyer} (${buyerExtension})` : '';

        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body1'>{combinedValue}</Typography>
          </Box>
        );
      },
    },
    {
      header: 'Rep',
      accessorKey: 'mfr_rep',
      Cell: ({ renderedCellValue }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Rep Business',
      accessorKey: 'mfr_rep_business_name',
      Cell: ({ renderedCellValue }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
  ];

  const contactColumns = [
    {
      header: 'Name',
      accessorKey: 'contact_name',
      Cell: ({ renderedCellValue }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Address 1',
      accessorKey: 'address_1',
      Cell: ({ renderedCellValue }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Address 2',
      accessorKey: 'address_2',
      Cell: ({ renderedCellValue }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'City',
      accessorKey: 'city',
      Cell: ({ renderedCellValue }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'State',
      accessorKey: 'state',
      Cell: ({ renderedCellValue }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Zip',
      accessorKey: 'zip_code',
      Cell: ({ renderedCellValue }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Notes',
      accessorKey: 'notes',
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            maxWidth: '200px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <Typography
            variant='body1'
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '100%',
            }}
            title={renderedCellValue}
          >
            {renderedCellValue}
          </Typography>
        </Box>
      ),
    },
  ];

  return (
    <>
      <>
        <Box
          sx={{
            p: 2,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            '& > .MuiPaper-root': {
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              '& > .MuiTableContainer-root': {
                flexGrow: 1,
              },
            },
          }}
        >
          <MaterialReactTable
            key={groupByContact ? 'contact' : 'business'}
            columns={groupByContact ? contactColumns : businessColumns}
            data={groupByContact ? entitiesByContact : entitiesByBusiness}
            enableHiding={false}
            enableRowExpansion
            enableStickyHeader
            enableRowActions
            enableGlobalFilter
            initialState={{
              showColumnFilters: false,
              density: 'compact',
              pagination: { pageIndex: 0, pageSize: 20 },
              showGlobalFilter: true,
              sorting: [
                groupByContact
                  ? { id: 'contact_name', desc: false }
                  : { id: 'business_name', asc: true },
              ],
            }}
            positionToolbarAlertBanner='bottom'
            muiTableBodyRowProps={({ row }) => ({
              onClick: (event) => {
                row.toggleExpanded();
              },
              sx: {
                cursor: 'pointer',
              },
            })}
            renderTopToolbarCustomActions={({ table }) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 2,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                  <Business sx={{ color: '#2471a3', mr: 1 }} />
                  <Typography variant='h6' sx={{ fontWeight: '600px', color: '#2471a3' }}>
                    Group By Business
                  </Typography>
                </Box>

                <BlueSwitch
                  checked={groupByContact}
                  onChange={() => setGroupByContact(!groupByContact)}
                />

                <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                  <Person sx={{ color: '#2471a3', mr: 1 }} />
                  <Typography variant='h6' sx={{ fontWeight: '600px', color: '#2471a3' }}>
                    Group By Contact
                  </Typography>
                </Box>
              </Box>
            )}
            renderToolbarInternalActions={({ table }) => (
              <Box sx={{ whiteSpace: 'pre' }}>
                <MrtToggleGlobalFilterButton table={table} />
                <MrtToggleFiltersButton table={table} />
                <MrtToggleDensePaddingButton table={table} />
                <MrtToggleFullScreenButton table={table} />
                <IconButton
                  tabIndex={0}
                  type='button'
                  aria-label='Refresh'
                  onClick={() => {
                    if (groupByContact) {
                      getEntitiesByContact();
                    } else {
                      getEntitiesByBusiness();
                    }
                  }}
                >
                  <Refresh />
                </IconButton>
              </Box>
            )}
            renderRowActions={({ row }) => {
              return (
                <Box>
                  <Tooltip title='Report Data Problem'>
                    <IconButton
                      sx={{ color: '#2471a3' }}
                      onClick={(e) => {
                        setSelectedRow(row);
                        setSelectedRowDetails(row.original); // Set the selected row's details
                        e.stopPropagation();
                        setReportModalOpen(true);
                      }}
                    >
                      <Announcement />
                    </IconButton>
                  </Tooltip>
                </Box>
              );
            }}
            renderDetailPanel={({ row }) => (
              <Box sx={{ p: 2, backgroundColor: '#f2f3f4', color: '#ffffff', borderRadius: 2 }}>
                {!groupByContact ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant='h6' sx={{ color: '#2471a3', fontWeight: 'bold' }}>
                        {row.original.business_name}
                      </Typography>
                    </Grid>

                    {/* Address Information */}
                    <Grid item xs={12}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <LocationOn sx={{ color: '#2471a3', mr: 1 }} />
                        <Typography variant='body1' sx={{ color: '#2471a3' }}>
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                              `${row.original.address_1 || ''} ${row.original.address_2 || ''} ${
                                row.original.city || ''
                              } ${row.original.state_id || ''} ${row.original.zip_code || ''} ${
                                row.original.country || ''
                              }`
                            )}`}
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{ textDecoration: 'none', color: '#2471a3' }}
                            onClick={(e) => e.stopPropagation()} // Prevents row toggle on click
                            onMouseEnter={(e) =>
                              (e.currentTarget.style.textDecoration = 'underline')
                            }
                            onMouseLeave={(e) => (e.currentTarget.style.textDecoration = 'none')}
                          >
                            {row.original.address_1 && row.original.address_1}
                            {row.original.address_2 && `, ${row.original.address_2}`}
                            {(row.original.city ||
                              row.original.state_id ||
                              row.original.zip_code) &&
                              `, `}
                            {row.original.city && row.original.city}
                            {row.original.state_id && `, ${row.original.state_id}`}
                            {row.original.zip_code && ` ${row.original.zip_code}`}
                            {(row.original.city ||
                              row.original.state_id ||
                              row.original.zip_code) &&
                              row.original.country &&
                              `, `}
                            {row.original.country && row.original.country}
                          </a>
                        </Typography>
                      </Box>
                    </Grid>

                    {/* Website Information */}
                    {row.original.website && (
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                          <Language sx={{ color: '#2471a3', mr: 1 }} />
                          <Typography variant='body1' sx={{ color: '#2471a3' }}>
                            <a
                              href={row.original.website}
                              target='_blank'
                              rel='noopener noreferrer'
                              style={{ textDecoration: 'none', color: '#2471a3' }}
                              onClick={(e) => e.stopPropagation()} // Prevents row toggle on click
                              onMouseEnter={(e) =>
                                (e.currentTarget.style.textDecoration = 'underline')
                              }
                              onMouseLeave={(e) => (e.currentTarget.style.textDecoration = 'none')}
                            >
                              {row.original.website}
                            </a>
                          </Typography>
                        </Box>
                      </Grid>
                    )}

                    {/* About Information */}
                    {row.original.about && (
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                          <Notes sx={{ color: '#2471a3', mr: 1 }} />
                          <Typography variant='body1' sx={{ color: '#2471a3' }}>
                            {row.original.about}
                          </Typography>
                        </Box>
                      </Grid>
                    )}

                    {/* Notes Information */}
                    {row.original.notes && (
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                          <Notes sx={{ color: '#2471a3', mr: 1 }} />
                          <Typography variant='body1' sx={{ color: '#2471a3' }}>
                            {row.original.notes}
                          </Typography>
                        </Box>
                      </Grid>
                    )}

                    {/* Contact Details */}
                    {row.original.contact_details_json.length > 0 &&
                      row.original.contact_details_json.map((contact, index) => (
                        <Grid item xs={12} key={index}>
                          <Paper
                            elevation={3}
                            sx={{ p: 2, borderRadius: 2, backgroundColor: '#fdfefe' }}
                          >
                            <Grid container spacing={2}>
                              {/* Role Description */}
                              {contact.role_description && (
                                <Grid item xs={12}>
                                  <Typography
                                    variant='subtitle1'
                                    sx={{
                                      color: contact.role_color || '#0d47a1',
                                      fontWeight: 'bold',
                                      display: 'inline-flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {contact.role_description}
                                  </Typography>
                                </Grid>
                              )}

                              {/* Contact Name */}
                              {contact.contact_name && (
                                <Grid item xs={12} sm={4}>
                                  <Typography variant='body2' color='textSecondary'>
                                    Contact Name:
                                  </Typography>
                                  <Typography
                                    variant='body1'
                                    sx={{ display: 'inline-flex', alignItems: 'center' }}
                                  >
                                    <Person sx={{ color: '#2471a3', mr: 1 }} />
                                    {contact.contact_name}
                                  </Typography>
                                </Grid>
                              )}

                              {/* Contact Phone */}
                              {contact.phone && (
                                <Grid
                                  item
                                  xs={12}
                                  sm={4}
                                  onMouseEnter={() => setHoveredPhone(contact.phone)}
                                  onMouseLeave={() => setHoveredPhone(null)}
                                >
                                  <Typography variant='body2' color='textSecondary'>
                                    Phone:
                                  </Typography>
                                  <Typography
                                    variant='body1'
                                    sx={{
                                      display: 'inline-flex',
                                      alignItems: 'center',
                                      position: 'relative',
                                    }}
                                  >
                                    <Phone sx={{ color: '#2471a3', mr: 1 }} />
                                    <a
                                      href={`tel:${contact.phone}`}
                                      style={{ textDecoration: 'none', color: 'inherit' }}
                                      onClick={(e) => e.stopPropagation()} // Prevents row toggle on click
                                      onMouseEnter={(e) =>
                                        (e.currentTarget.style.textDecoration = 'underline')
                                      }
                                      onMouseLeave={(e) =>
                                        (e.currentTarget.style.textDecoration = 'none')
                                      }
                                    >
                                      {contact.phone}
                                    </a>
                                    {hoveredPhone === contact.phone && (
                                      <IconButton
                                        sx={{
                                          ml: 1,
                                          cursor: 'pointer',
                                          position: 'absolute',
                                          right: -40,
                                          top: -8,
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleCopy(contact.phone);
                                        }}
                                      >
                                        <ContentCopy sx={{ fontSize: 16, color: '#2471a3' }} />
                                      </IconButton>
                                    )}
                                  </Typography>
                                </Grid>
                              )}

                              {/* Contact Email */}
                              {contact.email && (
                                <Grid
                                  item
                                  xs={12}
                                  sm={4}
                                  onMouseEnter={() => setHoveredEmail(contact.email)}
                                  onMouseLeave={() => setHoveredEmail(null)}
                                >
                                  <Typography variant='body2' color='textSecondary'>
                                    Email:
                                  </Typography>
                                  <Typography
                                    variant='body1'
                                    sx={{
                                      display: 'inline-flex',
                                      alignItems: 'center',
                                      position: 'relative',
                                    }}
                                  >
                                    <Email sx={{ color: '#2471a3', mr: 1 }} />
                                    <a
                                      href={`mailto:${contact.email}`}
                                      style={{ textDecoration: 'none', color: 'inherit' }}
                                      onClick={(e) => e.stopPropagation()} // Prevents row toggle on click
                                      onMouseEnter={(e) =>
                                        (e.currentTarget.style.textDecoration = 'underline')
                                      }
                                      onMouseLeave={(e) =>
                                        (e.currentTarget.style.textDecoration = 'none')
                                      }
                                    >
                                      {contact.email}
                                    </a>
                                    {hoveredEmail === contact.email && (
                                      <IconButton
                                        sx={{
                                          ml: 1,
                                          cursor: 'pointer',
                                          position: 'absolute',
                                          right: -40,
                                          top: -8,
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleCopy(contact.email);
                                        }}
                                      >
                                        <ContentCopy sx={{ fontSize: 16, color: '#2471a3' }} />
                                      </IconButton>
                                    )}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                          </Paper>
                        </Grid>
                      ))}
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant='h6' sx={{ color: '#2471a3', fontWeight: 'bold' }}>
                        {row.original.contact_name}
                      </Typography>
                    </Grid>

                    {/* Address Information */}
                    <Grid item xs={12}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <LocationOn sx={{ color: '#2471a3', mr: 1 }} />
                        <Typography variant='body1' sx={{ color: '#2471a3' }}>
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                              `${row.original.address_1 || ''} ${row.original.address_2 || ''} ${
                                row.original.city || ''
                              } ${row.original.state || ''} ${row.original.zip_code || ''} ${
                                row.original.country || ''
                              }`
                            )}`}
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{ textDecoration: 'none', color: '#2471a3' }}
                            onClick={(e) => e.stopPropagation()} // Prevents row toggle on click
                            onMouseEnter={(e) =>
                              (e.currentTarget.style.textDecoration = 'underline')
                            }
                            onMouseLeave={(e) => (e.currentTarget.style.textDecoration = 'none')}
                          >
                            {row.original.address_1 && row.original.address_1}
                            {row.original.address_2 && `, ${row.original.address_2}`}
                            {(row.original.city || row.original.state || row.original.zip_code) &&
                              `, `}
                            {row.original.city && row.original.city}
                            {row.original.state && `, ${row.original.state}`}
                            {row.original.zip_code && ` ${row.original.zip_code}`}
                            {(row.original.city || row.original.state || row.original.zip_code) &&
                              row.original.country &&
                              `, `}
                            {row.original.country && row.original.country}
                          </a>
                        </Typography>
                      </Box>
                    </Grid>

                    {/* Notes Information */}
                    {row.original.notes && (
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                          <Notes sx={{ color: '#2471a3', mr: 1 }} />
                          <Typography variant='body1'>{row.original.notes}</Typography>
                        </Box>
                      </Grid>
                    )}

                    {/* Roles Information */}
                    {row.original.roles_info.map((role, index) => {
                      const hasBusinessName = role.business_name && role.business_name.trim();
                      const hasRoleDescription =
                        role.role_description && role.role_description.trim();
                      const hasPhoneNumber =
                        role.phone.phone_number && role.phone.phone_number.trim();
                      const hasEmailAddress =
                        role.email.email_address && role.email.email_address.trim();

                      // Check if there's any content to display
                      if (
                        !hasBusinessName &&
                        !hasRoleDescription &&
                        !hasPhoneNumber &&
                        !hasEmailAddress
                      ) {
                        return null; // Skip rendering this Paper if there's no content
                      }

                      return (
                        <Grid item xs={12} key={index}>
                          <Paper
                            elevation={3}
                            sx={{ p: 2, borderRadius: 2, backgroundColor: '#fdfefe' }}
                          >
                            <Grid container spacing={2}>
                              {hasBusinessName && (
                                <Grid item xs={12}>
                                  <Typography
                                    variant='subtitle1'
                                    sx={{
                                      color: '#2471a3',
                                      fontWeight: 'bold',
                                      display: 'inline-flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Business sx={{ color: '#2471a3', mr: 1 }} />
                                    {role.business_name}
                                  </Typography>
                                </Grid>
                              )}

                              {hasRoleDescription && (
                                <Grid item xs={12} sm={4}>
                                  <Typography variant='body2' color='textSecondary'>
                                    Role Description:
                                  </Typography>
                                  <Typography variant='body1'>{role.role_description}</Typography>
                                </Grid>
                              )}

                              {hasPhoneNumber && (
                                <Grid
                                  item
                                  xs={12}
                                  sm={4}
                                  onMouseEnter={() => setHoveredPhone(role.phone.phone_number)}
                                  onMouseLeave={() => setHoveredPhone(null)}
                                >
                                  <Typography variant='body2' color='textSecondary'>
                                    Phone:
                                  </Typography>
                                  <Typography
                                    variant='body1'
                                    sx={{
                                      display: 'inline-flex',
                                      alignItems: 'center',
                                      position: 'relative',
                                    }}
                                  >
                                    <Phone sx={{ color: '#2471a3', mr: 1 }} />
                                    <a
                                      href={`tel:${role.phone.phone_number}`}
                                      style={{ textDecoration: 'none', color: 'inherit' }}
                                      onClick={(e) => e.stopPropagation()} // Prevents row toggle on click
                                      onMouseEnter={(e) =>
                                        (e.currentTarget.style.textDecoration = 'underline')
                                      }
                                      onMouseLeave={(e) =>
                                        (e.currentTarget.style.textDecoration = 'none')
                                      }
                                    >
                                      {role.phone.phone_number}
                                    </a>
                                    {hoveredPhone === role.phone.phone_number && (
                                      <IconButton
                                        sx={{
                                          ml: 1,
                                          cursor: 'pointer',
                                          position: 'absolute',
                                          right: -40,
                                          top: -8,
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleCopy(role.phone.phone_number);
                                        }}
                                      >
                                        <ContentCopy sx={{ fontSize: 16, color: '#2471a3' }} />
                                      </IconButton>
                                    )}
                                  </Typography>
                                </Grid>
                              )}

                              {hasEmailAddress && (
                                <Grid
                                  item
                                  xs={12}
                                  sm={4}
                                  onMouseEnter={() => setHoveredEmail(role.email.email_address)}
                                  onMouseLeave={() => setHoveredEmail(null)}
                                >
                                  <Typography variant='body2' color='textSecondary'>
                                    Email:
                                  </Typography>
                                  <Typography
                                    variant='body1'
                                    sx={{
                                      display: 'inline-flex',
                                      alignItems: 'center',
                                      position: 'relative',
                                    }}
                                  >
                                    <Email sx={{ color: '#2471a3', mr: 1 }} />
                                    <a
                                      href={`mailto:${role.email.email_address}`}
                                      style={{ textDecoration: 'none', color: 'inherit' }}
                                      onClick={(e) => e.stopPropagation()} // Prevents row toggle on click
                                      onMouseEnter={(e) =>
                                        (e.currentTarget.style.textDecoration = 'underline')
                                      }
                                      onMouseLeave={(e) =>
                                        (e.currentTarget.style.textDecoration = 'none')
                                      }
                                    >
                                      {role.email.email_address}
                                    </a>
                                    {hoveredEmail === role.email.email_address && (
                                      <IconButton
                                        sx={{
                                          ml: 1,
                                          cursor: 'pointer',
                                          position: 'absolute',
                                          right: -40,
                                          top: -8,
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleCopy(role.email.email_address);
                                        }}
                                      >
                                        <ContentCopy sx={{ fontSize: 16, color: '#2471a3' }} />
                                      </IconButton>
                                    )}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                          </Paper>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </Box>
            )}
          />
        </Box>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message='Copied to clipboard'
        />

        <Snackbar
          open={snackbarReportOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarReportClose}
          message='Report sent successfully'
        />
      </>
      <Modal
        open={reportModalOpen}
        onClose={handleReportModalClose}
        aria-labelledby='report-modal-title'
        aria-describedby='report-modal-description'
      >
        <Grid container spacing={3}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              borderRadius: 2,
              backgroundColor: '#fdfefe',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: '90%', sm: '50%' },
            }}
          >
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
              <Typography variant='h6' id='report-modal-title'>
                Report Data Problem
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', pb: 2 }}>
              <Typography variant='body1' id='report-modal-description'>
                Please describe the issue you encountered with the data:
              </Typography>
            </Grid>

            {/* Display selected row details here */}
            {selectedRowDetails && (
              <Grid item xs={12} sx={{ pb: 2 }}>
                {groupByContact ? (
                  <>
                    <Typography variant='body2'>
                      <strong>Contact Name:</strong> {selectedRowDetails.contact_name}
                    </Typography>
                    <Typography variant='body2'>
                      <strong>Address:</strong> {selectedRowDetails.address_1}
                    </Typography>
                    <Typography variant='body2'>
                      <strong>City:</strong> {selectedRowDetails.city}
                    </Typography>
                    <Typography variant='body2'>
                      <strong>State:</strong> {selectedRowDetails.state}
                    </Typography>
                    <Typography variant='body2'>
                      <strong>Zip Code:</strong> {selectedRowDetails.zip_code}
                    </Typography>
                    <Typography variant='body2'>
                      <strong>Notes:</strong> {selectedRowDetails.notes}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant='body2'>
                      <strong>Business Name:</strong> {selectedRowDetails.business_name}
                    </Typography>
                    <Typography variant='body2'>
                      <strong>Type:</strong> {selectedRowDetails.entity_type}
                    </Typography>
                    <Typography variant='body2'>
                      <strong>Buyer:</strong> {selectedRowDetails.buyer}
                    </Typography>
                    <Typography variant='body2'>
                      <strong>Rep:</strong> {selectedRowDetails.mfr_rep}
                    </Typography>
                    <Typography variant='body2'>
                      <strong>Rep Business:</strong> {selectedRowDetails.mfr_rep_business_name}
                    </Typography>
                  </>
                )}
              </Grid>
            )}

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', pb: 2 }}>
              <TextField
                id='report-modal-textfield'
                label='Report Description'
                multiline
                rows={4}
                variant='outlined'
                fullWidth
                disabled={isLoading}
                onChange={(e) => setReportDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleSubmitReport}
                  disabled={isLoading}
                >
                  Submit Report
                </Button>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Modal>
    </>
  );
};

export default EntityViewer;
