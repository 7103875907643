import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Stack } from '@mui/material';

const list = [
	{ id: 1, label: 'Product Audit', url: '/mfr/product-audit' },
	{ id: 2, label: 'Marketing Opportunities', url: '/mfr/marketing-opportunities' },
	{ id: 3, label: 'Product Performance', url: '/mfr/product-performance' },
	{ id: 4, label: 'Landing Page', url: '/mfr/landing-page' },
	{ id: 5, label: 'Advertising', url: '/mfr/advertising' },
];
const MainButtonStack = () => {
	const navigate = useNavigate();
	return (
		<div>
			<Stack direction='row' spacing={2} sx={{ p: 4, justifyContent: 'center' }}>
				{list.map((item) => (
					<Box key={item.id}>
						<Button variant='contained' onClick={() => navigate(item.url)}>
							{item.label}
						</Button>
					</Box>
				))}
			</Stack>
		</div>
	);
};

export default MainButtonStack;
