import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	IconButton,
	Modal,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import {
	AddCircleOutlined,
	BuildCircleOutlined,
	CheckBox,
	CheckBoxOutlineBlankOutlined,
	CheckBoxOutlined,
	Handyman,
	HandymanOutlined,
	HandymanRounded,
} from '@mui/icons-material';

import React, { useEffect, useState } from 'react';

const AddBrand = ({ cardData, handleUpdatingContract, entityBrands }) => {
	// INIT's
	const theme = useTheme();

	// LOCAL STATE
	const [open, setOpen] = React.useState(false);
	//const [entityBrandsState, setEntityBrandsState] = useState([]);
	const [entityObjects, setEntityObjects] = useState([]);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleChange = (e, brand) => {
		const updatedItems = entityObjects.map((item) =>
			item.id === brand.id ? { ...item, selected: e.target.checked } : item
		);
		setEntityObjects(updatedItems);
	};

	const handleSubmit = async () => {
		const selectedBrands = entityObjects
			.filter((e) => e.selected === true)
			.map((brand) => brand.id)
			.join(',');
		const updatedArray = selectedBrands.split(',');
		cardData.product_manufacturers = String(updatedArray);

		await handleUpdatingContract(cardData);
		setOpen(false);
	};

	useEffect(() => {
		const tempArry = [];
		const currentBrands = cardData.product_manufacturers.split(',');
		const action = () => {
			entityBrands.map((brand) =>
				tempArry.push({
					id: brand.entity_id,
					brand: brand.business_name,
					selected: currentBrands.includes(String(brand.entity_id)) ? true : false,
				})
			);
			setEntityObjects([...tempArry]);
			//setEntityBrandsState([...entityBrands]);
		};
		entityBrands.length > 0 && action();
	}, [entityBrands, cardData]);

	console.log('cardData: ', cardData);
	return (
		<>
			<Tooltip title='Select Additonal Brand'>
				<IconButton color='success' sx={{ ml: 'auto' }} onClick={() => handleClickOpen()}>
					<BuildCircleOutlined sx={{ fontSize: '1.3em' }} />
				</IconButton>
			</Tooltip>
			<Modal open={open} onClose={handleClose}>
				<Box
					sx={{
						backgroundColor: 'white',
						border: `solid 2px ${theme.palette.primary.main}`,
						borderRadius: '24px',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%,-50%)',
						width: '90%',
						maxWidth: '350px',
						p: '24px',
					}}
				>
					<Box>
						<Box
							sx={{
								fontWeight: 'bold',
								fontSize: '1.2em',
								color: theme.palette.primary.main,
								mb: '16px',
							}}
						>
							Mangage Advertised Brands:
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'column', mx: '24px', mb: '24px' }}>
							{entityObjects.map((brand) => (
								<FormControlLabel
									control={
										<Checkbox
											checked={brand.selected}
											onChange={(e) => handleChange(e, brand)}
											icon={<CheckBoxOutlineBlankOutlined color='disabled' />}
											checkedIcon={
												<CheckBoxOutlined sx={{ color: theme.palette.secondary.main }} />
											}
										/>
									}
									label={brand.brand}
								/>
							))}
						</Box>
					</Box>
					<Button
						fullWidth
						variant='contained'
						color='success'
						sx={{ fontWeight: 'bold' }}
						onClick={() => handleSubmit()}
					>
						Update Brand Selection
					</Button>
				</Box>
			</Modal>
		</>
	);
};

export default AddBrand;
