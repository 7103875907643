import React from 'react';

const ContactAddress = ({ street, city, state, zip }) => {
	const Content = () => {
		return (
			<div>
				<div>{street}</div>
				<div>{`${city}, ${state}, ${zip}`}</div>
			</div>
		);
	};
	return <>{street === '' ? '---' : <Content />}</>;
};

export default ContactAddress;
