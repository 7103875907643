import React, { useContext, useEffect, useState, useRef } from 'react';
import { EntityContextState } from '../../../../../../Context/Entity/EntityContext';
import { EntityContactContextState } from '../../../../../../Context/Marketing/EntityContactContext';
import axios from 'axios';
import { Box, IconButton, MenuItem, TextField, useTheme } from '@mui/material';
import { Save } from '@mui/icons-material';
import useContactEmailList from '../../../../DataFeeds/ContactEmailList';
import useContactPhonelList from '../../../../DataFeeds/ContactPhoneList';
import ContainerAlpha from '../../../ContainerAlpha';
import ContainerTitle from '../../../ContainerTitle';
import ContactEntityListAutoComplete from '../../../AutoCompletes/ContactEntityListAutoComplete';
import AssociationList from './components/AssociationList';

const ContactAssociation = ({ side, objId }) => {
	// LOCAL STATE
	const [entities, setEntities] = useState([]);
	const [entitiesMod, setEntitiesMod] = useState([]);
	const [contacts, setContacts] = useState([]);
	const [contactsMod, setContactsMod] = useState([]);
	const [selectedContactId, setSelectedContactId] = useState(0);
	const [myPhoneList, setMyPhoneList] = useState([]);
	const [myEmailList, setMyEmailList] = useState([]);

	const objData = useRef({
		entity_contact_id: 0,
		entity_contact_role_id: 1,
		entity_contact_email_id: 0,
		entity_contact_phone_number_id: 0,
		entity_id: 0,
	});

	// CONTEXT DATA
	const {
		triggerRefreshEntityData,
		triggerRefreshContactData,
		listOfContactRoles,
		updateTriggerRefreshContactAssociationList,
		triggerRefreshContactPhoneList,
		triggerRefreshContactEmailList,
	} = useContext(EntityContextState);

	const { setAllAssociationLink } = useContext(EntityContactContextState);

	const myContactId = side === 'entity' ? selectedContactId : objId;

	const handleOnSave = () => {
		const addFunc = async () => {
			try {
				const response = await axios.post('/entity/insertEntityContactLinksRecord', {
					recordData: objData.current,
				});
				updateTriggerRefreshContactAssociationList();
				setAllAssociationLink();
			} catch (error) {
				console.error('Error:', error);
			}
		};

		addFunc();
	};

	// FETCH GET ALL ENTITIES
	useEffect(() => {
		let tempEntities = [];
		const fetchData = async () => {
			try {
				const response = await axios.get(`/entity/getAllEntities`);
				setEntities([{ entity_id: 0, business_name: 'Choose Entity' }, ...response.data]);
				response.data.map((e) => {
					return tempEntities.push({ id: e.entity_id, label: e.business_name });
				});
			} catch (error) {
				console.log('fetchContacts error: ', error);
			}
			setEntitiesMod([...tempEntities]);
		};

		fetchData();
	}, [triggerRefreshEntityData]);

	// FETCH GET ALL CONTACTS
	useEffect(() => {
		let tempArray = [];
		const fetchData = async () => {
			try {
				const response = await axios.get(`/entity/getAllContacts`);
				setContacts([{ entity_contact_id: 0, first_name: '_John', last_name: 'Doe' }, ...response.data]); // Assuming the response has a data object
				response.data
					.filter((e) => e.deleted !== '1')
					.map((c) => {
						return tempArray.push({
							id: c.entity_contact_id,
							label: `${c.first_name} ${c.last_name} (${c.entity_contact_id})`,
						});
					});
				console.log('response.dataaa:', response.data);
			} catch (error) {
				console.log('fetchContacts error: ', error);
			}
			setContactsMod([...tempArray]);
		};
		fetchData();
	}, [triggerRefreshContactData]);

	// SET INITIAL VALUES
	useEffect(() => {
		if (side === 'contact') {
			setSelectedContactId(objId);
		} else {
			setSelectedContactId(0);
		}
		side === 'contact' ? (objData.current.entity_contact_id = objId) : (objData.current.entity_id = objId);
	}, [objId, side, setSelectedContactId]);

	const handleEntitySelection = (newValue) => {
		newValue ? (objData.current.entity_id = newValue.id) : (objData.current.entity_id = null);
	};
	const handleContactSelection = (newValue) => {
		newValue ? (objData.current.entity_contact_id = newValue.id) : (objData.current.entity_contact_id = null);
		setSelectedContactId(newValue.id);
	};

	useEffect(() => {
		console.log('selectedContactIdd: ', selectedContactId);
		const myFunc = async () => {
			try {
				const response = await axios.get(`/entity/getContactsPhoneNumbers/${selectedContactId}`);
				const phones = response.data.map((e) => ({
					entity_contact_phone_number_id: e.entity_contact_phone_number_id,
					phone_number: e.phone_number,
					phone_type_id: e.phone_type_id,
					description: e.description,
					phone_type: e.phone_type,
				}));
				console.log('phonesdd', phones);
				setMyPhoneList([...phones]);
			} catch (error) {
				console.error('fetchContacts error: ', error);
			}
		};
		myFunc();
	}, [triggerRefreshContactPhoneList, selectedContactId]);

	// SET EMAIL LIST
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`/entity/getContactsEmails/${selectedContactId}`);
				const emails = response.data.map((e) => ({
					entity_contact_email_id: e.entity_contact_email_id,
					email_address: e.email_address,
				}));
				setMyEmailList([...emails]);
			} catch (error) {
				console.error('fetchContacts error: ', error);
			}
		};
		fetchData();
	}, [triggerRefreshContactEmailList, selectedContactId]);

	return (
		<ContainerAlpha>
			<Box>
				<ContainerTitle title='Contact Association:' />
			</Box>
			<Box
				sx={{
					display: 'grid',
					alignItems: 'center',
					gridTemplateColumns: 'repeat(12,1fr)',
					gap: 3,
				}}
			>
				<div style={{ gridColumn: '1/4' }}>
					{side === 'contact' ? (
						<ContactEntityListAutoComplete
							dataArray={entitiesMod}
							action={handleEntitySelection}
							title='Choose Entity'
						/>
					) : (
						<ContactEntityListAutoComplete
							dataArray={contactsMod}
							action={handleContactSelection}
							title='Choose Contact'
						/>
					)}
				</div>

				<div style={{ gridColumn: '4 / 6' }}>
					<TextField
						fullWidth
						select
						label='Role'
						defaultValue={null}
						onChange={(e) => {
							objData.current.entity_contact_role_id = e.target.value;
						}}
						InputLabelProps={{
							shrink: true,
						}}
					>
						{listOfContactRoles.map((e) => (
							<MenuItem key={e.role_id} value={e.role_id}>
								{e.label}
							</MenuItem>
						))}
					</TextField>
				</div>

				<div style={{ gridColumn: '6 / 9' }}>
					<TextField
						fullWidth
						select
						label='Emails'
						defaultValue={'10000'}
						onChange={(e) => {
							objData.current.entity_contact_email_id = e.target.value;
						}}
						InputLabelProps={{
							shrink: true,
						}}
					>
						{myEmailList.map((e) => (
							<MenuItem key={e.entity_contact_email_id} value={e.entity_contact_email_id}>
								{e.email_address}
							</MenuItem>
						))}
					</TextField>
				</div>

				<div style={{ gridColumn: '9 / 12' }}>
					<TextField
						fullWidth
						select
						label='Phone Number'
						defaultValue={''}
						onChange={(e) => {
							objData.current.entity_contact_phone_number_id = e.target.value;
						}}
						InputLabelProps={{
							shrink: true,
						}}
					>
						{myPhoneList.map((e) => (
							<MenuItem key={e.entity_contact_phone_number_id} value={e.entity_contact_phone_number_id}>
								{e.phone_type} - {e.phone_number}
							</MenuItem>
						))}
					</TextField>
				</div>

				<div style={{ gridColumn: '12/13', textAlign: 'right' }}>
					<IconButton onClick={() => handleOnSave()}>
						<Save color='success' />
					</IconButton>
				</div>
			</Box>

			<Box sx={{ p: 2 }}>
				<AssociationList type={side} identifier={objId} />
			</Box>
		</ContainerAlpha>
	);
};

export default ContactAssociation;
