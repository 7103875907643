import { Card, Grid, CardActionArea } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import WebServicesItemLookup from './WebServicesItemLookup/WebServicesItemLookup';

const Products = () => {
  
  const [isWebServicesModalOpen, setIsWebServicesModalOpen] = useState(false);
  const userPermission = useSelector((state) => state.userPermission.value);

  return (
    <Grid container spacing={2}>
      {userPermission[953] && 
      <Grid item xs={3}>        
        <CardActionArea>
          <Card onClick={() => setIsWebServicesModalOpen(true)} sx={{ p:3, textAlign:'center' }}>WEB SERVICES ITEM LOOKUP</Card>
        </CardActionArea>
      </Grid>}
      {/* <Grid item xs={1}>
        <Card sx={{ pl:1, textAlign:'center' }}>Product 2</Card>
      </Grid>
      <Grid item xs={1}>
        <Card sx={{ pl:1, textAlign:'center' }}>Product 3</Card>
      </Grid>
      <Grid item xs={1}>
        <Card sx={{ pl:1, textAlign:'center' }}>Product 4</Card>
      </Grid>       */}
      <WebServicesItemLookup
        open={isWebServicesModalOpen}
        onClose={() => setIsWebServicesModalOpen(false)}
      />
    </Grid>
  );
};

export default Products;
