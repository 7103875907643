import React, { useEffect, useState } from 'react';
import axios from 'axios';

// CONTEXT STATE
export const EntityContextState = React.createContext({
	allEntities: [],
	setAllEntities: () => {},
	allEntityTypes: [],
});

const EntityContext = ({ children }) => {
	// LOCAL STATE
	const [allEntitiesState, setAllEntitiesState] = useState([]);
	const [allEntityTypesState, setAllEntityTypesState] = useState([]);

	const setAllEntities = async () => {
		try {
			const response = await axios.get(`/entities/getAllEntities`);
			setAllEntitiesState([...response.data]);
		} catch (error) {
			console.error('Error fetching data:', error);
			return [];
		}
	};

	const setAllEntityTypes = async () => {
		try {
			const response = await axios.get(`/entities/getAllEntityTypes`);
			setAllEntityTypesState([...response.data]);
		} catch (error) {
			console.error('Error fetching data:', error);
			return [];
		}
	};

	useEffect(() => {
		setAllEntities();
		setAllEntityTypes();
	}, []);

	const contextValue = {
		allEntities: allEntitiesState,
		setAllEntities,
		allEntityTypes: allEntityTypesState,
	};

	return <EntityContextState.Provider value={contextValue}>{children}</EntityContextState.Provider>;
};
export default EntityContext;
