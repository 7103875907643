import { Card, Grid, CardActionArea } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ManufacturerAssignedToBuyer from './ManufacturerAssignedToBuyer/ManufacturerAssignedToBuyer';


const Manufacturer = () => {
  const [isManufacturerAssignedToBuyerOpen, setManufacturerAssignedToBuyerOpen] = useState(false);
  const userPermission = useSelector((state) => state.userPermission.value);
  
  console.log('userPermission :', + userPermission);
  
  return (
    <Grid container spacing={2}>
      {userPermission[951] && 
        <Grid item xs={3}>
        <CardActionArea>
          <Card onClick={() => setManufacturerAssignedToBuyerOpen(true)} sx={{ pt:3,pb:3,pl:1,pr:1, textAlign:'center' }}>MANUFACTURER ASSIGNED TO BUYER</Card>
        </CardActionArea>        
      </Grid>}
      {/* <Grid item xs={3}>
        <Card>Manufacturer 2</Card>
      </Grid>
      <Grid item xs={3}>
        <Card>Manufacturer 3</Card>
      </Grid>
      <Grid item xs={3}>
        <Card>Manufacturer 4</Card>
      </Grid> */}
      
      <ManufacturerAssignedToBuyer
        open={isManufacturerAssignedToBuyerOpen}
        onClose={() => setManufacturerAssignedToBuyerOpen(false)}
      />
    </Grid>
  );
};

export default Manufacturer;