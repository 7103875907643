import React, { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

const ContactEntityListAutoComplete = ({ dataArray, action, title }) => {
	const [value, setValue] = useState(null);

	return (
		<Autocomplete
			value={value}
			options={dataArray}
			getOptionLabel={(option) => option.label}
			renderInput={(params) => <TextField {...params} label={title} />}
			onChange={(event, newValue) => {
				setValue(newValue); // Update the state with the selected value
				action(newValue); // Call the handler with the new value
			}}
		/>
	);
};

export default ContactEntityListAutoComplete;
