import React, { useContext, useEffect, useState, useRef } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dayjs from 'dayjs';
import { EntityContextState } from '../../../../../Context/Entity/EntityContext';
import axios from 'axios';
import { Box, Button, MenuItem, TextField } from '@mui/material';
import SnackBarAlert from '../../../../_GlobalComponents/SnackBarAlert';
import ContainerAlpha from '../../ContainerAlpha';
import ContainerTitle from '../../ContainerTitle';

const ContactInfoForm = () => {
	// LOCAL STATE DATA
	const [data, setData] = useState({});
	const [timeZoneList, setTimeZoneList] = useState([]);
	const [stateList, setStateList] = useState([]);
	const workingData = useRef({});
	const [loaded, setLoaded] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);

	// CONTEXT DATA
	const { selectedContactIds, updateTriggerRefreshContactData, addSelectedContactIds } =
		useContext(EntityContextState);

	// FUNCTION TO CREATE PROFILE DATA
	const handleAddSubmit = async () => {
		try {
			const response = await axios.post('/entity/addContactRecord', {
				contact_add_data: workingData,
			});
			console.log(response.data);
			response.data.affectedRows > 0 && addSelectedContactIds(response.data.insertId);
			setSnackbarOpen(true);
			updateTriggerRefreshContactData();
		} catch (error) {
			console.error('Error:', error);
		}
	};

	// FUNCTION TO UPDATE PROFILE DATA
	const handleUpdateSubmit = () => {
		try {
			const response = axios.post('/entity/updateContact', {
				contact_update_data: workingData,
			});
			setSnackbarOpen(true);
			updateTriggerRefreshContactData();
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const ResetOpen = () => {
		setSnackbarOpen(false);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`/entity/getContactById/${selectedContactIds}`);
				setData({ ...response.data[0] });
				workingData.current = { ...response.data[0] };
				setLoaded(true);
			} catch (error) {
				console.log('fetchContacts error: ', error);
			}
		};

		fetchData();
	}, [selectedContactIds]);

	// FETCH TIMEZONE DATA
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`/entity/getTimeZones`);
				setTimeZoneList([...response.data]);
			} catch (error) {
				console.log('fetchContacts error: ', error);
			}
		};

		fetchData();
	}, []);

	// FETCH STATE DATA
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`/entity/getStates`);
				setStateList([...response.data]);
			} catch (error) {
				console.log('fetchContacts error: ', error);
			}
		};
		fetchData();
	}, []);

	const Content = () => {
		return (
			<ContainerAlpha>
				<Box>
					<ContainerTitle title='Contact Info:' />
				</Box>
				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: 'repeat(12,1fr)',
						gap: 3,
					}}
				>
					<Box sx={{ gridColumn: 'span 1' }}>
						<TextField
							fullWidth
							id='outlined-controlled'
							label='User Id'
							defaultValue={workingData.current.user_id || 0}
							onChange={(e) =>
								(workingData.current = { ...workingData.current, user_id: e.target.value })
							}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Box>
					<Box sx={{ gridColumn: 'span 2' }}>
						<TextField
							fullWidth
							id='outlined-controlled'
							label='First Name'
							defaultValue={workingData.current.first_name || ''}
							onChange={(e) =>
								(workingData.current = { ...workingData.current, first_name: e.target.value })
							}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Box>
					<Box sx={{ gridColumn: 'span 2' }}>
						<TextField
							fullWidth
							id='outlined-controlled'
							label='Last Name'
							defaultValue={workingData.current.last_name || ''}
							onChange={(e) =>
								(workingData.current = { ...workingData.current, last_name: e.target.value })
							}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Box>
					<Box sx={{ gridColumn: 'span 4' }}>&nbsp;</Box>
					{/* <Box sx={{ gridColumn: 'span 4' }}>
					<TextField
						fullWidth
						id='outlined-controlled'
						label='Attention Name'
						value={attnLine}
						onChange={(e) => setAttnLine(e.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Box>

				<Box sx={{ gridColumn: 'span 4' }}>
					<TextField
						fullWidth
						id='outlined-controlled'
						label='Job Title'
						value={jobTitle}
						onChange={(e) => setJobTitle(e.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Box> */}
					<Box sx={{ gridColumn: 'span 6' }}>
						<TextField
							fullWidth
							id='outlined-controlled'
							label='Street'
							defaultValue={workingData.current.address_1 || ''}
							onChange={(e) =>
								(workingData.current = { ...workingData.current, address_1: e.target.value })
							}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Box>
					<Box sx={{ gridColumn: 'span 6' }}>
						<TextField
							fullWidth
							id='outlined-controlled'
							label='Address 2'
							defaultValue={workingData.current.address_2 || ''}
							onChange={(e) =>
								(workingData.current = { ...workingData.current, address_2: e.target.value })
							}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Box>
					<Box sx={{ gridColumn: 'span 6' }}>
						<TextField
							fullWidth
							id='outlined-controlled'
							label='City'
							defaultValue={workingData.current.city || ''}
							onChange={(e) => (workingData.current = { ...workingData.current, city: e.target.value })}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Box>
					<Box sx={{ gridColumn: 'span 3' }}>
						<TextField
							fullWidth
							select
							id='outlined-controlled'
							label='State'
							defaultValue={workingData.current.state_id || ''}
							onChange={(e) =>
								(workingData.current = { ...workingData.current, state_id: e.target.value })
							}
							InputLabelProps={{
								shrink: true,
							}}
						>
							<MenuItem key={0} value={0}>
								Unknown
							</MenuItem>
							{stateList.map((e, i) => (
								<MenuItem key={i + 1} value={e.state_id}>
									{e.description}
								</MenuItem>
							))}
						</TextField>
					</Box>
					<Box sx={{ gridColumn: 'span 3' }}>
						<TextField
							fullWidth
							id='outlined-controlled'
							label='Zip Code'
							defaultValue={workingData.current.postal_code || ''}
							onChange={(e) =>
								(workingData.current = { ...workingData.current, postal_code: e.target.value })
							}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Box>
					<Box sx={{ gridColumn: 'span 3' }}>
						<TextField
							fullWidth
							id='outlined-controlled'
							label='Latitude'
							defaultValue={workingData.current.latitude || ''}
							onChange={(e) =>
								(workingData.current = { ...workingData.current, latitude: e.target.value })
							}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Box>
					<Box sx={{ gridColumn: 'span 3' }}>
						<TextField
							fullWidth
							id='outlined-controlled'
							label='Longitude'
							defaultValue={workingData.current.longitude || ''}
							onChange={(e) =>
								(workingData.current = { ...workingData.current, longitude: e.target.value })
							}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Box>
					<Box sx={{ gridColumn: 'span 3' }}>
						<TextField
							fullWidth
							select
							id='outlined-controlled'
							label='Time Zone'
							defaultValue={workingData.current.timezone_id || ''}
							onChange={(e) =>
								(workingData.current = { ...workingData.current, timezone_id: e.target.value })
							}
							InputLabelProps={{
								shrink: true,
							}}
						>
							<MenuItem key={0} value={249}>
								Unknown
							</MenuItem>
							{timeZoneList.map((e, i) => (
								<MenuItem key={i + 1} value={e.timezone_id}>
									{e.timezone_identifier}
								</MenuItem>
							))}
						</TextField>
					</Box>
					<Box sx={{ gridColumn: 'span 3' }}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								sx={{ width: '100%' }}
								defaultValue={Dayjs(workingData.current.birthdate)}
								onChange={(e) =>
									(workingData.current = {
										...workingData.current,
										birthdate: Dayjs(e).format('YYYY-MM-DD'),
									})
								}
								label='Birth Date'
							/>
						</LocalizationProvider>
					</Box>

					<Box sx={{ gridColumn: 'span 12' }}>
						<TextField
							fullWidth
							id='outlined-controlled'
							label='Notes'
							defaultValue={workingData.current.notes}
							onChange={(e) => (workingData.current = { ...workingData.current, notes: e.target.value })}
							multiline
							rows={4}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Box>
					<Box sx={{ gridColumn: 'span 12', textAlign: 'right' }}>
						{selectedContactIds > 0 ? (
							<Button variant='contained' color='success' onClick={() => handleUpdateSubmit()}>
								Update Contact
							</Button>
						) : (
							<Button variant='contained' color='success' onClick={() => handleAddSubmit()}>
								Add Contact
							</Button>
						)}
					</Box>
				</Box>
			</ContainerAlpha>
		);
	};

	return (
		<>
			{loaded ? <Content /> : '...Loading'}
			<SnackBarAlert doOpen={snackbarOpen} ResetOpen={ResetOpen} message='Update Data Saved!' />
		</>
	);
};

export default ContactInfoForm;
