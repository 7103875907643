import React, { useEffect, useState } from 'react';
import axios from 'axios';

// CONTEXT STATE
export const EntityContactContextState = React.createContext({
	allEntityContacts: [],
	setAllEntityContacts: () => {},

	allAssociationLink: [],
	setAllAssociationLink: () => {},

	allAssociationRolesTypes: [],

	allEmails: [],
	setAllEmails: () => {},

	allPhoneNumbers: [],
	setAllPhoneNumbers: () => {},

	allPhoneTypes: [],
	setAllPhoneTypes: () => {},

	allStates: [],
	allTimeZones: [],

	//updateEntityContract: () => {},
});

const EntityContactContext = ({ children }) => {
	// LOCAL STATE
	const [allEntityContactsState, setAllEntityContactsState] = useState([]);
	const [allAssociationLinkState, setAllAssociationLinkState] = useState([]);
	const [allAssociationRolesTypesState, setAllAssociationRolesTypesState] = useState([]);
	const [allEmailsState, setAllEmailsState] = useState([]);
	const [allPhoneNumbersState, setAllPhoneNumbersState] = useState([]);
	const [allPhoneTypesState, setAllPhoneTypesState] = useState([]);
	const [allStatesState, setAllStatesState] = useState([]);
	const [allTimeZonesState, setAllTimeZonesState] = useState([]);

	// FUNCTION
	const setAllEntityContacts = async () => {
		try {
			const response = await axios.get(`/entity-contacts/getAllEntityContacts`);
			setAllEntityContactsState([...response.data]);
		} catch (error) {
			console.error('Error fetching data:', error);
			return [];
		}
	};

	const setAllAssociationLink = async () => {
		try {
			const response = await axios.get(`/entity-contacts/getAllAssociaionLinks`);
			setAllAssociationLinkState([...response.data]);
		} catch (error) {
			console.error('Error fetching data:', error);
			return [];
		}
	};

	const setAllAssociationRolesTypes = async () => {
		try {
			const response = await axios.get(`/entity-contacts/getAllAssociationRolesTypes`);
			setAllAssociationRolesTypesState([...response.data]);
		} catch (error) {
			console.error('Error fetching data:', error);
			return [];
		}
	};

	const setAllEmails = async () => {
		try {
			const response = await axios.get(`/entity-contacts/getAllEmails`);
			setAllEmailsState([...response.data]);
			return response.data.length;
		} catch (error) {
			console.error('Error fetching data:', error);
			return [];
		}
	};

	const setAllPhoneNumbers = async () => {
		try {
			const response = await axios.get(`/entity-contacts/getAllPhoneNumbers`);
			setAllPhoneNumbersState([...response.data]);
			return response.data.length;
		} catch (error) {
			console.error('Error fetching data:', error);
			return [];
		}
	};

	const setAllPhoneTypes = async () => {
		try {
			const response = await axios.get(`/entity-contacts/getAllPhoneTypes`);
			setAllPhoneTypesState([...response.data]);
		} catch (error) {
			console.error('Error fetching data:', error);
			return [];
		}
	};

	const setAllStates = async () => {
		try {
			const response = await axios.get(`/entity-contacts/getAllStates`);
			setAllStatesState([...response.data]);
		} catch (error) {
			console.error('Error fetching data:', error);
			return [];
		}
	};

	const setAllTimeZones = async () => {
		try {
			const response = await axios.get(`/entity-contacts/getAllTimeZones`);
			setAllTimeZonesState([...response.data]);
		} catch (error) {
			console.error('Error fetching data:', error);
			return [];
		}
	};

	// const updateEntityContract = async (data) => {
	// 	try {
	// 		const response = await axios.post(`/entity-contacts/updateEntityContact`);
	// 	} catch (error) {
	// 		console.log('error: updateEntityContract ', error);
	// 	}
	// };

	useEffect(() => {
		setAllEntityContacts();
		setAllAssociationLink();
		setAllAssociationRolesTypes();
		setAllEmails();
		setAllPhoneNumbers();
		setAllPhoneTypes();
		setAllStates();
		setAllTimeZones();
	}, []);

	// CONTEXT OBJECT TO PASS THROUGH PROVIDER
	const contextValue = {
		allEntityContacts: allEntityContactsState,
		setAllEntityContacts,
		allAssociationLink: allAssociationLinkState,
		setAllAssociationLink,
		allAssociationRolesTypes: allAssociationRolesTypesState,
		allEmails: allEmailsState,
		setAllEmails,
		allPhoneNumbers: allPhoneNumbersState,
		setAllPhoneNumbers,
		allPhoneTypes: allPhoneTypesState,
		setAllPhoneTypes,
		allStates: allStatesState,
		allTimeZones: allTimeZonesState,
		//updateEntityContract,
	};

	return <EntityContactContextState.Provider value={contextValue}>{children}</EntityContactContextState.Provider>;
};

export default EntityContactContext;
