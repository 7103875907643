import {
  Modal,
  Paper,
  Typography,
  Box,
  Grid,
  CardMedia,
  IconButton,
  Button,
  Divider,
  TextField,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CancelPresentationTwoToneIcon from '@mui/icons-material/CancelPresentationTwoTone';
import PublishIcon from '@mui/icons-material/Publish';
import FlagIcon from '@mui/icons-material/Flag';
import CloseIcon from '@mui/icons-material/Close';

const ItemModal = ({ isOpen, closeModal, itemData }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [flagNote, setFlagNote] = useState('');
  const userInformation = useSelector((state) => state.userInformation.value);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseImageModal = () => {
    setSelectedImage(null);
  };

  const handleFlagNoteChange = (e) => {
    setFlagNote(e.target.value);
  };

  const publishItem = (productId) => {
    axios
      .post('/publishItems/publish', {
        userId: userInformation.userID,
        productId: productId,
      })
      .then((response) => {
        handleCloseImageModal();
        closeModal();
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const publishItemAndFlag = (productId) => {
    console.log('Product Id:', productId, 'User ID:', userInformation.userID);
    axios
      .post('/publishItems/publishAndFlag', {
        userId: userInformation.userID,
        productId: productId,
        note: flagNote,
      })
      .then((response) => {
        handleCloseImageModal();
        closeModal();
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const flagItem = (productId) => {
    axios
      .post(`/publishItems/flag`, {
        userId: userInformation.userID,
        productId: productId,
        note: flagNote,
      })
      .then((response) => {
        handleCloseImageModal();
        closeModal();
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Box
      sx={{
        maxHeight: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Modal open={isOpen} onClose={closeModal}>
        <Paper
          elevation={8}
          sx={{
            width: '90%',
            maxWidth: '85vw',
            maxHeight: '90vh',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            p: 2,
          }}
        >
          {itemData ? (
            <>
              <Grid container spacing={1}>
                <Grid
                  container
                  item
                  xs={12}
                  direction='row'
                  alignItems='center'
                  justifyContent='space-around'
                >
                  {[
                    'thumb_image_url',
                    'small_image_url',
                    'large_image_url',
                    'highres_image_url',
                  ].map((imgKey) => (
                    <Grid item key={imgKey} xs={3} textAlign='center'>
                      <IconButton onClick={() => handleImageClick(itemData[imgKey])}>
                        <CardMedia
                          component='img'
                          image={itemData[imgKey]}
                          alt={imgKey}
                          sx={{
                            maxWidth: '200px',
                            maxHeight: '70px',
                            cursor: 'pointer',
                            borderRadius: 2,
                            height: 'auto',
                          }}
                        />
                      </IconButton>
                    </Grid>
                  ))}
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  direction='row'
                  alignItems='center'
                  justifyContent='space-around'
                >
                  {[
                    'thumb_image_url',
                    'small_image_url',
                    'large_image_url',
                    'highres_image_url',
                  ].map((imgKey) => (
                    <Grid item key={imgKey} xs={3} textAlign='center'>
                      <Typography
                        variant='caption'
                        sx={{
                          fontFamily: 'Roboto, sans-serif',
                          fontWeight: 'bold',
                          textTransform: 'uppercase',
                          color: '#6e6e6e',
                          mt: 1,
                          letterSpacing: 1,
                          fontSize: '0.75rem',
                          textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
                        }}
                      >
                        {imgKey.split('_')[0]}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: 2,
                    border: '1px solid #ddd',
                    borderRadius: 1,
                    height: '100%',
                    mb: 1,
                    backgroundColor: '#FAF9F6',
                  }}
                >
                  {[
                    ['Item #', itemData.product_code],
                    ['MFR #', itemData.manufacturer_sku],
                    ['UPC #', itemData.upc_code],
                    ['Brand', itemData.brand],
                    ['Category', itemData.category],
                    ['Model', itemData.model],
                    ['Series', itemData.series],
                  ].map(([label, value]) => (
                    <Box sx={{ display: 'flex', alignItems: 'center' }} key={label}>
                      <Typography
                        variant='body2'
                        sx={{ fontWeight: 'bold', color: 'text.primary', mr: 1 }}
                      >
                        {label}:
                      </Typography>
                      <Typography
                        variant='body2'
                        sx={{
                          color: value ? 'text.secondary' : 'error.main',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {value || (
                          <CancelPresentationTwoToneIcon sx={{ verticalAlign: 'middle' }} />
                        )}
                      </Typography>
                    </Box>
                  ))}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid #ddd',
                    borderRadius: 1,
                    height: '100%',
                    p: 2,
                    mb: 1,
                    backgroundColor: '#FAF9F6',
                  }}
                >
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        variant='body2'
                        sx={{ fontWeight: 'bold', color: 'text.primary', mr: 1 }}
                      >
                        Description:
                      </Typography>
                      <Typography
                        variant='body2'
                        sx={{
                          color: itemData.description ? 'text.secondary' : 'error.main',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {itemData.description || (
                          <CancelPresentationTwoToneIcon sx={{ verticalAlign: 'middle' }} />
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid #ddd',
                    borderRadius: 1,
                    height: '100%',
                    p: 2,
                    mb: 1,
                    backgroundColor: '#FAF9F6',
                  }}
                >
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        variant='body2'
                        sx={{ fontWeight: 'bold', color: 'text.primary', mr: 1 }}
                      >
                        Paragraph:
                      </Typography>
                      <Typography
                        variant='body2'
                        sx={{
                          color: itemData.paragraph_text ? 'text.secondary' : 'error.main',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {itemData.paragraph_text || (
                          <CancelPresentationTwoToneIcon sx={{ verticalAlign: 'middle' }} />
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    height: '200px',
                    overflowY: 'auto',
                    border: '1px solid #ddd',
                    borderRadius: 1,
                    backgroundColor: '#FAF9F6',
                  }}
                >
                  <Box sx={{ p: 2, height: '100%' }}>
                    <Grid item container spacing={1}>
                      {itemData.facets ? (
                        Object.entries(JSON.parse(itemData.facets)).map(([key, value], index) => (
                          <Grid item xs={12} md={3} key={key} sx={{}}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Typography
                                variant='body2'
                                sx={{ fontWeight: 'bold', color: 'text.primary', mr: 1 }}
                              >
                                {key}:
                              </Typography>
                              <Typography
                                variant='body2'
                                sx={{
                                  color: 'text.secondary',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {value}
                              </Typography>
                            </Box>
                            <Divider sx={{ my: 1 }} />
                          </Grid>
                        ))
                      ) : (
                        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                          No facets available
                        </Typography>
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>

              <Divider sx={{ my: 2 }} />
              <Grid container spacing={2} sx={{ flexShrink: 0 }}>
                <Grid item xs={12}>
                  <TextField
                    label='Flag Note'
                    rows={4}
                    variant='outlined'
                    fullWidth
                    value={flagNote}
                    onChange={handleFlagNoteChange}
                  />
                </Grid>

                <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
                  <Grid item container xs={12} md={4} spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Button
                        variant='contained'
                        color='primary'
                        sx={{ width: '100%' }}
                        startIcon={<FlagIcon />}
                        onClick={() => publishItemAndFlag(itemData.product_id)}
                        disabled={!flagNote.trim()}
                      >
                        Publish & Flag
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        variant='contained'
                        color='secondary'
                        sx={{ width: '100%' }}
                        startIcon={<FlagIcon />}
                        onClick={() => flagItem(itemData.product_id)}
                        disabled={!flagNote.trim()}
                      >
                        Flag
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid item container xs={12} md={4} justifyContent='flex-end' spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Button
                        variant='contained'
                        color='success'
                        sx={{ width: '100%' }}
                        startIcon={<PublishIcon />}
                        onClick={() => publishItem(itemData.product_id)}
                      >
                        Publish
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        variant='contained'
                        color='error'
                        sx={{ width: '100%' }}
                        startIcon={<CloseIcon />}
                        onClick={closeModal}
                      >
                        Close
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <Typography variant='body1'>No data</Typography>
          )}
        </Paper>
      </Modal>

      <Modal open={!!selectedImage} onClose={handleCloseImageModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            p: 2,
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
        >
          {selectedImage && (
            <CardMedia
              component='img'
              image={selectedImage}
              alt='Selected'
              sx={{ maxHeight: '80vh', maxWidth: '80vw' }}
            />
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default ItemModal;
