import React from 'react';
import { Grid, Typography, Container, Paper, Box } from '@mui/material';
import Logo from '../../Assets/BugLogo.png';

const Home = () => {
  return (
    <Container
      maxWidth='lg'
      sx={{
        height: 'calc(100vh - 64px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid container spacing={4} alignItems='center' justifyContent='center'>
        <Grid item xs={12} md={6}>
          <Box
            component={Paper}
            elevation={3}
            sx={{
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={Logo} alt='Logo' style={{ width: '80%', marginBottom: '20px' }} />
            <Typography variant='h3' align='center' gutterBottom>
              Welcome to the Hub
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
