import { useSelector } from 'react-redux';
// Custom Components
import GridContainer from '../../_GlobalComponents/GridContainer';
import SectionTitle from '../../_GlobalComponents/SectionTitle';
import PageTitle from '../../_GlobalComponents/PageTitle';
import SearchResults from './Components/SearchResults';
import SelectedItemsAttributes from './Components/SelectedItemsAttributes';
import BrandCategorySearch from './Components/BrandCategorySearch';
import SearchTerm1 from './Components/SearchTerm1';
import ModalFlags from './Components/ModalFlags';
import RowFilterInput from './Components/RowFilterInput';
import NoDescriptionCheckBox from './Components/NoDescriptionCheckBox';
import ReductiveSearchValues from './Components/ReductiveSearchValuesV5';

// Material UI
import { Box, Divider, Paper, styled } from '@mui/material';
import PDMSnackBar from '../Components/PDMSnackBar';
import TestState from './Components/TestState';
import TestCart from './Components/TestCart';

// Material Styled Components
const Wrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
}));

// Primary Component
const AS400DescriptionManager = () => {
  const reductiveNavFullList = useSelector((state) => state.productSlice.reductiveNavFullList);

  const testFunc = (id) => {
    console.log('function called with id');
  };

  return (
    <Wrapper id='AS400DescriptionManager' className='apple' sx={{ marginLeft: '36px' }}>
      <PageTitle title='AS400 Description Management' padding='15px' />

      <GridContainer minHeight='100%' styles={{ minHeight: '100%', maxHeight: '100%', overflow: 'hidden' }}>
        <Box sx={{ gridColumn: '1/6', height: '100%', overflowY: 'auto' }}>
          <SearchTerm1 />
          <Divider />
          <BrandCategorySearch groupName='as400ExtendedDescription' func={testFunc} />
          <Divider />
          {/* <TestCart />
          <Divider />
          <TestState />
          <Divider /> */}
          <ReductiveSearchValues groupName='as400ExtendedDescription' />
        </Box>
        <Box sx={{ gridColumn: '6/19', display: 'grid', gridTemplateRows: 'auto auto 1fr auto', minHeight: '100%', maxHeight: '100%', overflow: 'auto' }}>
          <Box>{/* <SectionTitle title='Search Results:' /> */}</Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <RowFilterInput />
            <NoDescriptionCheckBox />
          </Box>
          <Box sx={{ overflow: 'auto', minHeight: '100%', maxHeight: '100%' }}>{/* <SearchResults commonRedux='descriptonManagementIds' /> */}</Box>
          <Box>pagination</Box>
        </Box>
        <Box sx={{ gridColumn: '19/25', backgroundColor: 'rgba(0,0,0,.0)', height: '100%', overflowY: 'auto' }}>
          <Paper sx={{ padding: 2 }}>
            <SectionTitle title='Items Attributes:' />
            <SelectedItemsAttributes />
          </Paper>
        </Box>
      </GridContainer>
      <ModalFlags />
      <PDMSnackBar />
    </Wrapper>
  );
};
export default AS400DescriptionManager;
