
import { Autocomplete, Chip, Stack, Menu, TextField, Grid, Modal, Typography, Box, CircularProgress } from '@mui/material';
import { List, ListItem, ListItemText, Button, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';

export default function PDMAttributeDrawer(props){
  const [curAttr, setCurAttr] = useState([])
  const [pageAttr, setPageAttr] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [activeFacets, setActiveFacets] = useState({id:0, label:'Loading'})
  const [value, setValue] = useState({})
  let [openAttribute, setOpenAttribute] = useState(false)
  let [open, setOpen] = useState(false)
  let workingAttr = useRef({})
  const pCode = (props.productCode)
  let pID = useRef()
  const [openModal, setOpenModal] = useState(false)
  const errData = useRef({info: '', title: ''})
  const facetOptions = useRef({label: 'Loading', id:0})
  let modalType = useRef('default')
  const addedColor = 'success'
  const deletedColor = 'error'
  const neutralColor = 'default'

  const style = {
    top: '40%',
    left: '50%',
    width: 300,
    bgcolor: 'background.paper',
    position: 'absolute',
    alignItems: 'center',
  }

  //TODO
  //    Change how delete is being done
  //    Have a process to show what attributes are being changed
  //        Green for Added
  //        Red for Deleted DONE
  //    After changing, have the save button on the bottom proceed with a confirmation box
  //        Confirmation will list all added and all deleted attributes
  //        Add save to modal


  useEffect(() => {
    const getAllAttributes = () => {
        axios.get("/pdm/getAllActiveFacets"
        ).then((res) => {
            setActiveFacets(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    const getCurAttributes = () => {
        axios.get("/productData/getAttributesForProductCode/"+pCode)
            .then((res) => {
                let fields = []
                let openList = []
                let id;
                res.data.map((atr)=> {
                    let oldData = false
                    id = atr.product_id
                    let newAtr = {
                        "description": atr.description,
                        "product_code": atr.product_code,
                        //"product_facet_value_id": atr.product_facet_value_id,
                        "product_id": atr.product_id,
                        "product_facet_id": atr.product_facet_id,
                        //"product_facet_option_id": atr.product_facet_option_id,
                        "facet_info": [{
                            facet_value: atr.facet_value, 
                            //product_facet_id: atr.product_facet_id,  
                            product_facet_option_id: atr.product_facet_option_id, 
                            product_facet_value_id: atr.product_facet_value_id,
                            color: neutralColor
                        }] 
                    }
                    if(fields.length !== 0){
                        fields.forEach((record) =>{
                            if(record.description===atr.description){
                            //console.log(atr)
                            record.facet_info.push(newAtr.facet_info[0])
                            oldData = true
                            }
                        })
                        if(!oldData){
                            openList[atr.description] = false
                            fields.push(newAtr)
                        }
                    }else{
                        openList[atr.description] = false
                        fields.push(newAtr)
                    }
          
                })
                setCurAttr(fields)
                setPageAttr(fields)
                pID.current = id;
                //console.log(fields)
            }).catch((err) => {
                console.log("Error: " + err)
            })
    }   

    getAllAttributes()
    getCurAttributes()
}, [])

const getOptionsForFacet = async (facetValue) => {
    await axios.get("/pdm/getAllValueForAttribute/" + facetValue)
        .then((res) => {
            console.log(facetValue)
            console.log(res.data)
            facetOptions.current = res.data
            setOpen(true)
        })
        .catch((err) => {
            console.log(err)
        })
}

const modalContents = () => {
    if(modalType.current == "Delete"){
        return(
        <Box sx={style}>
            <Typography textAlign={'center'} padding={3} fontWeight={'bold'}>
                Are you sure you want to delete the attribute, including all options added to it?
            </Typography>
            <Stack 
                direction={"row"} 
                paddingBottom={3} 
                flex={1} 
                justifyContent={'center'} 
                spacing={1} 
            >
                <Button variant='contained' color='success'  onClick={() => handleDeleteAttribute()}>Yes</Button>
                <Button variant='contained' color='error' onClick={() => toggleModal()} >No</Button>
            </Stack>
        </Box>
        )
    }
    if(modalType.current == 'Error'){
        return(
            <Box sx={style}>
                <Typography textAlign={'center'} padding={2} fontWeight={'bold'} fontSize={22}>
                    {errData.current.title}
                </Typography>
                <Typography textAlign={'center'} padding={2} fontSize={15}>
                    {errData.current.info}
                </Typography>
                <Stack 
                    direction={"row"} 
                    paddingBottom={3} 
                    flex={1} 
                    justifyContent={'center'} 
                    spacing={1} 
                >
                    <Button 
                        paddingLeft={3}
                        paddingBottom={3}
                        textAlign={'center'}
                        variant='contained' 
                        color='success'  
                        onClick={() => toggleModal()}
                    >
                        Okay
                    </Button>
                </Stack>
            </Box>
        )
    }
    return(
        <div></div>
    )
}

const handleClick = async (event, attribute) => {
    getOptionsForFacet(attribute.product_facet_id)
    console.log(attribute)
    setAnchorEl(event.currentTarget);
    workingAttr.current = attribute
  }

const handleClickAttribute = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenAttribute(true)
  }

const handleClose = (description) => {
  setAnchorEl(null)
  setOpen(false)
}  

const handleCloseAttribute = () => {
  setAnchorEl(null)
  setOpenAttribute(false)
}

const handleAutoComplete = (newValue, event) => {
  //Need Product ID to which product I'm adding to
  //Need Product Facet ID for which facet I'm saving EX Caliber 
  //Need Option ID aka the ID for selected value EX ID for 9mm
  console.log(newValue)
  setValue(newValue.label)
  let updatedFacetValues = []
  try {
    
  //Adding new Facet option here

  const modifiedAttr = pageAttr.map((record) => {
    if (record.description === workingAttr.current.description) {
        let exists = false
      record.facet_info.forEach((val) => {
        updatedFacetValues.push(val)
        console.log(val)
        if(newValue.label === val.facet_value){
            throw new Error('Similar value in list')
        }
      })
      updatedFacetValues.push({
        facet_value: newValue.label, 
        product_facet_option_id: newValue.option_id,
        product_facet_value_id: 'new', 
        color: addedColor})
      //console.log(updatedFacetValues)
      return { ...record, facet_info: updatedFacetValues };
    }
    return record;

  });
  handleClose(event)
  setPageAttr(modifiedAttr)
} catch (error) {
    handleClose(event)
    errData.current  = {title: 'Invalid Selection', info: 'Option already added to list'}
    modalType.current = "Error"
    toggleModal()
  }
}

const handleAutoCompleteAttribute = (event, value) => {
  console.log(value)
  let tempPAttr = []
  let exists = false
  pageAttr.map((record) => {
    if(record.description === value.label){
        exists = true
    }
  })

    if(!exists){
        tempPAttr.push({
            description: value.label,
            facet_info:[],
            product_code: pCode,
            product_id: pID.current,
            product_facet_id: value.id
        }, ...pageAttr)
        setPageAttr(tempPAttr)
    }else{
        errData.current = {info: 'Facet already added to Attribute list', title: 'Invalid Selection'}
        modalType.current = "Error"
        toggleModal()
        console.log("Showing duplicate facet error")
    }
}

const setupDelete = (event, attr) => {
    modalType.current = "Delete"
    workingAttr.current = attr
    toggleModal()

}

const handleToggleMarkDelete = (atr, atrIdx, facIdx) => {
    let newAttrArray = [...pageAttr]
    switch(newAttrArray[atrIdx].facet_info[facIdx].color){
                case 'success':
                    let tempFIArray = []
                    newAttrArray[atrIdx].facet_info.map((val, i) => {
                        if(i !== facIdx){
                            tempFIArray.push(val)
                        }
                    })
                    console.log(tempFIArray)
                    newAttrArray[atrIdx].facet_info = tempFIArray
                    break;
                case 'error':
                    newAttrArray[atrIdx].facet_info[facIdx].color = neutralColor
                    break;
                case 'default':
                    newAttrArray[atrIdx].facet_info[facIdx].color = deletedColor
                    break;
                default:
                    console.log('unused color')   
    }
    setPageAttr(newAttrArray)
}



const saveChanges = () => {

    pageAttr.map((atr) => {
        let temp = [{
            product_code: atr.product_code,
            product_id: atr.product_id,
            product_facet_id: atr.product_facet_id
        }]
        let tempDelete =[]
        let tempAdd = []
        console.log(atr)
        atr.facet_info.map((fi) => {
            console.log(fi.color)
            switch(fi.color){
                case 'success':
                    tempAdd.push(fi)
                    break;
                case 'error':
                    tempDelete.push(fi)
                    break;
                default:
                    break;        
            }

        });
        console.log(tempDelete)
        handleDeleteAttribute(temp, tempDelete)
        handleSaveAttribute(temp, tempAdd)
        closeDrawer()
    })

}

const handleDeleteAttribute = (info, attributes) => {
    console.log('deleting')
    attributes.map((val) => {
        console.log(val)
        
        axios.post('/productData/deleteProductFacetRecord', {
            product_facet_value_id: val.product_facet_value_id
        }).then((res) => {
         console.log(res)    
        }).catch((err) => {
         console.log(err)
        })
    })
}

const handleSaveAttribute = (info, attributes) => {
    attributes.map((val) => {
        axios.post('/productData/insertProductFacetRecord', {
            product_id: info[0].product_id,
            product_facet_id: info[0].product_facet_id,
            product_facet_option_id: val.product_facet_option_id
        }).then((res) => {
            if(res.data.code){
                errData.current = {info: res.data, title: 'Error'}
                modalType.current = "Error"
                toggleModal()
            }
            console.log(res)
        }).catch((err) => {
            errData.current = {info: err, title: 'Error'}
            modalType.current = "Error"
            toggleModal()
        })
    })
}



const closeDrawer = () => {
    props.closeCallback()
}

const toggleModal = () => {
    setOpenModal(!openModal)
}

if(pageAttr.length !== 0){
    return (
      <div style={{width:'300', maxWidth:'300', overflowX:'hidden'}}>
        <Stack direction={'row'}>
        <div style={{textAlign: 'center', fontWeight:'700', color:'#00325a', fontSize:'22px', margin:'10px'}}> Current Attributes</div>
        <IconButton
          id={'iconButtonAdd'}
          onClick={(event) => {
            handleClickAttribute(event)
          }}
        >
          <AddIcon/>
        </IconButton>
        <Menu
                 slotProps={{paper:{sx: {width: 300}}}}
                  id={"fade-menu-attribute"}
                  anchorEl={anchorEl}
                  open={openAttribute|| false}
                  onClose={() => handleCloseAttribute()}
                >
                <Autocomplete  
                  options={activeFacets}
                  getOptionKey={(option) => option.id}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, value) => [handleAutoCompleteAttribute(event, value), handleCloseAttribute()]}
                  renderInput={(params) => <TextField {...params} />}
                />
                </Menu>
        </Stack>
        <List>
          {pageAttr.map((atr, atrIdx) => (
            <ListItem key={"ListItem-"+atrIdx} id={atr.product_facet_value_id} sx={{width:"100%"}}>
              <Stack direction='column' alignItems={'left'} width={"100%"}>
                <Grid container spacing={2} alignItems={'center'}>
                  <Grid item>
                    <ListItemText primary={atr.description} sx={{fontSize:12, paddingLeft:1.25}}/>
                  </Grid>
                  <Grid item alignItems={'right'}>
                <IconButton 
                  id={"iconButtonDelete-" + atrIdx}
                  onClick={(event) => setupDelete(event, atr)}
                >
                  <DeleteIcon/>
                </IconButton>
                </Grid>
                
                </Grid>
              <Stack direction={"row"} overflow={'scroll'} flex={1} flexWrap={'wrap'} spacing={1} borderBottom={1} paddingBottom={1} alignItems={'center'}>
                {atr.facet_info.map((value, facIdx) => 
                ( 
                  <Chip color={value.color} key={"Chip-"+value.facet_value} sx={{marginTop:'5'}} label={value.facet_value} onDelete={() => handleToggleMarkDelete(atr, atrIdx, facIdx)}/>
                )
                )}
                <IconButton 
                  id={"iconButtonEdit-" + atr.description}
                  onClick={event => handleClick(event, atr)} 
                >
                  <AddIcon/>
                </IconButton>
              </Stack>
              </Stack>
              
            </ListItem>
          ))}
            <Menu
                slotProps={{paper:{sx: {width: 300}}}}
                id={"fade-menu"}
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
            >
                <Autocomplete
                    options={facetOptions.current}
                    onChange={(event, newValue) => handleAutoComplete(newValue, event)}
                    renderInput={(params) => <TextField {...params} />}
                    isOptionEqualToValue={(option, newValue) => option.label === newValue.label}
                />
            </Menu>
        </List>
        <Stack direction={'row'} spacing={1} padding={1}>
            <Button variant='contained' onClick={(event) => closeDrawer()}>Discard Changes</Button>
            <Button variant='contained' onClick={(event) => saveChanges()}>Save and Close</Button>
        </Stack>
        <Modal
            open={openModal}
            onClose={toggleModal}
        >
            {modalContents()}

        </Modal>
      </div>
      
    );
  }else{
    return(<div><CircularProgress/></div>)
  }
};