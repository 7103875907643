import React, { useEffect, useMemo, useState } from 'react';
import { Box, Chip } from '@mui/material';
import DashBoardActions from './DashBoardActions';
import { getSubBrandsByMfrId } from '../ManageContracts/Feeds/ContractsFeed';

const useColumns = () => {
	return useMemo(
		() => [
			{
				accessorFn: (row) => `${row.entity_id} ${row.ss_publication_contract_id}`,
				id: 'action_01',
				header: 'Action',
				Cell: ({ cell }) => (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-around',
							gap: 4,
							alignItems: 'center',
							width: '100%',
						}}
					>
						<DashBoardActions
							entityId={cell.row.original.entity_id}
							contractId={cell.row.original.ss_publication_contract_id}
						/>
					</Box>
				),
				size: 200,
				grow: false,
			},

			{
				accessorKey: 'manufacturer', // accessor is the "key" in the data
				id: 'manufacturer',
				header: 'Manufacturer',
				size: 166,
				grow: false,
			},

			{
				accessorKey: 'product_manufacturers', // accessor is the "key" in the data
				id: 'product_manufacturers',
				header: 'Brands',
				Cell: ({ cell }) => {
					const [brands, setBrands] = useState([]);

					useEffect(() => {
						const fetchBrands = async () => {
							try {
								const returnData = await getSubBrandsByMfrId(cell.row.original.entity_id);
								setBrands(returnData);
							} catch (error) {
								console.error('error fetching brands: ', error);
							}
						};
						fetchBrands();
					}, [cell.row.original.entity_id]);

					const contractBrands = cell.row.original.product_manufacturers.split(',');

					const matchingBrands = brands
						.filter((brand) => contractBrands.includes(brand.entity_id.toString()))
						.map((brand) => brand.business_name);

					return (
						<Box
							sx={{
								display: 'flex',
								flexWrap: 'wrap',
								gap: 4,
								alignItems: 'center',
								width: '100%',
							}}
						>
							{matchingBrands.map((brand, i) => (
								<Chip key={i} label={brand} sx={{ fontWeight: 'bold' }} color='success' />
							))}
						</Box>
					);
				},

				size: 300,
				grow: true,
			},
			{
				accessorKey: 'publicaiton_option', // accessor is the "key" in the data
				id: 'publicaiton_option',
				header: 'Elected Option',
				size: 166,
				grow: false,
			},
			{
				accessorKey: 'sold_price', // accessor is the "key" in the data
				id: 'sold_price',
				header: 'Sold Price',
				Cell: ({ cell }) => `$${cell.row.original.sold_price}`,
				size: 200,
				grow: false,
			},
			{
				accessorKey: 'contract_status', // accessor is the "key" in the data
				id: 'contract_status',
				header: 'Contract Status',
				size: 200,
				grow: false,
			},
			{
				accessorKey: 'time_created', // accessor is the "key" in the data
				id: 'time_created',
				header: 'Created On:',
				size: 200,
				grow: false,
			},
			{
				accessorKey: 'last_status_changed', // accessor is the "key" in the data
				id: 'last_status_changed',
				header: 'Last Updated:',
				size: 200,
				grow: false,
			},
			// Add more column definitions as needed
		],
		[]
	);
};

export default useColumns;
