import React, { useContext, useEffect } from 'react';
//import { Handyman } from '@mui/icons-material';
import { Box, Button, Tooltip } from '@mui/material';
//import { EntityContextState } from '../../../../../Context/Entity/EntityContext';
//import ContractsByEntities from '../../../../Contracts/components/ContractsByEntities/ContractsByEntities';

import EditContactMenu from '../../EditContactMenu/EditContactMenu';
import ButtonToModalContracts from './ButtonToModalContracts';
import ButtonToModalAssociations from './ButtonToModalAssociations';

const RowEditAction = ({ contactId }) => {
	//	const { toggleUpdateContactDrawerOpener, addSelectedContactIds } = useContext(EntityContextState);

	// const handleClick = () => {
	// 	addSelectedContactIds(contactId);
	// 	toggleUpdateContactDrawerOpener(true);
	// };

	return (
		<Box sx={{ display: 'flex', gap: '8px' }}>
			{/* <Tooltip title={`Contact ${contactId} Maintenance`}>
				<Button
					variant='contained'
					sx={{ borderRadius: '14px' }}
					size='small'
					color='warning'
					startIcon={<Handyman />}
					onClick={() => handleClick()}
				>
					<span style={{ fontWeight: 'bold' }}>Maint.</span>
				</Button>
			</Tooltip> */}
			<Tooltip title={`Contact ${contactId} Maintenance`}>
				<span>
					<EditContactMenu contactId={contactId} />
				</span>
			</Tooltip>

			<ButtonToModalContracts contactId={contactId} />

			<ButtonToModalAssociations contactId={contactId} />
			{/* <ContractsByEntities contactId={contactId} /> */}
		</Box>
	);
};

export default RowEditAction;
