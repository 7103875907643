import { RequestQuote } from '@mui/icons-material';
import { Backdrop, IconButton, Modal, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { getContractsByBrandId } from '../../../../Contracts/components/ManageContracts/Feeds/ContractsFeed';
//import ContractCards from '../../../../Contracts/components/ManageContracts/components/ContractCards/ContractCards';
import ContractCards from '../../../../Contracts/components/ContractCards';

// const style = {
// 	position: 'absolute',
// 	top: '50%',
// 	left: '50%',
// 	transform: 'translate(-50%, -50%)',
// 	width: 400,
// 	bgcolor: 'background.paper',
// 	border: '2px solid #000',
// 	boxShadow: 24,
// 	pt: 2,
// 	px: 4,
// 	pb: 3,
// };

const ViewContractsAction = ({ item }) => {
	const theme = useTheme();

	const [open, setOpen] = useState(false);
	const [data, setData] = useState({});
	const [ids, setIds] = useState([]);

	const handleClick = async () => {
		try {
			// CALLING getContractsByBrandId WILL RETURN ALL ASSOCIATED CONTRACTS
			const response = await getContractsByBrandId(
				item.entity_id,
				item.parent_entity_id,
				item.entity_type_id,
				item.parent_type_id
			);

			setData(response);
			console.log('response respone: ', response);
			setIds([...response.map((item) => item.entity_id)]);
			handleOpen();
		} catch (error) {
			console.log('error ViewContractsAction ', error);
		}
	};

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	console.log('data data: ', data);
	console.log('ids ids: ', ids);
	return (
		<>
			<IconButton
				disabled={item.number_of_contracts === 0 && item.number_of_subbrand_contracts === 0}
				sx={{
					color:
						item.number_of_contracts > 0 || item.number_of_subbrand_contracts > 0
							? theme.palette.success.main
							: 'rgb(200,200,200)',
				}}
				onClick={() => handleClick()}
			>
				<RequestQuote />
			</IconButton>

			<Modal
				open={open}
				onClose={() => handleClose()}
				aria-labelledby='parent-modal-title'
				aria-describedby='parent-modal-description'
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						onTouchEnd: handleClose, // Handle touch events on mobile
						sx: {
							bgcolor: 'rgba(21, 57, 84, 0.8)',
						},
					},
				}}
			>
				{/* <ContractCards data={data} /> */}
				<ContractCards search='entities' ids={ids} />
			</Modal>
		</>
	);
};

export default ViewContractsAction;
