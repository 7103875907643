import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import Dayjs from 'dayjs';
import UpdateContactDrawer from '../../Components/Entities/components/ManageContacts/components/UpdateContactDrawer';
import ContactDrawerUpdater from '../../Components/Entities/components/ManageContacts/components/ContactDrawerUpdater';
import ContactsDataList from '../../Components/Entities/components/ManageContacts/components/ContactsDataList';
import EntityDrawerUpdater from '../../Components/Entities/components/ManageEntities/components/EntityDrawerUpdater';
import { contactTypeData } from '../../Components/Entities/DataFeeds/ContactData';

export const EntityContextState = React.createContext({
	// NEW
	allEntities: [],
	setAllEntities: () => {},

	// OLD
	selectedContactIds: 0,
	selectedEntityTypeId: 0,
	updateSelectedEntityTypeId: () => {},
	triggerRefreshContactData: 0,
	triggerRefreshContactPhoneList: 0,
	triggerRefreshContactEmailList: 0,
	triggerRefreshEntitiyData: 0,
	triggerRefreshContactAssociationList: 0,
	updateContactDrawerOpener: false,
	stateList: [],
	addSelectedContactIds: () => {},
	subtractSelectedContactIds: () => {},
	toggleUpdateContactDrawerOpener: () => {},
	createContact: () => {},
	updateContact: () => {},
	updateContactsActiveStatus: () => {},
	updateTriggerRefreshContactData: () => {},
	updateTriggerRefreshContactPhoneList: () => {},
	updateTriggerRefreshContactEmailList: () => {},
	updateTriggerRefreshContactAssociationList: () => {},
	addPhoneNumberToContact: () => {},
	deletePhoneNumberFromContact: () => {},
	contactsDataByContactId: {},
	getContactDataByContactId: () => {},
	listOfContactRoles: [],
	// ENTITIY INITS
	selectedEntityId: 0,
	updateTriggerRefreshEntitiyData: () => {},
	updateEntityActiveStatus: () => {},
	toggleUpdateEntityDrawerOpener: () => {},
	updateEntityDrawerOpener: true,
	addSelectedEntityId: () => {},
	triggerRefreshEntityData: 0,
});

const EntityContext = ({ children }) => {
	// LOCAL STATE
	const [allEntitiesState, setAllEntitiesState] = useState([]);
	// OLD
	const [selectedContactIdsState, setSelectedContactIdsState] = useState(0);
	const [selectedEntityTypeIdState, setSelectedEntityTypeIdState] = useState(0);
	const [updateContactDrawerOpenerState, setUpdateContactDrawerOpenerState] = useState(false);
	const [triggerRefreshContactDataState, setTriggerRefreshContactData] = useState(0);
	const [triggerRefreshContactPhoneListState, setTriggerRefreshContactPhoneList] = useState(0);
	const [triggerRefreshContactEmailListState, setTriggerRefreshContactEmailListState] = useState(0);
	const [stateListData, setStateListData] = useState([]);
	const [contactsDataByContactIdState, setContactsDataByContactIdState] = useState({});
	const [triggerRefreshContactAssociationListState, setTriggerRefreshContactAssociationListState] = useState(0);
	const [contactRoleTypesState, setContactRoleTypeState] = useState([]);
	// ENTITY STATE
	const [triggerRefreshEntityDataState, setTriggerRefreshEntityDataState] = useState(0);
	const [selectedEntityIdState, setSelectedEntityIdState] = useState(0);
	const [updateEntityDrawerOpenerState, setUpdateEntityDrawerOpenerState] = useState(false);

	const setAllEntities = async () => {
		try {
			const response = await axios.get(`/entity/getAllEntities`);

			setAllEntitiesState([...response.data]);
		} catch (error) {
			console.error('Error fetching data:', error);
			return [];
		}
	};

	// OLD FUNCTIONS ***********************************************************

	// FUNCTIONS FOR CONTEXT
	const addSelectedContactIds = useCallback((id) => {
		setSelectedContactIdsState(id);
	}, []);

	// FUNCTIONS FOR CONTEXT
	const addSelectedEntityId = useCallback((id) => {
		setSelectedEntityIdState(id);
	}, []);

	// FUNCTION TO GET CONTACT INFO FOR CONTACT_ID
	const getContactDataByContactId = (contact_id) => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`/entity/getContactById/${contact_id}`);
				setContactsDataByContactIdState(response.data);
			} catch (error) {
				console.log('fetchContacts error: ', error);
			}
		};

		contact_id === 0 ? setContactsDataByContactIdState({}) : fetchData();
	};

	// FUNCTION TO UPDATE A CONTACT
	const updateContact = (valuesArray) => {
		const handlePostRequest = async () => {
			try {
				const result = await axios.post('/entity/updateContact', {
					user_id: valuesArray.userId,
					first_name: valuesArray.firstName,
					last_name: valuesArray.lastName,
					job_title: valuesArray.jobTitle,
					address_1: valuesArray.street,
					address_2: valuesArray.address2,
					attention_line: valuesArray.attnLine,
					city: valuesArray.city,
					state_id: valuesArray.state === '' || valuesArray.state === 0 ? null : valuesArray.state,
					country_id: 1000,
					latitude: valuesArray.lat,
					longitude: valuesArray.long,
					timezone_id: valuesArray.timeZone === '' || valuesArray.state === 0 ? null : valuesArray.timeZone,
					birthdate: valuesArray.birthDate,
					postal_code: valuesArray.zip,
					notes: valuesArray.notes,
					deleted: 0,
					entity_contact_id: valuesArray.entityContactId,
				});
			} catch (error) {
				console.error('Error making POST request', error);
			}
		};
		handlePostRequest();
	};

	// FUNCTION TO CREATE A CONTACT
	const createContact = (valuesArray) => {
		const handlePostRequest = async () => {
			try {
				const result = await axios.post('/entity/addContactRecord', {
					user_id: valuesArray.userId,
					first_name: valuesArray.firstName,
					last_name: valuesArray.lastName,
					job_title: valuesArray.jobTitle,
					address_1: valuesArray.street,
					address_2: valuesArray.address2,
					attention_line: valuesArray.attnLine,
					city: valuesArray.city,
					state_id: valuesArray.state,
					country_id: 1000,
					latitude: valuesArray.lat,
					longitude: valuesArray.long,
					timezone_id: valuesArray.timeZone,
					birthdate: valuesArray.birthDate,
					postal_code: valuesArray.zip,
					notes: valuesArray.notes,
					date_created: Dayjs(new Date()).format('YYYY-MM-DD'),
					deleted: 0,
				});
			} catch (error) {
				console.error('Error making POST request', error);
			}
		};
		handlePostRequest();
	};

	// FUNCTION TO TOGGLE DRAWER
	const toggleUpdateContactDrawerOpener = (val) => setUpdateContactDrawerOpenerState(val);
	const toggleUpdateEntityDrawerOpener = (val) => setUpdateEntityDrawerOpenerState(val);

	// FUNCTION TO UPDATE CONTACT STATUS
	const updateContactsActiveStatus = (activeStatus, id) => {
		const handlePostRequest = async () => {
			try {
				const result = await axios.post('/entity/updateContactsDeletedStatus', {
					active_status: !activeStatus,
					id: id,
				});
			} catch (error) {
				console.error('Error making POST request', error);
			}
		};
		handlePostRequest();
	};

	// FUNCTION TO UPDATE ENTITY STATUS
	const updateEntityActiveStatus = (activeStatus, id) => {
		const handlePostRequest = async () => {
			try {
				const result = await axios.post('/entity/updateEntityActiveStatus', {
					active_status: !activeStatus,
					id: id,
				});
			} catch (error) {
				console.error('Error making POST request', error);
			}
		};
		handlePostRequest();
	};

	// FUNCITON TO TRIGGER REFRESHING CONTACT DATA
	const updateTriggerRefreshContactData = () => setTriggerRefreshContactData(triggerRefreshContactDataState + 1);

	// FUNCTION TO TRIGGER REFRESH OF CONTACT ASSOCIATIONS
	const updateTriggerRefreshContactAssociationList = () => {
		setTriggerRefreshContactAssociationListState(triggerRefreshContactAssociationListState + 1);
	};
	// FUNCTION TO TRIGGER REFRESH CONTACT PHONE LIST
	const updateTriggerRefreshContactPhoneList = () =>
		setTriggerRefreshContactPhoneList(triggerRefreshContactPhoneListState + 1);

	// FUNCTION TO TRIGGER REFRESH CONTACT EMAIL LIST
	const updateTriggerRefreshContactEmailList = () =>
		setTriggerRefreshContactEmailListState(triggerRefreshContactEmailListState + 1);

	// FUNCTION TO ADD PHONE NUMBER TO CONTACT
	const addPhoneNumberToContact = async (entity_contact_id) => {
		try {
			const response = await axios.post('/enity/addPhoneNumberToContact', {
				entity_contact_id: entity_contact_id,
				phone_type_id: 1,
				phone_number: '(888) 888-8888',
				description: '',
			});

			updateTriggerRefreshContactPhoneList();
		} catch (error) {
			console.error('Error:', error);
		}
	};

	//	UPDATE SELECTED ENTITY_TYPE_ID
	const updateSelectedEntityTypeId = (enity_type_id) => {
		setSelectedEntityTypeIdState(enity_type_id);
	};

	// FUNCTION TO DELTE PHONE NUMBER FROM CONTACT
	const deletePhoneNumberFromContact = async (entity_contact_phone_number_id) => {
		try {
			const response = await axios.post('/entity/deletePhoneNumberFromContact', {
				entity_contact_phone_number_id: entity_contact_phone_number_id,
			});

			updateTriggerRefreshContactPhoneList();
		} catch (error) {
			console.error('Error:', error);
		}
	};

	// FUNCITON TO TRIGGER REFRESHING ENTITY DATA
	const updateTriggerRefreshEntityData = () => setTriggerRefreshEntityDataState(triggerRefreshEntityDataState + 1);

	// FETCH STATES DATA
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`/entity/getStates`);
				setStateListData([...response.data]);
			} catch (error) {
				console.log('fetchContacts error: ', error);
			}
		};
		fetchData();
	}, []);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		setAllEntities();
	}, []);

	// ASSIGNING VALUES TO CONTEXT STATE
	const contextValue = {
		allEntities: allEntitiesState,
		setAllEntities,
		// OLD
		selectedContactIds: selectedContactIdsState,
		selectedEntityTypeId: selectedEntityTypeIdState,
		updateSelectedEntityTypeId,
		triggerRefreshContactData: triggerRefreshContactDataState,
		triggerRefreshContactPhoneList: triggerRefreshContactPhoneListState,
		triggerRefreshContactEmailList: triggerRefreshContactEmailListState,
		triggerRefreshContactAssociationList: triggerRefreshContactAssociationListState,
		stateList: stateListData,
		updateContactDrawerOpener: updateContactDrawerOpenerState,
		addSelectedContactIds,
		toggleUpdateContactDrawerOpener,
		createContact,
		updateContact,
		updateContactsActiveStatus,
		updateTriggerRefreshContactData,
		updateTriggerRefreshContactPhoneList,
		updateTriggerRefreshContactEmailList,
		updateTriggerRefreshContactAssociationList,
		addPhoneNumberToContact,
		deletePhoneNumberFromContact,
		contactsDataByContactId: contactsDataByContactIdState,
		getContactDataByContactId,
		// ENTITIES VALUES
		updateTriggerRefreshEntityData,
		selectedEntityId: selectedEntityIdState,
		updateEntityActiveStatus,
		toggleUpdateEntityDrawerOpener,
		updateEntityDrawerOpener: updateEntityDrawerOpenerState,
		addSelectedEntityId,
		triggerRefreshEntityData: triggerRefreshEntityDataState,
		listOfContactRoles: contactRoleTypesState,
		// listOfContactRoles: [
		// 	contactTypeData(),
		// 	// { role_id: 3, label: 'Data Contact' },
		// 	// { role_id: 2, label: 'Marketing Contact' },
		// 	// { role_id: 1, label: 'Manufacturer Rep' },
		// 	// { role_id: 6, label: 'Sports South Buyer' },
		// 	// { role_id: 4, label: 'Vendor Manager' },
		// ],
	};

	useEffect(() => {
		const loadData = async () => {
			const someData = await contactTypeData();
			setContactRoleTypeState([...someData]);
		};

		loadData();
	}, []);

	return (
		<EntityContextState.Provider value={contextValue}>
			{children}
			{/* GLOBALS */}
			<ContactDrawerUpdater />
			<EntityDrawerUpdater />
			{/* <UpdateContactDrawer /> */}
		</EntityContextState.Provider>
	);
};

export default EntityContext;
