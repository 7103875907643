import { Paper, Typography, Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import Logo from '../../../Assets/ssDuckSquare.png';
import ColorKey from './ColorKey';
import { useDispatch, useSelector } from 'react-redux';
import { setLastInsertId } from '../../../Redux/AnalyticsSlice';

const AccountDetail = ({ account }) => {
  const [accountInfo, setAccountInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isExtraInfoOpen, setIsExtraInfoOpen] = useState(false);
  const lastRecordedAccount = useRef(null);

  const dispatch = useDispatch();

  const userId = useSelector((state) => state.userInformation.value.userID);

  useEffect(() => {
    setIsLoading(true);
    axios
      .post('/crm/accountInfo', {}, { params: { accountNumber: account.key } })
      .then((res) => {
        const newAccountInfo = res.data;
        setAccountInfo(newAccountInfo);
        setIsLoading(false);

        if (account.key !== lastRecordedAccount.current) {
          recordAccountViewAnalytics(newAccountInfo);
          lastRecordedAccount.current = account.key;
        }
      })
      .catch((err) => {
        console.error('Error getting account info:', err);
      });
  }, [account]);

  const recordAccountViewAnalytics = (accountData) => {
    const parseCurrency = (value) => parseFloat(value.replace(/[,$]/g, ''));

    const ytdSales = parseCurrency(accountData.year_to_date_sales);
    const lastYearSales = parseCurrency(accountData.last_year_sales);

    axios
      .post(
        '/crm/analytics/accountView',
        {},
        {
          params: {
            userId: userId,
            repName: accountData.sales_rep,
            accountNumber: accountData.account_number,
            accountName: accountData.account_name,
            accountTier: accountData.account_tier,
            ytdSales: ytdSales,
            lastYearSales: lastYearSales,
          },
        }
      )
      .then((res) => {
        console.log('Account view analytics recorded:', res.data.lastInsertId);
        dispatch(setLastInsertId(res.data.lastInsertId));
      })
      .catch((err) => {
        console.error('Error recording account view analytics:', err);
      });
  };

  const handleExtraInfo = () => {
    setIsExtraInfoOpen(!isExtraInfoOpen);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        width: '100%',
        height: '100%',
        padding: 2,
        margin: 'auto',
        boxShadow: '0px 3px 5px rgba(0,0,0,0.2)',
        backgroundColor: '#f5f5f5',
      }}
    >
      <Box
        sx={{
          backgroundColor: accountInfo.account_highlight_color,
          width: '100%',
          borderRadius: '5px',
        }}
      >
        <Typography
          variant='h5'
          component='h2'
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#333',
            fontSize: '24px',
            marginBottom: 0,
          }}
        >
          {accountInfo.account_name} - {accountInfo.account_number}
        </Typography>
      </Box>
      {account && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: isLoading ? 'center' : 'flex-start',
            alignItems: 'center',
            height: isLoading ? '100%' : 'auto',
          }}
        >
          {isLoading && <CircularProgress sx={{ alignSelf: 'center' }} />}
          <Typography
            sx={{
              textAlign: 'center',
              p: 1,
              fontWeight: 'bold',
              width: '100%',
              maxWidth: '600px',
              margin: 'auto',
            }}
          >
            {Object.keys(accountInfo)
              .filter(
                (key) =>
                  ![
                    'result',
                    'account_name',
                    'account_number',
                    'past_due_amount_color',
                    'account_highlight_color',
                    'problem_code_color',
                  ].includes(key)
              )
              .slice(0, -6) // Adjust to exclude last 6 keys
              .map((key) => {
                // Exclude certain keys
                if (
                  [
                    'result',
                    'account_name',
                    'account_number',
                    'past_due_amount_color',
                    'account_highlight_color',
                    'problem_code_color',
                    'city',
                    'state',
                    'zip_code',
                    'account_tier',
                  ].includes(key)
                ) {
                  return null;
                }

                // Determine the background color for specific keys
                let rowStyle = {};
                if (key === 'past_due_amount') {
                  rowStyle = { backgroundColor: accountInfo['past_due_amount_color'] };
                }
                if (key === 'problem_code') {
                  rowStyle = { backgroundColor: accountInfo['problem_code_color'] };
                }

                // Capitalize the first letter of each word in the key
                const formattedKey = key
                  .replace(/_/g, ' ')
                  .replace(/\b(\w)/g, (char) => char.toUpperCase());

                // Format value based on key
                let value;
                switch (key) {
                  case 'email_address':
                    value = <a href={`mailto:${accountInfo[key]}`}>{accountInfo[key]}</a>;
                    break;
                  case 'phone_number':
                    // Formatting phone number
                    const phone = accountInfo[key];
                    const formattedPhone = `(${phone.slice(0, 3)}) ${phone.slice(
                      3,
                      6
                    )}-${phone.slice(6)}`;
                    value = formattedPhone;
                    break;
                  case 'address':
                    value = `${accountInfo[key]} \n ${accountInfo['city']}, ${accountInfo['state']} ${accountInfo['zip_code']}`;
                    break;

                  default:
                    // Format dates
                    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
                    if (dateRegex.test(accountInfo[key])) {
                      value = moment.utc(accountInfo[key]).tz('America/Chicago').format('MM/DD/YY');
                    } else {
                      value = accountInfo[key];
                    }
                }

                return (
                  <div
                    key={key}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      margin: '5px 0',
                      padding: '5px',
                      ...rowStyle, // Apply the background color style
                    }}
                  >
                    <strong>{formattedKey}:</strong>
                    <span style={{ whiteSpace: 'pre' }}>{value}</span>
                  </div>
                );
              })}
          </Typography>
          <img
            src={Logo}
            alt='Logo'
            style={{
              width: '50px',
              margin: 'auto',
              cursor: 'pointer',
              filter: 'grayscale(100%) brightness(50%)',
              opacity: '0.9',
            }}
            onClick={handleExtraInfo}
          />
        </Box>
      )}
      {isExtraInfoOpen ? (
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: isLoading ? 'center' : 'flex-start',
              alignItems: 'space-evenly',
              height: isLoading ? '100%' : 'auto',
            }}
          >
            <Typography
              sx={{
                textAlign: 'center',
                p: 1,
                fontWeight: 'bold',
                width: '100%',
                maxWidth: '600px',
                margin: 'auto',
              }}
            >
              {Object.keys(accountInfo)
                .filter((key) =>
                  [
                    'account_tier',
                    'problem_code',
                    'last_invoice_date',
                    'year_to_date_sales',
                    'last_year_sales',
                    'past_due_amount',
                  ].includes(key)
                )
                .slice(-6) // Get only the last 6 keys
                .map((key) => {
                  // Exclude certain keys
                  if (
                    [
                      'result',
                      'account_name',
                      'account_number',
                      'past_due_amount_color',
                      'account_highlight_color',
                      'problem_code_color',
                    ].includes(key)
                  ) {
                    return null;
                  }

                  // Determine the background color for specific keys
                  let rowStyle = {};
                  if (key === 'past_due_amount') {
                    rowStyle = { backgroundColor: accountInfo['past_due_amount_color'] };
                  }
                  if (key === 'problem_code') {
                    rowStyle = { backgroundColor: accountInfo['problem_code_color'] };
                  }
                  if (key === 'account_tier') {
                    rowStyle = { backgroundColor: accountInfo['account_highlight_color'] };
                  }

                  // Capitalize the first letter of each word in the key
                  const formattedKey = key
                    .replace(/_/g, ' ')
                    .replace(/\b(\w)/g, (char) => char.toUpperCase());

                  // Format value based on key
                  let value;
                  switch (key) {
                    case 'email_address':
                      value = <a href={`mailto:${accountInfo[key]}`}>{accountInfo[key]}</a>;
                      break;
                    case 'phone_number':
                      // Formatting phone number
                      const phone = accountInfo[key];
                      const formattedPhone = `(${phone.slice(0, 3)}) ${phone.slice(
                        3,
                        6
                      )}-${phone.slice(6)}`;
                      value = formattedPhone;
                      break;
                    default:
                      // Format dates
                      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
                      if (dateRegex.test(accountInfo[key])) {
                        value = moment
                          .utc(accountInfo[key])
                          .tz('America/Chicago')
                          .format('MM/DD/YY');
                      } else {
                        value = accountInfo[key];
                      }
                  }

                  return (
                    <div
                      key={key}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '5px 0',
                        padding: '5px',
                        ...rowStyle, // Apply the background color style
                      }}
                    >
                      <strong>{formattedKey}:</strong>
                      <span>{value}</span>
                    </div>
                  );
                })}
            </Typography>
            <ColorKey />
          </Box>
        </Box>
      ) : null}
    </Paper>
  );
};

export default AccountDetail;
