import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, useTheme, Button, SwipeableDrawer } from '@mui/material';
import { getAllPublications } from '../../../../Publications/Feed/PrimaryFeed';
import { getSubBrandsByMfrId, insertOrUpdateContracts } from '../Feeds/ContractsFeed';
import { PublicationContextState } from '../../../../../Context/Marketing/PublicationContext';
import PublicationsByType from './PublicationsByType';
import PublicationTypesSelect from './PublicationTypesSelect';
import PublicationCard from '../../../../_GlobalComponents/MarketingStyledComponents/Cards/PublicationCard';
import PublicationProductsByType from './PublicationProductsByType';
import UsersManufacturersSelect from './UsersManufacturersSelect';
import BrandChipsSelect from './BrandChipsSelect';

// PRIMARY COMPONENT
const ContractForm = ({ userid, contractId = 0, initialData }) => {
	console.log('initialDataabc: ', initialData);
	// INIT
	const theme = useTheme();

	// CONTEXT DATA
	const { updateTriggerRefreshContractData } = useContext(PublicationContextState);

	// LOCAL STATE
	const [loaded, setLoaded] = useState(false);
	const [userId, setUserId] = useState(0);
	const [publicationDrawer, setPublicationDrawer] = useState(false);
	const [publicationData, setPublicationData] = useState([]);
	const [ssPublicaitionTypeId, setSSPublicationTypeId] = useState(initialData[0]?.ss_publication_type_id || 0);
	const [ssPublicationId, setSSPublicationId] = useState(initialData[0]?.ss_publication_id || 0);
	const [ssPublicationProductId, setSSPublicationProductId] = useState(
		initialData[0]?.ss_publication_product_id || 0
	);
	const [entityId, setEntityId] = useState(initialData[0]?.entity_id || 0);
	const [productManufacturers, setProductManufacturers] = useState([initialData[0]?.product_manufacturers]);
	const [ssPublicaitonContractStatusId, setSSPublicationContractStatusId] = useState(
		initialData[0]?.ss_publication_contract_status_id || 0
	);
	const [soldPrice, setSoldPrice] = useState(initialData[0]?.sold_price || '');

	// USE EFFECTS
	useEffect(() => {
		console.log('entity id chaged 1');
		const fetchData = async () => {
			const data = await getAllPublications();
			setPublicationData(data);
			setUserId(userid);
			setLoaded(true);
		};
		fetchData();
		setLoaded(false);
	}, [userid]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				//const data = await getSubBrandsByMfrId(entityId);
				setProductManufacturers(initialData[0]?.product_manufacturers.split(','));
				console.log('entity id chaged 2');
			} catch (error) {
				console.log('error: ', error);
			}
		};
		entityId > 0 && fetchData();
	}, [entityId, initialData]);

	// FUNCTIONS
	const ssPublicationIdFunc = (e) => setSSPublicationId(e);

	const handleSubmitting = (event, statusId) => {
		setSSPublicationContractStatusId(statusId);
		onSubmit(contractId);
	};

	const onSubmit = (id) => {
		const obj = {
			ss_publication_contract_id: contractId,
			user_id: userId,
			ss_publication_type_id: ssPublicaitionTypeId,
			ss_publication_id: ssPublicationId,
			ss_publication_product_id: ssPublicationProductId,
			entity_id: entityId,
			product_manufacturers: productManufacturers,
			ss_publication_contract_status_id: ssPublicaitonContractStatusId,
			sold_price: soldPrice,
		};

		insertOrUpdateContracts(obj, contractId);
		updateTriggerRefreshContractData();
	};

	console.log('fetched data: ', publicationData);
	console.log('initialData[0]: ', initialData[0]);
	console.log('product manufacturers: ', productManufacturers);
	return (
		<>
			{loaded && (
				<Box sx={{ px: '20px', pb: '24px' }}>
					<Typography variant='h5' sx={{ color: theme.palette.primary.main, mb: 2 }}>
						Choose Publicationn {ssPublicationId || 0}:
					</Typography>
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: '1fr',
							justifyContent: 'center',
							gap: 2,
							mb: 3,
						}}
					>
						<PublicationTypesSelect
							selectedPublicationTypeId={initialData[0]?.ss_publication_type_id || 0}
							handleChange={(e) => setSSPublicationTypeId(e.target.value)}
						/>
						<Box sx={{ textAlign: 'center' }}>
							<Button
								variant='contained'
								disabled={ssPublicaitionTypeId === 0}
								onClick={() => setPublicationDrawer(true)}
							>
								Choose Publication
							</Button>
						</Box>
					</Box>
					{ssPublicationId > 0 && (
						<>
							<PublicationCard
								item={publicationData.filter((e) => e.ss_publication_id === ssPublicationId)}
							/>
							<PublicationProductsByType
								ss_publication_type_id={ssPublicaitionTypeId}
								selectedPublicationProductId={`${ssPublicationProductId}~${soldPrice}`}
								handleChange={(e, cost) => {
									setSSPublicationProductId(e);
									setSoldPrice(cost);
								}}
							/>
						</>
					)}

					{ssPublicationProductId > 0 && (
						<Box>
							<UsersManufacturersSelect
								handleChange={(e) => {
									setEntityId(e.target.value);
									console.log('change mfr id: ' + e.target.value);
									//setEntityId(e.target.value);
								}}
								userid={userid}
								entityId={entityId}
							/>
						</Box>
					)}

					{entityId > 0 && (
						<Box>
							<BrandChipsSelect
								handleChange={(e) => setProductManufacturers(e)}
								mfrid={entityId}
								brandIds={productManufacturers}
								contractId={contractId}
							/>
						</Box>
					)}

					{productManufacturers && (
						<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
							<Button variant='contained' color='warning' onClick={(e) => handleSubmitting(e, 4)}>
								Save For Later
							</Button>
							<Button variant='contained' color='success' onClick={(e) => handleSubmitting(e, 1)}>
								Submit
							</Button>
						</Box>
					)}
					{/* DRAWER */}
					<SwipeableDrawer
						anchor={'bottom'}
						open={publicationDrawer}
						onClose={() => setPublicationDrawer(false)}
						sx={{ overflow: 'hidden' }}
					>
						<Box sx={{ flex: '1', overflowY: 'auto', p: 2 }}>
							<PublicationsByType
								ss_publication_type_id={ssPublicaitionTypeId}
								setValue={ssPublicationIdFunc}
							/>
							{/* <PublicationsByType
						ss_publication_type_id={ssPublicaitionTypeId}
						handleChange={handleChange}
						handlePublicationClick={handlePublicationClick}
					/> */}
						</Box>
					</SwipeableDrawer>
				</Box>
			)}
		</>
	);
};

export default ContractForm;
