import { createSlice } from '@reduxjs/toolkit';

export const RepSlice = createSlice({
  name: 'repInfo',
  initialState: {
    value: [],
  },
  reducers: {
    setRepInfo: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setRepInfo } = RepSlice.actions;
export default RepSlice.reducer;
