import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Grid,
  IconButton,
  lighten,
  Snackbar,
  Typography,
  useTheme,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  MaterialReactTable,
  MRT_ToggleDensePaddingButton as MrtToggleDensePaddingButton,
  MRT_ToggleFiltersButton as MrtToggleFiltersButton,
  MRT_ToggleFullScreenButton as MrtToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton as MrtToggleGlobalFilterButton,
  useMaterialReactTable,
} from 'material-react-table';
import { ContentCopy, Email, Phone, Refresh } from '@mui/icons-material';
import ExcelExport from '../_GlobalComponents/ExcelExport';
import dayjs from 'dayjs';
import CreditModal from './CreditModal';
import { setIsLoading } from '../../Redux/LoadingSlice';
import InnerTable from './InnerTable';

const Attendees = () => {
  const userId = useSelector((state) => state.userInformation.value.userID);
  const [attendees, setAttendees] = useState([]);
  const [selectedAttendee, setSelectedAttendee] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [hotels, setHotels] = useState([]);
  const [badges, setBadges] = useState(null);
  const [hoveredPhone, setHoveredPhone] = useState(null);
  const [hoveredEmail, setHoveredEmail] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();

  const getAttendees = useCallback(() => {
    dispatch(setIsLoading(true));
    axios
      .post('/attendees/all', {
        userId: userId,
      })
      .then((response) => {
        // Fix null values for filtering
        const preFixAttendees = response.data;
        const postFixAttendees = preFixAttendees.map((attendee) => {
          const updatedAttendee = Object.fromEntries(
            Object.entries(attendee).map(([key, value]) => [key, value === null ? '' : value])
          );
          return updatedAttendee;
        });

        setAttendees(postFixAttendees);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  }, [userId, dispatch]);

  const getHotels = useCallback(() => {
    axios
      .get('/attendees/hotels')
      .then((response) => {
        setHotels(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const getBadges = useCallback((visibleAttendees = []) => {
    if (visibleAttendees.length === 0) {
      return;
    }
    Promise.allSettled(
      visibleAttendees.map((attendeeId) =>
        axios.get('/attendees/badges', {
          params: {
            attendeeId: attendeeId,
          },
        })
      )
    )
      .then((responses) => {
        const newBadges = {};
        responses.forEach((response, index) => {
          if (response.status === 'fulfilled') {
            // Fix null values for filtering
            const preFixBadges = response.value.data;
            const postFixBadges = preFixBadges.map((badge) => {
              const updatedBadge = Object.fromEntries(
                Object.entries(badge).map(([key, value]) => [key, value === null ? '' : value])
              );
              return updatedBadge;
            });

            newBadges[visibleAttendees[index]] = postFixBadges;
          } else {
            newBadges[visibleAttendees[index]] = [];
          }
        });
        setBadges(newBadges);
      })
      .catch((err) => {
        console.error(err);
        setBadges({});
      });
  }, []);

  const submitCredit = (creditDetails) => {
    return new Promise((resolve, reject) => {
      console.log(selectedAttendee);
      console.log(creditDetails);
      resolve({ selectedAttendee: selectedAttendee, creditDetails: creditDetails });
      // axios
      //   .post('/attendees/credit', {
      //     attendeeId: creditDetails.attendee_id,
      //   })
      //   .then((response) => {
      //     console.log('Region updated successfully:', response.data);
      //     const updatedRegions = regions.map((r) => {
      //       if (r.location_zone_id === editedRegion.location_zone_id) {
      //         return { ...r, description: editedRegion.description };
      //       } else {
      //         return r;
      //       }
      //     });
      //     setRegions(updatedRegions);
      //     resolve(response.data);
      //   })
      //   .catch((error) => {
      //     console.error('Error updating region:', error);
      //     reject(error);
      //   });
    });
  };

  useEffect(() => {
    getAttendees();
  }, [getAttendees]);

  useEffect(() => {
    getHotels();
  }, [getHotels]);

  useEffect(() => {
    if (selectedAttendee) {
      setIsModalOpen(true);
    }
  }, [selectedAttendee]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedAttendee(null);
  };

  const handleSetPagination = (newValue) => {
    setBadges(null);
    setPagination(newValue);
  };

  const handleRefresh = () => {
    getAttendees();
    getHotels();
    const visibleAttendees = table.getRowModel().flatRows.map((row) => {
      return row.original.id;
    });
    getBadges(visibleAttendees);
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const columns = [
    {
      header: 'SS Account #',
      accessorKey: 'account_number',
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Dealer Name',
      accessorKey: 'company_name',
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Invite Code',
      accessorKey: 'invitation_code',
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Amount Credited',
      accessorKey: 'amount_credited',
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Credit Memo #',
      accessorKey: 'credit_memo',
      Cell: ({ renderedCellValue }) => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant='body1'>{renderedCellValue}</Typography>
          </Box>
        );
      },
    },
    {
      header: 'Hotel',
      accessorKey: 'hotel_name',
      Cell: ({ renderedCellValue }) => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant='body1'>{renderedCellValue}</Typography>
          </Box>
        );
      },
    },
    {
      header: 'Hotel Total',
      accessorKey: 'hotel_cost',
      Cell: ({ renderedCellValue }) => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant='body1'>{renderedCellValue}</Typography>
          </Box>
        );
      },
    },
    {
      header: 'Sales Rep',
      accessorKey: 'sales_rep',
      Cell: ({ renderedCellValue }) => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant='body1'>{renderedCellValue}</Typography>
          </Box>
        );
      },
    },
  ];

  const table = useMaterialReactTable({
    columns: columns,
    data: attendees,
    enableHiding: false,
    enableRowActions: attendees.some((attendee) => attendee.allow_credit !== 'false'),
    enableStickyHeader: true,
    initialState: {
      showColumnFilters: false,
      density: 'compact',
      pagination: { pageIndex: 0, pageSize: 20 },
    },
    positionToolbarAlertBanner: 'bottom',
    renderToolbarInternalActions: ({ table }) => {
      return (
        <Box sx={{ whiteSpace: 'pre' }}>
          <MrtToggleGlobalFilterButton table={table} />
          <MrtToggleFiltersButton table={table} />
          <MrtToggleDensePaddingButton table={table} />
          <MrtToggleFullScreenButton table={table} />
          <IconButton tabIndex={0} type='button' aria-label='Refresh' onClick={handleRefresh}>
            <Refresh />
          </IconButton>
        </Box>
      );
    },
    renderDetailPanel: ({ row }) => (
      <Box
        sx={{
          '& > .MuiPaper-root': {
            boxShadow: 'none',
            borderStyle: 'solid',
            borderColor: lighten(theme.palette.primary.main, 0.5),
            pt: 1,
            '& > .MuiBox-root:first-of-type': {
              '& > .MuiBox-root': {
                '& > .MuiBox-root': {
                  '& > .MuiBox-root': {
                    '& > .MuiTypography-root': {
                      color: lighten(theme.palette.primary.main, 0.25),
                    },
                  },
                },
              },
            },
          },
        }}
      >
        <Grid container>
          {row.original.phone_number && (
            <Grid
              item
              xs={12}
              sm={6}
              px={2}
              pb={1}
              onMouseEnter={() => setHoveredPhone(row.original.phone_number)}
              onMouseLeave={() => setHoveredPhone(null)}
            >
              <Typography variant='body2' color='textSecondary'>
                Phone:
              </Typography>
              <Typography
                variant='body1'
                sx={{ display: 'inline-flex', alignItems: 'center', position: 'relative' }}
              >
                <Phone sx={{ color: '#2471a3', mr: 1 }} />
                <a
                  href={`tel:${row.original.phone_number}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  onMouseEnter={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                  onMouseLeave={(e) => (e.currentTarget.style.textDecoration = 'none')}
                >
                  {row.original.phone_number}
                </a>
                {hoveredPhone === row.original.phone_number && (
                  <IconButton
                    sx={{
                      ml: 1,
                      cursor: 'pointer',
                      position: 'absolute',
                      right: -40,
                      top: -8,
                    }}
                    onClick={(e) => {
                      handleCopy(row.original.phone_number);
                    }}
                  >
                    <ContentCopy sx={{ fontSize: 16, color: '#2471a3' }} />
                  </IconButton>
                )}
              </Typography>
            </Grid>
          )}
          {row.original.email_address && (
            <Grid
              item
              xs={12}
              sm={6}
              px={2}
              pb={1}
              onMouseEnter={() => setHoveredEmail(row.original.email_address)}
              onMouseLeave={() => setHoveredEmail(null)}
            >
              <Typography variant='body2' color='textSecondary'>
                Email:
              </Typography>
              <Typography
                variant='body1'
                sx={{ display: 'inline-flex', alignItems: 'center', position: 'relative' }}
              >
                <Email sx={{ color: '#2471a3', mr: 1 }} />
                <a
                  href={`mailto:${row.original.email_address}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  onMouseEnter={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                  onMouseLeave={(e) => (e.currentTarget.style.textDecoration = 'none')}
                >
                  {row.original.email_address}
                </a>
                {hoveredEmail === row.original.email_address && (
                  <IconButton
                    sx={{
                      ml: 1,
                      cursor: 'pointer',
                      position: 'absolute',
                      right: -40,
                      top: -8,
                    }}
                    onClick={(e) => {
                      handleCopy(row.original.email_address);
                    }}
                  >
                    <ContentCopy sx={{ fontSize: 16, color: '#2471a3' }} />
                  </IconButton>
                )}
              </Typography>
            </Grid>
          )}
        </Grid>
        <InnerTable data={badges?.[row.original.id] ?? []} />
      </Box>
    ),
    muiTopToolbarProps: () => {
      return {
        sx: {
          // mb: 0.5,
          '& .MuiBox-root': {
            alignItems: 'center',
            // '&::after': {
            //   content: '""',
            //   position: 'absolute',
            //   bottom: 0,
            //   left: '0%',
            //   width: '100%',
            //   height: '1px',
            //   backgroundColor: 'rgba(224, 224, 224, 1)',
            // },
          },
        },
      };
    },
    renderTopToolbarCustomActions: () => {
      const data = attendees.map((attendee) => {
        return {
          'SS Account #': attendee.account_number,
          'Dealer Name': attendee.company_name,
          'Dealer Email': attendee.email_address,
          'Dealer Phone #': attendee.phone_number,
          'Invite Code': attendee.invitation_code,
          'Amount Credited': attendee.amount_credited,
          'Credit Memo #': attendee.credit_memo,
          Hotel: attendee.hotel_name,
          'Hotel Total': attendee.hotel_cost,
          'Sales Rep': attendee.sales_rep,
        };
      });

      return (
        <>
          <ExcelExport
            data={data}
            fileName={`attendees-${dayjs().format('YYYY-MM-DD_HH-mm-ss')}`}
          />
        </>
      );
    },
    renderRowActions: ({ row }) => {
      const handleApplyCredit = () => {
        setSelectedAttendee(row.original);
      };
      return (
        <Box>
          {row.original.allow_credit !== 'false' && (
            <Button
              variant='contained'
              onClick={handleApplyCredit}
              disabled={
                row.original.allow_credit === 'disabled' || row.original.credit_applied === 'true'
              }
            >
              Apply Credit
            </Button>
          )}
        </Box>
      );
    },
    muiTableBodyRowProps: ({ isDetailPanel }) => {
      if (isDetailPanel) {
        return {
          sx: {
            '& > .MuiTableCell-root': {
              transition: 'all 150ms ease-in-out, background-color 0s',
            },
            '&:hover': {
              '& > .MuiTableCell-root': {
                '&::after': {
                  backgroundColor: 'transparent',
                },
                backgroundColor: 'rgba(175, 175, 175, 0.3)',
              },
            },
          },
        };
      }
    },
    onPaginationChange: handleSetPagination,
    state: { pagination },
  });

  useEffect(() => {
    const visibleAttendees = table.getRowModel().flatRows.map((row) => {
      return row.original.id;
    });
    getBadges(visibleAttendees);
  }, [table, pagination, getBadges]);

  return (
    <>
      <Box
        sx={{
          p: 2,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          '& > .MuiPaper-root': {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            '& > .MuiTableContainer-root': {
              flexGrow: 1,
            },
          },
        }}
      >
        <MaterialReactTable table={table} />
      </Box>
      <CreditModal
        open={isModalOpen}
        onClose={handleCloseModal}
        hotels={hotels}
        onSave={submitCredit}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message='Copied to clipboard'
      />
    </>
  );
};

export default Attendees;
