import React, { useContext, useEffect, useState } from 'react';
import { ContractContextState } from '../../../../../Context/Marketing/ContractContext';
import { EntityContactContextState } from '../../../../../Context/Marketing/EntityContactContext';
import { RequestQuote } from '@mui/icons-material';
import { IconButton, Modal, Tooltip, useTheme } from '@mui/material';
import ContractCards from '../../../../Contracts/components/ContractCards';

const ButtonToModalContracts = ({ contactId }) => {
	// INIT's
	const theme = useTheme();

	// CONTEXT STATE
	const { allContracts } = useContext(ContractContextState);
	const { allAssociationLink } = useContext(EntityContactContextState);

	// LOCAL STATE
	const [loading, setLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const [assoEntities, setAssoEntities] = useState([]);
	const [show, setShow] = useState(false);

	// USE EFFECTS
	useEffect(() => {
		const fetch = async () => {
			let entityIds = [];
			allAssociationLink
				.filter((e) => e.entity_contact_id === contactId)
				.map((link) => entityIds.push(link.entity_id));

			const uniqueContracts = allContracts.filter((e) => entityIds.includes(e.entity_id) && e.deleted !== 1);
			uniqueContracts.length > 0 ? setShow(true) : setShow(false);

			setAssoEntities(entityIds);
		};
		fetch();
		setLoading(false);
	}, [allContracts, contactId, allAssociationLink]);

	// FUNCTIONS
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<>
			<Tooltip title={`Contact ${contactId}'s Contracts`}>
				<div>
					<IconButton disabled={!show} onClick={() => handleOpen()}>
						<RequestQuote color={show ? 'success' : 'disabled'} />
					</IconButton>
				</div>
			</Tooltip>
			<Modal
				open={open}
				onClose={() => handleClose()}
				aria-labelledby='Contracts'
				aria-describedby="Contact's Contracts"
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: `${theme.palette.primary.light}33`,
				}}
			>
				<div>
					<ContractCards search='entities' ids={assoEntities} />
				</div>
			</Modal>
		</>
	);
};

export default ButtonToModalContracts;
