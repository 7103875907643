import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';

const FileUploadButton = ({ onFileSelect }) => {
  const [fileName, setFileName] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check file size (e.g., 50MB max)
      if (file.size > 50 * 1024 * 1024) {
        // 50MB in bytes
        alert('File size exceeds 50MB limit.');
        return;
      }
      setFileName(file.name);
      onFileSelect(file);
    }
  };

  return (
    <Box>
      {fileName && <p>{fileName}</p>}
      <Button variant='contained' component='label'>
        Select File
        <input
          type='file'
          hidden
          onChange={handleFileChange}
          accept={isMobile ? 'image/*,video/*' : '*'}
          capture={isMobile ? 'camera' : undefined}
        />
      </Button>
    </Box>
  );
};

export default FileUploadButton;
