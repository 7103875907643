import React, { useContext, useEffect, useState } from 'react';
import { PublicationContextState } from '../../../Context/Marketing/PublicationContext';
import { getAllPublications } from '../Feed/PrimaryFeed';
import useColumns from './PublicationsTableColumns';
import {
	MaterialReactTable,
	useMaterialReactTable,
	MRT_ToggleFullScreenButton as MrtToggleFullScreenButton,
	MRT_ToggleDensePaddingButton as MrtToggleDensePaddingButton,
	MRT_ShowHideColumnsButton as MrtShowHideColumnsButton,
	MRT_GlobalFilterTextField as MrtGlobalFilterTextField,
	MRT_ToggleFiltersButton as MrtToggleFiltersButton,
} from 'material-react-table';

import { Box, useTheme } from '@mui/material';
import CreatePublicationButton from './CreatePublicationButton';

const PublicationsTable = () => {
	const { triggerRefreshPublicationData } = useContext(PublicationContextState);
	const theme = useTheme();
	const [allPubs, setAllPubs] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const [allPublications] = await Promise.all([getAllPublications()]);
				setAllPubs(allPublications);
			} catch (error) {
				console.error('Error fetching publications:', error);
				setError('Error fetching publications');
				setLoading(false);
			} finally {
				setLoading(false);
			}
		};
		fetchData();
	}, [triggerRefreshPublicationData]);

	// COLUMNS FOR TABEL
	const columns = useColumns();
	// TABLE OPTIONS
	const table = useMaterialReactTable({
		columns,
		data: allPubs,
		enableStickyHeader: true,
		enableStickyFooter: true,
		enablePagination: true,
		initialState: {
			density: 'compact',
			pagination: { pageIndex: 0, pageSize: 100 },
			showGlobalFilter: true,
			columnOrder: ['entity_id', 'contact_name', 'address', 'emails', 'phone_numbers', 'active', 'associated'],
		},
		enableColumnResizing: true,
		enableRowSelection: false,
		// state: { rowSelection: selectedRowIds },
		// onRowSelectionChange: setSelectedRowIds,
		layoutMode: 'grid',
		muiTablePaperProps: { sx: { backgroundColor: 'white', padding: '18px', borderRadius: '18px' } },
		muiTableContainerProps: { sx: { maxHeight: '700px' } },
		muiTableBodyRowProps: { sx: { fontWeight: 'bold', backgroundColor: 'white' } },
		muiTableHeadRowProps: {
			sx: {
				backgroundColor: 'white',
				pt: 2,
				fontWeight: 'bold',
				height: 'auto',
				borderTopLeftRadius: '12px',
				borderTopRightRadius: '12px',
				mb: 0,
			},
		},
		muiTableHeadCellProps: {
			sx: {
				color: theme.palette.primary.main,
				fontWeight: 'bold',
				borderBottomColor: theme.palette.secondary.main,
				pb: 2,
			},
		},
		muiSearchTextFieldProps: {
			placeholder: 'Search Publications',
			sx: { minWidth: '500px' },
			variant: 'outlined',
		},
		renderTopToolbar: () => (
			<Box sx={{ alignItems: 'center', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '15px' }}>
				<Box sx={{ gridColumn: 'span 2' }}>
					<MrtGlobalFilterTextField table={table} />
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<CreatePublicationButton />
					<MrtToggleDensePaddingButton table={table} />
					<MrtToggleFiltersButton table={table} />
					<MrtShowHideColumnsButton table={table} />
					<MrtToggleFullScreenButton table={table} />
					{/* <ContactMaterialTableDropDownMenu /> */}
				</Box>
			</Box>
		),
	});

	if (loading) {
		return <div>Loading...</div>;
	}

	if (error) {
		return <div>{error}</div>;
	}

	return (
		<Box className='ManagePublications' sx={{ px: 6, pt: 6, boxSizing: 'border-box', height: '100%' }}>
			<MaterialReactTable table={table} />
		</Box>
	);
};

export default PublicationsTable;
