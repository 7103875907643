import React, { useEffect, useState } from 'react';
import axios from 'axios';

export const EditProductDataContextState = React.createContext({
	products: [],
	usersManufacturers: [],
	// userCategories: [],
	// userSeries: [],
	// userModels: [],
	tabs: [],
	activeTab: 0,
	selectedMfrId: 0,
	selectedCatId: 0,
	selectedSeriesId: 0,
	selectedModelId: 0,
	addTab: () => {},
	removeTab: () => {},
	updateSelectedMfrId: () => {},
	updateSelectedCatId: () => {},
	updateSelectedSeriesId: () => {},
	updateSelectedModelId: () => {},
});

const EditProductDataContext = ({ children }) => {
	const [activeTabData, setActiveTabData] = useState(0);
	const [tabsData, setTabsData] = useState([]);
	const [selectedProducts, setSelectedProducts] = useState([
		{
			id: 12,
			name: {
				firstName: 'Johnny Boy',
				lastName: 'Doeball',
			},
			address: '261 Erdman Ford',
			city: 'East Daphne',
			state: 'Kentucky',
		},
		{
			id: 1,
			name: {
				firstName: 'Jane',
				lastName: 'Doe',
			},
			address: '769 Dominic Grove',
			city: 'Columbus',
			state: 'Ohio',
		},
	]);

	const [searchValues, setSearchValues] = useState({
		selectedMfrId: 0,
		selectedCatId: 0,
		selectedSeriesId: 0,
		selectedModelId: 0,
	});

	const [usersManufacturersData, setUsersManufacturersData] = useState([
		{
			label: 'Choose Manufacturer',
			id: '0',
		},
		{
			label: 'Sturm Ruger',
			id: '369',
		},
		{
			label: 'Taurus',
			id: '85',
		},
		{
			label: 'Glock',
			id: '137',
		},
		{
			label: 'Smith & Wesson',
			id: '31',
		},
	]);

	// CONTEXT FUNCTIONS
	const addTab = (id, label, type, productId) => {
		setTabsData([...tabsData, { id: id, label: label, type: type, productId: productId }]);
		setActiveTabData(id);
		console.log('tabs: ', tabsData);
	};

	const removeTab = (id) => {
		const arry = tabsData.filter((e) => e.id !== id);
		setTabsData([...arry]);
	};
	const updateActiveTab = (id) => {
		setActiveTabData(id);
	};
	const updateSelectedMfrId = (id) => {
		setSearchValues({
			...searchValues,
			selectedMfrId: id,
			selectedCatId: 0,
			selectedSeriesId: 0,
			selectedModelId: 0,
		});
	};

	const updateSelectedCatId = (id) => {
		setSearchValues({ ...searchValues, selectedCatId: id, selectedSeriesId: 0, selectedModelId: 0 });
	};

	const updateSelectedSeriesId = (id) => {
		setSearchValues({ ...searchValues, selectedSeriesId: id, selectedModelId: 0 });
	};

	const updateSelectedModelId = (id) => {
		setSearchValues({ ...searchValues, selectedModelId: id });
	};

	// GET SERIES
	// const fetchSeriesListFromMfrIdCatid = async (mfrid, catid) => {
	// 	console.log('fetchSeriesListFromMfrIdCatid: ' + mfrid + ' | ' + catid);
	// 	try {
	// 		const response = await axios.get(`/pdm/getUniqueSeriesByMfrIdCatId/${mfrid}/${catid}`);
	// 		setUsersSeriesData(response.data); // Assuming the response has a data object
	// 		console.log('setUsersSeriesData: ', response.data);
	// 	} catch (error) {
	// 		console.log('fetchSeriesListFromMfrIdCatid error: ', error);
	// 	}
	// };

	// useEffect(() => {
	// 	console.log('this ran');
	// 	fetchSeriesListFromMfrIdCatid(searchValues.selectedMfrId, searchValues.selectedCatId);
	// }, [searchValues.selectedMfrId, searchValues.selectedCatId]);

	const contextValue = {
		products: [...selectedProducts],
		tabs: tabsData,
		activeTab: activeTabData,
		usersManufacturers: [...usersManufacturersData],
		selectedMfrId: searchValues.selectedMfrId,
		selectedCatId: searchValues.selectedCatId,
		selectedSeriesId: searchValues.selectedSeriesId,
		selectedModelId: searchValues.selectedModelId,
		addTab,
		removeTab,
		updateActiveTab,
		updateSelectedMfrId,
		updateSelectedCatId,
		updateSelectedSeriesId,
		updateSelectedModelId,
	};

	return <EditProductDataContextState.Provider value={contextValue}>{children}</EditProductDataContextState.Provider>;
};

export default EditProductDataContext;
