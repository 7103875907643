import React, { useContext } from 'react';
import { EntityContextState } from '../../../../../Context/Entity/EntityContext';
import { Box, Drawer } from '@mui/material';
import EntityInfoForm from './EntityInfoForm';
import ContactAssociation from '../../ManageContacts/components/ContactAssociation/ContactAssociation';

const EntityDrawerUpdater = ({ entity_info }) => {
	// CONTEXT STORAGE AND FUNCTIONS
	const { selectedEntityId, toggleUpdateEntityDrawerOpener, updateEntityDrawerOpener, selectedEntityTypeId } =
		useContext(EntityContextState);

	return (
		<Drawer open={updateEntityDrawerOpener} anchor='right' onClose={() => toggleUpdateEntityDrawerOpener(false)}>
			<Box
				sx={{
					maxWidth: '1600px',
					minWidth: '1600px',
					height: '100%',
					backgroundColor: '#fcfcfc',
					overflowX: 'hidden',
					overflowY: 'auto',
					padding: '32px',
				}}
			>
				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: 'repeat(12, 1fr)',
						gridTemplateRows: 'auto auto',
						gap: '24px',
					}}
				>
					{/* CONTACT FORM AREA */}
					<Box sx={{ gridColumn: 'span 12', minHeight: '400px' }}>
						<EntityInfoForm />
					</Box>
					{/* CONTACT ASSOCIATION AREA */}

					{selectedEntityTypeId === 2
						? ''
						: selectedEntityId !== 0 && (
								<Box sx={{ gridColumn: 'span 12', minHeight: '400px' }}>
									<ContactAssociation side='entity' objId={selectedEntityId} />
								</Box>
						  )}
				</Box>
			</Box>
		</Drawer>
	);
};

export default EntityDrawerUpdater;
