import React from 'react';

const EmailBuilder = () => {
  return (
    <div>
      <h1>Email Builder</h1>
    </div>
  );
};

export default EmailBuilder;
