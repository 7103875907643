import React from 'react';
import { Paper, Typography, Box, Divider } from '@mui/material';

const NoteHistoryItem = ({ note }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        paddingTop: '16px',
        paddingLeft: '16px',
        paddingRight: '16px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
        backgroundColor: '#f5f5f5',
        transition: 'transform 0.2s',
        '&:hover': {
          transform: 'scale(1.02)',
        },
      }}
    >
      <Typography variant='h6' align='center' sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
        {note.description}
      </Typography>
      <Typography variant='body1' sx={{ padding: '8px', marginBottom: '8px' }}>
        {note.notes}
      </Typography>
      <Box sx={{ alignSelf: 'flex-end', textAlign: 'right' }}>
        <Divider />
        <Typography variant='caption' sx={{ pr: '10px' }}>
          Created by: {note.created_by} on{' '}
          {new Date(note.time_created).toLocaleString('en-US', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
          })}
        </Typography>
      </Box>
    </Paper>
  );
};

const NoteHistory = ({ noteHistory }) => {
  return (
    <div>
      {noteHistory.map((historyItem, index) => (
        <NoteHistoryItem key={index} note={historyItem} />
      ))}
    </div>
  );
};

export default NoteHistory;
