import { Save } from '@mui/icons-material';
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { EntityContextState } from './../../../../../../Context/Entity/EntityContext';
import axios from 'axios';
import DeletePhoneNumberFromContact from './DeletePhoneNumberFromContact';
import SnackBarAlert from '../../../../../_GlobalComponents/SnackBarAlert';
import InputMask from 'react-input-mask';

const typesList = [
	{
		type_list_id: 6,
		type_description: 'Alternate',
	},
	{
		type_list_id: 2,
		type_description: 'Fax',
	},
	{
		type_list_id: 5,
		type_description: 'Home',
	},
	{
		type_list_id: 4,
		type_description: 'Mobile',
	},
	{
		type_list_id: 1,
		type_description: 'Office',
	},
	{
		type_list_id: 3,
		type_description: 'Store',
	},
];
const PhoneEntryForm = ({ contactId, item, savePhoneNumber }) => {
	const [typeState, setTypeState] = useState(0);
	const [numberState, setNumberState] = useState();
	const [descState, setDescState] = useState();
	const [saveButtonState, setSaveButtonState] = useState(false);
	const [hideRow, setHideRow] = useState([]);
	const [snackbarOpen, setSnackbarOpen] = useState(false);

	const { updateTriggerRefreshContactData, updateTriggerRefreshContactPhoneList } = useContext(EntityContextState);

	// Functions
	const removeDeletedPhoneNumber = (entity_contact_phone_number_id) => {
		setHideRow((prev) => [...prev, entity_contact_phone_number_id]);
		updateTriggerRefreshContactPhoneList();
	};

	const handleChange = (typeOf, value) => {
		switch (typeOf) {
			case 'type':
				setTypeState(value);
				break;

			case 'number':
				setNumberState(value);
				break;

			case 'desc':
				setDescState(value);
				break;

			default:
				console.log('No Type Of Passed');
		}
		setSaveButtonState(true);
	};

	const handleSaveButtonClick = () => {
		setSaveButtonState(false);

		try {
			const response = axios.post('/entity/updatePhoneNumberForContact', {
				entity_contact_phone_number_id: item.phone_number_id,
				phone_type_id: typeState,
				phone_number: numberState,
				description: descState,
			});
			updateTriggerRefreshContactData();
			updateTriggerRefreshContactPhoneList();
			setSnackbarOpen(true);
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const ResetOpen = () => {
		setSnackbarOpen(false);
	};

	// Use Effects
	useEffect(() => {
		setTypeState(item.phone_type_id);
		setNumberState(item.phone_number);
		setDescState(item.description);
	}, [item]);

	return (
		<Box
			className='phone-entry-phone-form-wrapper'
			sx={{
				display: hideRow.includes(item.phone_number_id) ? 'none' : 'flex',
				justifyContent: 'space-between',
				gap: '16px',
				marginBottom: '24px',
			}}
		>
			<Box sx={{ width: '200px' }}>
				<FormControl fullWidth>
					<InputLabel id='select-label'>Type</InputLabel>
					<Select
						labelId='select-label'
						id='controlled-select'
						value={typeState}
						label='Type'
						onChange={(ele) => handleChange('type', ele.target.value)}
					>
						<MenuItem value={0}>-- Choose Type</MenuItem>
						{typesList.map((item) => (
							<MenuItem key={item.type_list_id} value={item.type_list_id}>
								{item.type_description}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>

			<Box sx={{ width: '200px' }}>
				<InputMask
					mask='(999) 999-9999'
					value={numberState}
					onChange={(ele) => handleChange('number', ele.target.value)}
				>
					{(inputProps) => <TextField {...inputProps} label='Phone Number' variant='outlined' fullWidth />}
				</InputMask>
				{/* <TextField
					fullWidth
					label='Phone Number'
					type='text'
					value={numberState}
					InputLabelProps={{
						shrink: true,
					}}
					onChange={(ele) => handleChange('number', ele.target.value)}
				/> */}
			</Box>

			<TextField
				sx={{ flex: 1 }}
				fullWidth
				label='Description'
				type='text'
				value={descState}
				InputLabelProps={{
					shrink: true,
				}}
				onChange={(ele) => handleChange('desc', ele.target.value)}
			/>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				{saveButtonState ? (
					<>
						<IconButton onClick={() => handleSaveButtonClick()}>
							<Save color='success' />
						</IconButton>
						<Box>|</Box>
					</>
				) : (
					<>
						<IconButton>
							<Save sx={{ color: '#e7e7e7' }} />
						</IconButton>
						<Box>|</Box>
					</>
				)}

				<DeletePhoneNumberFromContact
					phone_number_id={item.phone_number_id}
					removeDeletedPhoneNumber={removeDeletedPhoneNumber}
				/>
			</Box>
			<SnackBarAlert doOpen={snackbarOpen} ResetOpen={ResetOpen} message='Phone Data Saved!' />
		</Box>
	);
};

export default PhoneEntryForm;
