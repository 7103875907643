import { Icon } from '@mui/material';
import React from 'react';

const IconFinder = ({ name }) => {
  if (!name) {
    return <Icon fontSize='small'>clear_all</Icon>;
  }
  return <Icon fontSize='small'>{name}</Icon>;
};

export default IconFinder;
