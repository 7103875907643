import {
  Grid,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  Button,
  Divider,
  Box,
  CircularProgress, 
  Backdrop  
} from '@mui/material';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';
import axios from 'axios';
import React, { useState, useEffect } from 'react';



const LookupLabelPrintingForm = () => {

  // State For Form Entry
  const [printer, setPrinter] = useState(''); // set for selecting a printer
  const [printResult, setPrintResult] = useState([]); // dynamic response for building printer list
  const [label, setLabel] = useState(''); // set for selecting a printer
  const [labelResult, setLabelResult] = useState([]); // dynamic response for building label list
  const [itemNumber, setItemNumber] = useState(''); 
  const [serialNumber, setSerialNumber] = useState('');
  const [searchResult, setSearchResult] = useState('');
  const [searchResultMessage, setSearchResultMessage] = useState(''); 
  const [printQuantity, setPrintQuantity] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Loading state
  
  useEffect(() => {
    setIsLoading(true);
    axios
    .post('/hubRelay', 
    { 
      j: "/webservice/hub/helpdesk_utilities/printing/label_print_relay.php?WHAT_CALL=DATA" 
    })
    .then((response) => {
      console.log(response.data);
      
      setPrintResult(response.data.PRINTERS);
      setLabelResult(response.data.LABELS);
      setIsLoading(false);
      
    })
    .catch((error) => {
      console.log(error);
    });

  }, []);


  // Handle Submit For Item Lookup
  const handleSubmit = () => {
    
    const parameters = "&PRINTER_HANDLE="+printer+"&LABEL_FORMAT="+label+"&PRINT_QTY="+printQuantity+"&ITEM_NUMBER="+itemNumber+"&SERIAL_NUMBER="+serialNumber;
    console.log(parameters);
    setIsLoading(true);  
    axios
    .post('/hubRelay', {
      j: "/webservice/hub/helpdesk_utilities/printing/label_print_relay.php?WHAT_CALL=SERVICE"+ parameters
    })
    .then((response) => {
      console.log(response.data);
      setSearchResult(response.data.RESULT);
      setSearchResultMessage('SUCCESS --> LABELS PRINTING MOMENTARILY');
      setIsLoading(false);
      
      setTimeout(function () {
        console.log('hit timeout');
        setPrinter(null);
        setLabel(null);
        setPrintQuantity('');
        setItemNumber('');
        setSerialNumber('');
        setSearchResult('');
        setSearchResultMessage('');
      }, 3000);

    })
    .catch((error) => {
      console.log(error);
    });
        
  };

  const handleRefresh = () => {
    setPrinter(null);
    setLabel(null);
    setPrintQuantity('');
    setItemNumber('');
    setSerialNumber('');
    setSearchResult('');
    setSearchResultMessage('');
  };

  return (
    
    <Grid container spacing={5} alignItems='center' sx={{ p: 6 }}>
      <Grid item xs={3} style={{paddingLeft: '20px',paddingTop: '20px'}}>
        <Typography variant='h6' sx={{textAlign: 'left'}} color="error">PRINTER</Typography>
      </Grid>
      <Grid item xs={9} style={{paddingLeft: '20px',paddingTop: '20px'}}></Grid>
      <Grid item xs={4} style={{paddingLeft: '20px',paddingTop: '10px'}}>
        <FormControl fullWidth={true}>            
          <Select labelId="select_printer" value={printer} onChange={(e) => setPrinter(e.target.value)}>
            <MenuItem value={null}>CLEAR</MenuItem>              
            {Object.entries(printResult).map(([key, detail]) => (                                
              <MenuItem key={key} value={detail.PRINTER_HANDLE}>
              {detail.DISPLAY_NAME}
              </MenuItem>                
            ))}
          </Select>            
        </FormControl>
      </Grid>
      <Grid item xs={8} style={{paddingLeft: '20px',paddingTop: '20px'}}></Grid>
      
      <Grid item xs={5} style={{paddingLeft: '20px',paddingTop: '20px'}}>
        <Typography variant='h6' sx={{textAlign: 'left'}} color="primary">LABEL FORMAT</Typography>
      </Grid>
      <Grid item xs={7} style={{paddingLeft: '20px',paddingTop: '20px'}}></Grid>
      <Grid item xs={6} style={{paddingLeft: '20px',paddingTop: '10px'}}>
        <FormControl fullWidth={true}>            
          <Select labelId="select_label" value={label} onChange={(e) => setLabel(e.target.value)}>
            <MenuItem value={null}>CLEAR</MenuItem>              
            {Object.entries(labelResult).map(([key, detail]) => (                                
              <MenuItem key={key} value={detail.PRINT_CODE}>
              {detail.DISPLAY_NAME}
              </MenuItem>                
            ))}
          </Select>            
        </FormControl>
      </Grid>
      <Grid item xs={6} style={{paddingLeft: '20px',paddingTop: '20px'}}></Grid>
      
      {label === "GUN_SERIAL_LABEL" && 
        <>
        <Grid item xs={4} style={{paddingLeft: '20px',paddingTop: '20px'}}>
          <Typography variant='h6' sx={{textAlign: 'left', color: 'slategrey'}}>ITEM #</Typography>
        </Grid>
        <Grid item xs={4} style={{paddingLeft: '20px',paddingTop: '20px'}}>
          <Typography variant='h6' sx={{textAlign: 'left', color: 'slategrey'}}>SERIAL #</Typography>
        </Grid>
        <Grid item xs={4} style={{paddingLeft: '20px',paddingTop: '20px'}}></Grid>

        <Grid item xs={4} style={{paddingLeft: '20px',paddingTop: '10px'}}>
          <FormControl fullWidth={true}>
            <TextField
              value={itemNumber}            
              onChange={(e) => setItemNumber(e.target.value)}
            />
          </FormControl>
        </Grid>      
        <Grid item xs={4} style={{paddingLeft: '20px',paddingTop: '10px'}}>
          <FormControl fullWidth={true}>
            <TextField
              value={serialNumber}                          
              onChange={(e) => setSerialNumber(e.target.value)}               
            />
          </FormControl>
        </Grid>      
        <Grid item xs={4} style={{paddingLeft: '20px',paddingTop: '20px'}} sx={{textAlign: 'left'}}></Grid>
        </>
      }

      <Grid item xs={4} style={{paddingLeft: '20px',paddingTop: '20px'}}>
        <Typography variant='h6' sx={{textAlign: 'left', color: '#28a745'}}>QUANTITY</Typography>
      </Grid>
      <Grid item xs={8} style={{paddingLeft: '20px',paddingTop: '20px'}} sx={{textAlign: 'left'}}></Grid>
      <Grid item xs={4} style={{paddingLeft: '20px',paddingTop: '10px'}}>
        <FormControl fullWidth={true}>
          <TextField
            value={printQuantity}            
            onChange={(e) => setPrintQuantity(e.target.value)}
            label="MAX 500"
          />
        </FormControl>
      </Grid>      
      <Grid item xs={8} style={{paddingLeft: '20px',paddingTop: '20px'}} sx={{textAlign: 'left'}}></Grid>

      <Grid item xs={2} style={{paddingLeft: '20px'}}>
        <Button variant='contained' color='primary' onClick={handleSubmit}>
          Submit
        </Button>        
      </Grid>
      <Grid item xs={2} style={{paddingLeft: '20px'}}>        
        <Button variant='contained' color='error' onClick={handleRefresh}>
          Clear
        </Button>
      </Grid>
      <Grid item xs={8} style={{paddingLeft: '20px',paddingTop: '20px'}} sx={{textAlign: 'left'}}></Grid>

      <Grid item xs={12} style={{paddingLeft: '20px',paddingTop: '20px'}} sx={{textAlign: 'center'}}>
        <Typography 
          variant='span' 
          fontSize='small' 
          style={{color: searchResult === "OK" ? "#198754" : "#dc3545"}}
        >{searchResultMessage}
        </Typography>
      </Grid>      

      {isLoading && (
        <Backdrop
          open={isLoading}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}
        >
          <CircularProgress sx={{color: '#0FFF00'}} />
        </Backdrop>
      )}
    </Grid>
    
    
  );
};

export default LookupLabelPrintingForm;
