import React, { useContext } from 'react';
import { EntityContactContextState } from '../../../../../Context/Marketing/EntityContactContext';
import { Box, useTheme } from '@mui/material';

const ContactAddress = ({ street, city, state, zip }) => {
	// INIT's
	const theme = useTheme();

	// CONTEXT STATE
	const { allStates } = useContext(EntityContactContextState);

	const Content = () => {
		const handleRedirect = () => {
			const url = `https://www.google.com/maps/search/?api=1&query=${street},${city}%20${
				state ? allStates.find((s) => s.state_id === state).abbreviation : 'Undefined'
			}%20${zip}%20United%20States`;
			window.open(url, '_blank');
		};

		return (
			<Box
				sx={{
					borderRadius: '16px',
					p: '8px',
					cursor: 'pointer',
					'&:hover': {
						color: theme.palette.primary.alt,
						textDecoration: 'underline',
					},
				}}
				onClick={() => handleRedirect()}
			>
				<div>{street}</div>
				<div>{`${city}, ${
					state ? allStates.find((s) => s.state_id === state).abbreviation : 'Undefined'
				}, ${zip}`}</div>
			</Box>
		);
	};

	return <>{street === undefined || street === '' || street === null ? '---' : <Content />}</>;
};

export default ContactAddress;
//https://www.google.com/maps/search/?api=1&query=101%20Robert%20G%20Harris%20Drive%20%20Shreveport%20LA%2071115%20United%20States
