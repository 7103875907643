import { Card, Grid, CardActionArea } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import UnbusyOrder from './UnbusyOrder/UnbusyOrder';


const Orders = () => {
  const [isUnbusyOrderOpen, setIsUnbusyOrderOpen ] = useState(false);
  const userPermission = useSelector((state) => state.userPermission.value);
  
  return (
    <Grid container spacing={2}>
      {userPermission[956] && <Grid item xs={3}>
        <CardActionArea>
          <Card onClick={() => setIsUnbusyOrderOpen(true)} sx={{ pt:3,pb:3,pl:1,pr:1, textAlign:'center' }}>UNBUSY ORDER</Card>
        </CardActionArea>
      </Grid>}
      
      <UnbusyOrder
        open={isUnbusyOrderOpen}
        onClose={() => setIsUnbusyOrderOpen(false)}
      />      
    </Grid>
  );
};

export default Orders;