import React, { useContext, useEffect, useState } from 'react';
import { PublicationContexV2tState } from '../../../../../../Context/Marketing/PublicationContextV2';
import { Box, Divider, FormControlLabel, FormGroup, MenuItem, Switch, TextField, useTheme } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dayjs from 'dayjs';
import SaveButton from './SaveButton';

const Form = ({ publicationData }) => {
	// INIT's
	const initialData = publicationData;
	const theme = useTheme();

	// CONTEXT STATE
	const { allPublicationTypes } = useContext(PublicationContexV2tState);

	// LOCAL STATE
	const [loading, setLoading] = useState(true);
	const [formData, setFormData] = useState(initialData);

	// FUNCTIONS
	const handleTextChange = (e) => {
		const { name, value } = e.target;
		console.log('name: ' + name + ' | value: ' + value);
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleDateChange = (name, newDate) => {
		setFormData((prev) => ({ ...prev, [name]: newDate }));
	};

	const handleIsActiveChange = (e) => {
		console.log('checked: ', e);
		e.target.checked
			? setFormData((prev) => ({ ...prev, inactive: 0 }))
			: setFormData((prev) => ({ ...prev, inactive: 1 }));
	};

	const handleIsIUOChange = (e) => {
		console.log('checked: ', e);
		e.target.checked
			? setFormData((prev) => ({ ...prev, internal_use_only: 1 }))
			: setFormData((prev) => ({ ...prev, internal_use_only: 0 }));
	};
	// USE EFFECT
	useEffect(() => {
		setLoading(false);
	}, []);

	if (loading) {
		return <div>Loading...</div>;
	}

	console.log('allPublicationTypes: ', allPublicationTypes);
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Box
				sx={{
					backgroundColor: 'white',
					padding: '24px',
					display: 'grid',
					gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
					gap: '24px',
					width: '100%',
					borderRadius: '24px',
				}}
			>
				<Box sx={{ gridColumn: 'span 2', fontWeight: 'bold', fontSize: '1.3em' }}>
					<span style={{ color: theme.palette.primary.main, display: 'inline-block', marginRight: '8px' }}>
						Publication:
					</span>
					{formData.ss_publication_id === 0 ? 'Create Publication' : formData.ss_publication_id}
				</Box>

				<Box sx={{ gridColumn: 'span 2', color: 'black' }}>Header Details:</Box>

				<TextField
					name='ss_publication_code'
					sx={{}}
					fullWidth
					label='Publication Code'
					value={formData.ss_publication_code}
					variant='outlined'
					InputLabelProps={{
						shrink: true,
					}}
					onChange={(e) => handleTextChange(e)}
				/>

				<TextField
					select
					name='ss_publication_type_id'
					sx={{}}
					fullWidth
					label='Publication Type'
					value={formData.ss_publication_type_id}
					variant='outlined'
					InputLabelProps={{
						shrink: true,
					}}
					onChange={(e) => handleTextChange(e)}
				>
					{allPublicationTypes.map((type) => (
						<MenuItem key={type.ss_publication_type_id} value={type.ss_publication_type_id}>
							{type.description}
						</MenuItem>
					))}
				</TextField>

				<Box sx={{ gridColumn: 'span 2', color: 'black' }}>Descriptions:</Box>

				<TextField
					name='description'
					sx={{ gridColumn: 'span 2' }}
					fullWidth
					label='Title'
					value={formData.description}
					variant='outlined'
					InputLabelProps={{
						shrink: true,
					}}
					onChange={(e) => handleTextChange(e)}
				/>

				<TextField
					name='detailed_description'
					sx={{ gridColumn: 'span 2' }}
					fullWidth
					label='Description'
					value={formData.detailed_description}
					multiline
					rows={2}
					variant='outlined'
					InputLabelProps={{
						shrink: true,
					}}
					onChange={(e) => handleTextChange(e)}
				/>
				<Box sx={{ gridColumn: 'span 2', color: 'black' }}>Media:</Box>
				<TextField
					name='flyer_url'
					sx={{ gridColumn: 'span 2' }}
					fullWidth
					label='Flyer URL'
					value={formData.flyer_url}
					variant='outlined'
					InputLabelProps={{
						shrink: true,
					}}
					onChange={(e) => handleTextChange(e)}
				/>
				<Box sx={{ gridColumn: 'span 2', color: 'black' }}>Dates:</Box>

				<DatePicker
					name='submit_contract_date'
					label='Contract Due'
					value={Dayjs(formData.submit_contract_date)}
					onChange={(e) => handleDateChange('submit_contract_date', e)}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							variant='outlined'
							InputLabelProps={{
								shrink: true,
							}}
						/>
					)}
				/>

				<DatePicker
					name='submit_art_date'
					label='Art Due'
					value={Dayjs(formData.submit_art_date)}
					onChange={(e) => handleDateChange('submit_art_date', e)}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							variant='outlined'
							InputLabelProps={{
								shrink: true,
							}}
						/>
					)}
				/>

				<DatePicker
					name='production_start_date'
					label='Production Begins'
					value={Dayjs(formData.production_start_date)}
					onChange={(e) => handleDateChange('production_start_date', e)}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							variant='outlined'
							InputLabelProps={{
								shrink: true,
							}}
						/>
					)}
				/>

				<DatePicker
					name='start_date'
					label='Start Date'
					value={Dayjs(formData.start_date)}
					onChange={(e) => handleDateChange('start_date', e)}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							variant='outlined'
							InputLabelProps={{
								shrink: true,
							}}
						/>
					)}
				/>

				<DatePicker
					name='expiration_date'
					label='Expiration Date'
					value={Dayjs(formData.expiration_date)}
					onChange={(e) => handleDateChange('expiration_date', e)}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							variant='outlined'
							InputLabelProps={{
								shrink: true,
							}}
						/>
					)}
				/>

				<FormGroup>
					<FormControlLabel
						control={
							<Switch
								color='success'
								checked={!formData.inactive}
								onChange={(e) => handleIsActiveChange(e)}
								inputProps={{ 'aria-label': 'controlled' }}
							/>
						}
						label='Active'
					/>
					<FormControlLabel
						sx={{ '& .MuiFormControlLabel-label': { fontWeight: 'bold' } }}
						control={
							<Switch
								color='success'
								checked={formData.internal_use_only}
								onChange={(e) => handleIsIUOChange(e)}
								inputProps={{ 'aria-label': 'controlled' }}
							/>
						}
						label='Internal Use Only'
					/>
				</FormGroup>

				<Divider sx={{ gridColumn: 'span 2', borderBottomWidth: '3px' }} />
				<SaveButton formData={formData} />
				{/* <Box
					sx={{
						gridColumn: 'span 2',
						position: 'relative',
						overflow: 'hidden',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: 'yellow',
						transition: '2000ms ease-in-out',
						transform:`scale(${showSaveMessage ? 1 : 0})`
					}}
				>
					<Box
						sx={{
							width: '100%',
							color: theme.palette.success.main,
							textAlign: 'center',
							fontWeight: 'bold',
						}}
					>
						Publication Updated!
					</Box>
				</Box>

				<Box
					sx={{
						gridColumn: 'span 2',
						textAlign: 'right',
						borderRadius: '4px',
						backgroundColor: 'rgba(100,100,150,.1)',
						padding: '16px',
					}}
				>
					<SaveButton data={formData} />
				</Box> */}
			</Box>
		</LocalizationProvider>
	);
};

export default Form;
