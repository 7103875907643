import React, { useContext } from 'react';
import { Button } from '@mui/material';
import { PublicationContextState } from '../../../Context/Marketing/PublicationContext';
import { ControlPoint } from '@mui/icons-material';

const CreatePublicationButton = () => {
	const { updateSelectedPublicationId, updatePublicationDrawerOpener } = useContext(PublicationContextState);

	const handleClick = () => {
		updateSelectedPublicationId(0);
		updatePublicationDrawerOpener(true);
	};

	return (
		<Button
			sx={{ fontWeight: 'bold' }}
			variant='contained'
			color='success'
			onClick={() => handleClick()}
			endIcon={<ControlPoint />}
			size='small'
		>
			Add Publication
		</Button>
	);
};

export default CreatePublicationButton;
