import { Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import React from 'react';

const MarketingLayoutMenuItem = ({ item }) => {
	const navigate = useNavigate();
	const location = useLocation();
	return (
		<Button
			variant={location.pathname === item.path ? 'contained' : 'outlined'}
			onClick={() => navigate(item.path)}
		>
			{item.label}
		</Button>
	);
};

export default MarketingLayoutMenuItem;
