import {
  Grid,
  TextField,
  Typography,
  FormControl,
  Button,
  CircularProgress, 
  Backdrop,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Paper 
} from '@mui/material';
import axios from 'axios';
import { DatePicker } from "@mui/x-date-pickers";

import React, { useState, useEffect } from 'react';

const WebPaymentReceiptsForm = () => {  
  // onchange states
  const [search, setSearch] = useState('');
  const [customerNumber, setCustomerNumber] = useState('');
  const [toDate, setToDate] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [iframeSrc, setIframeSrc] = useState('');
  
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableHeight, setTableHeight] = useState('50vh');

  const [selectedRow, setSelectedRow] = useState(0);
  const handleSelectRow = (rowIndex) => { setSelectedRow(rowIndex); }

  // Loading state
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {    
    setIsLoading(true);
      axios
      .post('/hubRelay', {
        j: '/webservice/hub/helpdesk_utilities/payments/get_web_payments.php'
      })
      .then((response) => {
        // handle the response
        console.log(response.data);
        setTableData(response.data.PAYMENTS);
        setShowTable(true);
        setIsLoading(false);
      })
      .catch((error) => {
        // handle errors
        console.log(error);
        setIsLoading(false);
      });

  }, []);

  const handleViewReceipt = (e) => {         
    setIsLoading(true);
    axios
      .post('/hubRelay', {
        j: e.target.value
      })
      .then((response) => {
        // handle the response
        console.log(response.data);
        setIframeSrc(response.data.ADDRESS);
        setTableHeight('35vh');
        setIsLoading(false);
      })
      .catch((error) => {
        // handle errors
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleSubmit = (e) => {     
    let newToDate;
    let newFromDate;
    
    !toDate ? newToDate = '' : newToDate = toDate.$d.toISOString();
    !fromDate ? newFromDate = '' : newFromDate = fromDate.$d.toISOString();
    setIsLoading(true);
    axios
      .post('/hubRelay', {
        j: '/webservice/hub/helpdesk_utilities/payments/get_web_payments.php?BEGDATE='+newFromDate+'&ENDDATE='+newToDate+'&CUSTOMER_NUMBER='+customerNumber
      })
      .then((response) => {
        // handle the response
        console.log(response.data);
        setTableData(response.data.PAYMENTS);
        setIsLoading(false);
      })
      .catch((error) => {
        // handle errors
        console.log(error);
        setIsLoading(false);
      });
  };

  return (

    <Grid container spacing={5} sx={{ p: 4}}>
      <Grid item xs={4} md={2} style={{paddingTop: '0px', marginBottom: 0}}>
        <Typography variant='h6' sx={{textAlign: 'left', mt: 1}} style={{fontSize: '1.25vw'}}>Web Payment Receipts</Typography>
      </Grid>
      <Grid item xs={4} md={2} sx={{textAlign: 'left', mt: 1}} style={{paddingTop: '0px'}}>
        <DatePicker 
          label="FROM DATE"        
          value={fromDate}
          onChange={(newFromDate) => setFromDate(newFromDate)}
          slotProps={{ field: { clearable: true } }}
        />
      </Grid>
      <Grid item xs={4} md={2} sx={{textAlign: 'left', mt: 1}} style={{paddingTop: '0px'}}>
        <DatePicker 
          label="TO DATE"
          value={toDate}
          onChange={(newToDate) => setToDate(newToDate)}
          slotProps={{ field: { clearable: true } }}
        />
      </Grid>    
      <Grid item xs={4} md={2} sx={{textAlign: 'left', mt: 1}} style={{paddingTop: '0px'}}>
        <FormControl fullWidth={false}>
          <TextField
            value={customerNumber}
            onChange={(e) => setCustomerNumber(e.target.value)}
            label="Customer #"
          />
        </FormControl>
      </Grid>
      <Grid item xs={4} md={2} style={{paddingTop: 20, paddingLeft: 10}}>
        <Button variant='contained' color='primary' onClick={handleSubmit}>
          Search
        </Button>          
      </Grid>
      <Grid item xs={4} md={2} sx={{textAlign: 'left', mt: 1}} style={{paddingTop: '0px'}}>
        <FormControl fullWidth={false}>
          <TextField
            onChange={(e) => setSearch(e.target.value)}
            label="Filter..."          
          />
        </FormControl>
      </Grid>   
      <Grid item xs={12} style={{paddingTop: '10px', marginBottom: 10, marginTop: 20}}>
        {showTable &&
          <Paper sx={{ width: '100%', overflowX: 'initial' }}>
            <TableContainer sx={{ maxHeight: tableHeight }}>          
              <Table stickyHeader size="small" aria-label="a dense table">
                <TableHead>                  
                    <TableRow selected={selectedRow === 0} onClick={() => handleSelectRow(0)}>
                      <TableCell style={{backgroundColor: '#cb5935', color: 'white'}} component="th" scope="row">WEB PMT ID</TableCell>
                      <TableCell style={{backgroundColor: '#cb5935', color: 'white'}} component="th" scope="row">METHOD</TableCell>
                      <TableCell style={{backgroundColor: '#cb5935', color: 'white'}} component="th" scope="row">CUSTOMER #</TableCell>
                      <TableCell style={{backgroundColor: '#cb5935', color: 'white'}} component="th" scope="row">CUSTOMER NAME</TableCell>
                      <TableCell style={{backgroundColor: '#cb5935', color: 'white'}} component="th" scope="row">PAYMENT TOTAL</TableCell>
                      <TableCell style={{backgroundColor: '#cb5935', color: 'white'}} component="th" scope="row">FEE</TableCell>
                      <TableCell style={{backgroundColor: '#cb5935', color: 'white'}} component="th" scope="row">PAY TIME</TableCell>
                      <TableCell style={{backgroundColor: '#cb5935', color: 'white'}} component="th" scope="row">VIEW RECEIPT</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>                                        
                  {tableData.filter((rowData) => {
                    if(search.toLowerCase() === '') {
                      return rowData;
                    } else if(rowData.WEB_PAYMENT_ID.toLowerCase().includes(search)){
                      return rowData;                      
                    } else if(rowData.METHOD.toLowerCase().includes(search)){
                      return rowData;                      
                    } else if(rowData.CUSTOMER_NUMBER.toLowerCase().includes(search)){
                      return rowData;                      
                    } else if(rowData.CUSTOMER_NAME.toLowerCase().includes(search)){
                      return rowData;                      
                    } else if(rowData.PAYMENT_TOTAL_FORMATTED.toLowerCase().includes(search)){
                      return rowData;                      
                    } else if(rowData.FEE.toLowerCase().includes(search)){
                      return rowData;                      
                    } else if(rowData.PAY_TIME.toLowerCase().includes(search)){
                      return rowData;                      
                    }
                  }).map((rowData, index)=>(
                    <TableRow 
                      key={index} 
                      sx={{ '&:last-child td, &:last-child th, & td': { border: 0 }}} 
                      selected={selectedRow === index + 1} 
                      onClick={() => handleSelectRow(index + 1)}
                    >
                      {Object.keys(rowData).map((index)=>(
                        index == 'RECEIPT_LINK' ? <Button variant='contained' color='success' onClick={handleViewReceipt} value={rowData[index]} style={{marginLeft: 30, padding: '2px 10px'}}>PDF</Button> :
                        <TableCell key={index} component="td" scope="row">{rowData[index]}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>        
            </TableContainer>
          </Paper>}
      </Grid> 
      
      {iframeSrc && 
        <Box sx={{ overflowY: 'auto', width: '100%', height: '40vh', pl: '2.5em' }}>
          <Grid item xs={12} display="flex" style={{paddingTop: '5px', height: 'inherit'}}>
            <Paper sx={{ width: '100%', height: '100%'}}>
              <iframe src={iframeSrc}  width={'100%'} height={'100%'} loading='eager'></iframe>
            </Paper>          
          </Grid>
        </Box>
      }

      {isLoading && (
        <Backdrop
          open={isLoading}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}
        >
          <CircularProgress sx={{color: '#0FFF00'}} />
        </Backdrop>
      )}
    </Grid>
  );
};

export default WebPaymentReceiptsForm;
