import { 
    Accordion, 
    AccordionSummary, 
    AccordionDetails, 
    Typography, 
    Autocomplete, 
    TextField, 
    FormGroup, 
    FormControlLabel, 
    Button 
} from "@mui/material"
import { CheckBox, ExpandMore } from "@mui/icons-material"
import { useEffect, useRef, useState } from "react"
import axios from "axios"

export default function PDMBatch(props){
    const [allFacets, setAllFacets] = useState([{'label' : 'Loading', 'id' : 0}])
    const [hasValues, setHasValues] = useState(false)
    const [facetOptions, setFacetOptions] = useState([])
    const [categoryOptions, setCategoryOptions] = useState([])

    /*
        Need backend for Models, Brands, Departments, Categories and Category groups
    */

    const departmentOptions = [
        {'label': 'Accessories'},
        {'label': 'Ammo'},
        {'label': 'Firearms'},
        {'label': 'Gear and Supplies'},
        {'label': 'Optics'}
    ]

      const categoryGroupOptions = [
        { "label": "Assault Rifle" },
        { "label": "Shotgun" },
        { "label": "Pistol" },
        { "label": "Sniper Rifle" },
        { "label": "Revolver" },
        { "label": "Submachine Gun" },
        { "label": "Handgun" },
        { "label": "Scope" },
        { "label": "Suppressor" },
        { "label": "Holster" },
        { "label": "Ammunition" },
        { "label": "Gun Case" },
        { "label": "Red Dot Sight" },
        { "label": "Bipod" },
        { "label": "Tactical Light" },
        { "label": "Grip" },
        { "label": "Gun Safe" },
        { "label": "Foregrip" },
        { "label": "Laser Sight" },
        { "label": "Gun Cleaning Kit" }
      ]
      
      const brandOptions = [
        { "label": "Smith & Wesson" },
        { "label": "Glock" },
        { "label": "Colt" },
        { "label": "Sig Sauer" },
        { "label": "Ruger" },
        { "label": "Beretta" },
        { "label": "Remington" },
        { "label": "Winchester" },
        { "label": "Browning" },
        { "label": "Springfield Armory" },
        { "label": "Heckler & Koch" },
        { "label": "FN Herstal" },
        { "label": "CZ-USA" },
        { "label": "Mossberg" },
        { "label": "Benelli" },
        { "label": "Taurus" },
        { "label": "Kimber" },
        { "label": "Smith & Wesson" },
        { "label": "Colt" },
        { "label": "Walther" }
      ]
      
      const modelOptions = [
        { "label": "M&P Shield" },
        { "label": "Glock 19" },
        { "label": "1911" },
        { "label": "P226" },
        { "label": "Ruger 10/22" },
        { "label": "PX4 Storm" },
        { "label": "870 Express" },
        { "label": "Model 70" },
        { "label": "Buck Mark" },
        { "label": "XD-S" },
        { "label": "VP9" },
        { "label": "SCAR 17" },
        { "label": "P-09" },
        { "label": "MVP Patrol" },
        { "label": "Super Black Eagle" },
        { "label": "Judge" },
        { "label": "Micro 9" },
        { "label": "M&P 15" },
        { "label": "686" },
        { "label": "PPQ" },
        { "label": "Mossberg 500" },
        { "label": "Nova Tactical" },
        { "label": "Raging Bull" },
        { "label": "P320" },
        { "label": "FNX-45" },
        { "label": "CZ 75" },
        { "label": "590A1" },
        { "label": "Super Redhawk" },
        { "label": "P99" }
      ]

      useEffect(() => {
        const getAllActiveFacets = () => {
            axios.get("/pdm/getAllActiveFacets"
            ).then((res) => {
                setAllFacets(res.data)
            }).catch((err) => {
                console.log(err)
            })
        }
        const getCategories = () => {
            axios.get('/pdm/getAllActiveCategories')
            .then((res) => {
                setCategoryOptions(res.data)
            }).catch((err) => {
                console.log(err)
            })
        } 
        getCategories()
        getAllActiveFacets()
      }, []) 

      const getFacetValues = async (id) => {
        await axios.get("/pdm/getAllValueForAttribute/" + id)
        .then((res) => {
            console.log(id)
            console.log(res.data)
            setFacetOptions(res.data)
            toggleHasValues()
        })
        .catch((err) => {
            console.log(err)
        })
      }

      const toggleHasValues = () => {
        if(facetOptions.length != 1){
            
        }
        console.log('Toggling')
        setHasValues(!hasValues)
      }

    return(
        <div style={{width: '350px'}}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                >
                    <Typography>
                        Categories
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    
                    <Autocomplete
                        sx={{padding:3}}
                        disableClearable
                        options={departmentOptions}
                        isOptionEqualToValue={(option, newValue) => option.label === newValue.label}
                        onChange={(event, val) => {
                            console.log(val)
                        }}
                        renderInput={(params) => <TextField {...params} label="Departments"/>}
                    />
                    
                    <Autocomplete
                        sx={{padding:3}}
                        options={categoryOptions}
                        isOptionEqualToValue={(option, newValue) => option.label === newValue.label}
                        onChange={(event, val) => {
                            console.log(val)
                        }}
                        renderInput={(params) => <TextField {...params} label="Categories"/>}
                    />
                    
                    <Autocomplete
                        sx={{padding:3}}
                        disableClearable
                        options={categoryGroupOptions}
                        isOptionEqualToValue={(option, newValue) => option.label === newValue.label}
                        onChange={(event, val) => {
                            console.log(val)
                        }}
                        renderInput={(params) => <TextField {...params} label="Category Group"/>}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                >
                    <Typography>
                        Brands
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Autocomplete
                        sx={{padding:3}}
                        options={brandOptions}
                        isOptionEqualToValue={(option, newValue) => option.label === newValue.label}
                        onChange={(event, val) => {
                            console.log(val)
                        }}
                        renderInput={(params) => <TextField {...params} label="Brands"/>}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                >
                    <Typography>
                        Attributes
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Autocomplete
                        sx={{padding:3}}
                        options={categoryOptions}
                        isOptionEqualToValue={(option, newValue) => option.label === newValue.label}
                        onChange={(event, val) => {
                            console.log(val)
                        }}
                        renderInput={(params) => <TextField {...params} label="Categories"/>}
                    />
                <Autocomplete
                        sx={{padding:3}}
                        disableClearable
                        options={allFacets}
                        isOptionEqualToValue={(option, newValue) => option.label === newValue.label}
                        onChange={(event, val, reason) => {
                            toggleHasValues()
                            console.log(val)
                            if(reason === 'selectOption'){
                                getFacetValues(val.id)
                            }
                        }}
                        renderInput={(params) => <TextField {...params} label="Attributes"/>}
                    />
                <Autocomplete
                        sx={{padding:3}}
                        disableClearable
                        disabled={!hasValues}
                        options={facetOptions}
                        isOptionEqualToValue={(option, newValue) => option.label === newValue.label}
                        onChange={(event, val) => {
                            console.log(val)
                            
                        }}
                        renderInput={(params) => <TextField {...params} label="Options"/>}
                    />    

                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                >
                    <Typography>
                        Model
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Autocomplete
                        sx={{padding:3}}
                        options={modelOptions}
                        isOptionEqualToValue={(option, newValue) => option.label === newValue.label}
                        onChange={(event, val) => {
                            console.log(val)
                            
                        }}
                        renderInput={(params) => <TextField {...params} label="Models"/>}
                    />    
                    
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                >
                    <Typography>
                        Series
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    
                </AccordionDetails>
            </Accordion>
            <FormGroup sx={{paddingLeft:3, paddingTop:2}}>
                <FormControlLabel control={<CheckBox />} label="Deselect Items in Results Panel"/>
            </FormGroup>
            <Button sx={{marginLeft:2, marginTop:2}}variant="contained">Confirm</Button>
        </div>
    )
}